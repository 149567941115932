<template>
	<div class="modal-signup-success">
		<div class="bg"></div>
		<div class="content">

			<div class="success-animation">
				<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
			</div>

			<div class="texts-wrap">
				<transition name="texts">
					<div v-show="contentVisible" class="texts">
						<p class="title">{{ $t('login.formSignupSuccessTitle') }}</p>

						<p>{{ $t('login.formSignupSuccessUsernameLabel') }}</p>
						<div class="username">{{ username }}</div>
						<p>{{ $t('login.formSignupSuccessInfo') }}</p>

						<div class="buttons">
							<ui-button size="big" color="orange" v-tap="close">{{ $t('login.formSignupSuccessButton') }}</ui-button>
						</div>
					</div>
				</transition>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	name: 'modal-signup-success',
	data() {
		return {
			contentVisible: false
		}
	},
	props: {
		username: { default: '' }
	},
	methods: {
		close() {
			if (this.contentVisible) {
				this.$emit('close')
			}
		},
	},
	mounted() {
		setTimeout(() => {
			this.contentVisible = true
		}, 1250)
	},
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.modal-signup-success
	absolute 0
	flex center center
	height 100%
	padding 80px 16px
	z-index 100000
	.bg
		absolute 0
		background-color alpha(#C58B62, 80%)
	.content
		width 100%
		max-width 500px
		padding 32px
		border-radius 8px
		background-color #fff
		text-align center
		.success-animation
			margin 0 0 32px 0
			.checkmark
				width 96px
				height 96px
				border-radius 50%
				display block
				stroke-width 2
				stroke #4bb71b
				stroke-miterlimit 10
				box-shadow inset 0px 0px 0px #4bb71b
				animation fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
				top 5px
				right 5px
				margin 0 auto
			.checkmark__circle
				stroke-dasharray 166
				stroke-dashoffset 166
				stroke-width 2
				stroke-miterlimit 10
				stroke #4bb71b
				fill #fff
				animation stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
			.checkmark__check
				transform-origin 50% 50%
				stroke-dasharray 48
				stroke-dashoffset 48
				animation stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
			@keyframes stroke
				100%
					stroke-dashoffset 0
			@keyframes scale
				0%, 100%
					transform none
				50%
					transform scale3d(1.1, 1.1, 1)
			@keyframes fill
				100%
					box-shadow inset 0px 0px 0px 30px #4bb71b

		.texts-wrap
			min-height 277px
			.texts
				&.texts-enter-active
					transition 0.25s easeOutQuart
				&.texts-enter-from
					opacity 0
					transform translateY(-16px)
				.title
					margin 0 0 32px 0
					color #4bb71b
					text-transform uppercase
					font-weight 500
					font-size 2.4rem
				p
					margin 0 0 8px 0
				.username
					margin 0 0 16px 0
					background-color #eee
					padding 16px
					border-radius 4px
					font-weight 500
					font-size 2rem


				.buttons
					padding-top 16px
					flex center center
					.ui-button
						min-width 200px

</style>
