<template>
	<div class="modal-add">

		<form-add :type="modal.type" :modal="modal" @close="$emit('close')" />

		<template v-if="isAddedByInvitation">
			<p class="info">{{ $t('adminDashboard.modals.add.emailImportant') }}</p>
		</template>

	</div>
</template>

<script>
import FormAdd from '@/components/admin-dashboard/modals/forms/form-add.vue'

export default {
	props: {
		modal: { default: null }
	},
	computed: {
		isAddedByInvitation() {
			return [ 'district', 'school', 'teacher' ].includes(this.modal.type)
		},
	},
	components: { FormAdd }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


</style>