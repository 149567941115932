<template>
	<div v-if="role" class="dashboard" :class="[ $route.meta?.type, { 'parts-3': role === 'vireo' } ]">

		<div class="part left">
			<div class="part-bg"><div class="image-bg" v-bg="'login/plant-green.svg'"></div></div>
			<div v-if="$route.name === 'dashboard'" class="part-content">
				<div class="part-intro">
					<div class="title">{{ $t('dashboard.registeredTeachers') }}</div>
					<p class="description">{{ $t('dashboard.registeredTeachersDescription') }}</p>
					<ui-button size="big" v-tap="() => $router.push({ name: 'teachers' })">{{ $t('dashboard.view') }}</ui-button>
				</div>
			</div>
			<router-view v-slot="{ Component }">
				<transition name="route" mode="out-in">
					<component v-if="$route.meta?.type === 'teachers'" :is="Component" :key="$route.name" />
				</transition>
			</router-view>
		</div>

		<div v-if="role === 'vireo'" class="part middle">
			<div class="part-bg"><div class="image" v-bg="'notepad/main-bg.svg'"></div></div>
			<div v-if="$route.name === 'dashboard'" class="part-content">
				<div class="part-intro">
					<div class="title">{{ $t('dashboard.schoolOverview') }}</div>
					<p class="description">{{ $t('dashboard.schoolOverviewDescription') }}</p>
					<ui-button size="big" v-tap="() => $router.push({ name: 'schools' })">{{ $t('dashboard.view') }}</ui-button>
				</div>
			</div>
			<router-view v-slot="{ Component }">
				<transition name="route" mode="out-in">
					<component v-if="$route.meta?.type === 'schools'" :is="Component" :key="$route.name" />
				</transition>
			</router-view>
		</div>

		<div class="part right">
			<div class="part-bg"><div class="image" v-bg="'notepad/main-bg.svg'"></div></div>
			<div v-if="$route.name === 'dashboard'" class="part-content">
				<div class="part-intro">
					<div class="title">{{ $t('dashboard.groupOverview') }}</div>
					<p class="description">{{ $t('dashboard.groupOverviewDescription') }}</p>
					<ui-button size="big" v-tap="() => $router.push({ name: 'groups' })">{{ $t('dashboard.view') }}</ui-button>
				</div>
			</div>
			<router-view v-slot="{ Component }">
				<transition name="route" mode="out-in">
					<component v-if="$route.meta?.type === 'groups'" :is="Component" />
				</transition>
			</router-view>
		</div>

		<transition name="bar-rdv">
			<div v-if="role === 'school' && $route.name === 'dashboard'" class="bar-rdv">
				<p>
					{{ $t('dashboard.rdvSentence') }}
					<span class="link-hubspot" @click="openHubspotLink">{{ $t('dashboard.rdvLink') }}</span>
				</p>
			</div>
		</transition>

		<transition name="opacity">
			<div v-if="backButtonVisible" class="back-button" v-tap="back">
				<div class="icon" v-bg="'half-turn.svg'"></div>
				{{ $t('dashboard.back') }}
			</div>
		</transition>

		<transition name="mobile-bar">
			<div v-if="(device === 'mobile' || device === 'tablet-vertical') && $route.name !== 'dashboard'" class="mobile-bar">
				<div class="back" v-tap="() => $router.push({ name: 'dashboard' })">
					<div class="icon" v-bg="'half-turn.svg'"></div>
				</div>
			</div>
		</transition>

	</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useAppStore } from '@/store/app'
import { useOnboardingStore } from '@/store/onboarding'
import { wait } from '@affordancestudio/functions'

export default {
	name: 'dashboard',
	computed: {
		...mapState(useAppStore, [ 'lang', 'device', 'user', 'role', 'currentUserGroupId' ]),
		backButtonVisible() {
			return this.$route.name !== 'dashboard'
		},
	},
	watch: {
		$route() {
			this.checkRouteAuthorizationAndTutos()
		}
	},
	methods: {
		...mapActions(useOnboardingStore, [ 'startTuto', 'completeCheckpoint' ]),
		back() {
			if (this.$route.meta.backTo) this.$router.push({ name: this.$route.meta.backTo })
			else this.$router.push({ name: 'dashboard' })
		},
		checkRouteAuthorizationAndTutos() {
			if (this.$route.name === 'dashboard' && this.role === 'school') {
				setTimeout(() => {
					this.startTuto({ slug: 'dashboard_school_1' })
					this.startTuto({ slug: 'dashboard_school_3' })
				}, 1000)
			}
			if (this.$route.name === 'groups' && this.role === 'teacher') {
				setTimeout(() => {
					if (this.currentUserGroupId) {
						this.startTuto({ slug: 'dashboard_teacher_with_group' })
					} else {
						this.startTuto({ slug: 'dashboard_teacher_without_group' })
					}
				}, 1000)
			}

			if (!this.role) return this.$router.push({ name: 'home' })
			if (this.$route.meta.authorizations && this.$route.meta.type !== 'groups' && this.role === 'teacher') return this.$router.push({ name: 'groups' })
		},
		openHubspotLink() {
			const url = this.lang === 'fr'
				? 'https://meetings.hubspot.com/mathieu-coupal/faisons-connaissance'
				: 'https://meetings.hubspot.com/mathieu-coupal/get-to-know-us'
			window.open(url)
			this.completeCheckpoint({ slug: 'prenez_rdv' })
		}
	},
	created() {
		this.checkRouteAuthorizationAndTutos()
	},
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#mobile, #tablet-vertical, #tablet-horizontal
	.dashboard
		&.parts-3
			.part
				&.left
					top 0
					height 33.3333%
				&.middle
					top 33.3333%
					height 33.3333%
				&.right
					bottom 0
					height 33.3333%
					padding-bottom 56px

		&.teachers
			.part.left
				top 0
				height 100%
		&.schools
			.part.middle
				top 0
				height 100%
		&.groups
			.part.right
				bottom 0
				height 100%
		.part
			width 100%
			top auto
			bottom auto
			left 0
			right 0
			padding 32px
			transition 0.25s easeOutQuart
			overflow hidden
			&.left
				top 0
				height 50%
			&.right
				bottom 0
				height 50%
				padding-bottom 56px
			.part-bg
				absolute 0
				transform none
				.image-bg
					background-size 100% auto !important
					transform none
			.part-content
				.title
					margin 0 0 16px 0
					font-size 2.8rem
				.description
					display none

#mobile, #tablet-vertical
	.mobile-bar
		height 56px
		position absolute
		left 0
		bottom 0
		right 0
		flex center flex-end
		background-color #687E7F
		transition transform 0.25s easeOutQuart
		&.mobile-bar-enter-from, &.mobile-bar-leave-to
			transform translateY(100%)
		.back
			width 56px
			height 56px
			flex center center
			.icon
				width 32px
				height 32px
				background center center no-repeat
				transform translateY(-2px)

#mobile
	.bar-rdv
		a
			display block
	.back-button
		display none

#app > .low-perf
	.dashboard
		transition none !important
		.part, .back-button
			transition none !important
			.part-bg, .part-content
				transition none !important

.dashboard
	fixed 0
	flex center center
	height 100%
	color #EFF1F1
	background-color #677E7F
	&.route-enter-active, &.route-leave-active
		background none
		transition transform 0.5s easeInOutQuart
		.part
			transition 0.5s easeInOutQuart
		.bar-rdv
			transition 0.5s easeInOutQuart
	&.route-enter-from, &.route-leave-to
		.part
			&.left
				transform translateX(-200%) !important
			&.middle
				transform translateX(400%) !important
			&.right
				transform translateX(200%) !important
		.bar-rdv
			transform translateY(100%)
			opacity 0

	&.parts-3
		.part
			&.left
				width 33.3333%
			&.middle
				width 33.3333%
				left 33.3333%
			&.right
				width 33.3333%
				left 66.6666%

	&.teachers
		.part
			&.left
				width 100%
			&.middle
				transform translateX(210%)
			&.right
				transform translateX(100%)

	&.schools
		.part
			&.left
				transform translateX(-100%)
			&.middle
				width 120%
				left -10%
			&.right
				transform translateX(100%)

	&.groups
		.part
			&.left
				transform translateX(-100%)
			&.middle
				transform translateX(-100%)
			&.right
				left 0
				width 100%
				.part-bg
					left -52.7%

			::v-deep(.main-box) .main-box-header
				background-color #667D7E

	.part
		position absolute
		top 0
		height 100%
		flex center center
		transition 0.5s easeInOutQuart
		&.left
			left 0
			width 50%
			.part-bg
				background-color #677E7F
				right 0
				width 150%
			.part-content
				::v-deep(.ui-button)
					background-color #395F5F
					&:hover, &.active
						background-color darken(@background-color, 10%)
		&.middle
			left 33.3333%
			.part-bg
				background-color #279593
				right 0
				.image
					absolute 0
					background-size 200px auto !important
					opacity 0.15
			.part-content
				::v-deep(.ui-button)
					background-color #395F5F
					&:hover, &.active
						background-color darken(@background-color, 10%)
		&.right
			left 50%
			width 50%
			.part-bg
				background-color #395F5F
				left 2.6%
				width 150%
				.image
					absolute 0
					background-size 500px auto !important
			.part-content
				::v-deep(.ui-button)
					background-color #677E7F
					&:hover, &.active
						background-color lighten(@background-color, 10%)
		.part-bg
			position absolute
			top -100%
			bottom -100%
			width 100%
			transform rotate(15deg)
			transition 0.5s easeInOutQuart
			.image-bg
				absolute 0
				background center center no-repeat
				background-size auto 40% !important
				transform rotate(-12deg)
				opacity 0.25
		.part-content
			height 100%
			width 100%
			flex center center
			text-align center
			max-width 350px
			.part-intro
				flex center center column
				.title
					width 100%
					margin 0 0 16px 0
					font-weight 500
					font-size 4rem
					text-transform uppercase
				.description
					width 100%
					margin 0 0 24px 0
					font-size 2rem
				.ui-button
					min-width 200px
			.part-content-enter-active, .part-content-leave-active
				transition 0.25s easeInOutQuart
			.part-content-enter-from, .part-content-leave-to
				opacity 0

		::v-deep(.main-box)
			height 100%
			width 100%
			max-width 960px
			background-color #fff
			border-bottom 8px solid #3A5F5F
			.main-box-header
				background-color #3A5F5F
				height 88px
				width 100%
				padding 0 32px
				flex center space-between
				.title
					font-size 2.4rem
					text-transform uppercase
				.profile-button
					inline-flex center center
					font-size 2.4rem
					text-transform uppercase
					cursor pointer
					gap 8px
					&:hover
						opacity 0.75
					.icon
						display inline-block
						width 40px
						height 40px
			.main-box-content
				height calc(100% - 88px)
				.scrollable
					height 100%
				.inner-content
					padding 16px
					color #667D7E
					.search
						padding 0 0 16px 0
						margin 0 0 16px 0
						border-bottom 2px solid #687E7F
						flex center space-between
						.input
							display block
							width 32%
							height 32px
							padding 0 16px
							background #fff
							border 1px solid #687E7F
							border-radius 40px
							outline none
							font inherit
							color inherit

	.bar-rdv
		position absolute
		left 0
		bottom 0
		right 0
		height 64px
		padding 0 8px
		flex center center
		background-color #F5A164
		color #fff
		font-size 1.8rem
		text-align center
		display none
		&.bar-rdv-enter-active, &.bar-rdv-leave-active
			transition 0.5s easeInOutQuart
		&.bar-rdv-enter-from, &.bar-rdv-leave-to
			opacity 0
			transform translateY(100%)
		.link-hubspot
			cursor pointer
			color #104544
			text-decoration underline
			&:hover
				text-decoration none


	.back-button
		position absolute
		right 0px
		bottom 0px
		width 96px
		height 96px
		cursor pointer
		flex center center column
		// background-color red
		text-transform uppercase
		font-weight 500
		&:hover, &:active
			opacity 0.5
		&:active
			transform scale(0.95)
		&.opacity-enter-active, &.opacity-leave-active
			transition opacity 0.25s easeInOutQuart
		&.opacity-enter-from, &.opacity-leave-to
			opacity 0
		.icon
			width 32px
			height 32px
			margin 0 0 8px 0
			background center center no-repeat


</style>
