<template>
	<div class="list-items" :class="{ 'has-dark-background': hasDarkBackground }">

		<div v-if="search && searchFields.length && searchable" class="search" :class="{ searching: loading === 'searching' }">
			<input type="text" v-model="q" class="input" :placeholder="$t('adminDashboard.listItems.searchPlaceholder')" />
			<div v-if="loading === 'searching'" class="loading-search">
				<ui-loader />
			</div>
		</div>

		<div class="list-items-content">
			<div class="items-wrap" :class="format">
				<template v-if="visibleItems.length">
					<div class="items" :class="'cols-' + nbCols">
						<list-item
							v-for="item in visibleItems" :key="item.id"
							:type="itemFormat"
							:item="item"
							:checked="selectedIds.includes(item.id)"
							:format="format"
							:actions="actions"
							:params="params"
							@check="toggleChecked"
						/>
					</div>
					<div v-if="items.length < total" class="buttons">
						<ui-button size="big" :loading="loading === 'next'" v-tap="nextItems">{{ $t('adminDashboard.listItems.seeMore') }}</ui-button>
					</div>
				</template>
				<template v-else-if="loaded">
					<div class="no-data">{{ $t('adminDashboard.listItems.noResults') }}</div>
				</template>
			</div>
			<div v-if="loading" class="prevent"></div>
		</div>


	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useAdminDashboardStore } from '@/store/admin-dashboard'
import ListItem from '@/components/admin-dashboard/list/list-item.vue'
import UiButton from '@/components/ui/ui-button.vue'
import UiLoader from '@/components/ui/ui-loader.vue'
import { engage } from '@/engage'
import { getRandomID, debounce } from '@affordancestudio/functions'
import { get } from '@/requests/request'


const getItemsFromResponse = (response) => {
	// On retire les clés superflues
	const data = response?.data || response?.groupAdmins?.data || response?.memberships?.data || response?.groups || []

	const items = data.map(item => {
		if (item?.clientUser) return item.clientUser
		return item
	})

	return items
}


export default {
	data() {
		return {
			loaded: false,
			loading: null,
			uniqId: getRandomID(),
			items: [],
			total: 0,
			selectedIds: [],
			q: '',
			searchable: true,
		}
	},
	props: {
		request: { default: null, required: true },
		itemFormat: { default: '', required: true },
		format: { default: 'grid' },
		search: { default: true },
		nbCols: { default: 2 },
		actions: { default: [] },
		hasDarkBackground: { default: false },
		params: { default: {} },
	},
	computed: {
		...mapState(useAdminDashboardStore, [ 'lastUpdate' ]),
		visibleItems() {
			return this.items
		},
		selectedItems() {
			return this.items.filter(({ id }) => this.selectedIds.includes(id))
		},
		searchFields() {
			if (Array.isArray(this.search)) return this.search
			return {
				teacher: [ 'user_name', 'first_name', 'last_name', 'profile.school_name', 'email' ],
				student: [ 'user_name', 'first_name', 'last_name', 'profile.school_name', 'email' ],
				school: [ 'user_name', 'first_name', 'last_name', 'profile.school_name', 'email' ],
				district: [ 'user_name', 'first_name', 'last_name', 'profile.school_name', 'email' ],
				group: [ 'name', 'access_code' ],
			}[this.itemFormat] || []
		}
	},
	watch: {
		'lastUpdate.time'() {
			if (this.lastUpdate.type === this.itemFormat) this.updateItems({ type: 'current' })
		},
		selectedIds() {
			this.$emit('change-selection', this.selectedItems)
		},
		q(str) {
			this.addFilter(str)
		}
	},
	methods: {
		addFilter: debounce(function(value) {
			engage.filter.add({
				filter: [
					{
						options: this.searchFields,
						action: 'include',
						with: { value }
					}
				],
				get,
				surname: this.uniqId
			})
			this.updateItems({ type: 'current', loading: 'searching' })
		}, 500),

		async nextItems() {
			this.updateItems({ type: 'next' })
		},

		// type = 'current' or 'next'
		async updateItems({ type, loading = null }) {
			if (!this.loading) {
				this.selectedIds = []
				this.loading = loading || type

				if (type === 'current') engage.paginate.resetPage({ surname: this.uniqId })
				const response = await engage.paginate[type]({ surname: this.uniqId })
				if (response) {
					const items = getItemsFromResponse(response)
					if (type === 'current') {
						this.items = items
						this.total = response?.meta?.count || 0
						this.searchable = !!response?.meta
					} else if (type === 'next') {
						this.items = [ ...this.items, ...items ]
					}
				}
				this.loading = null
			}
		},

		toggleChecked({ id }) {
			if (id) {
				this.selectedIds = this.selectedIds.includes(id)
					? this.selectedIds.filter(tempId => tempId !== id)
					: [ ...this.selectedIds, id ]
			}
		},
	},
	async created() {
		if (this.request) {
			engage.paginate.init({
				data: this.request?.params || {},
				get,
				limit: this.request?.limit || 12,
				name: this.request.name,
				surname: this.uniqId,
			})
			await this.updateItems({ type: 'current' })
		}
		this.$emit('loaded', { items: this.items, total: this.total })
		this.loaded = true
	},
	activated() {
		this.$emit('change-selection', this.selectedItems)
	},
	deactivated() {
		this.$emit('change-selection', [])
	},
	beforeUnmount() {
		this.$emit('change-selection', [])
		engage.filter.remove({
			surname: this.uniqId,
			action: 'include',
		})
	},
	components: { UiButton, UiLoader, ListItem }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.list-items
	height 100%
	width 100%
	&.has-dark-background
		.search
			border none
			height 40px
			padding-bottom 0
			.input
				border-color #0F4444
				&:focus
					border-color #EF771C
		.list-items-content > .prevent
			background-color alpha(#000, 10%)
	.search
		height (40px + 16px)
		// margin 0 0 16px 0
		padding 0 0 16px 0
		flex center center
		border-bottom 1px solid #0F4444
		&.searching
			&:after
				content ''
				position absolute
				left 0
				top 0
				right 0
				height 40px
				border-radius 40px
				cursor wait
		.input
			width 100%
			height 40px
			padding 0 16px
			font inherit
			color #0f4444
			border 2px solid #667D7E
			border-radius 40px
			outline none
			&:focus
				border-color #0F4444
		.loading-search
			position absolute
			right 0
			top 0
			width 40px
			height 40px
			color #0F4444
	.list-items-content
		height calc(100% - 56px)
		.items-wrap
			height 100%
			min-height 96px
			overflow auto
			padding 16px 0
			&.table .items
				display grid
				grid-template-columns 1fr !important
				gap 0
			.items
				display grid
				padding 0 0 16px 0
				gap 16px
				grid-auto-rows max-content
				grid-auto-flow row
				for i in 1..4
					&.cols-{i}
						grid-template-columns 'repeat(%s, 1fr)' % i

			.buttons
				flex center center
				padding 0 0 16px 0

			> .no-data
				flex center center
				// margin 16px 0
				height 96px
				background-color alpha(#000, 20%)
				opacity 0.5
				border-radius 8px
				text-align center
				text-transform uppercase
				font-weight 500

		> .prevent
			position absolute
			top 16px
			right 0
			left 0
			height calc(100% - 32px)
			background-color alpha(#fff, 25%)
			flex center center
			color #667D7E
			cursor wait
			border-radius 8px

</style>
