import { engage } from '@/engage'
import { get, post } from '@/requests/request'


// =============== SINGUP ETUDIANT (page /login => créer un compte) ===============

export const initStudentSignUp = async ({ groupCode }) => {
	const response = await engage.createAnonymousUser({ groupAccessCode: groupCode })
	if (response?.user?.groups[0]) {
		return { progression: await startOnBoardingGame(), group: response?.user?.groups[0] }
	}
	return false
}

export const submitStudentSignup = async ({ progression, values }) => {
	const response = await post('submitForm', { progressionId: progression.id, answers: values })
	if (response?.score === 1) {
		return await post('completeStep', { progressionId: progression.id })
	}
	return response
}


// =============== SINGUP TEACHER (page /invite) ===============

export const initTeacherInvitation = async () => {
	return await startOnBoardingGame()
}

export const submitTeacherInvitation = async ({ progression, values }) => {
	const answers = values.map((v) => {
		let data = [ v.value === false ? "" : v.value.toString() ]
		if (v.type === 'Content::Form::SelectInput' && v.tags.includes('check_box'))
			data = v.options.filter(o => o.checked).map(o => o.id)
		else if (v.type === 'Content::Form::PasswordInput')
			data = [ v.value.toString(), v.value.toString() ]
		return { formElementId: v.id, data }
	})
	const response = await post('submitForm', { progressionId: progression.id, answers })
	if (response?.score === 1) {
		return await post('completeStep', { progressionId: progression.id })
	}
	return response
}


// =============== COMMUNS ===============

const startOnBoardingGame = async () => {
	const sections = await get('clientSectionBy', { slug: 'onboarding' })
	const section = sections.length ? sections[0] : false
	if (section?.clientGames?.length) {
		const gameId = section.clientGames[0]?.id
		if (gameId) {
			const progression = await post('createProgression', { gameId })
			if (progression?.id && progression?.currentMark?.step?.formElements) {
				return progression
			}
		}
	}
	return null
}



// =============== RESET PASSWORD ===============

export const initResetPassword = async () => {
	const sections = await get('clientSectionBy', { slug: 'login' })
	const section = sections.length ? sections[0] : false
	if (section?.clientGames?.length) {
		const gameId = section.clientGames[0]?.id
		if (gameId) {
			const progression = await post('createProgression', { gameId })
			if (progression?.id && progression?.currentMark?.step?.formElements) {
				return progression
			}
		}
	}
	return null
}

export const submitResetPassword = async ({ progression, values }) => {
	const answers = values.map((v) => {
		if (v.type === 'password')
			return { formElementId: v.id, data: [ v.value.toString(), v.value.toString() ] }
		else
			return { formElementId: v.id, data: [ v.value === false ? "" : v.value.toString() ] }
	})
	const response = await post('submitForm', { progressionId: progression.id, answers })
	if (response?.score === 1) {
		return await post('completeStep', { progressionId: progression.id })
	}
	return response
}


// =============== CHECKPOINTS ===============

export const getCheckpoints = () => {
	return new Promise((resolve) => {
		get('myCheckpoints')
			.then(checkpoints => {
				resolve(checkpoints ?? [])
			})
	})
}

export const completeCheckpoint = async ({ id }) => {
	const response = await post('reachCheckpoint', { checkpointId: id })
	return response ? response : []
}


export const resetAchievement = async ({ userId = null } = {}) => {
	return await post('resetAchievement', userId ? { userId } : null)
}
