<template>
	<button type="button" class="calendar-button" :class="[ color, { loading } ]" :disabled="disabled">
		<slot />
		<span v-if="loading" class="loader">
			<svg width="120" height="30" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#fff">
				<circle cx="15" cy="15" r="15">
					<animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite" />
					<animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite" />
				</circle>
				<circle cx="60" cy="15" r="9" fill-opacity="0.3">
					<animate attributeName="r" from="9" to="9" begin="0s" dur="0.8s" values="9;15;9" calcMode="linear" repeatCount="indefinite" />
					<animate attributeName="fill-opacity" from="0.5" to="0.5" begin="0s" dur="0.8s" values=".5;1;.5" calcMode="linear" repeatCount="indefinite" />
				</circle>
				<circle cx="105" cy="15" r="15">
					<animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite" />
					<animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite" />
				</circle>
			</svg>
		</span>
	</button>
</template>

<script>
export default {
	props: {
		disabled: { default: false },
		loading: { default: false },
		color: { default: 'yellow' },
	}
}
</script>

<style lang="stylus" scoped>

.calendar-button
	width 100%
	height 48px
	flex center center
	appearance none
	border none
	outline none
	border-radius 8px
	font inherit
	text-transform uppercase
	color #fff
	text-align center
	cursor pointer
	user-select none
	font-size 2rem
	font-weight 300
	&.yellow
		background-color #F9AE19
		&:hover:not([disabled])
			background-color darken(#F9AE19, 10%)
		&:active:not([disabled])
			background-color darken(#F9AE19, 15%)
		.loader
			background-color darken(#F9AE19, 15%)
	&.red
		background-color #D67474
		&:hover:not([disabled])
			background-color darken(#D67474, 10%)
		&:active:not([disabled])
			background-color darken(#D67474, 15%)
		.loader
			background-color darken(#D67474, 15%)
	&[disabled]
		background-color #999
		color #bbb
		cursor not-allowed
	.loader
		absolute 0
		flex center center
		border-radius @border-radius
		cursor wait
		svg
			display inline-block
			height 12px

</style>
