<template>
	<div v-if="subElementTransformed" class="archive-form-row" :class="{ 'is-text': subElementTransformed.type === 'Content::Text', 'margin-top': hasMarginTop }">

		<form-maintenance-bubble v-if="subElementTransformed.type === 'Content::Text'" :subElement="subElementTransformed" :decal="subElementTransformed?.entry.tags?.includes('avatar')" />
		<form-maintenance-select v-if="subElementTransformed.type === 'Content::Form::SelectInput'" :subElement="subElementTransformed" :editable="false" />
		<div class="accept-input" v-if="(subElementTransformed.type === 'Content::Form::AcceptInput')">
			<ui-checkbox :text="subElementTransformed.entry.label" v-model="uneditableValue" :editable="false" />
		</div>
		<div v-if="(subElementTransformed.type === 'Content::Form::TextInput' || subElementTransformed.type === 'Content::Form::NumberInput') && subElementTransformed.answer">
			<p v-if="subElementTransformed?.value?.block?.label" class="label" v-html="subElementTransformed.value.block.label"></p>
			<!-- <ui-checkbox v-if="subElementTransformed?.value?.block?.tags?.includes('check_box')" :text="subElementTransformed.answer" v-model="uneditableValue" :editable="false" /> -->
			<div class="text-input">{{ subElementTransformed.answer }}</div>
		</div>

	</div>
</template>

<script>
import FormMaintenanceBubble from '@/components/notepad/form-maintenance/form-maintenance-bubble'
import FormMaintenanceSelect from '@/components/notepad/form-maintenance/form-maintenance-select'
import UiCheckbox from '@/components/ui/ui-checkbox'

export default {
	name: 'archive-form-row',
	data() {
		return {
			uneditableValue: true
		}
	},
	props: {
		subElement: { default: null },
		index: { default: null }
	},
	computed: {
		subElementTransformed() {
			if (this.subElement.type === 'Content::Text') {
				return { type: this.subElement.type, entry: this.subElement.value }
			}
			if (this.subElement.type === 'Content::Form::AcceptInput') {
				return { type: this.subElement.type, entry: { label: this.subElement.value.block.label } }
			}
			if (this.subElement.type === 'Content::Form::SelectInput') {
				const selectableElements = this.subElement.value.block.selectableElements
					.filter(({ id }) => Array.isArray(this.subElement.answer.id) ? this.subElement.answer.id.includes(id) : this.subElement.answer.id === id)
				return {
					type: this.subElement.type, entry: {
						tags: this.subElement?.tags ? this.subElement.tags : [], // [ 'radio' ],
						selectableElements
					}
				}
			}
			return this.subElement
		},
		hasMarginTop() {
			return (this.subElementTransformed?.type === 'Content::Text' && this.index !== 0)
		}
	},
	components: { FormMaintenanceBubble, FormMaintenanceSelect, UiCheckbox }
}
</script>

<style lang="stylus" scoped>

.archive-form-row
	padding 0 0 0 80px
	&.is-text
		margin 0 0 16px 0
		&.margin-top
			margin-top 32px
	&.is-accept-input
		margin 0 0 16px 0
	.text-input
		padding 16px
		border 1px solid #ccc
		border-radius 4px

</style>
