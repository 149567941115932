<template>
	<div class="modal-user-disabled">
		<div class="bg"></div>
		<div class="content">

			<template v-if="hasSchoolErrorMessage">
				<p class="title" v-html="$t('login.modalUserAccountExpiredTitle')"></p>
				<p v-html="$t('login.modalUserAccountExpiredText')"></p>
			</template>
			<template v-else>
				<p class="title" v-html="$t('login.modalUserDisabledTitle')"></p>
				<p v-html="$t('login.modalUserDisabledText')"></p>
			</template>

			<div class="buttons">
				<ui-button size="big" v-tap="close">{{ $t('login.modalUserDisabledCloseButton') }}</ui-button>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	name: 'modal-user-disabled',
	props: {
		hasSchoolErrorMessage: { default: false }
	},
	methods: {
		close() {
			this.$emit('close')
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.modal-user-disabled
	absolute 0
	flex center center
	height 100%
	padding 80px 16px
	.bg
		absolute 0
		background-color alpha(#333, 90%)
	.content
		width calc(100% - 32px)
		max-width 550px
		padding 32px
		border-radius 8px
		background-color #fff
		text-align center
		.title
			margin 0 0 32px 0
			text-transform uppercase
			font-weight 500
			font-size 2.4rem
		p
			margin 0 0 4px 0
			::v-deep(a)
				color primary
				text-decoration underline
				&:hover
					text-decoration none
		.buttons
			padding-top 32px
			flex center center
			.ui-button
				min-width 200px

</style>
