import { defineStore } from 'pinia'
import { useAppStore } from '@/store/app'
import { getRandomID } from '@affordancestudio/functions'
import { i18n } from '@/js/i18n'

export const useNotepadStore = defineStore({
	id: 'notepad',

	state: () => ({
		animation: 'starting',
		tree: null,
		pages: [],
		scrollTo: {
			time: null,
			element: null,
			smooth: false
		}
	}),

	actions: {

		setScrollTo({ element, smooth = true }) {
			this.scrollTo = { time: Date.now(), element, smooth }
		},

		setTree({ tree }) {
			this.tree = tree
		},

		setPages({ pages }) {
			this.pages = pages.map((page, index) => {
				return { ...page, zIndex: pages.length - index, native: true }
			})
		},

		setActivePage({ page }) {
			if (page) {
				const max = Math.max(...this.pages.map(p => p.zIndex))
				page.zIndex = max + 1
			}
		},

		setPageIndex({ page, pageIndex }) {
			page.pageIndex = pageIndex
		},

		addPage({ page, pageIndex, native = false }) {
			if (page && page.id) {
				page.zIndex = Math.max(...this.pages.map(p => p.zIndex)) + 1
				page.pageIndex = pageIndex ? pageIndex : 0
				page.native = native
				if (page?.children?.length && useAppStore().isConnected) {
					// page.children.push({
					// 	id: getRandomID(),
					// 	tags: [ 'content-trivia' ],
					// 	document: { title: i18n.global.t('contentTrivia.title') }
					// })
					page.children.push({
						id: getRandomID(),
						tags: [ 'content-review' ],
						document: { title: i18n.global.t('formContentReview.title') }
					})
				}
				this.pages.push(page)
			}
		},

		addPages({ pages }) {
			pages.forEach((page) => {
				this.addPage({ page })
			})
		},

		removePage({ page }) {
			if (page && page.id) {
				const index = this.pages.findIndex(p => p.id === page.id)
				if (index > -1) this.pages.splice(index, 1)
			}
		},

		removePages({ pages }) {
			pages.forEach((page) => {
				this.removePage({ page })
			})
		},

	},

})
