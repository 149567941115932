<template>
	<div class="modal-remove">

		<div class="texts">
			<p>{{ $t('adminDashboard.modals.disable.questionValidation') }} {{ $tc('adminDashboard.modalTitle.' + this.modal.type, items.length) }}&nbsp;?</p>
		</div>

		<div class="items">
			<div v-for="item in items" :key="item.id" class="item">
				<p class="name" v-html="(item?.firstName || item?.lastName ? item?.firstName + ' ' + item?.lastName : false) || item?.profile?.school_name || item?.userName || item?.name || '-'"></p>
				<p v-if="item?.email" class="description" v-html="item?.email"></p>
				<admin-state :state="item?.disabled ? 'disabled' : 'enabled'" />
				<transition>
					<div v-if="dones.includes(item.id)" class="result done" :title="$t('adminDashboard.modals.global.resultSuccessTitle')" v-bg="'check-white.svg'"></div>
					<div v-else-if="errors.includes(item.id)" class="result error" :title="$t('adminDashboard.modals.global.resultErrorTitle')" v-bg="'close.svg'"></div>
				</transition>
			</div>
		</div>

		<admin-form-results :items="items" :dones="dones" :errors="errors" />

		<div class="buttons">
			<ui-button color="dark-green" size="big" :block="true" v-tap="() => $emit('close')">{{ $t('adminDashboard.modals.global.close') }}</ui-button>
			<ui-button v-if="dones.length + errors.length !== items.length" color="green" size="big" :block="true" :loading="loading" :disabled="!submitable" v-tap="submit">{{ $t('adminDashboard.modals.global.submit') }}</ui-button>
		</div>

	</div>
</template>

<script>
import { mapActions } from 'pinia'
import { useAdminDashboardStore } from '@/store/admin-dashboard'
import { disableUser, enableUser } from '@/requests/dashboard'
import AdminState from '@/components/admin-dashboard/ui/admin-state.vue'
import AdminFormResults from '@/components/admin-dashboard/ui/admin-form-results.vue'

export default {
	data() {
		return {
			loading: false,
			dones: [],
			errors: [],
		}
	},
	props: {
		modal: { default: null }
	},
	computed: {
		submitable() {
			return this.modal?.items?.length
		},
		items() {
			return this.modal?.items || []
		}
	},
	methods: {
		...mapActions(useAdminDashboardStore, [ 'logUpdate' ]),
		async submit() {
			if (this.submitable && !this.loading && this.items.length) {
				this.loading = true
				this.dones = []

				await Promise.all(this.items.map(async (item) => {
					if (typeof item.disabled === 'boolean') {
						const state = item.disabled ? 'enable' : 'disable'
						if (await this.callRequest({ state, params: { id: item.id } })) {
							item.disabled = !item.disabled
							this.dones.push(item.id)
						} else {
							this.errors.push(item.id)
						}
					}
				}))
				// this.logUpdate({ type: this.modal.type })

				this.loading = false
			}
		},

		async callRequest({ state, params }) {
			if ([ 'district', 'teacher', 'school', 'student' ].includes(this.modal.type)) {
				if (state === 'enable') return await enableUser(params)
				if (state === 'disable') return await disableUser(params)
			}
			console.error('[modal-disable.vue] No defined request for type: ' + this.modal.type)
		}
	},
	components: { AdminState, AdminFormResults }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.modal-remove
	text-align center
	.texts
		margin 0 0 32px 0
		> p
			margin 0 0 8px 0
			&:last-child
				margin-bottom 0
	.items
		flex flex-start flex-start column
		gap 8px
		margin 0 0 32px 0
		max-height 400px
		overflow auto
		border 2px solid darken(#D5DEDE, 10%)
		border-radius 8px
		padding 8px
		.item
			width 100%
			background-color #D5DEDE
			padding 16px
			border-radius 8px
			text-align left
			> .name
				font-weight 500
				text-transform uppercase
			> .description
				color #666
			.admin-state
				margin 4px 0 0 0
			.result
				position absolute
				right 0px
				top 0px
				width 32px
				height 32px
				border-radius 50%
				background #999 center center no-repeat
				box-shadow 0 0 0 4px #E7EEEE
				transition 0.15s easeInQuart
				&.done
					background-color #8BAE63
					background-size 24px !important
				&.error
					background-color #C17474
					background-size 16px !important
				&.v-enter-from
					transform scale(1.5)
					opacity 0
				
	.results
		flex center center
		gap 16px
		margin 0 0 32px 0
		.result
			flex center center column
			width 96px
			height 96px
			padding 8px 16px
			border-radius 50%
			background-color #999
			color #fff
			text-align center
			&.success
				background-color #8BAE63
			&.error
				background-color #C17474
			.label
				text-transform uppercase
				font-size 1.4rem
			.value
				font-size 2.4rem

	.buttons
		flex center center
		gap 16px
	

</style>