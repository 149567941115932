<template>
	<div class="side-panel-school">

		<div class="side primary white">
			<template v-if="panel.school">
				<div class="main-title">{{ panel.school.profile.school_name || $t('adminDashboard.panels.school.defaultTitle') }}</div>
			</template>
			<template v-else>
				<div class="main-title">{{ $t('adminDashboard.panels.school.yourInvitations') }}</div>
			</template>

			<div v-if="tabs.length" class="tabs">
				<div v-if="features.teacher.includes('view') && tabs.includes('teacher')" class="tab" :class="{ active: currentTab === 'teacher' }" v-tap="() => currentTab = 'teacher'">{{ $t('adminDashboard.panels.school.tabTeachers') }} {{ panel.school ? ' ' + $t('adminDashboard.panels.school.tabTeachersSaved') : '' }}</div>
				<div v-if="features.group.includes('view') && tabs.includes('group')" class="tab" :class="{ active: currentTab === 'group' }" v-tap="() => currentTab = 'group'">{{ $t('adminDashboard.panels.school.tabGroups') }}</div>
				<div v-if="features.school.includes('view') && tabs.includes('school')" class="tab" :class="{ active: currentTab === 'school' }" v-tap="() => currentTab = 'school'">{{ $t('adminDashboard.panels.school.tabSchools') }}</div>
			</div>

			<keep-alive>
				<list-items
					:key="currentTab"
					:request="request"
					:item-format="currentTab"
					:nb-cols="nbCols"
					@loaded="setLoaded"
					@change-selection="(items) => selectedItems = items"
				/>
			</keep-alive>

		</div>

		<div class="side secondary green has-boxes">

			<div v-if="[ 'add', 'reset-password', 'disable', 'remove' ].find(e => features[currentTab].includes(e))" class="header">
				<div class="title">{{ $t('adminDashboard.actions') }}</div>
				<div class="actions">
					<ui-round-button v-if="features[currentTab].includes('add')" :size="40" :disabled="selectedItems.length" v-tap="() => !selectedItems.length && addModal({ slug: 'add', type: currentTab, school: role === 'vireo' ? panel.school : null })" :tooltip="$t('adminDashboard.tooltips.add') + ' ' + $tc('adminDashboard.modalTitle.' + currentTab, 1)" color="white" icon="admin/plus-green.svg" id="add-button" />
					<ui-round-button v-if="features[currentTab].includes('reset-password')" :size="40" :disabled="!selectedItems.length" v-tap="() => selectedItems.length && addModal({ slug: 'reset-password', type: currentTab, items: selectedItems })" :tooltip="$t('adminDashboard.tooltips.resetPasswordSelectedElements')" tooltip-position="right" color="white" icon="admin/send-green.svg" />
					<ui-round-button v-if="features[currentTab].includes('disable')" :size="40" :disabled="!selectedItems.length" v-tap="() => selectedItems.length && addModal({ slug: 'disable', type: currentTab, items: selectedItems })" :tooltip="$t('adminDashboard.tooltips.disabledSelectedElements')" tooltip-position="right" color="yellow" icon="admin/disable-green.svg" />
					<ui-round-button v-if="features[currentTab].includes('remove')" :size="40" :disabled="!selectedItems.length" v-tap="() => selectedItems.length && addModal({ slug: 'remove', type: currentTab, items: selectedItems })" :tooltip="$t('adminDashboard.tooltips.deleteSelectedElements')" tooltip-position="right" color="red" icon="admin/trash-green.svg" id="delete-button" />
				</div>
			</div>

			<div class="content">
				<div v-if="panel.school" class="boxes">

					<admin-box>
						<div class="title">{{ $t('adminDashboard.panels.school.stats') }}</div>
						<div class="content">
							<div class="row">
								<div class="label">{{ $t('adminDashboard.panels.school.nbTeachers') }}</div>
								<div class="value">{{ totalTeachers }}</div>
							</div>
						</div>
					</admin-box>

					<admin-box>
						<div class="title">{{ $t('adminDashboard.panels.school.schoolManager') }}</div>
						<div class="content">
							<div class="row">
								<div class="label">{{ $t('adminDashboard.panels.school.email') }}</div>
								<div class="value"><a :title="panel.school.email" :href="'mailto:' + panel.school.email">{{ panel.school.email }}</a></div>
							</div>
							<div class="row">
								<div class="label">{{ $t('adminDashboard.panels.school.accountState') }}</div>
								<div class="value">
									<admin-state :state="panel.school.disabled ? 'disabled' : 'enabled'" />
								</div>
							</div>
							<div class="row">
								<div class="label">{{ $t('adminDashboard.panels.school.subscribeState') }}</div>
								<div class="value">
									<admin-onboarding :onboarding="panel.school.onboarding" background="light" />
								</div>
							</div>
						</div>
						<div class="actions">
							<ui-round-button :size="40" icon="admin/edit-green.svg" v-tap="() => addModal({ slug: 'edit', type: 'school', item: panel.school })" :tooltip="$t('adminDashboard.tooltips.editSchool')" />
							<ui-round-button v-if="features.school.includes('edit-email')" :size="40" icon="admin/email-green.svg" v-tap="() => addModal({ slug: 'edit-email', type: 'school', item: panel.school })" :tooltip="$t('adminDashboard.tooltips.editEmail')" />
							<ui-round-button :size="40" icon="admin/send-green.svg" v-tap="() => addModal({ slug: 'reset-password', type: 'school', items: [ panel.school ] })" :tooltip="$t('adminDashboard.tooltips.resetSchoolPassword')" />
							<ui-round-button :size="40" icon="admin/disable-green.svg" v-tap="() => addModal({ slug: 'disable', type: 'school', items: [ panel.school ] })" :tooltip="$t('adminDashboard.tooltips.disableSchool')" color="yellow" />
							<ui-round-button :size="40" icon="admin/trash-green.svg" v-tap="() => addModal({ slug: 'remove', type: 'school', items: [ panel.school ] })" :tooltip="$t('adminDashboard.tooltips.deleteSchool')" color="red" />
						</div>
					</admin-box>

					<admin-box>
						<div class="title">{{ $t('adminDashboard.panels.school.supporterModels') }}</div>
						<div class="content">
							<div class="row">
								<div class="label">{{ $t('dashboard.models') }}</div>
								<div class="value">
									<template v-if="models.length">
										<div class="models">
											<span v-for="model in models" :key="model" class="model">{{ model }}</span>
										</div>
									</template>
									<template v-else>
										{{ $t('adminDashboard.none') }}
									</template>
								</div>
							</div>
						</div>
						<div class="actions">
							<ui-round-button :size="40" icon="admin/edit-green.svg" v-tap="() => addModal({ slug: 'edit-alternatives', school: panel.school })" :tooltip="$t('adminDashboard.tooltips.editSchoolModels')" />
						</div>
					</admin-box>

					<!-- <admin-box :disabled="true">
						<div class="title">Souscription de l'école</div>
						<div class="content">
							<div class="row">
								<div class="label">Type</div>
								<div class="value">Payant</div>
							</div>
							<div class="row">
								<div class="label">Date de renouvellement</div>
								<div class="value">12 novembre 2022</div>
							</div>
						</div>
						<div class="actions">
							<ui-round-button :size="40" icon="admin/edit-green.svg" v-tap="() => addModal({ slug: 'edit', type: 'subscription', item: null })" tooltip="Éditer la souscription" />
							<ui-round-button :size="40" icon="admin/plus-green.svg" v-tap="() => addModal({ slug: 'add', type: 'subscription' })" tooltip="Ajouter une souscription" />
							<ui-round-button :size="40" icon="admin/disable-green.svg" v-tap="() => addModal({ slug: 'disable', type: 'subscription', items: [] })" tooltip="Activer/Désactiver" color="yellow" />
							<ui-round-button :size="40" icon="admin/trash-green.svg" v-tap="() => addModal({ slug: 'remove', type: 'subscription', items: [] })" tooltip="Supprimer" color="red" />
						</div>
					</admin-box> -->

				</div>

				<div v-if="panel.school" class="buttons">
					<!-- <ui-button size="big" color="yellow" :disabled="true">Générer un rapport de l'école</ui-button> -->
					<ui-button size="big" color="yellow" v-tap="() => addPanel({ slug: 'calendar', params: { school: panel.school } })">{{ $t('adminDashboard.panels.school.seeCalendar') }}</ui-button>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useAppStore } from '@/store/app'
import { useAdminDashboardStore } from '@/store/admin-dashboard'
import { useOnboardingStore } from '@/store/onboarding'
import UiRoundButton from '@/components/ui/ui-round-button.vue'
import AdminState from '@/components/admin-dashboard/ui/admin-state.vue'
import AdminOnboarding from '@/components/admin-dashboard/ui/admin-onboarding.vue'
import UiButton from '@/components/ui/ui-button.vue'
import AdminBox from '@/components/admin-dashboard/ui/admin-box.vue'
import ListItems from '@/components/admin-dashboard/list/list-items.vue'

export default {
	data() {
		return {
			currentTab: 'teacher',
			selectedItems: [],
			totalTeachers: 0
		}
	},
	props: {
		panel: { default: null }
	},
	computed: {
		...mapState(useAppStore, [ 'role' ]),
		...mapState(useOnboardingStore, [ 'tutos' ]),
		...mapState(useAdminDashboardStore, [ 'features' ]),
		request() {
			if (this.panel.school) {
				if (this.currentTab === 'teacher') return { name: 'teacherBySchool', params: { userId: this.panel.school.id } }
				if (this.currentTab === 'group') return { name: 'groupBySchool', params: { userId: this.panel.school.id } }
			} else {
				if (this.currentTab === 'school') return { name: 'invitedSchools' }
				if (this.currentTab === 'teacher') return { name: 'invitedTeachers' }
				if (this.currentTab === 'group') return { name: 'myGroups' }
				
			}
			return {}
		},
		nbCols() {
			return { teacher: 2, group: 2, potager: 2, calendar: 1 }[this.currentTab]
		},
		models() {
			return this.panel.school?.alternatives?.length ? [ ...this.panel.school.alternatives ].sort() : []
		},
		tabs() {
			return {
				vireo: [ 'teacher', 'group', !this.panel.school ? 'school' : null ],
				district: [ 'teacher', 'group', !this.panel.school ? 'school' : null ],
				school: [ 'teacher', 'group' ],
				teacher: [ 'group' ],
			}[this.role] || []
		}
	},
	methods: {
		...mapActions(useAdminDashboardStore, [ 'addModal', 'addPanel' ]),
		...mapActions(useOnboardingStore, [ 'startTuto' ]),
		setLoaded(e) {
			const nb = e.total
			if (this.currentTab === 'teacher') {
				this.totalTeachers = nb

				if (this.role === 'school') {
					if (nb > 0 && this.tutos.find(t => t?.slug === 'admin_school_without_teachers')?.status !== 'completed') {
						this.startTuto({ slug: 'admin_school_with_teachers' })
					}
					else if (nb === 0 && this.tutos.find(t => t?.slug === 'admin_school_with_teachers')?.status !== 'completed') {
						this.startTuto({ slug: 'admin_school_without_teachers' })
					}
				}
			}
			if (this.currentTab === 'group') {
				if (this.role === 'school') {
					if (nb > 0 && this.tutos.find(t => t?.slug === 'admin_school_without_groups')?.status !== 'completed') {
						this.startTuto({ slug: 'admin_school_with_groups' })
					}
					else if (nb === 0 && this.tutos.find(t => t?.slug === 'admin_school_with_groups')?.status !== 'completed') {
						this.startTuto({ slug: 'admin_school_without_groups' })
					}
				}
				else if (this.role === 'teacher') {
					if (nb > 0 && this.tutos.find(t => t?.slug === 'admin_teacher_without_groups')?.status !== 'completed') {
						this.startTuto({ slug: 'admin_teacher_with_groups' })
					} else if (nb === 0 && this.tutos.find(t => t?.slug === 'admin_teacher_with_groups')?.status !== 'completed') {
						this.startTuto({ slug: 'admin_teacher_without_groups' })
					}
				}
			}
		}
	},
	created() {
		if (this.tabs.length) this.currentTab = this.tabs[0]
	},
	components: { UiRoundButton, AdminBox, ListItems, AdminState, AdminOnboarding, UiButton }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.side-panel-school
	.side.primary
		.tabs
			height 56px
			margin 0 0 16px 0
			border-bottom 1px solid #0F4444
			flex center flex-start
			gap 16px
			.tab
				width 25%
				height 100%
				padding 0 8px
				flex center center
				flex-shrink 1
				background-color #C9CED0
				cursor pointer
				color #476D6E
				text-transform uppercase
				line-height 1.1em
				text-align center
				&:hover
					background-color darken(#C9CED0, 10%)
				&.active
					background-color #0F4444
					color #fff
					&:after
						content ''
						position absolute
						left calc(50% - 12px)
						top calc(100% - 1px)
						width 0
						height 0
						border-style solid
						border-width 12px 12px 0 12px
						border-color #0F4444 transparent transparent transparent

		.list-items
			height 'calc(100% - %s)' % (48px + 16px + 56px + 16px)
			&.no-tabs
				height 'calc(100% - %s)' % (48px + 16px)

</style>