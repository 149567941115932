<template>
	<div class="form-content-review">
		<form v-if="mainSection?.id" class="form-content-review-content" @submit.prevent="submit()">
			<p class="text question">{{ $t('formContentReview.question') }}</p>
			<div class="thumbs">
				<div class="thumb down" :class="{ active: thumb === 'down' }" v-tap="() => toggleThumb('down')">
					<div class="icon empty" v-bg="'thumb-down-empty.svg'"></div>
					<transition name="thumb">
						<div v-show="thumb === 'down'" class="icon full" v-bg="'thumb-down-full.svg'"></div>
					</transition>
				</div>
				<div class="thumb up" :class="{ active: thumb === 'up' }" v-tap="() => toggleThumb('up')">
					<div class="icon empty" v-bg="'thumb-up-empty.svg'"></div>
					<transition name="thumb">
						<div v-show="thumb === 'up'" class="icon full" v-bg="'thumb-up-full.svg'"></div>
					</transition>
				</div>
			</div>
			<p class="text">{{ $t('formContentReview.text') }}</p>

			<textarea rows="6" v-model="comment" class="input" :placeholder="$t('formContentReview.inputPlaceholder')"></textarea>

			<div class="buttons">
				<ui-button type="submit" color="dark-green" :disabled="!comment" :loading="loading">{{ $t('formContentReview.submitButton') }}</ui-button>
			</div>

			<div v-if="success" class="success">
				<p>{{ $t('formContentReview.success1') }}</p>
				<p>{{ $t('formContentReview.success2') }}</p>
			</div>
		</form>
		<div v-else class="unable">
			<p>{{ $t('formContentReview.unableToEvaluate') }}</p>
		</div>
	</div>
</template>

<script>
import { evaluate } from '@/requests/notepad'

export default {
	name: 'form-content-review',
	data() {
		return {
			thumb: null,
			comment: '',
			loading: false,
			success: false
		}
	},
	props: {
		page: { default: null },
		mainSection: { default: null }
	},
	methods: {
		toggleThumb(thumb) {
			this.thumb = this.thumb === thumb ? null : thumb
			const params = {}
			params[thumb === 'up' ? 'like' : 'dislike'] = this.thumb === thumb
			this.sendEvaluation(params)
		},
		async sendEvaluation(data) {
			return await evaluate({ elementId: this.mainSection.id, elementType: "Client::Section", ...data })
		},
		async submit() {
			if (!this.loading && this.comment) {
				this.loading = true
				const response = await this.sendEvaluation({ comment: this.comment })
				if (response) {
					this.success = true
				}
				this.loading = false
			}
		}
	},
	created() {
		if (this.mainSection?.evaluation?.current) {
			if (this.mainSection.evaluation.current.like) this.thumb = 'up'
			if (this.mainSection.evaluation.current.dislike) this.thumb = 'down'
		}
	}
}
</script>

<style lang="stylus" scoped>

.form-content-review
	.text
		margin 0 0 16px 0
	.question
		text-align center
	.thumbs
		flex center center
		margin 0 0 16px 0
		gap 16px
		.thumb
			width 80px
			height 80px
			cursor pointer
			border-radius 50%
			&:hover .icon
				transform scale(1.05)
			&.active .icon.empty
				opacity 0
			.icon
				absolute 0
				background center center no-repeat
				transition 0.25s easeOutBack
				pointer-events none
				&.thumb-enter-active
					transition 0.25s easeOutBack
				&.thumb-leave-active
					transition 0.25s easeOutQuart
				&.thumb-enter-from, &.thumb-leave-to
					transform scale(1.75)
					opacity 0
	.input
		width 100%
		margin 0 0 16px 0
		padding 16px
		border 1px solid #DADBDB
		background #fff
		max-width 100%
		min-width 100%
		outline none
		font inherit
		color inherit
		&:focus
			border-color #2D4949
			box-shadow 0 0 0 1px #2D4949
	.buttons
		flex center center
	
	.success
		absolute 0
		background-color #fff
		color #2D4949
		flex center center column
		text-align center
		font-weight 500
		font-size 1.8rem
		padding 16px
		p
			margin 0 0 8px 0
	
	.unable
		text-align center
		padding 40px 0
		text-transform uppercase
		color #999

</style>
