<template>
	<div v-if="archive" class="archive-list-item" :class="{ loading }" v-tap="openArchive">
		<div class="left">
			<span v-if="archiveDateHtml" v-html="archiveDateHtml"></span>
		</div>
		<div class="right">
			<div class="infos">
				<div class="info">
					<div class="label">{{ $t('archives.performedBy') }}:</div>
					<div class="value">{{ archive.clientUser.userName }}</div>
				</div>
				<!-- <div class="info">
					<div class="label">{{ $t('archives.ph') }}:</div>
					<div class="value">XX</div>
				</div>
				<div class="info">
					<div class="label">{{ $t('archives.ec') }}:</div>
					<div class="value">XX</div>
				</div> -->
				<div v-if="archive?.clientGame?.document?.title" class="info">
					<div class="label">{{ $t('archives.maintenance') }}:</div>
					<div class="value">{{ archive.clientGame.document.title }}</div>
				</div>
				<div v-if="archive?.clientGroup?.name" class="info">
					<div class="label">{{ $t('archives.group') }}:</div>
					<div class="value">{{ archive.clientGroup.name }}</div>
				</div>
			</div>
			<div class="footer">
				<ui-button :right-arrow="true" :block="true">{{ $t('archives.viewTheReport') }}</ui-button>
			</div>
		</div>
		<ui-loader v-show="loading" />
	</div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useNotepadStore } from '@/store/notepad'
import { useAppStore } from '@/store/app'
import { getArchiveDetail } from '@/requests/notepad'
import { i18n } from '@/js/i18n'

export default {
	name: 'archive-list-item',
	data() {
		return {
			loading: false
		}
	},
	props: {
		page: { default: null },
		archive: { default: null }
	},
	computed: {
		...mapState(useAppStore, [ 'device' ]),
		...mapState(useNotepadStore, [ 'pages' ]),
		archiveDate() {
			if (this.archive?.createdAt) {
				const date = new Date(this.archive.createdAt)
				return date.getDate().toString().padStart(2, "0") + '/' + (date.getMonth() + 1).toString().padStart(2, "0") + '/' + date.getFullYear()
			}
			return null
		},
		archiveDateHtml() {
			if (this.archiveDate) {
				const splits = this.archiveDate.split('/')
				if (splits.length === 3) return splits[0] + '/' + (this.device !== 'mobile' ? '<br>' : '') + splits[1] + '/' + splits[2]
			}
			return null
		}
	},
	methods: {
		...mapActions(useNotepadStore, [ 'addPage', 'setActivePage' ]),
		async openArchive() {
			if (!this.loading) {
				this.loading = true
				const maintenancePage = this.pages.find(p => p.slug === process.env.VUE_APP_MAINTENANCE_SECTION_SLUG)
				const archivesPage = this.pages.find(p => p.slug === 'archives')
				if (maintenancePage && archivesPage) {
					const subElements = await getArchiveDetail({ sectionId: maintenancePage.id, progressionId: this.archive.id })
					const page = {
						id: this.archive.id,
						category: archivesPage.document.title,
						document: {
							title: this.archive.clientGame.document.title,
							tooltip: i18n.global.t('archives.dateArchive') + ' ' + this.archiveDate
						},
						archive: {
							...this.archive,
							subElements,
						},
						type: 'archive'
					}
					this.addPage({ page })
					this.setActivePage({ page })
				}
				this.loading = false
			}
		}
	}
}
</script>

<style lang="stylus" scoped>

#mobile
	.archive-list-item
		flex-direction column
		padding 4px
		.left
			width 100%
			text-align center
			font-weight 600
		.right
			width 100%
			border none
			.footer
				border none
				padding 4px

.archive-list-item
	flex flex-start flex-start
	background-color #F2EFE0
	border-radius 4px
	margin 0 0 16px 0
	box-shadow 0 3px 6px alpha(#000, 30%)
	cursor pointer
	user-select none
	transition box-shadow 0.15s easeOutQuart
	&:last-child
		margin 0
	&:hover
		box-shadow 0 3px 6px 2px alpha(#000, 30%)
		.right .footer ::v-deep(.ui-button)
			background-color #1F927E
	&:active, &.loading
		transform translateY(2px)
		box-shadow @box-shadow
		.right .footer ::v-deep(.ui-button)
			background-color #1F927E
	.left
		width 80px
		padding 8px
		text-align right
	.right
		width calc(100% - 80px)
		border-left 1px solid #F2D0AE
		.infos
			padding 4px
			.info
				flex center flex-start
				flex-wrap wrap
				padding 6px 12px
				background-color #F8F7F2
				border-radius 4px
				margin 0 0 4px 0
				&:last-child
					margin 0
				.label
					display inline-block
					width auto
					white-space nowrap
					margin 0 8px 0 0
					color #789091
				.value
					display inline-block
		.footer
			padding 4px
			border-top 1px solid #ccc
			::v-deep(.ui-button)
				text-align left
				display block
				background-color #789091
				transition background-color 0.15s easeOutQuart
	> .ui-loader
		background alpha(#fff, 50%)
		border-radius 4px

</style>
