<template>
	<div class="modal-edit-alternatives">

		<div class="texts">
			<p class="sentence">{{ $t('dashboard.modalEditAlternativesInfo') }}</p>
			<div class="user">
				<p class="email">{{ school.profile.school_name }}</p>
				<p class="email">{{ school.email }}</p>
			</div>
		</div>


		<div v-if="alternatives.length" class="potagers" :class="{ 'has-selection': school.alternatives.length > 0 }">
			<div v-for="potager in alternatives" :key="potager.id" class="potager" v-tap="() => togglePotager(potager)"
					:class="{ selected: school.alternatives.includes(potager.id), loading: loading === potager.id }"
				>
				<div class="image"><img :src="potager.img"></div>
				<div class="texts">
					<p>{{ $t('potagerSelection.' + potager.id.replace('.', '')) }}</p>
					<p>{{ $t('potagerSelection.' + potager.id.replace('.', '') + 'Pods') }}</p>
				</div>
				<ui-loader v-if="loading === potager.id" />
			</div>
		</div>

		<div class="buttons">
			<ui-button color="dark-green" size="big" :block="true" v-tap="() => $emit('close')">{{ $t('adminDashboard.modals.global.close') }}</ui-button>
		</div>

	</div>
</template>

<script>
// import { mapState, mapActions } from 'pinia'
// import { useAdminDashboardStore } from '@/store/dashboard'
import { addAlternative, removeAlternative } from '@/requests/dashboard'

export default {
	data() {
		return {
			loading: null,
			alternatives: [
				{ id: "s02", img: require('@/assets/img/potagers/s02.jpg') },
				{ id: "s03", img: require('@/assets/img/potagers/s03.jpg') },
				{ id: "s03.2", img: require('@/assets/img/potagers/s032.jpg') },
			]
			// potagers: [
			// 	{ id: "s02", img: require('@/assets/img/potagers/s02.jpg') },
			// 	{ id: "s03", img: require('@/assets/img/potagers/s03.jpg') },
			// ]
		}
	},
	props: {
		modal: { default: null }
	},
	computed: {
		school() {
			return this.modal.school || null
		}
	},
	methods: {
		// ...mapActions(useAdminDashboardStore, [ 'addAlternative', 'removeAlternative' ]),

		async removeAlternative(alternative) {
			if (await removeAlternative({ userId: this.school.id, alternative })) {
				this.school.alternatives = this.school.alternatives.filter(a => a !== alternative)
			}
		},
		async addAlternative(alternative) {
			if (await addAlternative({ userId: this.school.id, alternative })) {
				this.school.alternatives.push(alternative)
			}
		},
		async togglePotager(potager) {
			if (!this.loading) {
				this.loading = potager.id

				if (this.school.alternatives.includes(potager.id)) {
					await this.removeAlternative(potager.id)
				} else {
					await this.addAlternative(potager.id)
				}
				// this.user.alternatives.push(potager.id)
				// const response = await resendTeacherInvitation({ email: this.user.email })
				// if (response) {
				// 	useAppStore().addNotification({ status: 'success', title: this.$t('notifications.sendInvitationSuccessTitle'), text: this.$t('notifications.sendInvitationSuccessText') })
				// } else
				// 	useAppStore().addNotification({ status: 'error', title: this.$t('notifications.sendInvitationErrorTitle'), text: this.$t('notifications.sendInvitationErrorText') })
				this.loading = null
				// this.close()
			}
		},
		close() {
			this.$emit('close')
		},
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.modal-edit-alternatives
	text-align center
	.texts
		margin 0 0 32px 0
		> p
			margin 0 0 8px 0
			&:last-child
				margin-bottom 0

	.user
		width 100%
		padding 16px 32px
		margin 0 0 40px 0
		background-color #D5DEDE
		border-radius 4px
		inline-flex center center column
		.name
			font-size 1.8rem
			font-weight 500
			text-transform uppercase
		
	.potagers
		width 100%
		flex center center
		flex-wrap wrap
		gap 8px
		margin 0 0 32px 0
		.potager
			width calc(50% - 8px)
			flex center flex-start
			text-align left
			gap 16px
			padding 16px
			border-radius 8px
			background-color #EFF3F3
			cursor pointer
			&:hover:not(.loading)
				background-color darken(#EFF3F3, 5%)
			&.selected
				box-shadow inset 0 0 0 3px #0D4444
				background darken(@background-color, 10%) url('@/assets/img/check-dark-green.svg') right 8px center no-repeat
				background-size 40px 40px
			.image
				img
					width 80px
					vertical-align middle
					border-radius 8px
			.texts
				:first-child
					font-weight 600
			.ui-loader
				background-color alpha(primary, 10%)
				border-radius 8px
				color #000

	.buttons
		flex center center
		gap 16px
	

</style>