<template>
	<div v-if="canAccess" class="admin-dashboard">

		<!-- Uncomment to display footer: -->
		<!-- <transition-group name="side-panel" tag="div" class="panels" :class="{ 'rdv-bar-visible': rdvBarVisible }"> -->
		
			<transition-group name="side-panel" tag="div" class="panels">
			<side-panel :panel="{ slug: startPanel }" key="default" />
			<side-panel
				v-for="(panel, index) in panels" :key="panel.id"
				:panel="panel"
				:index="index + 1"
			/>
		</transition-group>

		<transition name="bar-rdv">
			<div v-if="rdvBarVisible" class="bar-rdv">
				{{ $t('dashboard.rdvSentence') }}
				<span class="link-hubspot" @click="openHubspotLink">{{ $t('dashboard.rdvLink') }}</span>
			</div>
		</transition>

		<div class="home-button" v-tap="() => $router.push({ name: 'home' })"><div class="icon" v-bg="'home.svg'"></div></div>

		<transition-group v-if="modals?.length" name="modal" tag="div" class="modals" appear>
			<admin-modal v-for="modal in modals" :key="modal.id" :modal="modal" @close="removeModal(modal)" />
		</transition-group>

	</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useAppStore } from '@/store/app'
import { useOnboardingStore } from '@/store/onboarding'
import { useAdminDashboardStore } from '@/store/admin-dashboard'
import SidePanel from '@/components/admin-dashboard/panels/side-panel.vue'
import AdminModal from '@/components/admin-dashboard/modals/admin-modal.vue'

export default {
	computed: {
		...mapState(useAppStore, [ 'lang', 'role' ]),
		...mapState(useAdminDashboardStore, [ 'panels', 'modals', 'features' ]),
		startPanel() {
			return { vireo: 'home' }[this.role] || 'school'
		},
		canAccess() {
			return [ 'vireo', 'district', 'school', 'teacher' ].includes(this.role)
		},
		rdvBarVisible() {
			return this.role === 'school' && this.panels.length === 0
		}
	},
	methods: {
		...mapActions(useOnboardingStore, [ 'completeCheckpoint' ]),
		...mapActions(useAdminDashboardStore, [ 'removePanel', 'removeModal' ]),
		keyup(e) {
			if (e.code === 'Escape') {
				if (this.modals.length) this.removeModal(this.modals[this.modals.length - 1])
				else if (this.panels.length) this.removePanel(this.panels[this.panels.length - 1])
			}
		},
		openHubspotLink() {
			const url = this.lang === 'fr'
				? 'https://meetings.hubspot.com/mathieu-coupal/faisons-connaissance'
				: 'https://meetings.hubspot.com/mathieu-coupal/get-to-know-us'
			window.open(url)
			this.completeCheckpoint({ slug: 'prenez_rdv' })
		}
	},
	created() {
		if (!this.canAccess) {
			this.$router.replace({ name: 'home' })
			return
		}
		window.addEventListener('keyup', this.keyup)
	},
	beforeUnmount() {
		useAdminDashboardStore().$reset()
		window.removeEventListener('keyup', this.keyup)
	},
	components: { SidePanel, AdminModal }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.admin-dashboard
	absolute 0
	background-color #fff
	z-index 1
	.panels
		absolute 0
		&.rdv-bar-visible
			bottom 64px
	.modals
		absolute 0
	
	> .home-button
		position absolute
		left 0
		top 16px
		background red
		border-radius 0 8px 8px 0
		width 56px
		height 48px
		flex center center
		cursor pointer
		transform translateX(-4px)
		transition transform 0.15s easeOutQuart
		&:hover
			transform none
		.icon
			width 24px
			height 24px
			background center center no-repeat
			background-size 16px !important

	> .bar-rdv
		position absolute
		left 0
		right 0
		bottom 0
		height 64px
		padding 0 8px
		flex center center
		gap 8px
		background-color #F5A164
		color #fff
		font-size 1.8rem
		text-align center
		display none
		&.bar-rdv-enter-active
			transition 0.1s easeOutQuart
		&.bar-rdv-leave-active
			transition 0.15s easeInQuart
		&.bar-rdv-enter-from, &.bar-rdv-leave-to
			opacity 0
			transform translateY(100%)
		.link-hubspot
			cursor pointer
			color #104544
			text-decoration underline
			&:hover
				text-decoration none


</style>