<template>
	<div class="app-content" :id="device" :class="{ thin: ratio < 0.54, 'small-height': smallHeight, 'low-perf': settings.lowPerf }">

		<transition name="route" appear>
			<home v-if="status === 'ready'" />
		</transition>

		<router-view v-if="status === 'ready'" v-slot="{ Component }">
			<transition name="route" mode="out-in">
				<component v-if="$route.name !== 'home'" :is="Component" />
			</transition>
		</router-view>

		<transition name="tuto">
			<tuto v-if="currentTuto && !languageScreenVisible" :tuto="currentTuto" @end="endTuto" />
		</transition>

		<div v-if="!(($route.name === 'login' || $route.name === 'dashboard' || $route.name === 'help' || $route?.meta?.sectionNames?.includes('calendar')) && device === 'mobile')" class="logo" v-bg="'logo.png'"></div>

		<div v-if="!isConnected && $route.name === 'home' && device !== 'mobile'" class="global-menu">
			<div class="button" v-tap="toggleLang">{{ lang === 'en' ? 'fr' : 'en' }}</div>
		</div>

		<div v-if="$route.name !== 'invite'" class="side-menu top" :class="{ 'not-home': $route.name !== 'home', 'decal-for-rdv-bar': $route.name === 'dashboard' && role === 'school' }">
			<transition name="button" mode="out-in">
				<div v-if="userButton === 'home'" class="button home" key="home" v-tap="() => $router.push({ name: 'home' })">
					<div class="icon" v-bg="'home.svg'"></div>
					<div class="tooltip"><span class="text">{{ $t('tooltips.home') }}</span></div>
				</div>
				<div v-else-if="$route.name === 'home' && userButton === 'login'" class="button login" key="login" v-tap="() => $router.push({ name: 'login' })">
					<div class="icon" v-bg="'login.svg'"></div>
					<div class="tooltip"><span class="text">{{ $t('tooltips.login') }}</span></div>
				</div>
				<!-- <div v-else-if="userButton === 'dashboard'" class="button dashboard" key="dashboard" v-tap="() => $router.push({ name: 'dashboard' })">
					<div class="icon" v-bg="'dashboard.svg'"></div>
					<div class="tooltip"><span class="text">{{ $t('tooltips.dashboard') }}</span></div>
				</div> -->
			</transition>
			<!-- <transition name="button" mode="out-in">
				<div v-if="$route.name === 'home' && isConnected && user?.groups?.length > 1" class="button group" key="group" v-tap="() => setModalGroupSelectionVisible(true)">
					<div class="icon" v-bg="'users.svg'"></div>
					<div class="tooltip"><span class="text">{{ $t('tooltips.groupSelection') }}</span></div>
				</div>
			</transition>
			<transition name="button" mode="out-in">
				<div v-if="$route.name === 'home' && isConnected && role === 'vireo'" class="button stats" key="stats" v-tap="() => $router.push({ name: 'stats' })">
					<div class="icon" v-bg="'stats.svg'"></div>
					<div class="tooltip"><span class="text">{{ $t('tooltips.stats') }}</span></div>
				</div>
			</transition> -->
		</div>

		<div class="side-menu bottom">
			<transition name="button" mode="out-in">
				<div v-if="$route.name === 'home'" class="button calendar" key="calendar" v-tap="() => goto('calendar-details')">
					<div class="icon" v-bg="'calendar.svg'"></div>
					<div v-if="newEventIndicatorVisible" class="new-indicator"></div>
					<div class="tooltip"><span class="text">{{ $t('tooltips.calendar') }}</span></div>
				</div>
			</transition>
			<transition name="button" mode="out-in">
				<div v-if="$route.name === 'home'" class="button chatbot" key="chatbot" v-tap="() => goto('help')">
					<div class="icon" v-bg="'chatbot.svg'"></div>
					<div class="tooltip"><span class="text">{{ $t('tooltips.chatbot') }}</span></div>
				</div>
			</transition>
			<transition name="button" mode="out-in">
				<div v-if="$route.name === 'home' && device !== 'mobile'" class="button faq" key="faq" v-tap="() => goto('faq')">
					<div class="icon" v-bg="'help-circle.svg'"></div>
					<div class="tooltip"><span class="text">{{ $t('tooltips.faq') }}</span></div>
				</div>
			</transition>
			<transition name="button" mode="out-in">
				<div v-if="$route.name === 'home' && !isConnected" class="button settings" key="settings" v-tap="displaySettings">
					<div class="icon" v-bg="'settings.svg'"></div>
					<div class="tooltip"><span class="text">{{ $t('tooltips.settings') }}</span></div>
				</div>
			</transition>
			<!-- <transition name="button" mode="out-in">
				<div v-if="isConnected && ($route.name === 'home' || $route.name === 'reset-password')" class="button logout" key="logout" v-tap="() => modalLogoutConfirmationVisible = true">
					<div class="icon" v-bg="'logout.svg'"></div>
					<div class="tooltip"><span class="text">{{ $t('tooltips.logout') }}</span></div>
				</div>
			</transition> -->
		</div>

		<transition name="user-menu">
			<user-menu v-if="$route.name === 'home' && isConnected" :loading="loading" @logout="() => modalLogoutConfirmationVisible = true" />
		</transition>

		<transition name="modal-section">
			<modal-section v-if="modalSection" :section="modalSection" />
		</transition>

		<transition name="modal">
			<modal v-if="modal" :modal="modal" />
		</transition>

		<transition name="onboarding">
			<onboarding v-if="onBoardingVisible" />
		</transition>

		<transition name="modal-potager-selection">
			<modal-potager-selection v-if="modalPotagerSelectionVisible" />
		</transition>

		<transition name="modal-group-selection">
			<modal-group-selection v-if="modalGroupSelectionVisible && $route.name !== 'invite'" />
		</transition>

		<transition name="modal-locked-content">
			<modal-locked-content v-if="modalLockedContent" />
		</transition>

		<transition name="modal-login">
			<modal-login v-if="modalLoginVisible" />
		</transition>

		<transition name="modal-chatbot-infos">
			<modal-chatbot-infos v-if="modalChatbotInfosVisible" @close="() => modalChatbotInfosVisible = false" />
		</transition>

		<transition name="modal" mode="out-in">
			<modal-reset-tuto-confirmation v-if="modalResetTutoConfirmationVisible" @close="() => modalResetTutoConfirmationVisible = false" />
		</transition>

		<transition name="modal" mode="out-in">
			<modal-logout-confirmation v-if="modalLogoutConfirmationVisible" @close="() => modalLogoutConfirmationVisible = false" />
		</transition>

		<transition name="settings">
			<settings v-if="settingsVisible" />
		</transition>

		<transition name="loading-screen">
			<loading-screen v-if="status === 'loading'" />
		</transition>

		<language-screen v-if="languageScreenVisible" />

		<transition-group name="notification" class="notifications" tag="div">
			<ui-notification v-for="notification in notifications" :key="notification.id" :notification="notification" />
		</transition-group>

	</div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useAppStore } from '@/store/app'
import { useOnboardingStore } from '@/store/onboarding'
import { useCalendarStore } from '@/store/calendar'
import { useWebinarStore } from '@/store/webinar'
import Home from '@/components/home'
import Tuto from '@/components/tuto'
import Modal from '@/components/modal'
import ModalSection from '@/components/modal-section'
import Settings from '@/components/settings'
import UserMenu from '@/components/user-menu'
import LoadingScreen from '@/components/loading-screen'
import UiNotification from '@/components/ui/ui-notification'
import LanguageScreen from '@/components/language-screen.vue'
import ModalResetTutoConfirmation from '@/components/modal-reset-tuto-confirmation'
import ModalLogoutConfirmation from '@/components/login/modal-logout-confirmation'
import ModalLockedContent from '@/components/modal-locked-content'
import ModalGroupSelection from '@/components/modal-group-selection'
import ModalPotagerSelection from '@/components/modal-potager-selection'
import ModalChatbotInfos from '@/components/modal-chatbot-infos'
import ModalLogin from '@/components/modal-login'
import Onboarding from '@/components/home/onboarding'
import { getMainSection } from '@/requests/app'
import { loadMedias } from '@/requests/medias'
import { getUser, loginWithInvitation, removeHeaders, addHeaders } from '@/requests/login'
import { localStorage, medias } from '@affordancestudio/engage-game'
import { wait } from '@affordancestudio/functions'

export default {
	components: { Home, Tuto, Settings, Modal, UserMenu, LoadingScreen, UiNotification, LanguageScreen, ModalLogoutConfirmation, ModalSection, ModalLockedContent, ModalGroupSelection, ModalPotagerSelection, ModalChatbotInfos, Onboarding, ModalResetTutoConfirmation, ModalLogin },
	name: 'app',
	data() {
		return {
			modalLogoutConfirmationVisible: false,
			modalChatbotInfosVisible: false,
		}
	},
	computed: {
		...mapWritableState(useAppStore, [ 'currentUserGroupId', 'modalResetTutoConfirmationVisible' ]),
		...mapState(useAppStore, [ 'lang', 'status', 'device', 'ratio', 'smallHeight', 'modal', 'isConnected', 'role', 'notifications', 'settings', 'settingsVisible', 'user', 'languageScreenVisible', 'modalSection', 'modalLockedContent', 'modalGroupSelectionVisible', 'modalPotagerSelectionVisible', 'modalLoginVisible' ]),
		...mapState(useOnboardingStore, [ 'currentTuto', 'completedCheckpoint', 'rawCheckpoints', 'checkpoints', 'activeGroup', 'tutos', 'loading' ]),
		...mapState(useCalendarStore, [ 'events' ]),
		...mapState(useWebinarStore, [ 'webinars' ]),
		homeVisible() {
			return !this.currentTuto || this.currentTuto.name !== 'intro'
		},
		userButton() {
			if (this.$route.name === 'login' || this.$route.meta.authorizations) return 'home'
			if (!this.isConnected) return 'login'
			if (this.isConnected && this.$route.name === 'home' && ([ 'teacher', 'school', 'vireo' ].includes(this.role))) return 'dashboard'
			return null
		},
		onBoardingVisible() {
			return this.user && this.checkpoints?.length && this.activeGroup && (this.completedCheckpoint || (this.$route.name === 'home' && !this.modal))
		},
		newEventIndicatorVisible() {
			const today = new Date().toISOString().substr(0, 10)
			return this.events.findIndex(event => event?.date?.length > 10 && event.date.substr(0, 10) === today) > -1
		}
	},
	watch: {
		async $route(route) {
			this.checkUserResetPassword()
			if (this.$route?.meta?.checkpoint) {
				this.completeCheckpoint({ slug: this.$route.meta.checkpoint })
			}
			this.checkAndStartOnboardingIntroTuto()

			if (this.$route?.meta?.tuto) {
				await wait(1000)
				this.startTuto({ slug: this.$route.meta.tuto })
			}
		},
		rawCheckpoints: {
			async handler(rawCheckpoints) {
				if (this.rawCheckpoints?.length) {

					if (this.rawCheckpoints?.length > 1 && (this.rawCheckpoints[0]?.status === 'completed' || this.rawCheckpoints[1]?.status === 'completed')) {
						const tuto = this.tutos.find(t => t.slug === 'onboarding_middle_1')
						if (tuto?.status !== 'completed') {
							await wait(2000)
							this.startTuto({ slug: tuto.slug })
						}
					}

					if (this.rawCheckpoints?.length > 1 && (this.rawCheckpoints[0]?.status === 'completed' && this.rawCheckpoints[1]?.status === 'completed')) {
						const tuto = this.tutos.find(t => t.slug === 'onboarding_outro')
						if (tuto?.status !== 'completed') {
							await wait(2000)
							this.startTuto({ slug: tuto.slug })
						}
					}

				}
			},
			deep: true
		},
		async currentUserGroupId() {
			if (this.currentUserGroupId) {
				await this.loadOnboarding()
				this.checkAndStartOnboardingIntroTuto()
				removeHeaders()
				addHeaders({ headers: { group: this.currentUserGroupId }})
				localStorage.setItem('currentUserGroupId', this.currentUserGroupId)
			}
		},
		async user(user) {
			if (user && user?.groups) {
				if (!this.currentUserGroupId) {
					if (user.groups?.length > 1) {
						useAppStore().$patch({ modalGroupSelectionVisible: true })
					} else if (user.groups?.length === 1) {
						this.currentUserGroupId = user.groups[0].id
					} else if (user.groups?.length === 0) {
						await this.loadOnboarding()
						this.checkAndStartOnboardingIntroTuto()
					}
				}
				if (!this.events.length) this.initCalendar({ force: true })
				if (!this.webinars.length) this.loadWebinars()
			}
		},
	},
	methods: {
		...mapActions(useAppStore, [ 'setModalGroupSelectionVisible', 'addModal' ]),
		...mapActions(useOnboardingStore, [ 'loadOnboarding', 'startTuto', 'completeCheckpoint', 'endTuto' ]),
		...mapActions(useCalendarStore, [ 'initCalendar' ]),
		...mapActions(useWebinarStore, [ 'loadWebinars' ]),
		checkAndStartOnboardingIntroTuto() {
			if (this.$route.name === 'home' && this.user /*&& this.currentUserGroupId*/) {
				this.startTuto({ slug: 'onboarding_intro' })
			}
		},
		checkUserResetPassword() {
			if (this.isConnected && this.user.onboarding === 'is_reset' && this.$route.name !== 'reset-password') this.$router.push({ name: 'reset-password' })
		},
		goto(name) {
			if (name === 'help' && !this.isConnected) {
				this.modalChatbotInfosVisible = true
			} else
				this.$router.push({ name })
		},
		resize() {
			const store = useAppStore()
			store.setRatio({ width: window.innerWidth, height: window.innerHeight })
		},
		displaySettings() {
			useAppStore().setSettingsVisible(true)
		},
		async toggleLang() {
			const lang = this.lang === 'fr' ? 'en' : 'fr'
			localStorage.setItem('Language', lang)
			await medias.changeLanguage()
			await wait(500)
			location.reload()
		}
	},
	async created() {
		const start = Date.now()
		const appStore = useAppStore()

		document.title = this.$t('mainPageTitle')

		// SET LANG
		let lang = localStorage.getItem('Language')
		if (!lang) lang = navigator.language.substr(0, 2).toLowerCase()
		appStore.$patch({ lang: [ 'en', 'fr' ].includes(lang)  ? lang : 'en' })

		// SET SETTINGS
		let settings = false
		if (localStorage.getItem('vireo-settings')) {
			try {
				settings = JSON.parse(localStorage.getItem('vireo-settings'))
			} catch (e) {
				localStorage.removeItem('vireo-settings')
			}
		}
		if (settings) appStore.$patch({ settings })

		// GET USER
		let user = await getUser()

		// DISPLAY LANGUAGE SCREEN
		if (!localStorage.getItem('vireo-selected-lang') || !localStorage.getItem('vireo-accept-policy')) {
			const { pathname } = window.location
			if (!pathname.includes('/invite')) {
				appStore.$patch({ languageScreenVisible: true })
			}
		}

		// SET LOGIN WITH LOGIN (invite page)
		if (window.location.pathname === '/invite') {
			const invitationToken = new URL(location).searchParams.get('token')
			if (invitationToken) {
				if (user?.onboarding) {
					const emailFromUrl = new URL(location).searchParams.get('user')
					if (user?.onboarding !== 'is_anonymous' && user?.email === emailFromUrl) {
						return window.location.href = '/'
					}
				}
				appStore.logout()
				await loginWithInvitation({ invitationToken })
			} else
				return window.location.href = '/'
		}
		else if (user && user.onboarding === 'is_anonymous' && user.student) {
			appStore.logout()
			user = null
		}

		window.addEventListener('resize', this.resize)
		this.resize()

		const section = await getMainSection()
		await loadMedias()

		appStore.init({ section, user })

		this.checkUserResetPassword()

		if (!user) await this.loadOnboarding()

		appStore.$patch({ status: 'ready' })

		console.info('[ App started in ' + ((Date.now() - start) / 1000) + 's ]')
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.resize)
	},
}
</script>

<style lang="stylus">
@import './assets/css/reset'

#app .app-content#mobile,
#app .app-content#tablet-vertical
	> .logo
		width round(378px / 5)
		height round(212px / 5)
		bottom 8px
		top auto
		top auto
		left 'calc(50% - %s)' % round(@width / 2)
	.side-menu
		&.bottom
			absolute auto
			right 0
			top 88px
			flex flex-end center column-reverse
			// gap 8px
		&.top
			absolute auto
			right 0
			bottom 8px
			transition 0.25s easeOutQuart
			&.not-home
				left 0
				right auto
				flex flex-start center column
				.button
					transition none !important
					transform none
					border-radius 0 8px 8px 0
			&.decal-for-rdv-bar
				transform translateY(-56px)
		.button
			margin 0 0 8px 0
			width 48px
			height 48px
			padding-right 4px
			.icon
				width 24px
				height 24px


#app .app-content
	> .logo
		position fixed
		left 0
		top 0
		width round(378px / 3)
		height round(212px / 3)
		background center center no-repeat
		background-size contain
		pointer-events none

#app > .low-perf
	.side-menu .button
		transition none
		&:hover
			transform translateX(6px)

.global-menu
	position fixed
	right 8px
	top 8px
	flex center center
	.button
		display inline-block
		padding 0 16px
		height 40px
		min-width 40px
		margin 0 8px 0 0
		border-radius 4px
		font-weight 700
		text-transform uppercase
		background-color alpha(#fff, 15%)
		color #fff
		flex center center
		cursor pointer
		&.whats-next
			background-color #EAC435
			&:hover, &:active
				background-color darken(@background-color, 10%)
		&:hover, &:active
			background-color alpha(#fff, 25%)
		&:active
			transform translateY(1px)
		&:last-child
			margin 0
		> .icon
			absolute 0
			background center center no-repeat

.side-menu
	position fixed
	right 0
	width 64px
	flex flex-end center column
	// gap 8px
	&.top
		top 64px
	&.bottom
		bottom 32px
	.button
		display block
		// position absolute
		// left 0
		// top 0
		width 64px
		height 58px
		margin 0 0 8px 0
		transform translateX(6px)
		flex center center
		border-radius 8px 0 0 8px
		cursor pointer
		transition transform 0.25s easeOutQuart
		&:last-child
			margin 0
		&:hover
			transform translateX(0)
			.tooltip
				visibility visible
				transform translate(-4px, -50%)
				transition-delay 0.25s
				opacity 1
		&.login
			background-color #68BC9F
		&.home
			background-color #D67474
		&.dashboard
			background-color #FBBF53
		&.logout
			background-color #C9404E
		&.settings, &.faq, &.stats, &.group
			background-color #68BC9F
		&.chatbot, &.calendar
			background-color #306969
		&.button-enter-from, &.button-leave-to
			transform translateX(100%)
		.icon
			width 32px
			height 32px
			background center center no-repeat
		.new-indicator
			position absolute
			left -4px
			top -4px
			width 20px
			height 20px
			background-color #C83F4D
			box-shadow 0 0 0 2px #289592
			border-radius 50%
			pointer-events none
		.tooltip
			inline-flex center flex-end
			position absolute
			right calc(100% + 8px)
			top 50%
			width 200px
			transform translateY(-50%)
			opacity 0
			visibility hidden
			color #fff
			user-select none
			pointer-events none
			white-space nowrap
			transition 0.25s easeOutQuart
			// background-color alpha(#000, 50%)
			// padding 8px 16px
			// border-radius 4px
			&:before
				content ''
				position absolute
				right -7px
				top calc(50% - 8px)
				width 0
				height 0
				border-style solid
				border-width 8px 0 8px 8px
				border-color transparent transparent transparent #222
			.text
				display inline-block
				background-color #222
				padding 8px 16px
				border-radius 4px

.notifications
	position fixed
	left 16px
	top 16px
	width 340px
	z-index 5000
	.ui-notification
		margin 0 0 8px 0

</style>
