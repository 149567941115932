import { engage, localStorage } from '@affordancestudio/engage-game'

let lang = localStorage.getItem('Language')
if (!lang) lang = navigator.language.substr(0, 2).toLowerCase()

const env = {
	VUE_APP_CLIENT_ID: process.env.VUE_APP_CLIENT_ID,
	VUE_APP_END_POINT_STAGE: process.env.VUE_APP_END_POINT_STAGE,
	VUE_APP_END_POINT_TYPE: process.env.VUE_APP_END_POINT_TYPE,
	VUE_APP_ENV: process.env.VUE_APP_ENV,
	VUE_APP_PROXY_END_POINT: process.env.VUE_APP_PROXY_END_POINT,
}
engage.setOptions({ createAnonymousUser: false, env })
engage.setLanguage({ language: [ 'en', 'fr' ].includes(lang)  ? lang : 'en' })

console.info('=== INIT ENGAGE ===')

export { engage }
