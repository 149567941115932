import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import { createPinia } from 'pinia'
import { i18n } from '@/js/i18n'

import UiText from '@/components/ui/ui-text'
import UiButton from '@/components/ui/ui-button'
import UiLoader from '@/components/ui/ui-loader'
import UiImage from '@/components/ui/ui-image'

import BackgroundImage from '@/js/plugins/background-image'
import TouchEvents from '@/js/plugins/touch-events'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

// Polyfill for https://caniuse.com/css-scroll-behavior
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

import userflow from 'userflow.js'

userflow.init(process.env.VUE_APP_USERFLOW_ID)
userflow.identifyAnonymous().then()

createApp(App)
	.use(BackgroundImage)
	.use(TouchEvents, { events: [ 'tap' ] })
	.use(PerfectScrollbar)
	.use(createPinia())
	.use(i18n)
	.use(router)
	.component('ui-text', UiText)
	.component('ui-button', UiButton)
	.component('ui-loader', UiLoader)
	.component('ui-image', UiImage)
	.mount('#app')
