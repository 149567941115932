<template>
	<div class="modal-locked-content">
		<div class="bg" v-tap="close"></div>
		<div class="content">
			
			<div class="title">{{ $t('home.lockedContentTitle') }}</div>
			<div class="text">
				<p>{{ $t('home.lockedContentSentence') }}</p>
			</div>
			<div class="buttons">
				<ui-button class="button" size="big" :block="true" color="dark-green" v-tap="login">{{ $t('home.lockedContentButton') }}</ui-button>
				</div>
			
			<div class="close" :title="$t('settings.close')" v-tap="close">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
			</div>
		</div>
	</div>
</template>

<script>
import { useAppStore } from '@/store/app'

export default {
	name: 'modal-locked-content',
	methods: {
		login() {
			this.$router.push({ name: 'login' })
			this.close()
		},
		close() {
			useAppStore().$patch({ modalLockedContent: false })
		}
	},
}
</script>

<style lang="stylus" scoped>

#app > .low-perf
	.modal-locked-content
		transition none
		.bg, .content
			transition none
			backdrop-filter none

#mobile
	.modal-locked-content
		padding 16px


.modal-locked-content
	fixed 0
	flex center center
	z-index 100000
	&.modal-locked-content-enter-active
		transition 0.2s easeOutQuart
		.bg
			transition 0.2s easeOutQuart
		.content
			transition 0.2s easeOutBack
	&.modal-locked-content-enter-from
		.bg
			opacity 0
		.content
			opacity 0
			transform scale(0.95)

	&.modal-locked-content-leave-active
		transition 0.25s easeOutQuart
	&.modal-locked-content-leave-to
		opacity 0

	.bg
		background-color alpha(#446364, 70%)
		absolute 0
		backdrop-filter blur(8px)
		transition 0.2s easeOutQuart
	.content
		width 100%
		max-width 600px
		background-color #fff
		padding 32px
		text-align center
		border-radius 6px
		.title
			margin 0 0 16px 0
			font-size 2.8rem
			color #748787
			text-transform uppercase
		.text
			margin 0 0 32px 0
			font-size 1.8rem
			color #7D9697
		.buttons
			.button
				width 100%
				background-color #7D9697
				&:hover
					background-color #748787
	.close
		position absolute
		right 8px
		top 8px
		width 32px
		height 32px
		color red
		flex center center
		cursor pointer
		transition 0.1s easeOutQuart
		&:hover
			transform scale(1.1)
		&:active
			transform scale(0.9)
		svg
			width @width
			height @height
			
		
</style>
