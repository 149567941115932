<template>
	<div v-if="subElement?.entry" class="form-maintenance-select" :class="{ 'half': isImagesSelect }">

		<template v-if="editable">
			<div v-for="selectableElement in subElement.entry.selectableElements" :key="selectableElement.id" class="element" :class="{ 'has-image': selectableElement.option?.tags?.includes('with_img') }">
				<ui-checkbox v-if="type === 'checkbox'" :text="selectableElement.option.html" v-model="localValues[selectableElement.id]" :image="getSelectImageUrl(selectableElement)" />
				<ui-radio v-if="type === 'radio'" :text="selectableElement.option.html" :value="selectableElement.id" v-model="radioValue" />
			</div>
		</template>

		<template v-else>
			<div v-for="selectableElement in subElement.entry.selectableElements" :key="selectableElement.id" class="element" :class="{ 'has-image': selectableElement.option?.tags?.includes('with_img') }">
				<ui-checkbox v-if="type === 'checkbox'" :text="selectableElement.option.html" v-model="uneditableValue" :image="getSelectImageUrl(selectableElement)" :editable="false" />
				<ui-radio v-if="type === 'radio'" :text="selectableElement.option.html" :value="uneditableValue" v-model="uneditableValue" :editable="false" />
			</div>
		</template>

	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useFormMaintenanceStore } from '@/store/form-maintenance'
import { getMedia } from '@/requests/medias'
import UiCheckbox from '@/components/ui/ui-checkbox.vue'
import UiRadio from '@/components/ui/ui-radio.vue'

export default {
	name: 'form-maintenance-select',
	data() {
		return {
			ready: false,
			radioValue: null,
			localValues: null,
			uneditableValue: true,
		}
	},
	props: {
		subElement: { default: null },
		editable: { default: true }
	},
	computed: {
		...mapState(useFormMaintenanceStore, [ 'subElementsValues' ]),
		isImagesSelect() {
			return this.subElement.entry.selectableElements.some(selectableElement => selectableElement.option?.tags?.includes('with_img'))
		},
		type() {
			if (this.subElement.entry.tags.includes('radio')) return 'radio'
			return 'checkbox'
		},
		subElementValue: {
			get() { return this.subElementsValues.find(v => v.id === this.subElement.id) },
			set(value) { this.subElementsValues.find(v => v.id === this.subElement.id).value = value },
		}
	},
	watch: {
		localValues: {
			handler(values) {
				if (this.ready) this.subElementValue = Object.keys(values).filter(id => values[id])
			},
			deep: true
		},
		radioValue(value) {
			if (this.ready) this.subElementValue = [ value ]
		}
	},
	methods: {
		getSelectImageUrl(selectableElement) {
			if (selectableElement.option?.clientMedias?.length) {
				return getMedia({ id: selectableElement.option?.clientMedias[0]?.id })
			}
			return null
		}
	},
	created() {
		this.ready = false
		this.localValues = {}
		this.subElement.entry.selectableElements.forEach(selectableElement => {
			this.localValues[selectableElement.id] = this.subElementValue?.value?.includes(selectableElement.id)
		})
		if (this.type === 'radio') this.radioValue = this.subElementValue?.value?.length ? this.subElementValue.value[0] : null
		this.$nextTick(() => {
			this.ready = true
		})
	},
	components: { UiCheckbox, UiRadio }
}
</script>

<style lang="stylus" scoped>

#mobile
	.form-maintenance-select
		&.half
			.element
				&.has-image
					margin 0
					width 100%

.form-maintenance-select
	margin 0 0 16px 0
	&.half
		flex flex-start flex-start
		flex-wrap wrap
		gap 16px
		.element
			&.has-image
				margin 0
				width calc(50% - 12px)
	.element
		margin 0 0 16px 0
		width 100%

</style>
