import { defineStore } from 'pinia'
import { useAppStore } from '@/store/app'

export const useWebinarStore = defineStore({
	id: 'webinar',

	state: () => ({
		loadingState: 'not-loaded',
		rawWebinars: [],
	}),

	getters: {
		webinars() {
			if (!useAppStore().isConnected) {
				return this.rawWebinars.filter(w => w.guest === 'oui')
			}
			return this.rawWebinars
		}
	},

	actions: {

		async loadWebinars() {
			if (this.loadingState === 'not-loaded') {
				this.loadingState = 'loading'

				const request = await fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vQUXDxYH7PRHFXoQiIbVH6jv8LmMbeJPPx7NBdEGNm8vjvT9nqgeJG4fxG1JrrbtdWzoqC7X0dMC_Qd/pub?output=tsv')
				const csv = await request.text()
				const rows = csv.split('\r\n')
				rows.filter((row, index) => index > 1).forEach((row, index) => {
					const fields = row.split('	')
					if (fields[0] && fields[1] && fields[2] && fields[3] && fields[4] && fields[5]) {
						let date = null
						if (fields[3].length === 2) fields[3] += ':00:00'
						if (fields[3].length === 5) fields[3] += ':00'
						if (fields[2] && fields[3] && fields[2].length === 10 && fields[3].length === 8 && fields[2].split('-').length === 3 && fields[3].split(':').length === 3) {
							date = new Date(fields[2] + ' ' + fields[3])
							if (date) date = date.getTime() / 1000
						}
						this.rawWebinars.push({
							id: (index + 1),
							title: fields[0],
							description: fields[1],
							usDate: fields[2],
							date,
							room_link: fields[4],
							duration: fields[5],
							checkpoint: fields[6],
							lang: fields[7] || null,
							guest: fields[8] || 'non',
						})
					}
				})

				this.loadingState = 'loaded'
			}
		},


	},

})
