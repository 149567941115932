<template>
	<div class="sidebar-filters">
		
		<div class="title">Mes potagers</div>

		<div class="filters">
			<div v-for="calendar in calendars" :key="calendar.id" class="filter" v-tap="() => toggle(calendar)">
				<div class="checkbox">
					<div v-show="visibleCalendarIds.includes(calendar.id)" class="check" v-bg="'check.svg'"></div>
				</div>
				<div class="name" v-html="calendar.title"></div>
				<div class="color" :style="{ backgroundColor: calendar.color }"></div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapState, mapWritableState } from 'pinia'
import { useCalendarStore } from '@/store/calendar'

export default {
	computed: {
		...mapState(useCalendarStore, [ 'calendars' ]),
		...mapWritableState(useCalendarStore, [ 'visibleCalendarIds' ])
	},
	methods: {
		toggle(calendar) {
			if (this.visibleCalendarIds.includes(calendar.id)) {
				this.visibleCalendarIds = this.visibleCalendarIds.filter(id => id !== calendar.id)
			} else {
				this.visibleCalendarIds.push(calendar.id)
			}
		}
	}
}
</script>

<style lang="stylus" scoped>

.sidebar-filters
	position absolute
	left 0
	top 0
	width 250px
	height 100%
	padding 16px
	background-color #EFEDE1
	box-shadow 2px 0 4px alpha(#000, 50%)
	&.sidebar-filters-enter-active
		transition 0.25s easeOutQuart
	&.sidebar-filters-leave-active
		transition 0.25s easeInQuart
	&.sidebar-filters-enter-from, &.sidebar-filters-leave-to
		transform translateX(-110%)
	
	.title
		border-bottom 1px solid #0F4444
		margin 0 0 24px 0
		font-size 1.8rem
		text-transform uppercase
		font-weight 500
	
	.filters
		.filter
			flex center flex-start
			gap 8px
			margin 0 0 4px 0
			cursor pointer
			padding 8px 12px
			border-radius 4px
			&:hover
				background-color alpha(#000, 10%)
			.checkbox
				width 24px
				height 24px
				background-color #fff
				border 1px solid #7F9993
				.check
					position absolute
					left 2px
					bottom 2px
					width 120%
					height 120%
					background center center no-repeat
			.color
				position absolute
				right 12px
				top calc(50% - 8px)
				width 16px
				height 16px
				border-radius 50%


</style>
