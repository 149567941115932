<template>
	<div class="side-panel" :class="{ over: index > 0 }" :data-index="index">

		<component :is="'side-panel-' + panel.slug" :panel="panel" class="panel" />
		<div v-if="!isOnTop" class="prevent" v-tap="tap"></div>

		<transition name="back-button" appear>
			<div v-if="backButtonVisible" class="back-button" v-tap="back" v-bg="'admin/back-light-yellow.svg'"></div>
		</transition>

	</div>
</template>

<script>
import { mapWritableState, mapActions } from 'pinia'
import { useAdminDashboardStore } from '@/store/admin-dashboard'
import SidePanelHome from '@/components/admin-dashboard/panels/side-panel-home.vue'
import SidePanelDistrict from '@/components/admin-dashboard/panels/side-panel-district.vue'
import SidePanelSchool from '@/components/admin-dashboard/panels/side-panel-school.vue'
import SidePanelGroup from '@/components/admin-dashboard/panels/side-panel-group.vue'
import SidePanelTeacher from '@/components/admin-dashboard/panels/side-panel-teacher.vue'
import SidePanelCalendar from '@/components/admin-dashboard/panels/side-panel-calendar.vue'

export default {
	props: {
		panel: { default: null, required: true },
		index: { default: 0 },
	},
	computed: {
		...mapWritableState(useAdminDashboardStore, [ 'panels' ]),
		isOnTop() {
			if (this.panels.length) {
				return this.panel?.id === this.panels[this.panels.length - 1]?.id
			}
			return true
		},
		backButtonVisible() {
			return this.isOnTop && this.index > 0
		}
	},
	methods: {
		...mapActions(useAdminDashboardStore, [ 'removePanel' ]),
		tap() {
			if (!this.isOnTop) {
				this.panels = this.panels.filter((p, index) => index < this.index)
			}
		},
		back() {
			this.removePanel(this.panel)
		}
	},
	components: { SidePanelHome, SidePanelDistrict, SidePanelSchool, SidePanelGroup, SidePanelTeacher, SidePanelCalendar }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.side-panel
	absolute 0
	background-color #fff
	
	&.over
		border-left 16px solid #FEE0B0
		box-shadow 0 -8px 16px alpha(#000, 75%)
		for i in 1..20
			&[data-index=\"{i}\"]
				left (64px * i)
	
	&.side-panel-enter-active
		transition 0.25s easeOutQuart
	&.side-panel-enter-from
		transform translate3D(105%, 0, 0)
	&.side-panel-leave-active
		transition 0.25s easeInQuart
	&.side-panel-leave-to
		transform translate3D(105%, 0, 0)

	::v-deep(.panel)
		height 100%
		width 100%
		flex center center
		.side
			height 100%
			padding 64px 24px 0 24px

			&.secondary
				width 30%
				overflow auto
			&.primary
				width 70%

				> .main-title
					height 48px
					margin 0 0 16px 0
					flex center flex-start
					border-bottom 1px solid #0F4444
					font-size 2.4rem
					font-weight 500
					text-transform uppercase
					.arrow
						width 48px
						height 48px
						background url('@/assets/img/admin/chevron-down-white.svg') center center no-repeat
						background-size 24px
						transform rotate(-90deg)
					
			
			&.white
				background-color #fff
				color #0F4444
			&.green
				background-color #0F4444
				color #fff
			
			&.has-boxes
				flex center space-between column
				padding 80px 24px 24px 24px
				> .header
					width 100%
					margin 0 0 8px 0
					flex center space-between
					text-transform uppercase
					font-size 2.4rem
					.actions
						flex center center
						gap 8px
				> .content
					width 100%
					flex center center column
					gap 16px
					> .boxes
						width 100%
						flex center center column
						gap 16px
						padding @gap 0 0 0
						border-top 2px solid #E7EEEE
					> .buttons
						width 100%
						flex center center
						gap 16px
						.ui-button
							width 100%
							&.array
								width 140px
	
	.back-button
		position absolute
		left -48px
		bottom 16px
		width 64px
		height 64px
		border-radius 50%
		background #0F4444 center center no-repeat
		background-size 32px auto !important
		border 4px solid #FEE0B0
		cursor pointer
		transition transform 0.15s easeOutBack
		&:hover
			transform rotate(-4deg) scale(1.1)
			box-shadow 0 0 4px alpha(#000, 50%)
		&:active
			transform rotate(0)
		
		&.back-button-enter-active
			transition 0.15s easeOutBack 0.35s
		&.back-button-enter-from
			transform scale(0.5)
			opacity 0
		&.back-button-leave-active
			transition 0.15s easeInQuart
		&.back-button-leave-to
			transform scale(0.5)
			opacity 0
			
		
	.prevent
		absolute 0
		left -16px
		// background-color alpha(#fff, 95%)
	


</style>