<template>
	<div class="user-menu" :class="{ open: dropdownVisible }" v-click-away="close">

		<div class="initials-button" :class="{ active: dropdownVisible, loading }" v-tap="toggleDropdown">
			<span v-if="loading" class="loader"><ui-loader /></span>
			<span v-else>{{ initials }}</span>
		</div>

		<transition name="dropdown-menu">
			<div v-if="!loading && dropdownVisible" class="dropdown-menu">

				<div class="item ellipsis no-hover">
					<span class="icon"><span class="initials">{{ initials }}</span></span>
					<span class="text">{{ emailOrUsername }}</span>
				</div>

				<template v-if="[ 'district', 'teacher', 'school', 'vireo' ].includes(this.role)">
					<div class="separator"></div>

					<div class="item" v-tap="() => goto({ name: 'admin-dashboard' })">
						<span class="icon"></span>
						<span class="text">{{ $t('userMenu.dashboard') }}</span>
					</div>
					<div v-if="role === 'vireo'" class="item" v-tap="() => goto({ name: 'stats' })">
						<span class="icon"></span>
						<span class="text">{{ $t('userMenu.stats') }}</span>
					</div>
				</template>

				<div class="separator"></div>

				<div class="item no-hover" :class="{ loading: switchLangLoading }">
					<span class="icon"></span>
					<span class="switch">
						<span class="switch-item" v-tap="() => setLang('fr')" :class="{ active: lang === 'fr' }">{{ $t('userMenu.french') }}</span>
						<span class="switch-item" v-tap="() => setLang('en')" :class="{ active: lang === 'en' }">{{ $t('userMenu.english') }}</span>
					</span>
					<div v-if="switchLangLoading" class="loading">
						<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><circle cx="4" cy="12" r="1.5"><animate attributeName="r" dur="0.75s" values="1.5;3;1.5" repeatCount="indefinite"/></circle><circle cx="12" cy="12" r="3"><animate attributeName="r" dur="0.75s" values="3;1.5;3" repeatCount="indefinite"/></circle><circle cx="20" cy="12" r="1.5"><animate attributeName="r" dur="0.75s" values="1.5;3;1.5" repeatCount="indefinite"/></circle></svg>
					</div>
				</div>
				<div v-if="user?.groups?.length > 1" class="item" v-tap="displayModalGroupSelection">
					<span class="icon"></span>
					<span class="text">{{ $t('userMenu.groupSelection') }}</span>
				</div>
				<div class="item" v-tap="togglePerf">
					<span class="icon" :class="{ checked: settings.lowPerf }"></span>
					<span class="text">{{ $t('userMenu.lowPerformanceMode') }}</span>
				</div>
				<div class="item" v-tap="retryTuto">
					<span class="icon"></span>
					<span class="text">{{ $t('userMenu.retryTuto') }}</span>
				</div>

				<div class="separator"></div>

				<div class="item" v-tap="logout">
					<span class="icon"></span>
					<span class="text">{{ $t('userMenu.logout') }}</span>
				</div>

				<div class="separator"></div>

				<a class="item link" :href="pdfs[lang].privacyPolicy" target="blank">
					<span class="icon external-link"></span>
					<span class="text">{{ $t('userMenu.privacyPolicy') }}</span>
				</a>
				<a class="item link" :href="pdfs[lang].termeOfUse" target="blank">
					<span class="icon external-link"></span>
					<span class="text">{{ $t('userMenu.termsOfUse') }}</span>
				</a>

			</div>
		</transition>

	</div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useAppStore } from '@/store/app'
import { localStorage, medias } from '@affordancestudio/engage-game'
import { directive } from "vue3-click-away"
import { wait } from '@affordancestudio/functions'

export default {
	data() {
		return {
			dropdownVisible: false,
			switchLangLoading: false,
		}
	},
	props: {
		loading: { default: true }
	},
	computed: {
		...mapState(useAppStore, [ 'user', 'role', 'pdfs' ]),
		...mapWritableState(useAppStore, [ 'lang', 'settings', 'modalResetTutoConfirmationVisible' ]),
		initials() {
			if (this.user?.profile?.initials) return this.user.profile.initials
			if (this.user?.firstName && this.user?.lastName) return (this.user.firstName + ' ' + this.user.lastName).split(" ").map(n => n[0]).join("")
			return this.user?.userName[0]
		},
		emailOrUsername() {
			return this.user?.email ||this.user?.userName
		}
	},
	methods: {
		...mapActions(useAppStore, [ 'setModalGroupSelectionVisible' ]),
		toggleDropdown() {
			if (!this.loading) {
				this.dropdownVisible = !this.dropdownVisible
			}
		},
		togglePerf() {
			this.settings.lowPerf = !this.settings.lowPerf
			localStorage.setItem('vireo-settings', JSON.stringify(this.settings))
		},
		retryTuto() {
			this.modalResetTutoConfirmationVisible = true
			this.close()
		},
		goto(route) {
			// if (route.name === 'dashboard' && this.role === 'vireo') {
			// 	this.$router.push({ ...route, name: 'admin-dashboard' })
			// } else
			this.$router.push(route)
			this.close()
		},
		displayModalGroupSelection() {
			this.setModalGroupSelectionVisible(true)
			this.close()
		},
		async setLang(lang) {
			if (lang !== this.lang) {
				this.switchLangLoading = true
				localStorage.setItem('Language', lang)
				this.lang = lang
				await medias.changeLanguage()
				await wait(500)
				location.reload()
			}
		},
		logout() {
			this.$emit('logout')
			this.close()
		},
		close() {
			this.dropdownVisible = false
		},
	},
	directives: {
		ClickAway: directive
	}
}
</script>

<style lang="stylus" scoped>


#tablet-vertical, #mobile
	.user-menu
		top auto
		bottom 8px
		> .initials-button
			width 56px
			height 56px
		> .dropdown-menu
			top auto
			bottom calc(100% + 12px)
			&.dropdown-menu-enter-from, &.dropdown-menu-leave-to
				transform translateY(8px)
				opacity 0
			&:before
				right 20px
				bottom auto
				top 100%
				border-width 8px 8px 0 8px
				border-color #1E2021 transparent transparent transparent


.user-menu
	position fixed
	right 8px
	top 8px
	flex center center
	user-select none
	z-index 10

	&.user-menu-enter-active
		transition 0.35s easeOutBack
	&.user-menu-enter-from
		transform translateY(-100%)
		opacity 0


	> .initials-button
		flex center center
		width 64px
		height 64px
		border-radius 50%
		font-size 2rem
		color #fff
		font-weight 600
		text-transform uppercase
		cursor pointer
		&:not(.loading):hover:before
			background-color darken(#FBBF53, 10%)
			transform scale(1.025)
		&:not(.loading).active:before
			background-color darken(#FBBF53, 20%)
			transform scale(1)
		&:before
			content ''
			absolute 0
			background-color #FBBF53
			border-radius 50%
		&.loading
			cursor wait

	> .dropdown-menu
		position absolute
		right 0
		top calc(100% + 12px)
		width 308px
		background-color #1E2021
		padding 16px 0
		border-radius 12px

		&.dropdown-menu-enter-active
			transition 0.15s easeOutQuart
		&.dropdown-menu-leave-active
			transition 0.1s easeInQuart
		&.dropdown-menu-enter-from, &.dropdown-menu-leave-to
			transform translateY(-8px)
			opacity 0

		&:before
			content ''
			position absolute
			right 24px
			bottom 100%
			width 0
			height 0
			border-style solid
			border-width 0 8px 8px 8px
			border-color transparent transparent #1E2021 transparent

		.item
			display block
			width 100%
			padding 0 16px
			min-height 32px + 8px
			font-size 1.6rem
			color #fff
			cursor pointer
			gap 8px
			flex center flex-start
			text-decoration none
			&.ellipsis .text
				overflow hidden
				white-space nowrap
				text-overflow ellipsis
			&.link
				color #A2A0A2
			&:hover:not(.no-hover)
				background-color #2B2C2E
			&.no-hover
				cursor default
			.icon
				display block
				width 32px
				height 32px
				flex-shrink 0
				background center center no-repeat
				background-size 20px auto
				&.external-link
					background-image url('@/assets/img/user-menu/external-link.svg')
				&.checked
					background-size 24px auto
					background-image url('@/assets/img/user-menu/checked.svg')
				.initials
					display block
					width 100%
					height 100%
					flex center center
					background-color #FBBF53
					border-radius 50%
					font-size 1.4rem
					font-weight 600
					color #1E2021
					text-transform uppercase
			.text
				display block
				width calc(100% - 32px)
				flex-shrink 1
			.switch
				display block
				width calc(100% - 32px)
				flex-shrink 1
				height 32px
				border-radius 4px
				border 1px solid #424244
				flex center center
				.switch-item
					width 50%
					height 100%
					flex center center
					&:not(.active)
						cursor pointer
						&:hover
							background-color darken(#424244, 25%)
					&:first-child
						border-radius @border-radius 0 0 @border-radius
					&:last-child
						border-radius 0 @border-radius @border-radius 0
					&.active
						background-color #424244
			.loading
				absolute 0
				flex center center
				background alpha(#1E2021, 85%)
				cursor wait
				svg
					width auto
					height 100%
		.separator
			height 2px
			background-color #424244
			margin 8px 0

</style>
