<template>
	<div class="form-maintenance-link-box" :class="theme" v-tap="tap">

		<div class="title"><ui-text :text="title" /></div>
		<div class="description"><ui-text :text="description" /></div>

		<ui-loader v-if="loading" />

	</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useNotepadStore } from '@/store/notepad'
import { useFormMaintenanceStore } from '@/store/form-maintenance'
import { openNotepadBySection } from '@/requests/notepad'
import { startGame } from '@/requests/form-maintenance'

export default {
	name: 'form-maintenance-link-box',
	data() {
		return {
			loading: false
		}
	},
	props: {
		subElement: { default: null },
	},
	computed: {
		...mapState(useNotepadStore, [ 'pages' ]),
		type() {
			if (this.subElement.linkableElementType === 'Client::Section') return 'section'
			if (this.subElement.linkableElementType === 'Client::Game') return 'game'
			return null
		},
		theme() { return this.type === 'section' ? 'light' : '' },
		title() { return this.subElement.linkableElement?.document?.title },
		description() { return this.subElement.linkableElement?.document?.description }
	},
	methods: {
		...mapActions(useNotepadStore, [ 'setPages', 'addPage', 'removePages' ]),
		...mapActions(useFormMaintenanceStore, [ 'setProgression' ]),
		async tap() {
			if (!this.loading && this.subElement.linkableElementId) {
				this.loading = true

				if (this.type === 'section') {
					this.setPages({ pages: [] })
					setTimeout(() => {
						openNotepadBySection({ sectionIds: [ this.subElement.linkableElementId ] })
					}, 500)
				}

				else if (this.type === 'game') {
					const progression = await startGame({ game: { id: this.subElement.linkableElementId } })
					this.setProgression({ progression })
					this.removePages({ pages: this.pages.filter(p => !p.native) })
					this.addPage({
						page: {
							id: this.subElement.linkableElementId,
							type: 'form-maintenance',
							document: { title: this.title, subtitle: this.description }
						}
					})
				}

				else this.loading = false
			}
		}
	}
}
</script>

<style lang="stylus" scoped>

.form-maintenance-link-box
	display block
	width 100%
	background-color #3B5F5F
	color #fff
	padding 16px
	text-align center
	cursor pointer
	&:hover, &:active
		background-color darken(#3B5F5F, 10%)
	&:active
		transform translateY(2px)
		
	&.light
		background-color #467464
		&:hover, &:active
			background-color darken(#467464, 10%)
		.ui-loader
			background-color darken(#467464, 10%)
	
	.title
		display block
		margin 0 0 4px 0
		text-transform uppercase
	.description
		font-style italic
		font-weight 300
		opacity 0.75
		
	.ui-loader
		absolute 0
		background-color darken(#3B5F5F, 10%)
	
</style>
