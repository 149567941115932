<template>
	<div class="modal-food-selection">
		<div class="bg" v-tap="close"></div>
		<div class="content">
			<div class="title">{{ $t('login.modalFoodTitle') }}</div>

			<perfect-scrollbar class="scrollable" ref="scrollable">
				<div class="foods">
					<div v-for="food in foods" :key="food.id" class="food" v-tap="() => selectFood(food)">
						<div class="bg" v-bg="getImage(food)"></div>
						<div v-if="selectedFood === food" class="selected"></div>
						<div class="name">{{ food.document.title }}</div>
					</div>
				</div>
			</perfect-scrollbar>

			<div class="buttons">
				<ui-button size="big" color="orange" :disabled="!selectedFood" v-tap="submit">{{ $t('login.modalFoodButton') }}</ui-button>
			</div>

		</div>
	</div>
</template>

<script>
import { getMedia } from '@/requests/medias'

export default {
	name: 'modal-food-selection',
	data() {
		return {
			selectedFood: null
		}
	},
	props: {
		foods: { default: [] }
	},
	methods: {
		getImage(food) {
			if (food.clientMedias.length) {
				const id = food.clientMedias[0].id
				return getMedia({ id })
			}
			return null
		},
		selectFood(food) {
			this.selectedFood = this.selectedFood !== food ? food : null
		},
		submit() {
			if (this.selectedFood) {
				this.$emit('select', { food: this.selectedFood })
				this.close()
			}
		},
		close() {
			this.$emit('close')
		},
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

#mobile, #tablet-vertical
	.modal-food-selection
		padding 0
		.content
			border-radius 0
			padding 16px
			.title
				font-size 1.6rem
			.scrollable .foods
				grid-template-columns repeat(2, 1fr)
				gap 8px
			.buttons
				flex center center

#tablet-vertical
	.modal-food-selection
		.content
			.scrollable .foods
				grid-template-columns repeat(3, 1fr)

.modal-food-selection
	absolute 0
	flex center center
	height 100%
	padding 32px 16px
	z-index 100000
	.bg
		absolute 0
		background-color alpha(#C58B62, 80%)
	.content
		width 100%
		max-width 960px
		height 100%
		padding 32px
		border-radius 8px
		background-color #fff
		.title
			height 64px
			flex center center
			color #4C6D6D
			text-transform uppercase
			border-bottom 2px solid #4C6D6D
			margin 0 0 16px 0
			text-align center
			font-weight 500
			font-size 2rem
		.scrollable
			// overflow hidden
			margin 0 0 16px 0
			height 'calc(100% - %s)' % (64px + 16px + 64px)
			.foods
				// flex flex-start flex-start
				// flex-wrap wrap
				display grid
				grid-template-columns repeat(4, 1fr)

				gap 16px
				// padding 8px
				.food
					width 100%
					height 140px
					flex center center
					background-color #999
					padding 16px
					overflow hidden
					cursor pointer
					color #fff
					text-align center
					text-transform uppercase
					font-size 2rem
					font-weight 500
					.bg
						absolute 0
						background center center no-repeat
						background-size cover !important
					.selected
						absolute 0
						background-color alpha(#131313, 80%)
						&:before
							content ''
							absolute 8px
							border 2px solid #fff
					.name
						absolute 0
						background radial-gradient(alpha(#000, 50%), alpha(#000, 0))
						flex center center
						text-shadow 0 -1px #000, -1px 0 #000, 1px 0 #000, 0 1px #000

		.buttons
			height 48px
			flex flex-end flex-end
			.ui-button
				min-width 200px

</style>
