<template>
	<div class="side-panel-district">

		<div class="side primary green">

			<div class="calendar">
				<page-calendar :editable="false" />
			</div>
			
			<div v-if="loadingState !== 'loaded'" class="loading"><ui-loader /></div>
		</div>

		<div class="side secondary white has-boxes">

			

		</div>

		<teleport to="#app .app-content">
			<modal-event-detail v-if="eventDetail" :event="eventDetail" @close="() => eventDetail = null" />
		</teleport>

	</div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useCalendarStore } from '@/store/calendar'
import PageCalendar from '@/components/calendar/page-calendar/page-calendar.vue'
import ModalEventDetail from '@/components/calendar/modal-event-detail/modal-event-detail.vue'
// import AdminState from '@/components/admin-dashboard/ui/admin-state.vue'
// import AdminOnboarding from '@/components/admin-dashboard/ui/admin-onboarding.vue'
// import AdminBox from '@/components/admin-dashboard/ui/admin-box.vue'
// import ListItems from '@/components/admin-dashboard/list/list-items.vue'

export default {
	data() {
		return {
			selectedItems: [],
			total: 0
		}
	},
	props: {
		panel: { default: null, required: true }
	},
	computed: {
		...mapState(useCalendarStore, [ 'rawEvents' ]),
		...mapWritableState(useCalendarStore, [ 'eventDetail', 'loadingState', 'activeCalendarId' ]),
	},
	methods: {
		...mapActions(useCalendarStore, [ 'initCalendar' ]),
	},
	async created() {
		if (this.panel?.params?.school?.id) {
			this.loadingState = 'not-loaded'
			this.activeCalendarId = this.panel.params.school.id
			await this.initCalendar({ userId: this.activeCalendarId })
		}
	},
	beforeUnmount() {
		this.activeCalendarId = null
	},
	components: { PageCalendar, ModalEventDetail }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.side-panel-district
	.side
		&.primary
			flex center center
			padding 0 !important
			max-width 650px
			flex-shrink 0 !important
			> .calendar
				width calc(100% - 32px)
				height calc(100% - 32px)
				max-width 650px
				max-height 850px
				padding 16px
			> .loading
				absolute 0
				background-color alpha(#fff, 25%)
				flex center center
				color #0F4444
		&.secondary
			width 100% !important
			flex-shrink 1 !important
	

</style>