<template>
	<div v-if="day" class="day-item"
		:class="{ isWeekend, isToday, isDifferentMonth, active, 'edit-mode': editMode, 'has-editing-event': editingEvent, 'is-editing-day': editingEvent?.date === day.id, 'allow-editing': allowEditing }"
		v-tap="dayTap"
	>
		<div class="number">{{ day.date.getUTCDate() }}</div>
		<div v-if="dayEvents.length" class="events" :class="{ leftSide, topSide, rightSide, downSide }">
			<div v-for="event in dayEvents" :key="event?.id"
				class="event"
				:class="{
					'is-editing-event': editingEvent?.id === event.id,
					over: eventHover?.id === event?.id,
					highlight: highlightEventTimelineId === event?.timelineId
				}"
				:style="{ color: event?.timeline?.document?.hex || 'inherit' }"
				v-tap="() => eventTap(event)"
				@mouseenter="() => eventHover = event"
				@mouseleave="() => eventHover = null"
			>
				<ui-calendar-event-icon :event="event?.event" class="icon" />
				<span class="event-text" v-html="event?.event?.document?.title || event?.event?.slug"></span>
			</div>
			<div v-if="!active && (dayEvents.length > 4 || device === 'mobile' || device === 'tablet-vertical')" class="more" v-tap="() => $emit('show-more')">{{ dayEvents.length - 3 }} {{ $t('calendar.more') }}</div>
			<div v-if="active" class="close" v-bg="'close.svg'" v-tap="() => $emit('show-more')"></div>
		</div>

		<transition name="tooltip" mode="out-in">
			<div v-if="eventHover?.event?.slug && (((device === 'mobile' || device === 'tablet-vertical') && active) || (!(device === 'mobile' || device === 'tablet-vertical')))" :key="eventHover.id" class="tooltip" :class="{ leftSide, topSide, rightSide, downSide }">
				<p class="line">
					<ui-calendar-event-icon :event="eventHover.event" class="icon" />
					<span v-html="eventHover.event?.document?.title || event.event.slug"></span>
				</p>
				<p class="line"><span v-html="timelineName"></span></p>
			</div>
		</transition>
	</div>
</template>

<script>
import { mapState, mapWritableState } from 'pinia'
import { useAppStore } from '@/store/app'
import { useCalendarStore } from '@/store/calendar'
import UiCalendarEventIcon from '@/components/ui/ui-calendar-event-icon.vue'

export default {
	data() {
		return {
			eventHover: false,
		}
	},
	props: {
		day: { default: null },
		currentMonth: { default: null },
		active: { default: false },
		leftSide: { default: false },
		topSide: { default: false },
		rightSide: { default: false },
		downSide: { default: false },
		editMode: { default: false },
		editingEvent: { default: null },
		allowEditing: { default: false },
	},
	computed: {
		...mapState(useAppStore, [ 'device' ]),
		...mapState(useCalendarStore, [ 'events', 'timelines' ]),
		...mapWritableState(useCalendarStore, [ 'highlightEventTimelineId', 'eventDetail' ]),
		dayUTCString() {
			return new Date(this.day.date).toUTCString()
		},
		dayEvents() {
			return this.events
				.filter(e => new Date(e.date).toUTCString() === this.dayUTCString)
				.map(e => ({ ...e, timeline: this.timelines.find(t => t.id === e.timelineId)?.timeline }))
				.filter(e => e?.event?.slug)
		},
		isWeekend() {
			return this.day.date.getUTCDay() === 0 || this.day.date.getUTCDay() === 6
		},
		isDifferentMonth() {
			return this.day.date.getUTCMonth() !== this.currentMonth
		},
		isToday() {
			const day = new Date(this.day.date)
			const strDay = day.getUTCFullYear() + '-' + day.getUTCMonth() + '-' + day.getUTCDate()

			const today = new Date()
			const strToday = today.getUTCFullYear() + '-' + today.getUTCMonth() + '-' + today.getUTCDate()

			return strDay === strToday
		},
		timelineHover() {
			return this.highlightEventTimelineId ? this.timelines.find(t => t.id === this.highlightEventTimelineId) : null
		},
		timelineName() {
			if (this.timelineHover) {
				if (this.timelineHover?.name) return this.timelineHover.name
				if (this.timelineHover?.timeline?.document?.title) return this.timelineHover.timeline.document.title + ' ' + (this.timelineHover.index || '')
				return this.eventHover?.timeline?.document?.title || ""
			}
			return ""
		}
	},
	watch: {
		eventHover(eventHover) {
			this.highlightEventTimelineId = eventHover?.timelineId || null //this.getEventSlug(eventHover?.event?.slug || null)
		}
	},
	methods: {
		eventTap(event) {
			if (this.editMode) this.$emit('edit-event', event)
			else this.eventDetail = event
		},
		dayTap() {
			if (this.editingEvent?.date && new Date(this.editingEvent.date).toUTCString() !== this.dayUTCString) {
				this.$emit('set-new-editing-date', { day: this.day, warning: !this.allowEditing })
			}
		},
		getEventSlug(slug) {
			if (slug?.startsWith('pollinisation-')) return 'pollinisation'
			return slug
		},
		getIconUrl(slug) {
			const filename = this.getEventSlug(slug)
			try {
				return require('../../../assets/img/calendar/event-' + filename + '.svg')
			} catch (e) {
				return ''
			}
		}
	},
	components: { UiCalendarEventIcon }
}
</script>

<style lang="stylus" scoped>

#tablet-vertical, #mobile
	.day-item
		&:not(.active)
			.events
				flex flex-start center
				flex-wrap wrap
				align-content flex-start
				gap 2px
				.event
					padding 0
					gap 0
					pointer-events none
					.event-text
						display none
				.more
					height 100%
					background-color transparent
					color transparent


.day-item
	background-color #E5E3DB
	color #104544
	user-select none
	&.isWeekend
		color #687E7F
		background-color #EEEDE6
	&.isDifferentMonth
		color #C9CED0
		background-color #F4F3ED
	&.isToday
		color #d48401
		background-color #ffe2b6
	&.active
		z-index 2
		.events
			absolute -32px
			top -32px
			bottom -64px
			padding 8px 4px
			background-color #E5E3DB
			box-shadow 0 0 8px alpha(#000, 50%)
			&.leftSide
				transform translateX(36px)
				&.topSide
					transform translateX(36px) translateY(36px)
				&.downSide
					transform translateX(36px) translateY(-36px)
			&.rightSide
				transform translateX(-36px)
				&.topSide
					transform translateX(-36px) translateY(36px)
				&.downSide
					transform translateX(-36px) translateY(-36px)
			&.topSide
				transform translateY(36px)
			&.downSide
				transform translateY(-36px)
			.close
				position absolute
				left calc(50% - 16px)
				bottom 8px
				width 32px
				height 32px
				flex center center
				border-radius 50%
				background red center center no-repeat
				background-size 16px !important
				cursor pointer
				&:hover
					background-color darken(red, 15%)
		.tooltip
			bottom calc(100% + 32px)
			&.topSide
				bottom auto
				top calc(100% + 60px)
			&.downSide
				bottom calc(100% + 68px)
	&.edit-mode
		.events
			.event:hover
				background-color #fff
				cursor pointer
		&.has-editing-event
			background-color #728A88 !important
			color lighten(#728A88, 25%) !important
			cursor pointer
			&:hover
				background-color darken(#728A88, 5%) !important
			&.allow-editing
				background-color #fff !important
				cursor pointer
				&:hover
					background-color #E5E3DB !important
			&.is-editing-day
				background-color #E5E3DB !important
	.number
		height 24px
		width 100%
		flex flex-end center
		text-align center
		font-size 1.4rem
	.events
		absolute 0
		top 24px
		overflow hidden
		transition 0.25s easeOutQuart
		color #de5c00
		.event
			flex center flex-start
			gap 4px
			// margin 0 0 2px 0
			height 20px
			padding 0 0 0 4px
			font-size 1.3rem
			line-height @height
			white-space nowrap
			cursor pointer
			&:last-child
				margin-bottom 0
			&.highlight
				background-color alpha(#F9AE19, 20%) !important
				// color #fff !important
			&.over
				background-color alpha(#F9AE19, 40%) !important
				// opacity 0.5
			&.is-editing-event
				background-color #704992 !important
				color #fff !important
			.icon
				width 14px
				height 14px
				background center center no-repeat
				background-size contain
				flex-shrink 0
	.more
		position absolute
		left 0
		bottom 0
		right 0
		height 20px
		background-color #3B5F5F
		font-size 1.3rem
		color #fff
		text-align center
		line-height @height
		cursor pointer
		&:hover
			background-color lighten(#3B5F5F, 15%)

	.tooltip
		position absolute
		width calc(100% + 96px)
		left -48px
		bottom calc(100% + 4px)
		padding 8px
		border-radius 4px
		background-color #436C6C
		color #fff
		font-size 1.4rem
		line-height 16px
		pointer-events none
		transition 0.1s easeOutQuart
		z-index 1
		&.tooltip-enter-active
			transform translateY(8px)
			opacity 0
		&.tooltip-leave-active
			transform translateY(-8px)
			opacity 0
		&:before
			content ''
			position absolute
			left calc(50% - 8px)
			top calc(100% - 1px)
			width 0
			height 0
			border-style solid
			border-width 8px 8px 0 8px
			border-color @background-color transparent transparent transparent
		
		&.topSide
			bottom auto
			top calc(100% + 4px)
			z-index 1
			&:before
				top auto
				bottom calc(100% - 1px)
				border-width 0 8px 8px 8px
				border-color transparent transparent @background-color transparent
		
		&.rightSide
			left auto
			right 8px
			&:before
				left auto
				right 24px
		
		&.leftSide
			left 8px
			right auto
			&:before
				right auto
				left 24px

		.line
			margin 0 0 4px 0
			flex flex-start flex-start
			gap 4px
			&:first-child
				font-weight 500
			&:last-child
				font-style italic
			.icon
				width 16px
				height 16px
				flex-shrink 0
				color #fff


</style>
