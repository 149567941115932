<template>
	<div class="page-calendar" :class="{ 'edit-mode': editMode }">
		
		<div class="header">
			<div class="arrow left" v-tap="prev"><div class="icon" v-bg="'blue-arrow-left.svg'"></div></div>
			<div class="title">{{ $t('calendar.month' + (currentMonth + 1)) }} {{ currentYear }}</div>
			<div v-if="false" class="sidebar-button" :class="{ opened: sidebarVisible }" v-tap="() => sidebarVisible = !sidebarVisible">
				<div class="bar"></div>
				<div class="bar"></div>
				<div class="bar"></div>
				<div class="bar"></div>
			</div>
			<div v-if="canEdit && editable" class="edit-mode-button" v-tap="() => editMode = !editMode">
				<svg v-if="!editMode" xmlns="http://www.w3.org/2000/svg" width="25.439" height="34.638" viewBox="0 0 25.439 34.638">
					<path id="Tracé_56596" data-name="Tracé 56596" d="M0,0H9.424V28.764L4.843,34.556,0,28.764Z" transform="translate(17.278) rotate(30)" fill="currentColor"/>
				</svg>
				<svg v-else xmlns="http://www.w3.org/2000/svg" width="19.719" height="19.719" viewBox="0 0 19.719 19.719">
					<g transform="translate(-1255.436 244.977)"><g transform="translate(1255.436 -244.977)"><rect class="a" width="22.907" height="4.98" transform="translate(3.521 0) rotate(45)" fill="currentColor" /><rect class="a" width="22.907" height="4.98" transform="translate(19.719 3.521) rotate(135)" fill="currentColor"/></g></g>
				</svg>
			</div>
			<div class="today-button" v-tap="gotoToday">
				{{ $t('calendar.today') }}
			</div>
			<div class="arrow right" v-tap="next"><div class="icon" v-bg="'blue-arrow-right.svg'"></div></div>
		</div>

		<!-- <div class="content" :class="{ 'no-footer': !editable || !canEdit }"> -->
		<div class="content">
			<div class="day-names">
				<div v-for="i in 7" :key="i" class="cell">{{ $t('calendar.day' + (i - 1)) }}</div>
			</div>
			<div class="days-wrap">
				<div class="days">
					<day-item v-for="(day, index) in days"
						:day="day"
						:current-month="currentMonth"
						:key="day.date.getTime()"
						:active="activeDay?.id === day?.id"
						:left-side="index % 7 === 0"
						:right-side="index % 7 === 6"
						:top-side="device === 'mobile' || device === 'tablet-vertical' ? index < 14 : index < 7"
						:down-side="index >= days.length - 7"
						:edit-mode="editMode"
						:editing-event="editingEvent"
						:allow-editing="editingEvent && allowedEditDates.includes(day.id)"
						@show-more="activeDay = (activeDay?.id !== day?.id) ? day : null"
						@edit-event="editEvent"
						@set-new-editing-date="(d) => editingEventNewDate = d"
					/>
				</div>
			</div>
			<transition name="sidebar-filters">
				<sidebar-filters v-if="sidebarVisible" />
			</transition>
		</div>

		<div class="footer">
			<calendar-button v-if="editMode && editingEvent" color="red" v-tap="() => removingEventVisible = true">{{ $t('calendar.removeFromCalendar') }}</calendar-button>
			<calendar-button v-else v-tap="() => $router.push({ name: 'calendar-add-seed' })">{{ $t('calendar.addEvent.addToCalendar') }}</calendar-button>
		</div>

	</div>
</template>

<script>
import { mapState, mapWritableState } from 'pinia'
import { useAppStore } from '@/store/app'
import { useCalendarStore } from '@/store/calendar'
import CalendarButton from '@/components/calendar/calendar-button'
import DayItem from '@/components/calendar/page-calendar/day-item'
import SidebarFilters from '@/components/calendar/page-calendar/sidebar-filters'

const generateDay = (date) => {
	const id = date.getUTCFullYear() + '-' + ("0" + (date.getUTCMonth() + 1)).slice(-2) + '-' + ("0" + (date.getUTCDate())).slice(-2)
	return { id, date: new Date(date) }
}

export default {
	data() {
		return {
			currentMonth: 0,
			currentYear: 0,
			activeDay: null,

			sidebarVisible: false,
			removingEvent: false,
		}
	},
	props: {
		editable: { default: true }
	},
	computed: {
		...mapState(useAppStore, [ 'device' ]),
		...mapWritableState(useCalendarStore, [ 'editMode', 'editingEvent', 'editingEventNewDate', 'removingEventVisible' ]),
		...mapState(useCalendarStore, [ 'allowedEditDates', 'canEdit' ]),
		days() {
			const days = []

			// Ajout des jours du mois choisi
			let date = new Date(Date.UTC(this.currentYear, this.currentMonth, 1))
			while (date.getUTCMonth() === this.currentMonth) {
				days.push(generateDay(date))
				date.setUTCDate(date.getUTCDate() + 1)
			}

			// Ajout des jours du mois précédent
			if (days[0].date.getUTCDay() > 0) {
				let date = new Date(days[0].date.getTime())
				while (date.getUTCDay() !== 0) {
					date.setUTCDate(date.getUTCDate() - 1)
					days.unshift(generateDay(date))
				}
			}

			// Ajout des jours du mois suivant
			if (days[days.length - 1].date.getUTCDay() < 6) {
				let date = new Date(days[days.length - 1].date.getTime())
				while (date.getUTCDay() !== 6) {
					date.setUTCDate(date.getUTCDate() + 1)
					days.push(generateDay(date))
				}
			}

			return days
		}
	},
	watch: {
		editMode() {
			this.editingEvent = null
		}
	},
	methods: {
		editEvent(event) {
			this.editingEvent = this.editMode && this.editingEvent?.id !== event?.id ? event : null
		},
		gotoToday() {
			this.currentMonth = (new Date()).getUTCMonth()
			this.currentYear = (new Date()).getUTCFullYear()
		},
		next() {
			this.currentMonth++
			if (this.currentMonth > 11) {
				this.currentMonth = 0
				this.currentYear++
			}
		},
		prev() {
			this.currentMonth--
			if (this.currentMonth < 0) {
				this.currentMonth = 11
				this.currentYear--
			}
		}
	},
	created() {
		this.gotoToday()
	},
	components: { CalendarButton, DayItem, SidebarFilters }
}
</script>

<style lang="stylus" scoped>

#tablet-vertical, #mobile
	.page-calendar
		.header
			height 64px
			font-size 2.4rem
			.edit-mode-button
				display none
		.content
			height calc(100% - 64px)
			padding 0 8px
			.days
				grid-auto-rows 88px
		.footer
			display none

#mobile
	
	.page-calendar
		.header
			.today-button
				position fixed
				bottom 16px
				top auto
				color alpha(#fff, 50%)
				border-color alpha(#fff, 25%)
				background none
				&:hover, &:active
					color #fff
					border-color alpha(#fff, 50%)
					background-color alpha(#fff, 10%)
					
	@media (min-height: 850px)
		.page-calendar
			.header
				.today-button
					bottom calc((100vh - 850px) / 2 + 16px)



.page-calendar
	height 100%
	// padding-top 16px
	overflow hidden
	&.edit-mode
		.header
			.sidebar-button
				.bar
					background-color #F8F7F2
				&:hover .bar
					background-color darken(#F8F7F2, 10%)
			.edit-mode-button
				color #3B5F5F
				background-color #F8F7F2
				&:hover
					background-color darken(#F8F7F2, 10%)
		.content
			.days-wrap
				.days
					.day-item
						&.isDifferentMonth
							background-color #96A7A4
			.sidebar-filters
				background-color darken(#3B5F5F, 10%)
	.header
		width 100%
		height (64px + 32px)
		flex center center
		border-bottom 1px solid #B4BBBC
		text-transform uppercase
		font-size 3.2rem
		text-align center
		font-weight 500
		user-select none
		.title
			display inline-block
			width 260px
			flex-shrink 0
			text-align center
		.arrow
			cursor pointer
			width 48px
			height 48px
			flex center center
			border-radius 50%
			&:active
				transform translateY(2px)
			.icon
				absolute 0
				background center center no-repeat
		.sidebar-button
			position absolute
			left 16px
			top calc(50% - 16px)
			width 32px
			height 32px
			cursor pointer
			color #396060
			&:hover
				.bar
					background-color lighten(#396060, 10%)
			&.opened
				.bar
					width 24px
					top 0
					&:nth-child(1)
						transform rotate(-45deg) translate(-2px, 20px)
					&:nth-child(2)
						transform rotate(-45deg) translate(-10px, 12px)
					&:nth-child(3)
						transform rotate(45deg) translate(10px, 8px)
					&:nth-child(4)
						transform rotate(45deg) translate(18px, 0px)
			.bar
				position absolute
				left 0
				top 4px
				height 4px
				width 32px
				background-color #104544
				transition transform 0.25s easeOutSine, top 0.25s easeOutSine
				transform-origin left center
				border-radius 4px
				&:nth-child(2)
					transform translateY(10px)
				&:nth-child(3), &:nth-child(4)
					transform translateY(20px)
		.edit-mode-button
			position absolute
			// left 60px
			left 16px
			top calc(50% - 16px)
			width 32px
			height 32px
			cursor pointer
			border-radius 4px
			color #F8F7F2
			background-color #396060
			flex center center
			&:hover
				background-color lighten(#396060, 10%)
			svg
				width 20px
				height 20px
		.today-button
			position absolute
			right 16px
			top calc(50% - 16px)
			height 28px
			padding 0 8px
			cursor pointer
			border-radius 4px
			color #F8F7F2
			font-size 1.4rem
			border 1px solid alpha(#396060, 25%)
			color #396060
			line-height (@height - 2px)
			// background-color #396060
			&:hover
				border-color alpha(#396060, 75%)
				background-color alpha(#396060, 10%)
			&:active
				border-color alpha(#396060, 100%)
				background-color alpha(#396060, 20%)
				
	.content
		height 'calc(100% - %s)' % (64px + 32px + 48px + 32px)
		padding 0 16px
		&.no-footer
			height 'calc(100% - %s)' % (64px + 32px)
		.days-wrap
			height calc(100% - 40px)
			overflow auto
	.footer
		height (48px + 32px)
		padding 16px
		border-top 1px solid #89A095


.day-names
	display grid
	gap 4px
	grid-template-columns repeat(7, 1fr)
	grid-template-rows 40px
	align-items center
	text-align center
	text-transform uppercase
	font-size 1.3rem
	font-weight 500

.days
	display grid
	gap 4px
	grid-template-columns repeat(7, 1fr)
	grid-auto-rows 104px



</style>
