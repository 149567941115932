<template>
	<div v-if="user" class="side-panel-teacher">

		<div class="side primary green">
			<div class="main-title">{{ $t('adminDashboard.panels.teacher.userAccount') }} <span class="arrow"></span> {{ user.firstName || user.lastName ? user.firstName + ' ' + user.lastName : $t('adminDashboard.panels.teacher.anonymous') }}</div>

			<div class="content">

				<div class="infos">
					<div class="info">
						<div class="label">{{ $t('adminDashboard.panels.teacher.firstname') }}</div>
						<div class="value">{{ user.firstName || '-' }}</div>
					</div>
					<div class="info">
						<div class="label">{{ $t('adminDashboard.panels.teacher.lastname') }}</div>
						<div class="value">{{ user.lastName || '-' }}</div>
					</div>
					<div class="info">
						<div class="label">{{ $t('adminDashboard.panels.teacher.username') }}</div>
						<div class="value">{{ user.userName || '-' }}</div>
					</div>
					<div class="info">
						<div class="label">{{ $t('adminDashboard.panels.teacher.email') }}</div>
						<div class="value"><a :href="'mailto:' + user.email">{{ user.email || '-' }}</a></div>
					</div>
					<div v-if="lastActivity" class="info">
						<div class="label">{{ $t('adminDashboard.panels.teacher.lastActivity') }}</div>
						<div class="value">{{ lastActivity }}</div>
					</div>
					<div class="info">
						<div class="label">{{ $t('adminDashboard.panels.teacher.idUser') }}</div>
						<div class="value">{{ user.id }}</div>
					</div>
					<div class="info">
						<div class="label">{{ $t('adminDashboard.panels.teacher.accountState') }}</div>
						<div class="value"><admin-state v-if="state" :state="state" /></div>
					</div>
					<div class="info">
						<div class="label">{{ $t('adminDashboard.panels.teacher.subscribeState') }}</div>
						<div class="value"><admin-onboarding :onboarding="user.onboarding" background="light" /></div>
					</div>
				</div>

			</div>
		</div>

		<div class="side secondary white">

			<div class="content">

				<div class="main-title">{{ $t('adminDashboard.actions') }}</div>

				<div class="actions">
					<div class="action" v-tap="() => addModal({ slug: 'edit', type: 'teacher', item: user })">
						<ui-round-button color="green" icon="admin/edit-white.svg" />
						<span class="text">{{ $t('adminDashboard.panels.teacher.editUser') }}</span>
					</div>
					<div v-if="features.teacher.includes('edit-email')" class="action" v-tap="() => addModal({ slug: 'edit-email', type: 'teacher', item: user })">
						<ui-round-button color="green" icon="admin/email-white.svg" />
						<span class="text">{{ $t('adminDashboard.panels.teacher.editEmail') }}</span>
					</div>
					<div class="action" v-tap="() => addModal({ slug: 'reset-password', type: 'teacher', items: [ user ] })">
						<ui-round-button color="green" icon="admin/send-white.svg" />
						<span class="text">{{ $t('adminDashboard.panels.teacher.resetUserPassword') }}</span>
					</div>
					<div class="action" v-tap="() => addModal({ slug: 'disable', type: 'teacher', items: [ user ] })">
						<ui-round-button color="yellow" icon="admin/disable-green.svg" />
						<span class="text">{{ $t('adminDashboard.panels.teacher.disableUser') }}</span>
					</div>
					<div class="action" v-tap="() => addModal({ slug: 'remove', type: 'teacher', items: [ user ] })">
						<ui-round-button color="red" icon="admin/trash-green.svg" />
						<span class="text">{{ $t('adminDashboard.panels.teacher.deleteUser') }}</span>
					</div>
				</div>

			</div>
			
		</div>

	</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useAppStore } from '@/store/app'
import { useAdminDashboardStore } from '@/store/admin-dashboard'
import UiRoundButton from '@/components/ui/ui-round-button.vue'
import AdminState from '@/components/admin-dashboard/ui/admin-state.vue'
import AdminOnboarding from '@/components/admin-dashboard/ui/admin-onboarding.vue'

export default {
	data() {
		return {
			
		}
	},
	props: {
		panel: { default: null, required: true }
	},
	computed: {
		...mapState(useAppStore, [ 'lang' ]),
		...mapState(useAdminDashboardStore, [ 'features' ]),
		user() {
			return this.panel?.admin || this.panel?.teacher || this.panel?.student || null
		},
		state() {
			if (this.user.disabled === undefined) return null
			return this.user.disabled ? 'disabled' : 'enabled'
		},
		lastActivity() {
			const strDate = this.user?.lastAuthorization || null
			if (strDate) {
				const date = new Date(strDate)
				if (date instanceof Date && !isNaN(date)) return date.toLocaleDateString(this.lang === 'fr' ? 'fr-CA' : 'en-US', { year: 'numeric', month: 'long', day: 'numeric' })
			}
			return null
		},
	},
	methods: {
		...mapActions(useAdminDashboardStore, [ 'addModal' ]),
	},
	components: { UiRoundButton, AdminState, AdminOnboarding }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.side-panel-teacher
	.side.primary
		.main-title
			border-bottom 1px solid alpha(#fff, 50%) !important
		.content
			height calc(100% - 48px)
			flex flex-start center
			gap 32px
			.infos
				width 100%
				// max-width 800px
				flex flex-start center column
				.info
					width 100%
					// min-height 48px
					// background-color alpha(#fff, 10%)
					padding 16px 0
					// border-radius 8px
					flex center flex-start
					// gap 16px
					// border-bottom 2px solid alpha(#fff, 10%)
					&:nth-child(odd)
						background-color alpha(#fff, 5%)
						border-radius 8px
					> .label
						width 50%
						padding 0 8px 0 0
						text-transform uppercase
						font-size 1.4rem
						font-weight 500
						opacity 0.4
						text-align right
					> .value
						width 50%
						padding 0 0 0 8px
						a
							color inherit
							text-decoration underline
							&:hover
								text-decoration none
						::v-deep(.admin-state)
							display inline-flex
							width auto

	.side.secondary
		flex flex-start flex-start
		.main-title
			height 48px
			flex center space-between
			margin 0 0 16px 0
			border-bottom 1px solid #0F4444
			font-size 2.4rem
			font-weight 500
			text-transform uppercase
		.actions
			border-radius 16px
			flex flex-start flex-start column
			// gap 16px
			.action
				width 100%
				flex center flex-start
				padding 16px
				gap 16px
				border-radius 8px
				// background-color #E7EEEE
				cursor pointer
				font-weight 500
				&:hover
					background-color #E7EEEE
					::v-deep(.ui-round-button) .bg
						filter brightness(1.1) !important
				.ui-round-button
					flex-shrink 0
				.text
					display block
					width 100%
		
</style>