<template>
	<div class="modal-edit">

		<form-edit :type="modal.type" :item="modal.item" @close="$emit('close')" />

	</div>

</template>

<script>
import FormEdit from '@/components/admin-dashboard/modals/forms/form-edit.vue'

export default {
	props: {
		modal: { default: null }
	},
	components: { FormEdit }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.empty
	display block
	padding 32px 16px
	text-transform uppercase
	text-align center
	font-weight 500
	color #999

</style>