<template>
	<div v-if="page" class="section-content">

		<ui-image v-for="image in sectionIllustrations" :src="image.src" :key="image.id" :displayModalOnTap="true" :fullsize="image.tags?.includes('full-size')" />

		<div v-if="page.contentTexts" class="texts">
			<div v-for="(contentText, index) in page.contentTexts" :key="index" class="text-content">

				<div v-if="contentText.tags.includes('video-url') && getVidreoIframe(contentText.html)" class="video" :class="{ placeholder: !videoLoaded }">
					<ui-loader v-show="!videoLoaded" />
					<iframe width="100%" height="250" :src="getVidreoIframe(contentText.html)" @load="videoLoaded = true" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>

				<ui-text v-else :text="contentText.html" />
			</div>
		</div>

	</div>
</template>

<script>
import { useAppStore } from '@/store/app'
import { getMedia } from '@/requests/medias'

export default {
	name: 'section-content',
	data() {
		return {
			activeSection: null,
			imageLoaded: false,
			videoLoaded: false
		}
	},
	props: {
		page: { default: null }
	},
	computed: {
		sectionIllustrations() {
			return this.page.clientMedias
				.filter(({ tags }) => tags.includes('section-illustration'))
				.map(({ id, tags }) => { return { id, tags, src: getMedia({ id }) } })
		}
	},
	methods: {
		displayModal(src) {
			useAppStore().setModal({ src })
		},
		getVidreoIframe(text) {
			if (text) {

				// YOUTUBE IFRAME
				var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
				var match = text.match(regExp)
				const id = (match && match[7].length === 11) ? match[7] : false
				if (id) return 'https://www.youtube.com/embed/' + id

			}
			return null
		}
	}
}
</script>

<style lang="stylus" scoped>

.section-content
	.texts
		margin 0 0 16px 0
		.text-content
			::v-deep(.ui-text)
				p
					margin 0 0 8px 0
					line-height 1.5em
				strong
					color primary
					font-weight 700
				ul, ol
					display block
					line-height 1.5em
					margin 0 0 0 20px
					li
						margin 0 0 8px 0
				em
					color #777
					font-style italic

</style>
