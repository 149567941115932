<template>
	<div class="footer-sections-list">
		<div class="bg" v-tap="() => $emit('close')"></div>
		<div class="window">
			<div class="title">{{ page?.document?.title }}</div>
			<perfect-scrollbar class="content">
				<div class="scroll-content">
					<div v-for="(content, index) in page.children" :key="content.id" class="section-list-item"
						:class="{ active: page.pageIndex === index }"
						v-tap="() => gotoPage(index)"
					>
						<span class="no">{{ index + 1}}</span>
						<span class="text">{{ content?.document?.title }}</span>
					</div>
				</div>
			</perfect-scrollbar>
		</div>
		<div class="arrow">
			<svg width="24" height="24" viewBox="0 0 24 24" fill="#fff" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
				<path d="M 0 -2 L 12 15.985 L 24 -2.015 L 0 -2 Z"/>
			</svg>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		page: { default: [] }
	},
	methods: {
		gotoPage(index) {
			this.$emit('change', { index })
		}
	}
}
</script>

<style lang="stylus" scoped>

#app > .low-perf
	.footer-sections-list
		transition none
		.window, .arrow
			transition none

#mobile .footer-sections-list
	.window
		top 4px
		left 4px
		right 4px
		border-radius 0
		.content
			-webkit-overflow-scrolling touch !important

.footer-sections-list
	absolute 0
	z-index 100000
	&.footer-sections-list-enter-active, &.footer-sections-list-leave-active
		transition opacity 0.25s easeOutQuart
		.window, .arrow
			transition transform 0.25s easeOutQuart
	&.footer-sections-list-enter-from, &.footer-sections-list-leave-to
		opacity 0
		.window, .arrow
			transform translateY(8px)
	.bg
		absolute 0
		background-color alpha(#446364, 50%)
	.arrow
		position absolute
		left calc(50% - 12px)
		bottom (84px - 22px)
		width 24px
		height 24px
		background bottom center no-repeat
		background-size auto 100%
	.window
		absolute 16px
		top 32px
		bottom 84px
		border 2px solid #3B5F5F
		border-radius 8px
		overflow hidden
		.title
			height 64px
			padding 0 16px
			background-color #3B5F5F
			flex center flex-start
			font-size 2rem
			color #fff
			text-transform uppercase
			font-weight 500
		.content
			height calc(100% - 64px)
			background-color #fff
			.section-list-item
				width 100%
				height 64px
				flex flex-start flex-start
				border-bottom 1px solid #e9e9e9
				color #3B5F5F
				cursor pointer
				&:hover
					background-color #E7F4F1
				&.active
					color #F2903B
					.no:after
						background-color @color
				.no
					width 48px
					height 100%
					padding 8px 16px
					flex-shrink 0
					flex flex-end flex-end
					border-right 2px solid #FBEEE2
					font-size 1.8rem
					font-style italic
					&:after
						content ''
						position absolute
						right -4px
						bottom 14px
						width 6px
						height 6px
						background-color #3B5F5F
						transform rotate(45deg)
				.text
					height 100%
					padding 8px 16px
					flex flex-end flex-start
					font-weight 500

</style>
