import { defineStore } from 'pinia'
import { wait } from '@affordancestudio/functions'
import { useAppStore } from '@/store/app'
import { getAllTutorials, updateTutorial } from '@/requests/tutos'
import { getCheckpoints, completeCheckpoint, resetAchievement } from '@/requests/on-boarding'

export const useOnboardingStore = defineStore({
	id: 'onboarding',

	state: () => ({
		// Tutos
		tutos: [],
		currentTuto: null,

		// Checkpoints
		rawCheckpoints: [],
		completedCheckpoint: null,
		forceVisibility: false,
		loading: false,
	}),

	getters: {
		// Flatten checkpoints level 1
		groups() {
			return this.rawCheckpoints.filter(d => d.children?.length).reduce((acc, d) => [ ...acc, ...d.children ], [])
		},
		// Flatten checkpoints level 2
		checkpoints() {
			return this.groups?.filter(g => g.children?.length)?.reduce((acc, g) => [ ...acc, ...g.children ], [])
		},
		activeGroup() {
			return this.groups.find(g => g.children?.find(t => t.status !== 'completed'))
		},
		activeCheckpoint() {
			return this.activeGroup?.children?.find(t => t.status !== 'completed')
		},
	},

	actions: {

		async loadOnboarding() {
			if (this.tutos.length === 0 || this.rawCheckpoints.length === 0) {
				this.loading = true
				this.tutos = await getAllTutorials()
				this.loading = false
				const appStore = useAppStore()
				if (appStore.user) {
					this.loading = true
					this.rawCheckpoints = await getCheckpoints()
					this.loading = false
				}
			}
		},

		startTuto({ slug, force = false }) {
			if (!this.currentTuto && this.checkpoints.length && useAppStore().user) {
				const tuto = this.tutos.find(t => t.slug === slug)
				if (tuto?.slug && (tuto?.status !== 'completed' || force)) {
					this.currentTuto = tuto
				}
			}
		},

		async endTuto({ id }) {
			this.currentTuto = null
			const tutos = await updateTutorial({ id, status: 'completed' })
			if (tutos?.length) this.tutos = tutos
			await useAppStore().refreshUser()
		},

		async checkFirstCheckpointsAndDisplayNextGroup() {
			const checkpoints = this.checkpoints.filter(c => c?.tags?.includes('auto-check'))
			for (let i = 0; i < checkpoints.length; i++) {
				checkpoints[i].status = 'completed'
				completeCheckpoint(checkpoints[i])
				await wait(500)
			}
			await wait(1000)
		},

		async completeCheckpoint({ slug }) {
			const checkpoint = this.checkpoints?.find(t => t.slug === slug)
			if (checkpoint?.id && checkpoint?.status !== 'completed') {
				this.completedCheckpoint = checkpoint
				const id = checkpoint.id
				this.completedCheckpoint.status = 'completed'
				await wait(3000)
				this.completedCheckpoint = null
				
				// on n'attend pas le retour de la requête (optimistic)
				completeCheckpoint({ id })
					.then((rawCheckpoints) => {
						if (rawCheckpoints?.length) this.rawCheckpoints = rawCheckpoints
					})

			}
		},

		async resetAllTutos({ userId = null } = {}) {
			await resetAchievement({ userId })
		}

	},

})
