<template>
	<div class="login" :class="{ signup }">

		<div class="part right">
			<div class="part-bg"><div class="image-bg" v-bg="'login/plant-green.svg'"></div></div>
			<div class="part-content">
				<div class="title">{{ $t('login.loginTitle') }}</div>
				<form-login :foods="foods" :colors="colors" />
			</div>
		</div>

		<div class="part left">
			<div class="part-bg"><div class="image-bg" v-bg="'login/plant-orange.svg'"></div></div>
			<div class="part-content">
				<transition name="opacity" mode="out-in">
					<div v-if="!signup" class="signup-intro" key="1">
						<div class="title">{{ $t('login.signupTitle') }}</div>
						<ui-button size="big" color="orange" v-tap="() => signup = true">{{ $t('login.signupButton') }}</ui-button>
					</div>
					<div v-else class="signup-form" key="2">
						<form-signup :foods="foods" :colors="colors" />
					</div>
				</transition>
			</div>
		</div>

		<transition name="opacity">
			<div v-if="signup" class="back-button" v-tap="() => signup = false">
				<div class="icon" v-bg="'half-turn.svg'"></div>
				{{ $t('login.back') }}
			</div>
		</transition>

		<transition name="mobile-bar">
			<div v-if="(device === 'mobile' || device === 'tablet-vertical') && signup" class="mobile-bar">
				<div class="back" v-tap="() => signup = false">
					<div class="icon" v-bg="'half-turn.svg'"></div>
				</div>
			</div>
		</transition>

	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useAppStore } from '@/store/app'
import FormLogin from '@/components/login/form-login.vue'
import FormSignup from '@/components/login/form-signup.vue'
import { getLoginSection } from '@/requests/app'

export default {
	name: 'login',
	data() {
		return {
			signup: false,
			section: null
		}
	},
	computed: {
		...mapState(useAppStore, [ 'device', 'isConnected' ]),
		foods() {
			if (this.section?.clientDecks?.length >= 1) return this.section.clientDecks[1]?.clientCards
			return []
		},
		colors() {
			if (this.section?.clientDecks?.length) return this.section.clientDecks[0]?.clientCards
			return []
		}
	},
	watch: {
		signup(signup) {
			if (!signup) this.autofocus()
		}
	},
	methods: {
		autofocus() {
			if (this.device === 'desktop') this.$el.querySelector('input[type="text"]')?.focus()
		}
	},
	mounted() {
		this.autofocus()
	},
	async created() {
		if (this.isConnected) {
			this.$router.push({ name: 'home' })
		} else {
			this.section = await getLoginSection()
		}
	},
	components: { FormLogin, FormSignup }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

#mobile, #tablet-vertical, #tablet-horizontal
	.login
		&.signup
			.part.left
				height 100%
		.part
			width 100%
			top auto
			bottom auto
			left 0
			right 0
			padding 32px
			transition 0.25s easeOutQuart
			overflow hidden
			&.right
				top 0
				height 78%
				.part-bg .image-bg
					transform none
			&.left
				bottom 0
				height 22%
				.part-bg .image-bg
					opacity 0.25
			.part-bg
				absolute 0
				transform none
				.image-bg
					background-size 100% auto !important
			.part-content .title
				margin 0 0 8px 0
				font-size 2.4rem

#mobile, #tablet-vertical
	.mobile-bar
		height 56px
		position absolute
		left 0
		bottom 0
		right 0
		flex center flex-end
		background-color #687E7F
		transition transform 0.25s easeOutQuart
		&.mobile-bar-enter-from, &.mobile-bar-leave-to
			transform translateY(100%)
		.back
			width 56px
			height 56px
			flex center center
			.icon
				width 32px
				height 32px
				background center center no-repeat
				transform translateY(-2px)


#mobile
	.login .part
		padding 16px
		.part-content
			.title
				text-align center
			::v-deep(.form) .row
				margin 0 0 16px 0
				&.inputs
					display flex
				&.checkboxes
					flex center center
					padding 8px
				&.buttons
					display block
					text-align center
					.forgot-link, .ui-button
						display block
						width 100%
						max-width none
					.forgot-link
						margin 0 0 16px 0
				.input
					height 32px
					padding 0 8px
	.back-button
		display none

.login
	fixed 0
	flex center center
	height 100%
	color #EFF1F1
	&.route-enter-active, &.route-leave-active
		transition transform 0.5s easeInOutQuart
		.part
			transition transform 0.5s easeInOutQuart
			&.right
				transition-duration 0.47s
	&.route-enter-from, &.route-leave-to
		.part
			&.left
				transform translateX(-150%)
			&.right
				transform translateX(150%)

	&.signup
		.part
			&.left
				width 100%

	.part
		position absolute
		top 0
		height 100%
		flex center center
		transition width 0.5s easeInOutQuart
		&.left
			left 0
			width 35%
			text-align center
			.part-bg
				background-color #F1A064
				right 2.6%
			// .image-bg
			// 	background-position left center
			// 	// transform translate(-15%, 5%)
			.part-content ::v-deep(.form) .row .input
				&:focus
					box-shadow 0 0 0 4px darken(#F1A064, 25%)
		&.right
			right 0
			width 65%
			text-align left
			.part-bg
				background-color #3A5F5F
				left 2.6%
			.image-bg
				transform rotate(-30deg)
			.part-content
				max-width 500px
				::v-deep(.form) .row .input
					&:focus
						box-shadow 0 0 0 4px darken(#3A5F5F, 25%)
		.part-bg
			position absolute
			top -100%
			bottom -100%
			width 150%
			transform rotate(15deg)
			transition 0.25s easeOutQuart
			overflow hidden
		.image-bg
			absolute 0
			background center center no-repeat
			background-size auto 40% !important
			// transform rotate(-12deg)
		.part-content
			height 100%
			width 100%
			flex center center column
			.title
				width 100%
				margin 0 0 24px 0
				font-weight 500
				font-size 3.2rem
				text-transform uppercase
			.signup-intro
				flex center center column
				text-align center
			.signup-intro, .signup-form
				width 100%
				max-width 550px
				transition 0.25s easeInOutQuart
				&.opacity-enter-from, &.opacity-leave-to
					opacity 0
			::v-deep(.form)
				width 100%
				.row
					display block
					width 100%
					margin 0 0 24px 0
					&.inputs
						flex center center
						gap 16px
						.one-third
							width 33.3333%
						.two-third
							width 66.6666%
					&.checkboxes
						background-color #456767
						padding 16px
						border-radius 4px
					&.buttons
						flex center space-between
						&.align-right
							flex center flex-end
					.label
						display block
						width 100%
						margin 0 0 4px 0
						text-transform uppercase
						font-weight 500
					.input
						display block
						width 100%
						height 48px
						padding 0 16px
						border none
						background #fff
						border-radius 4px
						font inherit
						font-size 1.8rem
						color dark
						outline none
						text-align left
						&.failed
							animation shake 0.25s linear infinite
							background-color lighten(red, 75%)
						&.is-button
							cursor pointer
							&:hover
								background-color #E1E1E1
						.placeholder
							color #ccc
					div.input
						line-height 48px
						background-color #eee
						color #999
					.forgot-link
						max-width 40%
						text-decoration underline
						cursor pointer
						color #90AAAA
						&:hover
							text-decoration none
		// ::v-deep(.ui-button)
		// 	height 48px
		// 	padding 0 24px
		// 	text-transform uppercase
		// 	&.orange
		// 		background-color #EF771C
		// 		&:hover, &:active
		// 			background-color darken(#EF771C, 10%)

	.back-button
		position absolute
		right 0px
		bottom 0px
		width 96px
		height 96px
		cursor pointer
		flex center center column
		text-transform uppercase
		font-weight 500
		&:hover, &:active
			opacity 0.5
		&:active
			transform scale(0.95)
		&.opacity-enter-active, &.opacity-leave-active
			transition opacity 0.25s easeInOutQuart
		&.opacity-enter-from, &.opacity-leave-to
			opacity 0
		.icon
			width 32px
			height 32px
			margin 0 0 8px 0
			background center center no-repeat



@keyframes shake
	0%, 100%
		transform translate(0, 0)
	15%
		transform translate(-2px, 1px)
	25%
		transform translate(0px, -1px)
	50%
		transform translate(-1px, 2px)
	65%
		transform translate(-2px, -1px)
	75%
		transform translate(2px, 1px)
</style>
