<template>
	<div class="modal-login">
		<div class="bg" v-tap="close"></div>
		<div class="content">
			
			<!-- <div class="title">{{ $t('home.lockedContentTitle') }}</div> -->
			<div class="header">
				<div class="title">{{ $t('login.modalLoginTitle') }}</div>
				<p>{{ $t('login.modalLoginDescription') }}</p>
			</div>
			
			<form-login :foods="foods" :colors="colors" :redirection="false" @logged="() => close()" />
			
			<div class="close" :title="$t('settings.close')" v-tap="close">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapWritableState } from 'pinia'
import { useAppStore } from '@/store/app'
import { getLoginSection } from '@/requests/app'
import FormLogin from '@/components/login/form-login.vue'

export default {
	name: 'modal-login',
	data() {
		return {
			section: null
		}
	},
	computed: {
		...mapState(useAppStore, [ 'device' ]),
		...mapWritableState(useAppStore, [ 'modalLoginVisible' ]),
		foods() {
			if (this.section?.clientDecks?.length >= 1) return this.section.clientDecks[1]?.clientCards
			return []
		},
		colors() {
			if (this.section?.clientDecks?.length) return this.section.clientDecks[0]?.clientCards
			return []
		}
	},
	methods: {
		close() {
			this.modalLoginVisible = false
		}
	},
	mounted() {
		if (this.device === 'desktop') this.$el.querySelector('input[type="text"]')?.focus()
	},
	async created() {
		this.section = await getLoginSection()
	},
	components: { FormLogin }
}
</script>

<style lang="stylus" scoped>

#app > .low-perf
	.modal-login
		transition none
		.bg, .content
			transition none
			backdrop-filter none

#mobile
	.modal-login
		padding 16px
		::v-deep(.form) .row
			margin 0 0 16px 0
			&.inputs
				display flex
			&.checkboxes
				flex center center
				padding 8px
			&.buttons
				display block
				text-align center
				.forgot-link, .ui-button
					display block
					width 100%
					max-width none
				.forgot-link
					margin 0 0 16px 0
			.input
				height 32px
				padding 0 8px

.modal-login
	fixed 0
	flex center center
	z-index 100000
	&.modal-login-enter-active
		transition 0.2s easeOutQuart
		.bg
			transition 0.2s easeOutQuart
		.content
			transition 0.2s easeOutBack
	&.modal-login-enter-from
		.bg
			opacity 0
		.content
			opacity 0
			transform scale(0.95)

	&.modal-login-leave-active
		transition 0.25s easeOutQuart
	&.modal-login-leave-to
		opacity 0

	.bg
		background-color alpha(#446364, 70%)
		absolute 0
		backdrop-filter blur(8px)
		transition 0.2s easeOutQuart
	.content
		width 100%
		max-width 600px
		background-color #fff
		padding 32px
		text-align center
		border-radius 6px
		> .header
			margin 0 0 24px 0
			// padding 0 0 16px 0
			// border-bottom 2px solid #748787
			.title
				font-size 2.8rem
				color #748787
				text-transform uppercase

		::v-deep(.form-login)
			width 100%
			color #446767
			text-align left
			border 2px solid #eee
			border-radius 4px
			margin 0 0 16px 0
			.row
				display block
				width 100%
				margin 0 0 24px 0
				padding 0 16px
				&:last-child
					margin-bottom 16px
				&.inputs
					flex center center
					gap 16px
					.one-third
						width 33.3333%
					.two-third
						width 66.6666%
				&.checkboxes
					background-color #eee
					padding 16px
					border-radius 0
					// border-radius 4px
					// border-bottom 2px solid #eee
					.checkbox
						border-color #3A5F5F
						svg
							color #3A5F5F
					
				&.buttons
					flex center space-between
					&.align-right
						flex center flex-end
				.label
					display block
					width 100%
					margin 0 0 4px 0
					text-transform uppercase
					font-weight 500
				.input
					display block
					width 100%
					height 48px
					padding 0 16px
					border none
					background #fff
					border-radius 4px
					font inherit
					font-size 1.8rem
					color dark
					outline none
					text-align left
					background-color #eee
					&:focus
						background-color #E1E1E1
					&.failed
						animation shake 0.25s linear infinite
						background-color lighten(red, 75%)
					&.is-button
						cursor pointer
						&:hover
							background-color #E1E1E1
					.placeholder
						color #ccc
				div.input
					line-height 48px
					background-color #eee
					color #999
				.forgot-link
					max-width 50%
					text-decoration underline
					cursor pointer
					color #90AAAA
					&:hover
						text-decoration none
		::v-deep(.hubspot)
			.frame
				padding 16px 24px !important
				background-color #eee

	.close
		position absolute
		right 8px
		top 8px
		width 32px
		height 32px
		color red
		flex center center
		cursor pointer
		transition 0.1s easeOutQuart
		&:hover
			transform scale(1.1)
		&:active
			transform scale(0.9)
		svg
			width @width
			height @height

@keyframes shake
	0%, 100%
		transform translate(0, 0)
	15%
		transform translate(-2px, 1px)
	25%
		transform translate(0px, -1px)
	50%
		transform translate(-1px, 2px)
	65%
		transform translate(-2px, -1px)
	75%
		transform translate(2px, 1px)
		
</style>
