<template>
	<button :type="type" class="ui-button" :disabled="disabled" :class="[ color, size, { block, 'has-icon-left': icon && iconPosition === 'left', 'has-icon-right': icon && iconPosition === 'right', loading } ]">
		<slot />

		<div v-if="icon" class="icon" :class="iconPosition" v-bg="icon"></div>
		<div v-if="rightArrow" class="right-arrow"></div>

		<ui-loader v-show="loading" />

	</button>
</template>

<script>

export default {
	name: 'ui-button',
	props: {
		size: { default: '' },
		type: { default: 'button' },
		color: { default: '' },
		disabled: { default: false },
		block: { default: false },
		rightArrow: { default: false },
		icon: { default: '' },
		iconPosition: { default: 'right' },
		loading: { default: false },
	}
}
</script>

<style lang="stylus" scoped>

.ui-button
	display inline-block
	height 32px
	background-color primary
	flex center center
	padding 0 16px
	border none
	outline none
	border-radius 4px
	user-select none
	cursor pointer
	font-size 1.6rem
	line-height 1.1em
	font-weight 500
	color #fff
	text-align center
	&.big
		height 48px
		padding 0 24px
		text-transform uppercase
	&.block
		display block
		width 100%
	&.has-icon-left
		padding-left 40px
	&.has-icon-right
		padding-right 40px
	&:hover, &:active
		background-color darken(primary, 10%)
	&:active, &.loading
		transform translateY(1px)
	&:disabled
		opacity 0.5
		cursor not-allowed
		&:active, &.loading
			transform none
	&.dark-green
		background-color #446E6E
		&:hover, &:active
			background-color darken(#446E6E, 10%)
	&.orange
		background-color #EF771C
		&:hover, &:active
			background-color darken(#EF771C, 10%)
	&.yellow
		background-color #F9AE19
		&:hover, &:active
			background-color darken(#F9AE19, 10%)
	&.white
		background-color #fff
		color #687E7F
		&:hover, &:active
			background-color darken(#fff, 10%)
	&.red
		background-color #D67474
		&:hover, &:active
			background-color darken(#D67474, 10%)
	.icon
		position absolute
		top calc(50% - 8px)
		width 16px
		height 16px
		background center center no-repeat
		&.right
			right 12px
		&.left
			left 12px
	.right-arrow
		position absolute
		top calc(50% - 8px)
		right 8px
		width 0
		height 0
		border-style solid
		border-width 8px 0 8px 12px
		border-color transparent transparent transparent #fff
	::v-deep(.ui-loader)
		background-color inherit
		// background-color alpha(#fff, 50%)
		border-radius 4px
		color inherit
		svg
			height 28px
			width auto


</style>
