import { defineStore, acceptHMRUpdate } from 'pinia'
import { useAppStore } from '@/store/app'
import { getRandomID } from '@affordancestudio/functions'

export const useAdminDashboardStore = defineStore({
	id: 'admin-dashboard',

	state: () => ({
		lastUpdate: {
			time: null,
			type: '',
		},
		panels: [],
		modals: [],
	}),

	getters: {

		features() {
			const { role } = useAppStore()
			return {
				vireo: {
					district: [ 'view', 'add', 'edit', 'disable', 'reset-password', 'remove', 'edit-email', 'add-schools-to-district', 'remove-schools-from-district' ],
					school: [ 'view', 'add', 'edit', 'disable', 'reset-password', 'remove', 'edit-email' ],
					group: [ 'view', 'add', 'edit', 'remove', 'add-teachers-to-group', 'remove-teachers-from-group' ],
					teacher: [ 'view', 'add', 'edit', 'disable', 'reset-password', 'remove', 'edit-email' ],
					student: [ 'view', 'disable', 'reset-password' ],
				},
				district: {
					district: [ 'view', 'edit', 'add-schools-to-district', 'remove-schools-from-district' ],
					school: [ 'view', 'add', 'edit', 'disable', 'reset-password' ],
					group: [ 'view', 'add', 'edit', 'add-teachers-to-group', 'remove-teachers-from-group' ],
					teacher: [ 'view', 'add', 'edit', 'disable', 'reset-password' ],
					student: [ 'view', 'disable', 'reset-password' ],
				},
				school: {
					district: [],
					school: [ 'view', 'edit' ],
					group: [ 'view', 'add', 'edit', 'add-teachers-to-group', 'remove-teachers-from-group' ],
					teacher: [ 'view', 'add', 'edit', 'disable', 'reset-password' ],
					student: [ 'view', 'disable', 'reset-password' ],
				},
				teacher: {
					district: [],
					school: [],
					group: [ 'view', 'edit' ],
					teacher: [  ],
					student: [ 'view', 'disable', 'reset-password' ],
				},
			}[role] || { district: [], school: [], group: [], teacher: [], student: [] }
		}

	},

	actions: {

		logUpdate({ type }) {
			this.lastUpdate.type = type
			this.lastUpdate.time = Date.now()
		},

		addPanel(panel) {
			this.panels = [ ...this.panels, { ...panel, id: getRandomID() } ]
		},

		removePanel({ id }) {
			this.panels = this.panels.filter(p => p.id !== id)
		},


		addModal(modal) {
			this.modals = [ ...this.modals, { ...modal, id: getRandomID() } ]
		},

		removeModal({ id }) {
			this.modals = this.modals.filter(p => p.id !== id)
		},

		replaceModal({ oldModal, newModal }) {
			this.modals = [ ...this.modals.filter(p => p.id !== oldModal.id), { ...newModal, id: oldModal.id } ]
		},

	},

})


if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useAdminDashboardStore, import.meta.hot))
}
