<template>
	<div v-if="event?.slug" class="ui-calendar-event-icon" v-html="icon"></div>
</template>

<script>
import { medias } from '@affordancestudio/engage-game'

export default {
	props: {
		event: { required: true },
	},
	computed: {
		icon() {
			try {
				const m = this.event?.clientMedias?.find(m => m?.tags?.includes('event'))
				if (m?.base64) return atob(m?.base64?.split('base64,')?.pop()) || null

				const m2 = medias.findBySlug({ slug: this.iconSlug })
				if (m2?.base64) return atob(m2?.base64?.split('base64,')?.pop()) || null
			} catch (e) {
				console.error(e)
			}
			return '<svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"><path d="M 33.894 20 C 33.894 27.674 27.674 33.894 20 33.894 C 12.326 33.894 6.106 27.674 6.106 20 C 6.106 12.326 12.326 6.106 20 6.106 C 27.674 6.106 33.894 12.326 33.894 20 Z" style="stroke: currentColor; stroke-width: 5px; fill: none;"></path></svg>'
		},
		iconSlug() {
			if (this.event.slug.startsWith('pollinisation-')) return 'event-pollinisation'
			return 'event-' + this.event.slug
		}
	},
}
</script>

<style lang="stylus" scoped>

.ui-calendar-event-icon
	width 16px
	height 16px
	::v-deep(svg)
		width 100%
		height 100%
	::v-deep(span.empty)
		width 100%
		height 100%
		flex center center


</style>
