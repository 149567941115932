<template>
	<form class="form form-signup" :class="'step-' + step" @submit.prevent="submit()">

		<transition name="step-title">
			<div v-if="step > 1" class="step-2-title">{{ $t('login.formSignupStep2Title') }} <strong v-if="group?.name">{{ group.name }}</strong></div>
		</transition>

		<transition name="step" mode="out-in">

			<div v-if="step === 1" class="step">
				<div class="row">
					<label for="field-group-code" class="label">{{ $t('login.formSignupGroupCodeLabel') }}</label>
					<input type="text" v-model="groupCode" class="input" id="field-group-code" minlength="4" required autocomplete="off">
				</div>
				<div class="row">
					<div class="text"><ui-text :text="$t('login.formSignupGroupCodeInfo')" /></div>
				</div>
				<div class="row">
					<ui-checkbox v-model="acceptPolicies" :text="$t('login.formSignupAcceptPolicies', { link: pdfs[lang].privacyPolicy })" color="white" />
				</div>
				<div class="row">
					<ui-checkbox v-model="acceptTerms" :text="$t('login.formSignupAcceptTerms', { link: pdfs[lang].termeOfUse })" color="white" />
				</div>
			</div>

			<div v-else-if="step === 2" class="step">
				<div v-if="ageOptions?.length" class="row">
					<label for="field-age" class="label">{{ $t('login.formSignupAge') }}</label>
					<select v-model="age" class="input select" id="field-age" autocomplete="off">
						<option value="">{{ $t('login.formSignupAgePlaceholder') }}</option>
						<option v-for="option in ageOptions" :key="option.id" :value="option.id">{{ option.text }}</option>
					</select>
				</div>
				<br>
				<div v-if="goalsOptions?.length" class="row">
					<label class="label">{{ $t('login.formSignupGoals') }}</label>
					<div class="checkboxes">
						<div v-for="option in goalsOptions" :key="option.id" class="checkbox-wrap">
							<ui-checkbox v-model="goals[option.id]" :text="option.text" />
						</div>
					</div>
				</div>
				<br>
				<div v-if="moodOptions?.length" class="row">
					<label class="label">{{ $t('login.formSignupMood') }}</label>
					<div class="emojis">
						<div v-for="option in moodOptions" :key="option.id" class="emoji"
							:class="{ selected: mood === option.id }" @click="() => mood = mood !== option.id ? option.id : ''"
							v-html="option.text"
						></div>
					</div>
				</div>
				<br>
			</div>

			<div v-else-if="step === 3" class="step">
				<div class="row inputs">
					<div class="one-third">
						<label for="field-initials" class="label">{{ $t('login.formSignupInitialsLabel') }}*</label>
						<input type="text" v-model="initials" class="input uppercase" id="field-initials" minlength="2" maxlength="4" required autocomplete="off">
					</div>
					<div class="one-third">
						<label class="label">{{ $t('login.formSignupFoodLabel') }}*</label>
						<button type="button" class="input is-button" @click="() => currentModal = 'food-selection'">{{ food?.document?.title }}</button>
					</div>
					<div class="one-third">
						<label class="label">{{ $t('login.formSignupColorLabel') }}*</label>
						<button type="button" class="input is-button" @click="() => currentModal = 'color-selection'">{{ color?.document?.title }}</button>
					</div>
				</div>
				<div class="row username">
					<div class="username-label">{{ $t('login.formSignupUsernameLabel') }}:</div>
					<div class="username-value">{{ username }}</div>
				</div>
				<div class="row">
					<label for="field-password" class="label">{{ $t('login.formSignupPasswordLabel') }}</label>
					<input type="password" v-model="password" class="input" id="field-password" required autocomplete="off">
				</div>
				<div class="row">
					<label for="field-confirmation" class="label">{{ $t('login.formSignupConfirmationLabel') }}</label>
					<input type="password" v-model="confirmation" class="input" id="field-confirmation" required autocomplete="off">
				</div>
			</div>

		</transition>

		<div class="buttons">
			<ui-button type="submit" size="big" color="orange" :loading="loading" :disabled="!stepValid">{{ $t('login.formSignupNextButton') }}</ui-button>
		</div>

		<transition name="step-text">
			<div v-if="step === 3" class="step-2-text text"><div class="star">*</div><ui-text :text="$t('login.formSignupStep2Info')" /></div>
		</transition>

		<teleport to="#app .app-content">
			<modal-food-selection
				v-if="currentModal === 'food-selection'"
				:foods="foods"
				@close="closeModal"
				@select="selectFood"
			/>
		</teleport>

		<teleport to="#app .app-content">
			<modal-color-selection
				v-if="currentModal === 'color-selection'"
				:colors="colors"
				@close="closeModal"
				@select="selectColor"
			/>
		</teleport>

		<teleport to="#app .app-content">
			<modal-signup-success
				v-if="currentModal === 'signup-success'"
				@close="backToHome"
				:username="username"
			/>
		</teleport>

	</form>
</template>

<script>
import { mapState } from 'pinia'
import { useAppStore } from '@/store/app'
import { initStudentSignUp, submitStudentSignup } from '@/requests/on-boarding'
import { login, getUser } from '@/requests/login'
import ModalFoodSelection from '@/components/login/modal-food-selection.vue'
import ModalColorSelection from '@/components/login/modal-color-selection.vue'
import ModalSignupSuccess from '@/components/login/modal-signup-success.vue'
import UiCheckbox from '@/components/ui/ui-checkbox.vue'

const getSelectOptions = (fields, slug) => {
	return fields
		?.find(f => f?.entry?.tags?.includes(slug))?.entry?.selectableElements
		?.map(e => ({ id: e?.id, text: e?.option?.html }))
}

export default {
	name: 'form-signup',
	data() {
		return {
			loading: false,
			step: 1,
			progression: null,
			fields: [],
			currentModal: null,

			groupCode: '',
			group: null,
			acceptPolicies: false,
			acceptTerms: false,

			age: '',
			goals: {},
			mood: '',

			initials: '',
			password: '',
			confirmation: '',
			food: null,
			color: null,
		}
	},
	props: {
		foods: { default: [] },
		colors: { default: [] },
	},
	computed: {
		...mapState(useAppStore, [ 'device', 'lang', 'pdfs' ]),
		ageOptions() {
			return getSelectOptions(this.fields, 'age')
		},
		goalsOptions() {
			return getSelectOptions(this.fields, 'goals')
		},
		moodOptions() {
			return getSelectOptions(this.fields, 'mood')
		},
		username() {
			const food = this.food?.document?.title ? '-' + this.food.document.title : ''
			const color = this.color?.document?.title ? '-' + this.color.document.title : ''
			return (this.initials).toUpperCase() + (food + color).toLowerCase()
		},
		stepValid() {
			if (this.step === 1) return this.groupCode.length >= 4 && this.acceptPolicies && this.acceptTerms
			if (this.step === 2) return this.age && this.mood && Object.keys(this.goals).find(id => this.goals[id])
			if (this.step === 3) return this.username && this.food && this.color && this.initials.length >= 2 && this.initials.length <= 4 && this.password.length >= 6 && this.confirmation === this.password
			return false
		}
	},
	watch: {
		'goalsOptions.length'(n) {
			if (n) {
				this.goalsOptions.forEach(g => this.goals[g.id] = false)
			}
		},
		step() {
			setTimeout(() => { this.autofocus() }, 500)
		}
	},
	methods: {
		autofocus() {
			if (this.device === 'desktop') {
				[ ...this.$el.querySelectorAll('input') ].some($input => {
					if (!$input.value) {
						$input.focus()
						return true
					}
					return false
				})
			}
		},
		selectFood(e) {
			this.food = e.food
		},
		selectColor(e) {
			this.color = e.color
		},
		backToHome() {
			this.currentModal = null
			this.$router.push({ name: 'home' })
		},
		closeModal() {
			this.currentModal = null
			this.autofocus()
		},
		getFormElementValueByEntry({ id = null, tags = [] }) {
			if (tags.includes('initials')) return [ this.initials ]
			if (tags.includes('food')) return [ this.food.id ]
			if (tags.includes('color')) return [ this.color.id ]
			if (tags.includes('password')) return [ this.password, this.confirmation ]
			if (tags.includes('username')) return [ this.username ]
			if (tags.includes('age')) return [ this.age ]
			if (tags.includes('goals')) return Object.keys(this.goals).filter(id => this.goals[id])
			if (tags.includes('mood')) return [ this.mood ]
			if (tags.includes('terms')) return [ '1', '1' ]

			return [ '' ]
		},
		async submit() {
			if (!this.loading && this.stepValid) {
				this.loading = true

				// Group code submit
				if (this.step === 1) {
					const { progression, group } = await initStudentSignUp({ groupCode: this.groupCode })
					if (progression && group) {
						this.progression = progression
						this.group = group
						this.fields = progression.currentMark.step.formElements
						this.step++
					} else
						useAppStore().addNotification({ status: 'error', title: this.$t('login.formSignupStep1DefaultErrorTitle'), text: this.$t('login.formSignupStep1DefaultErrorText') })
				}

				// Form submit
				else if (this.step === 3) {
					const values = this.fields.map(formElement => {
						return { formElementId: formElement.id, data: this.getFormElementValueByEntry(formElement.entry) }
					})
					const response = await submitStudentSignup({ progression: this.progression, values })
					if (response?.score === 1) {
						this.currentModal = 'signup-success'
						const r = await login({ username: this.username, password: this.password })
						if (r) {
							const user = await getUser()
							useAppStore().setLoggedUser({ user })
						}
					}
				}

				else {
					this.step++
				}

				this.loading = false
			}
		}
	},
	mounted() {
		this.autofocus()
	},
	components: { ModalFoodSelection, ModalColorSelection, ModalSignupSuccess, UiCheckbox }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#mobile
	.form-signup
		transition 0.25s easeOutQuart
		&.step-2
			transform translateY(-40px)
		&.step-3
			transform translateY(-72px)
		.step, .step-2-text
			transition none
		.step
			height auto
		.step-2-title
			display none
		.row
			margin 0 0 12px 0 !important
			&.inputs
				display flex
				gap 12px
		.step-2-text
			font-size 1.4rem

.form-signup
	width 100%
	text-align left
	transition transform 0.25s easeInOutQuart
	&.step-2
		transform translateY(72px)
	.step
		// height ((72px + 24px) * 3)
		width 100%
		&.step-enter-active, &.step-leave-active
			transition 0.25s easeInOutQuart
		&.step-enter-from
			opacity 0
			transform translateX(32px)
		&.step-leave-to
			opacity 0
			transform translateX(-32px)
		.row
			.input.uppercase
				text-transform uppercase
			.input.select
				appearance none
				background #fff url('../../assets/img/arrow-down-black.svg') right 8px center no-repeat !important
				background-size 30px !important
			&.username
				transform translateY(-4px)
				background-color alpha(#fff, 15%)
				padding 8px
				border-radius 4px
				text-align center
				color #fff
				.username-label
					display inline-block
					margin 0 8px 0 0
					font-weight 500
				.username-value
					display inline-block
					font-weight 500
			.checkboxes
				// display grid
				// grid-template-columns 1fr 1fr
				// gap 8px
				padding 8px 0 0 0
				font-size 1.8rem
				.checkbox-wrap
					margin 0 0 8px 0
					::v-deep(.ui-checkbox) .checkbox
						background-color #fff
			.emojis
				flex flex-start flex-start
				gap 8px
				.emoji
					width 100%
					height 72px
					border-radius 8px
					background-color alpha(#fff, 15%)
					flex center center
					font-size 4rem
					cursor pointer
					&:hover
						background-color alpha(#fff, 35%)
					&.selected
						background-color #fff
						box-shadow 0 0 0 3px darken(#EF771C, 10%)
					
	.buttons
		flex flex-end flex-end
		.ui-button
			min-width 200px

	.text
		padding 16px
		border-radius 4px
		background-color alpha(#fff, 15%)
	.step-2-title
		position absolute
		left 0
		bottom calc(100% + 40px)
		width 100%
		text-transform uppercase
		font-size 2.4rem
		text-align center
		font-weight 500
		color #fff
		&.step-title-enter-active, &.step-title-leave-active
			transition 0.25s easeOutQuart
		&.step-title-enter-active
			transition-delay 0.5s
		&.step-title-enter-from, &.step-title-leave-to
			opacity 0
			transform translateY(16px)
		strong
			display block
			width 100%
			font-size 4.8rem
			font-weight 500
	.step-2-text
		position absolute
		left 0
		top calc(100% + 24px)
		color #fff
		.star
			position absolute
			left -9px
			top 7px
			font-size 4rem
		&.step-text-enter-active, &.step-text-leave-active
			transition 0.25s easeOutQuart
		&.step-text-enter-active
			transition-delay 0.5s
		&.step-text-enter-from, &.step-text-leave-to
			opacity 0
			transform translateY(-16px)


</style>
