<template>
	<div class="home-comment">
		<div class="bg" v-tap="closeAway"></div>
		<div class="content">
			<div class="close" v-bg="'close.svg'" v-tap="close"></div>

			<component :is="device === 'mobile' ? 'div' : 'perfect-scrollbar'" class="scroll">
				<form class="form" @submit.prevent="submit" ref="form">
					<input type="hidden" name="stars" v-model="starActive">
					<input type="hidden" name="session_id" :value="userId">
					<div class="title">
						<p>{{ $t('homeComment.question1') }}</p>
						<p><img :src="require('@/assets/img/logo-green.png')" alt="Vireo"> {{ $t('homeComment.question2') }}</p>
					</div>
					<div class="description">
						<p>{{ $t('homeComment.description1') }}</p>
					</div>
					<div class="notation">
						<p>{{ $t('homeComment.notation1') }}</p>
						<div class="stars" @mouseleave="unstar">
							<div v-for="i in 5" :key="i" class="star" :class="{ active: i <= starActive, over: i <= starHover }" @mouseenter="starHover = i" v-tap="() => setNote(i)">
								<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
									<polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
								</svg>
							</div>
						</div>
					</div>
					<div class="description">
						<p>{{ $t('homeComment.description2') }}</p>
					</div>

					<textarea class="input" :placeholder="$t('homeComment.inputPlaceholder')" ref="input" v-model="comment"></textarea>

					<div class="buttons">
						<span class="length" :class="{ error: comment.length > 999 }">{{ $t('homeComment.characters') }}: {{ comment.length }}/999</span>
						<ui-button type="submit" :disabled="!sendable" :loading="loading">{{ $t('homeComment.submitButton') }}</ui-button>
					</div>
				</form>
			</component>

			<div v-if="sended" class="sended">

				<div class="success-animation">
					<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
				</div>
				<p class="sended-title">{{ $t('homeComment.sendedTitle') }}</p>
				<p>{{ $t('homeComment.sendedDescription') }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useAppStore } from '@/store/app'
import { sendComment } from '@/requests/home'
import { localStorage } from '@affordancestudio/engage-game'

export default {
	name: 'home-comment',
	data() {
		return {
			comment: '',
			sended: false,
			loading: false,
			starActive: null,
			starHover: 0,
			userId: Math.random().toString(36).substr(2, 9)
		}
	},
	computed: {
		...mapState(useAppStore, [ 'device' ]),
		sendable() {
			return this.comment && this.comment.length < 1000
		}
	},
	methods: {
		unstar() {
			this.starHover = 0
		},
		setNote(starActive) {
			this.starActive = starActive

			this.$nextTick(async () => {
				var form = new FormData(this.$refs.form)
				const response = await fetch(process.env.VUE_APP_FEEDBACK_GOOGLE_SHEET, {
					method: "POST",
					body: form
				})
				if (response.ok) {
					localStorage.setItem('hasPostCommentOrNote', true)
				}
			})
		},
		close() {
			this.$emit('close')
		},
		closeAway() {
			if (!this.comment) this.close()
		},
		async submit() {
			if (!this.loading && this.sendable) {
				this.loading = true
				const response = await sendComment({ subject: "Feedback Vireo", body: this.comment })
				if (response && !response?.error) {
					this.sended = true
					localStorage.setItem('hasPostCommentOrNote', true)
					this.$emit('sended')
					setTimeout(() => {
						this.close()
					}, 3000)
				}
			}
		}
	},
	mounted() {
		if (this.device === 'desktop') this.$refs.input?.focus()
	}
}
</script>

<style lang="stylus" scoped>

#app > .low-perf
	.home-comment
		transition none
		.bg, .content
			transition none
			backdrop-filter none

#mobile
	.home-comment
		padding 8px
		.content
			height calc(100% - 80px)
			margin-bottom 40px
			width 100%
			padding 16px
			overflow auto
			.scroll
				padding 16px
				height auto
			.form
				height 100%
				flex center flex-start column
			.close
				position fixed
				right auto
				top auto
				left 16px
				bottom 20px
				border-radius 0 0 8px 8px
			.title
				width 100%
				font-size 2rem
				line-height 1.4em
				img
					height 44px

.home-comment
	fixed 0
	background-color alpha(#446364, 70%)
	padding 64px
	flex center center
	z-index 1
	transition 0.2s easeOutQuart
	&.home-content-enter-from, &.home-content-leave-to
		.bg
			opacity 0
		.content
			transform scale(0.95)
			opacity 0
	.bg
		absolute 0
		backdrop-filter blur(8px)
		transition 0.2s easeOutQuart
	.content
		width 100%
		max-width 700px
		height 100%
		max-height 788px
		transition 0.2s easeOutBack
		background-color #fff
		border-radius 8px
		.scroll
			height 100%
			padding 32px
			color #333
			text-align center
			.tab-content
				min-height 100%
		.form
			.title
				border-bottom 2px solid #9DB0B0
				padding 0 0 8px 0
				margin 0 0 16px 0
				text-align center
				font-size 3.2rem
				line-height 1.4em
				color #275F5B
				text-transform uppercase
				font-weight 500
				img
					display inline-block
					height 88px
			.description
				color #3B3B3B
				margin 0 0 16px 0
				text-align center
				font-size 1.8rem
				line-height 1.4em
				p
					margin 0 0 8px 0
					&:last-child
						margin 0
			.notation
				border-bottom 2px solid #9DB0B0
				padding 0 0 16px 0
				margin 0 0 16px 0
				font-size 1.8rem
				line-height 1.4em
				text-align center
				color #275F5B
				.stars
					margin 8px 0 0 0
					height 48px
					inline-flex center center
					.star
						width 56px
						height 48px
						cursor pointer
						color #F3E8D8
						&.active
							color #F9AF19
						&.over
							color darken(#F9AF19, 10%)
			.input
				display block
				width 100%
				height 200px
				flex-shrink 0
				padding 16px
				margin 0 0 16px 0
				border 2px solid #E5E7E7
				border-radius 4px
				font inherit
				color #3B3B3B
				outline none
				resize none
				&:focus
					border-color #275F5B
			.buttons
				flex flex-end space-between
				.ui-button
					background-color #889F9F
				.error
					color red
		.sended
			absolute 8px
			background-color #fff
			text-align center
			font-size 2rem
			line-height 1.4em
			flex center center column
			.success-animation
				margin 0 0 32px 0
				.checkmark
					width 96px
					height 96px
					border-radius 50%
					display block
					stroke-width 2
					stroke #4bb71b
					stroke-miterlimit 10
					box-shadow inset 0px 0px 0px #4bb71b
					animation fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
					top 5px
					right 5px
					margin 0 auto
				.checkmark__circle
					stroke-dasharray 166
					stroke-dashoffset 166
					stroke-width 2
					stroke-miterlimit 10
					stroke #4bb71b
					fill #fff
					animation stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
				.checkmark__check
					transform-origin 50% 50%
					stroke-dasharray 48
					stroke-dashoffset 48
					animation stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
				@keyframes stroke
					100%
						stroke-dashoffset 0
				@keyframes scale
					0%, 100%
						transform none
					50%
						transform scale3d(1.1, 1.1, 1)
				@keyframes fill
					100%
						box-shadow inset 0px 0px 0px 30px #4bb71b
			.sended-title
				width 100%
				margin 0 0 8px 0
				font-size 2.4rem
				text-transform uppercase
				color #4BB71C
				font-weight 500
		.close
			position absolute
			right -48px
			top 16px
			width 48px
			height 48px
			border-radius 0 8px 8px 0
			background red center center no-repeat
			background-size 24px 24px !important
			cursor pointer

@media (max-height: 700px)
	.home-comment .content .input
		height 80px


</style>
