<template>
	<div class="loading-screen">
		<div class="light"></div>
		<svg viewBox="0 0 135 135" xmlns="http://www.w3.org/2000/svg" fill="#fff">
			<path d="M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z">
				<animateTransform
					attributeName="transform"
					type="rotate"
					from="0 67 67"
					to="-360 67 67"
					dur="2.5s"
					repeatCount="indefinite"/>
			</path>
			<path d="M28.19 40.31c6.627 0 12-5.374 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.626 5.372 12 12 12zm30.72-19.825c4.686 4.687 12.284 4.687 16.97 0 4.686-4.686 4.686-12.284 0-16.97-4.686-4.687-12.284-4.687-16.97 0-4.687 4.686-4.687 12.284 0 16.97zm35.74 7.705c0 6.627 5.37 12 12 12 6.626 0 12-5.373 12-12 0-6.628-5.374-12-12-12-6.63 0-12 5.372-12 12zm19.822 30.72c-4.686 4.686-4.686 12.284 0 16.97 4.687 4.686 12.285 4.686 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.685-4.687-12.283-4.687-16.97 0zm-7.704 35.74c-6.627 0-12 5.37-12 12 0 6.626 5.373 12 12 12s12-5.374 12-12c0-6.63-5.373-12-12-12zm-30.72 19.822c-4.686-4.686-12.284-4.686-16.97 0-4.686 4.687-4.686 12.285 0 16.97 4.686 4.687 12.284 4.687 16.97 0 4.687-4.685 4.687-12.283 0-16.97zm-35.74-7.704c0-6.627-5.372-12-12-12-6.626 0-12 5.373-12 12s5.374 12 12 12c6.628 0 12-5.373 12-12zm-19.823-30.72c4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.687 4.686-4.687 12.284 0 16.97 4.686 4.687 12.284 4.687 16.97 0z">
				<animateTransform
					attributeName="transform"
					type="rotate"
					from="0 67 67"
					to="360 67 67"
					dur="8s"
					repeatCount="indefinite"/>
			</path>
		</svg>
		<div class="bar">
			<div class="progress-end"></div>
			<div class="progress"></div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'loading-screen'
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'

.loading-screen
	fixed 0
	flex center center column
	background radial-gradient(circle at -100%, #92CCCE, #1F9192)
	z-index 1
	gap 32px
	&.loading-screen-leave-active
		transition opacity 0.25s easeOutQuart 0.5s
	&.loading-screen-leave-to
		opacity 0
		.bar
			.progress-end
				absolute 0
				transform translateX(0)
				background-color #fff
				border-radius 8px
				transition 0.5s easeOutQuart
	.light
		absolute 0
		background radial-gradient(circle at -50%, alpha(#FFDF4D, 50%), alpha(#FFDF4D, 0))
		pointer-events none
	> svg
		width 75px
		height 75px
	.bar
		width 200px
		height 8px
		border-radius 8px
		overflow hidden
		-webkit-mask-image -webkit-radial-gradient(white, black)
		background-color alpha(#fff, 25%)
		.progress, .progress-end
			absolute 0
			transform translateX(-100%)
			background-color #fff
			border-radius 8px
		.progress
			animation 8s progress easeInOutSine forwards
			@keyframes progress
				0%
					transform translateX(-100%)
				100%
					transform translateX(-5%)
					

</style>
