<template>
	<div class="modal-event-detail">
		<div class="bg" v-tap="close"></div>
		<div class="modal-content">
			<div class="close-button" v-bg="'close.svg'" v-tap="close"></div>

			<div class="header">
				<div class="title"><ui-text :text="timelineName" /></div>
				<div class="timeline">
					<div class="arrow prev"></div>
					<div class="tabs">
						<modal-event-detail-tab v-for="tab in tabs" :key="tab.id"
							:event="tab"
							:active="activeTabId === tab.id"
							v-tap="() => activeTabId = tab.id"
						/>
					</div>
					<div class="arrow next"></div>
				</div>
			</div>

			<div class="content">
				<div class="tab-content-header">
					<div v-if="!loading" class="title"><ui-text :text="activeTab?.document?.title || ''" /></div>
				</div>
				<div class="tab-content-text">
					<div class="scroll-content">

						<section-content v-if="!loading" :page="activeTab" />
						<div v-else class="loader"><ui-loader /></div>

					</div>
				</div>
			</div>

			<div class="footer">
				
			</div>

		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useCalendarStore } from '@/store/calendar'
import { getTimeline } from '@/requests/calendar'
import ModalEventDetailTab from '@/components/calendar/modal-event-detail/modal-event-detail-tab.vue'
import SectionContent from '@/components/notepad/parts/section-content.vue'
import UiText from '@/components/ui/ui-text.vue'
import UiLoader from '@/components/ui/ui-loader.vue'

export default {
	data() {
		return {
			page: null,
			activeTabId: '',
			loading: true
		}
	},
	props: {
		event: { required: true }
	},
	computed: {
		...mapState(useCalendarStore, [ 'events', 'timelines' ]),
		timeline() {
			return this.event.timelineId ? this.timelines.find(t => t.id === this.event.timelineId) : null
		},
		timelineName() {
			if (this.timeline) {
				if (this.timeline?.name) return this.timeline.name
				if (this.timeline?.timeline?.document?.title) return this.timeline.timeline.document.title + ' ' + (this.timeline.index || '')
				return this.event?.timeline?.document?.title || ""
			}
			return ""
		},
		tabs() {
			return this.page?.timelineEvents?.map(t => t?.clientEvents?.[0]) || []
		},
		activeTab() {
			return this.tabs?.length ? this.tabs.find(t => t.id === this.activeTabId) || null : null
		},
	},
	methods: {
		close() {
			this.$emit('close')
		},
	},
	async mounted() {
		this.loading = true
		this.page = await getTimeline({ timelineId: this.event.timeline.id })
		if (this.tabs.length && !this.activeTabId) this.activeTabId = this.tabs[0]?.id ? this.tabs[0].id : this.event?.id
		this.loading = false
	},
	created() {
	},
	components: { SectionContent, ModalEventDetailTab, UiLoader, UiText }
}
</script>

<style lang="stylus" scoped>

#mobile
	.modal-event-detail
		.modal-content
			transform translateY(-20px)
			.close-button
				left 32px
				top 100%
				width 56px
				height 40px
				border-radius 0 0 8px 8px
			.header
				> .title
					font-size 2rem
				.timeline .tabs
					gap 24px
			.content
				.tab-content-header
					height 32px
					flex flex-start center
					.title
						font-size 2rem
				.tab-content-text
					height calc(100% - 32px)
			
#tablet-vertical
	.modal-event-detail
		.modal-content
			margin-right 32px

.modal-event-detail
	absolute 0
	flex center center
	height 100%
	padding 80px 16px
	z-index 2
	.bg
		absolute 0
		background-color alpha(#667D7E, 80%)
	
	.modal-content
		width 100%
		max-width 600px
		
		.close-button
			position absolute
			left 100%
			top 40px
			height 56px
			width 40px
			background red center center no-repeat
			background-size 20px 20px !important
			border-radius 0 8px 8px 0
			cursor pointer
			&:hover
				background-color darken(red, 10%)
			
		
		.header
			height (96px + 8px)
			width 100%
			padding 8px 0 0 0
			background-color #3B5F5F
			border-radius 8px 8px 0 0
			overflow hidden
			text-align center
			color #fff
			> .title
				height 48px
				flex center center
				font-size 2.4rem
				text-transform uppercase
				white-space nowrap
			.timeline
				width 100%
				height 48px
				// background-color alpha(#fff, 25%)
				flex center center
				.tabs
					flex center center
					gap 24px
					&:before
						content ''
						position absolute
						left 0
						top 16px
						width 100%
						height 2px
						background-color alpha(#fff, 50%)
					.tab
						height 48px
						width 48px
						flex flex-start center
						cursor pointer
						background-color #3B5F5F
						&:hover > .icon
							opacity 0.75
						&.active
							&:before
								content ''
								position absolute
								left calc(50% - 12px)
								bottom -2px
								width 0
								height 0
								border-style solid
								border-width 0 12px 12px 12px
								border-color transparent transparent #fff transparent
							.icon
								opacity 1
						.icon
							absolute 0
							width 48px
							height 48px
							color #fff
							opacity 0.5
							::v-deep(svg)
								width 32px
								height 32px
		
		.content
			height 600px
			max-height 60vh
			width 100%
			padding 16px 16px 0 16px
			background-color #fff
			.tab-content-header
				height 64px
				flex center center
				border-bottom 2px solid #6B8B8B
				// white-space nowrap
				text-align center
				.title
					display inline-block
					text-align center
					color #0F4444
					font-size 2.8rem
					line-height 1em
					font-weight 500
					text-transform uppercase
			.tab-content-text
				height calc(100% - 64px)
				overflow auto
				padding 16px 0
				.loader
					height 240px
		
		.footer
			height 48px
			width 100%
			background-color #3B5F5F
			border-radius 0 0 8px 8px

</style>