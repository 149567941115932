<template>
	<div class="side-panel-district">

		<div class="side primary white">

			<div class="main-title">
				<template v-if="panel.district">
					<span class="left">{{ panel.district?.profile?.district_name || $t('adminDashboard.panels.district.defaultTitle') }}</span>
					<span class="right">{{ $t('adminDashboard.panels.district.schoolsList') }}</span>
				</template>
				<template v-else>
					<span class="left">{{ $t('adminDashboard.panels.district.yourSchools') }}</span>
					<span class="right">{{ $t('adminDashboard.panels.district.invitedSchoolsList') }}</span>
				</template>
			</div>

			<list-items
				v-if="panel.district"
				item-format="school"
				:request="{ name: 'schoolByDistrict', params: { userId: panel.district.id } }"
				@loaded="(e) => total = e.total"
				@change-selection="(items) => selectedItems = items"
			/>
			<list-items
				v-else
				item-format="school"
				:request="{ name: 'invitedSchools' }"
				@loaded="(e) => total = e.total"
				@change-selection="(items) => selectedItems = items"
			/>
		</div>

		<div class="side secondary green has-boxes">

			<div class="header">
				<div class="title">{{ $t('adminDashboard.actions') }}</div>
				<div class="actions">
					<ui-round-button v-if="features.district.includes('add')" :size="40" :disabled="selectedItems.length" v-tap="() => !selectedItems.length && addModal({ slug: 'add', type: 'school', district: panel.district })" :tooltip="$t('adminDashboard.tooltips.addSchool')" color="white" icon="admin/plus-green.svg" />
					<ui-round-button v-if="features.district.includes('add-schools-to-district')" :size="40" :disabled="selectedItems.length" v-tap="() => !selectedItems.length && addModal({ slug: 'add-schools-to-district', district: panel.district })" :tooltip="$t('adminDashboard.tooltips.linkSchoolToDistrict')" color="white" icon="admin/link-green.svg" />
					<ui-round-button v-if="features.district.includes('reset-password')" :size="40" :disabled="!selectedItems.length" v-tap="() => selectedItems.length && addModal({ slug: 'reset-password', type: 'school', items: selectedItems })" :tooltip="$t('adminDashboard.tooltips.resetSelectedSchoolsPassword')" color="white" icon="admin/send-green.svg" />
					<ui-round-button v-if="features.district.includes('disable')" :size="40" :disabled="!selectedItems.length" v-tap="() => selectedItems.length && addModal({ slug: 'disable', type: 'school', items: selectedItems })" :tooltip="$t('adminDashboard.tooltips.disableSelectedSchools')" color="yellow" icon="admin/disable-green.svg" />
					<ui-round-button v-if="features.district.includes('remove-schools-from-district')" :size="40" :disabled="!selectedItems.length" v-tap="() => selectedItems.length && addModal({ slug: 'remove-schools-from-district', items: selectedItems, district: panel.district })" :tooltip="$t('adminDashboard.tooltips.unlinkSelectedSchoolsFromDistrict')" color="red" icon="admin/unlink-green.svg" />
					<ui-round-button v-if="features.district.includes('remove')" :size="40" :disabled="!selectedItems.length" v-tap="() => selectedItems.length && addModal({ slug: 'remove', type: 'school', items: selectedItems })" :tooltip="$t('adminDashboard.tooltips.deleteSelectedSchools')" color="red" icon="admin/trash-green.svg" />
				</div>
			</div>

			<div class="content">
				<div class="boxes">

					<admin-box>
						<div class="title">{{ $t('adminDashboard.panels.district.stats') }}</div>
						<div class="content">
							<div class="row">
								<div class="label">{{ $t('adminDashboard.panels.district.nbSchools') }}</div>
								<div class="value">{{ total }}</div>
							</div>
						</div>
					</admin-box>

					<admin-box v-if="panel.district">
						<div class="title">{{ $t('adminDashboard.panels.district.districtManager') }}</div>
						<div class="content">
							<div class="row">
								<div class="label">{{ $t('adminDashboard.panels.district.email') }}</div>
								<div class="value">{{ panel.district.email }}</div>
							</div>
							<div class="row">
								<div class="label">{{ $t('adminDashboard.panels.district.accountState') }}</div>
								<div class="value">
									<admin-state :state="panel.district.disabled ? 'disabled' : 'enabled'" />
								</div>
							</div>
							<div class="row">
								<div class="label">{{ $t('adminDashboard.panels.district.subscribeState') }}</div>
								<div class="value">
									<admin-onboarding :onboarding="panel.district.onboarding" background="light" />
								</div>
							</div>
						</div>
						<div class="actions">
							<ui-round-button :size="40" icon="admin/edit-green.svg" v-tap="() => addModal({ slug: 'edit', type: 'district', item: panel.district })" :tooltip="$t('adminDashboard.tooltips.editDistrictAccount')" />
							<ui-round-button v-if="features.district.includes('edit-email')" :size="40" icon="admin/email-green.svg" v-tap="() => addModal({ slug: 'edit-email', type: 'district', item: panel.district })" :tooltip="$t('adminDashboard.tooltips.editEmail')" />
							<ui-round-button :size="40" icon="admin/send-green.svg" v-tap="() => addModal({ slug: 'reset-password', type: 'district', items: [ panel.district ] })" :tooltip="$t('adminDashboard.tooltips.resetDistrictPassword')" />
							<ui-round-button :size="40" icon="admin/disable-green.svg" v-tap="() => addModal({ slug: 'disable', type: 'district', items: [ panel.district ] })" :tooltip="$t('adminDashboard.tooltips.disableDistrict')" color="yellow" />
							<ui-round-button :size="40" icon="admin/trash-green.svg" v-tap="() => addModal({ slug: 'remove', type: 'district', items: [ panel.district ] })" :tooltip="$t('adminDashboard.tooltips.deleteDistrict')" color="red" />
						</div>
					</admin-box>

				</div>

			</div>
		</div>

	</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useAdminDashboardStore } from '@/store/admin-dashboard'
import UiRoundButton from '@/components/ui/ui-round-button.vue'
import AdminState from '@/components/admin-dashboard/ui/admin-state.vue'
import AdminOnboarding from '@/components/admin-dashboard/ui/admin-onboarding.vue'
import AdminBox from '@/components/admin-dashboard/ui/admin-box.vue'
import ListItems from '@/components/admin-dashboard/list/list-items.vue'

export default {
	data() {
		return {
			selectedItems: [],
			total: 0
		}
	},
	props: {
		panel: { default: null, required: true }
	},
	computed: {
		...mapState(useAdminDashboardStore, [ 'features' ]),
	},
	methods: {
		...mapActions(useAdminDashboardStore, [ 'addModal' ]),
	},
	components: { UiRoundButton, AdminBox, ListItems, AdminState, AdminOnboarding }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.side-panel-district
	.side.primary
		.main-title
			justify-content space-between !important
		.list-items
			height calc(100% - 64px)
	

</style>