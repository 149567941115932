<template>
	<div class="language-screen">
		<div class="bg"></div>

		<div class="content">
			<div class="title">Langue · Language</div>
			<p class="description">{{ $t('languageScreen.description1') }}</p>
			<p class="description secondary">{{ $t('languageScreen.description2') }}</p>
			<div class="separator"></div>
			<div class="checkboxes">
				<div class="checkbox" v-tap="() => lang = lang !== 'fr' ? 'fr' : null">
					<div class="box">
						<transition name="stamp">
							<div v-show="lang === 'fr'" class="stamp" v-bg="'stamp-fr.svg'"></div>
						</transition>
					</div>
					<div class="text">Français</div>
				</div>
				<div class="checkbox" v-tap="() => lang = lang !== 'en' ? 'en' : null">
					<div class="box">
						<transition name="stamp">
							<div v-show="lang === 'en'" class="stamp" v-bg="'stamp-en.svg'"></div>
						</transition>
					</div>
					<div class="text">English</div>
				</div>
			</div>
			<div class="buttons">
				<ui-button :disabled="!lang" size="big" :loading="loading" v-tap="submit">{{ $t('languageScreen.button') }}</ui-button>
			</div>

			<div class="cookies">
				<p v-if="lang" v-html="$t('languageScreen.cookieSentence', { link1: pdfs[lang].privacyPolicy, link2: pdfs[lang].termeOfUse })"></p>
			</div>
		</div>

	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useAppStore } from '@/store/app'
import { localStorage, medias } from '@affordancestudio/engage-game'

export default {
	name: 'language-screen',
	data() {
		return {
			lang: null,
			loading: false,
		}
	},
	computed: {
		...mapState(useAppStore, [ 'pdfs' ])
	},
	watch: {
		lang(lang) {
			this.$i18n.locale = lang
		}
	},
	methods: {
		async submit() {
			if (this.lang) {
				localStorage.setItem('vireo-selected-lang', true)
				localStorage.setItem('vireo-accept-policy', true)
				if (this.lang !== localStorage.getItem('Language')) {
					this.loading = true
					localStorage.setItem('Language', this.lang)
					await medias.changeLanguage()
					location.reload()
				}
				else useAppStore().$patch({ languageScreenVisible: false })
			}
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'

#tablet-horizontal, #tablet-vertical, #mobile
	.bg
		absolute 0
		transform none
	.content
		absolute 0
		width 100%
		max-width 600px
		margin 0 auto
		padding 32px
		transform none
		flex center center column
		text-align center

#mobile
	.content
		text-align left
		.title
			margin 0 0 16px 0
			text-align center
			font-size 3.2rem
		.description
			height 64px
			flex center flex-start
			font-size 2rem
		.separator
			background none
			margin 0 0 24px 0
		.checkboxes
			flex-direction column
			margin 0 0 24px 0
			.checkbox
				width 100%
				margin 0 0 16px 0
				.text
					font-size 2.4rem
		.buttons ::v-deep(.ui-button)
			width 100%

.language-screen
	fixed 0
	z-index 100000
	background-color alpha(#B2D8D8, 75%)
	.bg
		background-color #1F9192
		position absolute
		top -40%
		bottom -40%
		left -40%
		width 100%
		transform rotate(10deg)
	.content
		position absolute
		top 50%
		left 10%
		width calc(60% - 192px)
		max-width 600px
		transform translateY(-50%)
		color #fff
		.title
			width 100%
			margin 0 0 8px 0
			font-size 4rem
			text-transform uppercase
		.description
			width 100%
			margin 0 0 8px 0
			font-size 2.4rem
			font-weight 300
			&.secondary
				opacity 0.7
		.separator
			width 100%
			width 100%
			height 2px
			margin 40px 0
			background-color #77BCBD
		.checkboxes
			width 100%
			flex flex-start flex-start
			margin 0 0 40px 0
			text-align left
			.checkbox
				flex center flex-start
				cursor pointer
				width 50%
				&:hover .box
					border-color lighten(#48A5A6, 25%)
				.box
					width 64px
					height 64px
					margin 0 16px 0 0
					border 2px solid #48A5A6
					flex-shrink 0
					border-radius 8px
					.stamp
						position absolute
						left -4px
						top -4px
						width 72px
						height 72px
						flex center center
						background center center no-repeat
						background-size 100%
						&.stamp-enter-active
							transition 0.25s easeOutQuart
						&.stamp-leave-active
							transition 0.25s easeOutQuart
						&.stamp-enter-from, &.stamp-leave-to
							transform scale(2)
							opacity 0
				.text
					font-size 3.2rem
					text-transform uppercase
		.buttons
			display block
			width 100%
			margin 0 0 32px 0
			::v-deep(.ui-button)
				background-color #1C6868
				min-width 240px
		.cookies
			min-height 64px
			font-size 1.4rem
			line-height 1.4em
			color #fff
			::v-deep(a)
				color #fff
				text-decoration underline
				&:hover
					text-decoration none

</style>
