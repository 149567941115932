import { get, post } from '@/requests/request'


export const startGame = async ({ game = null }) => {
	if (game) {
		console.info('Starting game [' + game.id + ']...')
		return await post('startMaintenance', { gameId: game.id })
	}
	return null
}

export const getFormDemo = async () => {
	// TODO Don't forget to change the ID when the game change
	console.info('Getting demo...')
	return await get('getFormDemo', { id: "6255c3178d268a000a515132" })
}


export const submitMaintenanceForm = async ({ progression, values }) => {
	const answers = values.map(v => {
		let data = Array.isArray(v.value) ? v.value : v.value ? v.value.toString() : ''
		if (!data && v.type === 'ContentFormNumberInputBaseQuery') data = '0'
		return { formElementId: v.id, data }
	})
	const response = await post('submitForm', { progressionId: progression.id, answers })
	if (response?.score === 1) {
		return await post('completeMaintenanceStep', { progressionId: progression.id })
	}
	return response
}


export const sendAnswer = async ({ progression, question, answers }) => {
	const response = await post('sendAnswer', { progressionId: progression.id, questionId: question.id, answers: answers.map(a => a.id) })
	if (!response?.error) {
		return await post('completeMaintenanceStep', { progressionId: progression.id })
	}
	return response
}


export const completeStep = async ({ progression }) => {
	return await post('completeMaintenanceStep', { progressionId: progression.id })
}
