<template>
	<form class="form form-login" @submit.prevent="submit()" autocomplete="off" ref="form">
		<div class="row checkboxes">
			<ui-checkbox v-model="isNotStudent" :text="$t('login.loginStudentCheckbox')" color="white" />
		</div>
		<div v-if="isNotStudent" class="row">
			<label for="field-username" class="label">{{ $t('login.loginFormUsernameLabel') }}</label>
			<input type="text" :value="username" @input="username = username = $event.target.value.toLowerCase()" class="input" :class="{ failed }" id="field-username" autocomplete="off" :placeholder="$t('login.loginFormUsernamePlaceholder')">
		</div>
		<div v-else class="row inputs">
			<div class="one-third">
				<label for="field-username" class="label">{{ $t('login.formSignupInitialsLabel') }}</label>
				<input type="text" v-model="initials" class="input uppercase" id="field-initials" minlength="2" maxlength="4" required autocomplete="off" :placeholder="$t('login.formSignupInitialsLabel')">
			</div>
			<div class="one-third">
				<label for="field-username" class="label">{{ $t('login.formSignupFoodLabel') }}</label>
				<button type="button" class="input is-button" @click="() => currentModal = 'food-selection'">
					<span v-if="!food" class="placeholder">{{ $t('login.formSignupFoodLabel') }}</span>
					{{ food?.document?.title }}
				</button>
			</div>
			<div class="one-third">
				<label for="field-username" class="label">{{ $t('login.formSignupColorLabel') }}</label>
				<button type="button" class="input is-button" @click="() => currentModal = 'color-selection'">
					<span v-if="!color" class="placeholder">{{ $t('login.formSignupColorLabel') }}</span>
					{{ color?.document?.title }}
				</button>
			</div>
		</div>
		<div class="row">
			<label for="field-password" class="label">{{ $t('login.loginFormPasswordLabel') }}</label>
			<input type="password" v-model="password" class="input" :class="{ failed }" id="field-password" autocomplete="off" :placeholder="$t('login.loginFormPasswordPlaceholder')">
		</div>
		<div class="row buttons" :class="{ 'align-right': !isNotStudent }">
			<span v-if="isNotStudent" class="forgot-link" v-tap="() => currentModal = 'request-reset-password'">{{ $t('login.loginFormForgotPasswordLabel') }}</span>
			<ui-button type="submit" size="big" :disabled="!formValid" :loading="loading">{{ $t('login.loginFormSubmitButton') }}</ui-button>
		</div>

		<teleport to="#app .app-content">
			<modal-food-selection
				v-if="currentModal === 'food-selection'"
				:foods="foods"
				@close="closeModal"
				@select="selectFood"
			/>
		</teleport>

		<teleport to="#app .app-content">
			<modal-color-selection
				v-if="currentModal === 'color-selection'"
				:colors="colors"
				@close="closeModal"
				@select="selectColor"
			/>
		</teleport>

		<teleport to="#app .app-content">
			<modal-request-reset-password
				v-if="currentModal === 'request-reset-password'"
				@close="closeModal"
			/>
		</teleport>

		<teleport to="#app .app-content">
			<modal-user-disabled
				v-if="currentModal === 'modal-user-disabled'"
				:has-school-error-message="hasSchoolErrorMessage"
				@close="closeModal"
			/>
		</teleport>
	</form>
	<div class="hubspot">
		<div class="frame">
			<p>{{ $t('login.loginJoinPlatform') }}</p>
			<p>
				<a :href="$t('login.loginHubspotLink')" target="_blank">
					{{ $t('login.loginContactRepresentative') }}
				</a>
			</p>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useAppStore } from '@/store/app'
import { login, getUser } from '@/requests/login'
import ModalFoodSelection from '@/components/login/modal-food-selection.vue'
import ModalColorSelection from '@/components/login/modal-color-selection.vue'
import ModalRequestResetPassword from '@/components/login/modal-request-reset-password.vue'
import ModalUserDisabled from '@/components/login/modal-user-disabled.vue'
import UiCheckbox from '@/components/ui/ui-checkbox.vue'

export default {
	components: { ModalFoodSelection, ModalColorSelection, ModalRequestResetPassword, ModalUserDisabled, UiCheckbox },
	name: 'form-login',
	data() {
		return {
			failed: false,
			loading: false,
			fields: [],
			username: '',
			initials: '',
			food: null,
			color: null,
			password: '',
			currentModal: null,
			isNotStudent: false,
			hasSchoolErrorMessage: false,
		}
	},
	emits: [ 'logged' ],
	props: {
		foods: { default: [] },
		colors: { default: [] },
		redirection: { default: true },
	},
	computed: {
		...mapState(useAppStore, [ 'device', 'isConnected', 'notifications' ]),
		formValid() {
			if (!this.isNotStudent) return this.initials && this.food && this.color && this.password
			return this.username && this.password
		},
		computedUsername() {
			if (!this.isNotStudent) {
				const food = this.food?.document?.title ? '-' + this.food.document.title : ''
				const color = this.color?.document?.title ? '-' + this.color.document.title : ''
				return (this.initials).toUpperCase() + (food + color).toLowerCase()
			}
			return this.username
		}
	},
	watch: {
		failed(failed) {
			if (failed) setTimeout(() => this.failed = false, 500)
		},
		isNotStudent() {
			if (this.device === 'desktop') {
				this.$nextTick(() => {
					this?.$refs?.form?.querySelector('input[type="text"]')?.focus()
				})
			}
		}
	},
	methods: {
		...mapActions(useAppStore, [ 'addNotification', 'setLoggedUser' ]),
		closeModal() {
			this.currentModal = null
		},
		selectFood(e) {
			this.food = e.food
		},
		selectColor(e) {
			this.color = e.color
		},
		loginError(e) {
			this.failed = true
			if (e?.error?.messages?.length) {
				if (e.error.messages.find(m => m?.message?.indexOf('user disabled') > -1)) {
					this.currentModal = 'modal-user-disabled'
					this.hasSchoolErrorMessage = e?.error?.data?.response?.user?.school === true
				}
			}
		},
		async submit() {
			if (!this.loading) {
				this.loading = true
				const response = await login({ username: this.computedUsername, password: this.password, loginError: this.loginError })
				if (response && !response.error && !response.code) {
					const user = await getUser()
					if ([ 'is_validated', 'is_confirmed', 'is_reset' ].includes(user?.onboarding)) {
						this.setLoggedUser({ user })
						this.addNotification({ status: 'success', title: this.$t('notifications.loginSuccessTitle'), text: this.$t('notifications.loginSuccessText') })
						if (this.redirection) {
							if (user.onboarding === 'is_reset') {
								this.$router.push({ name: 'reset-password' })
							} else
								this.$router.push({ name: 'home' })
						} else {
							this.$emit('logged', true)
						}
					}
				}
				this.loading = false
			}
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

#mobile, #tablet-vertical, #tablet-horizontal
	.hubspot
		.frame
			padding 8px 16px
	
.hubspot
	font-size 1.6rem
	width 100%
	.frame
		background-color #456767
		border-radius 4px
		padding 16px
		p
			margin 0 0 8px 0
			&:last-child
				margin 0
			a
				color #F19F64
				text-decoration underline
				&:hover
					text-decoration none
</style>
