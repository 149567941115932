export default {
	mainPageTitle: "Vireo éducation",

	notepad: {
		backToHome: "Retour à l'accueil",
		closePage: "Fermer la page",
		hide: "Cacher",
		copy: "Copier lien",
		copied: "Copié!",
		search: "Rechercher par titre...",
		archived: "Entretien<br>archivé!",
		backToGardenExperiments: "Retour au menu<br>des entretiens",
		tableOfContent: "Table des matières",
	},
	demo: {
		pageTitle: "Entretien terminé",
		sentence1: "Vous avez atteint la fin d'une expérience potager. Afin d'avoir accès à un historique dans la section Archives ainsi que d'obtenir d'autres expériences, veuillez vous connecter ou vous créer un compte.",
		close: "Fermer",
	},
	noAccess: {
		pageTitle: "Page non disponible",
		sentence1: "Vous tentez d'accéder à une page qui n'existe pas ou à laquelle vous n'avez pas accès.",
		close: "Fermer",
	},
	userMenu: {
		dashboard: "Tableau de bord",
		stats: "Statistiques",
		lowPerformanceMode: "Mode basse performance",
		retryTuto: "Réessayer le tutoriel",
		groupSelection: "Sélection de groupe",
		switchLang: "Changer de langue:",
		french: "Français",
		english: "Anglais",
		logout: "Déconnexion",
		privacyPolicy: "Politique de vie privée",
		termsOfUse: "Conditions d'utilisation",
	},
	groupSelection: {
		edit: "Modifier",
		title: "Sélectionner un groupe",
		description: "En sélectionnant un groupe, vous pourrez compléter des \"Expérience Potager\" qui s'archiveront dans les archives de ce groupe.",
		footer: "Vous pouvez toujours changer de groupe depuis le menu principal ou dans les \"Expérience Potager\".",
		button: "Sélectionner",
		noGroupTitle: "Vous n'avez pas de groupe.",
		noGroupSentence1: "Vous devez être associé.e à un groupe pour participer aux expériences potager.",
		noGroupSentence2: "Contacter votre administrateur pour en savoir plus.",
	},
	potagerSelection: {
		edit: "Modifier",
		title: "Sélectionnez un modèle",
		footer: "Vous pouvez toujours changer de potager pour consulter son Guide du potager.",
		close: "Fermer",
		button: "Sélectionner",
		noGardenTitle: "Vous n'avez pas de potager.",
		noGardenSentence1: "Vous devez être associé.e à un potager pour accéder au Guide du potager.",
		noGardenSentence2: "Contacter votre administrateur pour en savoir plus.",

		s02: "Potager Vireo S02",
		s02Pods: "102 paniers",
		s03: "Potager Vireo S03",
		s03Pods: "99 paniers",
		s032: "Potager Vireo S03.2",
		s032Pods: "99 paniers",
	},
	webinars: {
		today_title: "Aujourd'hui",
		future_title: "Nos prochaines formations",
		past_title: "Rediffusions des formations",
		show_summary: "Afficher le résumé du webinaire",
		hide_summary: "Cacher le résumé",
		signup: "S'inscrire",
		viewReplay: "Visionner",
		noResults: "Aucune diffusion aujourd'hui",
		diffusion: "Diffusion",
		duration: "Durée",
		lang_fr: "Français",
		lang_en: "Anglais",
	},
	archives: {
		backToArchives: "Retour aux archives",
		dateArchive: "Entretien effectué le",
		performedBy: "Effectué par",
		ph: "PH",
		ec: "EC",
		maintenance: "Expérience",
		group: "Groupe",
		viewTheReport: "Consulter le rapport",
		loadMore: "Voir plus",
		noArchiveFound: "Aucune archive trouvée",
		noArhiveToDisplay: "Aucune archive à afficher",
	},
	home: {
		whatsNextButton: "À l'horizon",
		lockedContentTitle: "Contenu verouillé",
		lockedContentSentence: "Pour accéder à ce contenu, vous devez avoir accès à un compte et vous y connecter.",
		lockedContentButton: "Me connecter",
	},
	homeComment: {
		question1: "Que pensez-vous de la plateforme",
		question2: "?",
		description1: "L’application répond-elle à vos besoins? Êtes-vous satisfait.e de son contenu?",
		notation1: "Donnez-nous une note sur 5! Celle-ci sera recueillie automatiquement et de façon anonyme, indépendamment de vos commentaires.",
		description2: "N’hésitez pas à nous partager votre opinion et vos observations. Vos commentaires sont faits de manière anonyme et nous aident à améliorer la plateforme!",
		inputPlaceholder: "Vos commentaires",
		submitButton: "Soumettre vos commentaires",
		sendedTitle: "Commentaires envoyés!",
		sendedDescription: "Merci de votre participation.",
		characters: "Caractères",
	},
	contentTrivia: {
		title: "Test de connaissances"
	},
	formContentReview: {
		title: "Évaluation du contenu",
		question: "Qu'avez-vous pensé du contenu de cet article?",
		text: "N'hésitez pas à nous partager votre opinion et vos observations. Vos commentaires sont faits de manière anonyme et nous aident à améliorer la plateforme!",
		inputPlaceholder: "Vos commentaires",
		submitButton: "Soumettre mes commentaires",
		success1: "Merci!",
		success2: "Nous avons bien reçu vos commentaires.",
		unableToEvaluate: "Ce contenu ne peut pas être évalué.",
	},
	formMaintenance: {
		audioPlayer: {
			guidedMeditation: "Méditation guidée",
			or: "ou",
			timer: "Minuteur",
		},
		between: "Entre",
		and: "et",
		pressToContinue1: "Appuie sur",
		pressToContinue2: "pour continuer",
	},
	settings: {
		title: "Paramètres",
		lowPerformanceMode: "Mode basse performance",
		close: "Fermer",
		lang: "Langue",
		privacyPolicy: '<a href="{link}" target="blank">Politique de vie privée</a>',
		termsOfUse: '<a href="{link}" target="blank">Conditions d\'utilisation</a>',
	},
	login: {
		back: "Retour",
		loginTitle: "Me connecter",
		loginStudentCheckbox: "Je ne suis pas un.e élève",
		loginFormUsernameLabel: "Courriel",
		loginFormUsernamePlaceholder: "Votre courriel ou nom d'utilisateur",
		loginFormPasswordLabel: "Mot de passe",
		loginFormPasswordPlaceholder: "Votre mot de passe secret",
		loginFormForgotPasswordLabel: "J'ai oublié mon mot de passe",
		loginFormSubmitButton: "Se connecter",
		loginJoinPlatform: "Vous souhaitez devenir client et embarquer dans l'aventure Vireo?",
		loginContactRepresentative: "Contactez un de nos représentants.",
		loginHubspotLink: "https://meetings.hubspot.com/shana-laurencelle/consultation-vireo",
		signupTitle: "Créer un compte élève",
		signupButton: "Allons-y",

		formSignupGroupCodeLabel: "Code groupe",
		formSignupGroupCodeInfo: "La création de compte s’adresse spécifiquement aux utilisateur.trice.s appartenant à un groupe associé à un potager et non à un.e administrateur.trice scolaire.",
		formSignupAcceptPolicies: 'J’accepte la <a href="{link}" target="blank">politique de vie privée</a>.',
		formSignupAcceptTerms: 'J’accepte les <a href="{link}" target="blank">conditions d\'utilisation</a>.',

		formSignupNextButton: "Suivant",

		formSignupStep2Title: "En continuant, vous rejoindrez le groupe",
		formSignupStep2Info: "Votre identifiant peut être vu par d'autres utilisateurs de la plateforme. Ne donnez pas votre nom complet. Votre identifiant ne pourra pas être modifié par la suite.",
		formSignupAge: "Quel âge as-tu ?",
		formSignupAgePlaceholder: "Sélectionnez votre âge",
		formSignupGoals: "À quoi as-tu le plus hâte dans le projet Vireo ?",
		formSignupMood: "Comment te sens-tu par rapport au projet Vireo ?",
		formSignupInitialsLabel: "Initiales",
		formSignupFoodLabel: "Aliment",
		formSignupColorLabel: "Couleur",
		formSignupUsernameLabel: "Votre identifiant",
		formSignupPasswordLabel: "Mot de passe",
		formSignupConfirmationLabel: "Confirmer le mot de passe",

		formSignupStep1DefaultErrorTitle: "Code de groupe non valide",
		formSignupStep1DefaultErrorText: "Ce code de groupe est incorrect. Veuillez contacter votre enseignant.e.",
		formSignupStep2DefaultErrorTitle: "",
		formSignupStep2DefaultErrorText: "",

		formSignupSuccessTitle: "Inscription terminée!",
		formSignupSuccessUsernameLabel: "Votre nom d'utilisateur est :",
		formSignupSuccessInfo: "En cas d'oubli, vous devrez demander votre nom d'utilisateur à votre enseignant.e.",
		formSignupSuccessButton: "J'ai compris!",

		modalFoodTitle: "Sélectionner un aliment pour vous représenter au sein de la plateforme",
		modalFoodButton: "Sélectionner",
		modalColorTitle: "Sélectionner une couleur pour vous représenter au sein de la plateforme",
		modalColorButton: "Sélectionner",

		modalLogoutTitle: "Veuillez confirmer la déconnexion",
		modalLogoutButtonCancel: "Ne pas me déconnecter",
		modalLogoutButtonSubmit: "Me déconnecter",

		modalRequestResetPasswordTitle: "Mot de passe oublié",
		modalRequestResetPasswordDescription: "Entrez votre adresse email ci-dessous pour recevoir un courriel vous permettant de changer votre mot de passe.",
		modalRequestResetPasswordInputPlaceholder: "Votre adresse email",
		modalRequestResetPasswordSubmitButton: "Envoyer",
		modalRequestResetPasswordSuccessTitle: "Email envoyé!",
		modalRequestResetPasswordSuccessText: "L'email de changement de mot de passe a correctement été envoyé.",
		modalRequestResetPasswordErrorTitle: "Une erreur est survenue",
		modalRequestResetPasswordErrorText: "L'email de changement de mot de passe n'a pas pu être envoyé.",

		modalUserDisabledTitle: "Compte désactivé",
		modalUserDisabledText: "Votre compte est actuellement désactivé. Veuillez contacter la personne responsable dans votre établissement.",
		modalUserAccountExpiredTitle: "Votre formule est expirée",
		modalUserAccountExpiredText: "Votre formule d’accompagnement Vireo est arrivée à échéance. Pour renouveler nos services, veuillez <a href=\"mailto:info@vireoeducation.com\">nous contacter</a>.",
		
		modalUserDisabledCloseButton: "Fermer",

		modalLoginTitle: "Bienvenue sur Vireo",
		modalLoginDescription: "Vous devez être connecté.e pour accéder à ce contenu.",
	},
	modalResetTuto: {
		title: "Réessayer le tutoriel",
		description: "Vous êtes sur le point de recommencer l’entièreté du tutoriel.",
		question: "Êtes-vous sûr.e de vouloir continuer?",
		buttonCancel: "Non, je ne ne pas refaire le tutoriel",
		buttonSubmit: "Oui, je veux refaire tout le tutoriel",
	},
	notifications: {
		loginSuccessTitle: "Connexion réussie!",
		loginSuccessText: "Vous êtes maintenant connecté.e.",
		loginErrorTitle: "Connexion échouée",
		loginErrorDefaultText: "Une erreur s'est produite lors de la connexion.",
		betaVersionTitle: "Version beta",
		betaVersionText: "Ce contenu n'est pas encore disponible en version beta.",
		signupSuccessTitle: "Inscription terminée !",
		signupSuccessText: "Votre inscription a été enregistrée correctement. Vous êtes maintenant connecté.e.",
		signupErrorTitle: "Inscription échouée !",
		signupErrorText: "Une erreur est survenue lors de votre inscription. Contactez l'administrateur pour en savoir plus.",
		notificationLoginRequiredTitle: "Connexion nécessaire",
		notificationLoginRequiredText: "Vous devez être connecté.e pour accéder à cette section.",
		notificationUnauthorizedAccessTitle: "Accès non autorisé",
		notificationUnauthorizedAccessText: "Vous n'avez pas accès à cette section.",
		sendInvitationSuccessTitle: "Invitation envoyée!",
		sendInvitationSuccessText: "Le courriel d'invitation a été envoyé correctement.",
		sendInvitationErrorTitle: "Erreur d'invitation",
		sendInvitationErrorText: "Une erreur s'est produite. Le courriel d'invitation n'a pas pu être envoyé.",
		sendResetPasswordErrorTitle: "Erreur",
		sendResetPasswordErrorText: "Une erreur s'est produite. La demande de réinitialisation de mot de passe n'a pas pu être traitée.",
		submitDeleteGroupSuccessTitle: "Suppression effectuée",
		submitDeleteGroupSuccessText: "Le groupe a correctement été supprimé.",
		submitDeleteGroupErrorTitle: "Erreur de suppression",
		submitDeleteGroupErrorText: "Une erreur s'est produite. Le groupe n'a pas été supprimé.",

		submitAddGroupSuccessTitle: "Succès!",
		submitAddGroupSuccessText: "Le groupe a correctement été ajouté.",
		submitEditSuccessTitle: "Succès!",
		submitEditSuccessText: "La modification a correctement été effectuée.",
		submitEditErrorTitle: "Une erreur est survenue!",
		submitEditErrorText: "La modification n'a pas été enregistrée.",

		editEmailSuccessTitle: "Succès!",
		editEmailSuccessText: "Le courriel a correctement été modifié.",
	},
	onboarding: {
		welcome1: "Bienvenue sur la plateforme Vireo!",
		welcome2: "Veuillez créer un mot de passe qui vous permettra de vous connecter à votre compte.",
		welcome3: "L'adresse courriel à laquelle vous avez reçu l'invitation à la plateforme sera votre identifiant. Conservez ces données pour vous connecter à votre compte.",
		formFieldPasswordLabel: "Votre mot de passe",
		formFieldConfirmationLabel: "Confirmer le mot de passe",
		formNextButton: "Continuer",
		formSubmitButton: "Confirmer et se connecter",
		invalidInvitation: "Cette invitation n'est plus valide.<br>Merci de contacter votre administrateur pour en recevoir une nouvelle si besoin.",
		invalidInvitationButton: "Aller à l'accueil",
	},
	reset_password: {
		welcome1: "Vous avez utilisé un mot de passe temporaire",
		welcome2: "Choisissez un nouveau mot de passe pour vous connecter à votre compte.",
		formSubmitButton: "Confirmer et se connecter",
		invalidUserText: "Vous devez contacter votre professeur si vous souhaitez réinitialiser votre mot de passe.",
		invalidUserButton: "Aller à l'accueil",
		submitSuccessTitle: "Succès",
		submitSuccessText: "Votre mot de passe a correctement été modifié.",
		submitErrorTitle: "Erreur",
		submitErrorText: "Une erreur s'est produite. Impossible de modifier votre mot de passe.",
	},
	dashboard: {
		rdvSentence: "Besoin de soutien?",
		rdvLink: "Prenez rendez-vous avec nous en cliquant ici!",
		enabled: "Actif",
		disabled: "Inactif",
		models: "Modèles",
		noModels: "Aucun",

		registeredTeachers: "Enseignant.e.s enregistré.e.s",
		registeredTeachersDescription: "Invitez des enseignant.e.s à rejoindre la plateforme et consultez la liste des enseignant.e.s déjà inscrit.e.s de votre établissement!",
		groupOverview: "Aperçu des groupes",
		groupOverviewDescription: "Consultez la liste des groupes de votre établissement et les élèves associé.e.s à chaque groupe!",
		schoolOverview: "Aperçu des écoles",
		schoolOverviewDescription: "Ajoutez des accès pour écoles à la plateforme et consultez les écoles déjà enregistrées!",
		view: "Consulter",
		back: "Retour",
		search: "Rechercher...",
		noTeachers: "Aucun.e enseignant.e enregistré.e.",
		noGroups: "Aucun groupe trouvé.",
		noSchools: "La liste des écoles arrive prochainement.",
		notFound: "Recherche infructueuse.",

		profile: "Profil",
		editProfile: "Editer votre profil",
		profileFirstName: "Prénom",
		profileCurrentFirstName: "Prénom actuel",
		profileFirstNamePlaceholder: "Entrer le nouveau prénom",
		profileLastName: "Nom",
		profileCurrentLastName: "Nom actuel",
		profileLastNamePlaceholder: "Entrer le nouveau nom",
		profileCancelButton: "Annuler",
		profileSubmitButton: "Actualiser",

		addTeacher: "Ajouter un.e enseignant.e",
		addSchool: "Ajouter une école",
		addAdmin: "Ajouter un.e enseignant.e",
		formAddTeacherUsername: "Identifiant",
		formAddTeacherUsernameInfo: "Cette information vous servira non seulement de référence, mais aussi d'identifiant/information de connexion pour l'enseignant.e en question. Il peut s'agir simplement du nom de l'enseignant.e ou d'un identifiant clair.",
		formAddTeacherEmail: "Adresse courriel",
		formAddTeacherEmailPlaceholder: "Exemple: john.doe{'@'}domaine.com",
		formAddTeacherEmailInfo: "Assurez-vous d’inscrire la bonne adresse courriel, car elle servira notamment de moyen de communication avec l'enseignant.e, mais aussi d'information de connexion. Le premier lien d'accès au compte enseignant sera également envoyé à cette adresse.",
		formAddTeacherCancelButton: "Annuler",
		formAddTeacherSubmitButton: "Ajouter l'enseignant.e",

		formAddSchoolEmail: "Adresse courriel",
		formAddSchoolEmailPlaceholder: "Exemple: john.doe{'@'}domaine.com",
		formAddSchoolEmailInfo: "Il est important que l'adresse courriel soit à jour et correcte; elle servira notamment de moyen de communication avec l'enseignant.e, mais aussi d'information de connexion. Le premier lien d'accès au compte enseignant sera également envoyé en utilisant cette adresse.",
		formAddSchoolCancelButton: "Annuler",
		formAddSchoolSubmitButton: "Ajouter l'école",

		addGroup: "Créer un groupe",
		formAddGroupName: "Nom du groupe",
		formAddGroupNameInfo: "Le nom du groupe agira principalement à titre de référence pour les administrateur.trice.s scolaires, les enseignant.e.s responsables du groupe et les élèves; tous.toutes pourront voir le nom assigné au groupe.",
		formAddGroupNamePlaceholder: "Entrer un nom de groupe",
		formAddGroupCode: "Code de groupe",
		formAddGroupCodeInfo: "Le code de groupe permettra aux élèves de joindre le groupe. Il est important de partager cette information aux élèves au moment de la première connexion. Le code de groupe sera également visible depuis la page du groupe.",
		formAddGroupCancelButton: "Annuler",
		formAddGroupSubmitButton: "Créer le groupe",

		groupNbStudents: "Nbre élèves",
		groupNbAdmins: "Nbre enseignant.e.s",
		schoolNbTeachers: "Nbre enseignant.e.s",
		groupCode: "Code de groupe",
		schoolEmail: "Email de l'école",
		studentsList: "Liste des élèves",
		adminsList: "Liste des enseignant.e.s",
		teachersList: "Liste des enseignant.e.s",
		firstname: "Prénom",
		lastname: "Nom",
		username: "Identifiant",
		gardener: "Jardinier",
		administration: "Administration",
		resetPassword: "Réinitialiser le mot de passe",

		modalSendInvitationTitle: "Souhaitez-vous renvoyer un lien de connexion à l'usager suivant?",
		modalSendInvitationInfo: "Dans le cas où un usager n'a pas bien reçu ou perdu son lien de connexion, il est possible d'en réacheminer un à son adresse courriel enregistré. L'usager devra réenregistrer un nouveau mot de passe si cela avait déjà été fait.",
		modalSendInvitationCancelButton: "Non, ne pas renvoyer le lien de connexion",
		modalSendInvitationSubmitButton: "Oui, envoyer un nouveau lien de connexion",

		modalResetPasswordTitle: "Envoyer une demande de réinitialisation de mot de passe à l'élève suivant.e?",
		modalResetPasswordInfo: "Un nouveau mot de passe sera automatiquement généré pour l'usager ci-haut. L'usager aura besoin de ce mot de passe temporaire lors de sa prochaine connexion.",
		modalResetPasswordCancelButton: "Non, annuler la demande de réinitialisation",
		modalResetPasswordSubmitButton: "Oui, envoyer une demande de réinitialisation",

		modalDeleteGroupTitle: "Souhaitez-vous vraiment supprimer le groupe suivant?",
		modalDeleteGroupInfo: "Cette action n'est pas réversible. Les usagers avec accès élève associés à ce groupe seront également supprimé, en plus de leur données.",
		modalDeleteGroupCancelButton: "Non, je veux conserver ce groupe",
		modalDeleteGroupSubmitButton: "Oui, je souhaite supprimer ce groupe",

		modalAddAdminTitle: "Ajouter des enseignant.e.s",
		modalAddAdminIntro: "Sélectionner les utilisateur.trice.s à ajouter comme enseignant.e responsable de ce groupe.",
		modalAddAdminTitleCancelButton: "Annuler",
		modalAddAdminTitleSubmitButton: "Ajouter",

		modalEditAlternativesTitle: "Modifier les modèles accessibles à cette école",
		modalEditAlternativesInfo: "Seuls les modèles sélectionnés ci-dessous seront accessibles à l'école suivante:",
		modalEditAlternativesCloseButton: "Fermer",

		modalDeleteUser: {
			titleEnabled: "Activer ou supprimer un compte utilisateur",
			titleDisabled: "Désactiver ou supprimer un compte utilisateur",
			questionEnabled: "Souhaitez-vous activer ou supprimer le compte de l'usager ?",
			questionDisabled: "Souhaitez-vous désactiver ou supprimer le compte de l'usager ?",
			disableTitle: "Désactiver le compte",
			disableDescription1: "En désactivant ce compte, ce dernier ne pourra plus se connecter.",
			disableDescription2: "Toutes les informations du compte seront conservées et vous pourrez le réactiver à tous moments.",
			enableTitle: "Activer le compte",
			enableDescription1: "En activant ce compte, son utilisateur pourra à nouveau se connecter à l'aide de ses identifiants.",
			deleteTitle: "Supprimer le compte",
			deleteDescription1: "En supprimant le compte, toutes ses données, ainsi que celles des étudiants associés, seront définitivement effacées.",
			deleteDescription2: "Il sera impossible de récupérer les données de ces comptes.",
			warning1: "Attention ! Vous êtes sur le point de supprimer un compte école.",
			warning2Singular: "Le compte étudiant associé sera également définitivement supprimé!",
			warning2Plural: "Les {n} comptes étudiants associés seront également définitivement supprimés!",
			warningNoConnectedUsers: "Ce compte n'a aucun compte étudiant associé.",
			close: "Fermer",
			cancelButton: "Annuler, je ne souhaite pas modifier ce compte utilisateur",
			submitButtonDelete: "Confirmer, je souhaite supprimer définitivement ce compte et ses étudiants",
			submitButtonDisable: "Confirmer, je souhaite désactiver ce compte",
			submitButtonEnable: "Confirmer, je souhaite activer ce compte",
			studentsToDeleteTitle: "{n} compte{s} {sera} définitivement supprimé{s}:",
			studentsToDeleteAnonyme1: "Ainsi que {n} compte{s} anonyme{s}",
			noUserData: "Informations non renseignées",
			search: "Rechercher...",
		}
	},
	errors: {
		errorDefaultText: "Une erreur s'est produite.",
		errorRequestTitle: "Erreur",
		errorRequestText: "Impossible de charger la requête '<strong>{queryName}</strong>'.",
		errorUserDisabled: "Impossible de se connecter car l'utilisateur est désactivé.",
		errorInvalidCredentials: "Vos identifiants de connexion sont incorrects.",
	},
	tooltips: {
		login: "Connexion / Inscription",
		logout: "Déconnexion",
		dashboard: "Tableau de bord",
		home: "Retour à l'accueil",
		stats: "Statistiques",
		chatbot: "Assistant interactif",
		faq: "FAQ",
		settings: "Paramètres",
		groupSelection: "Sélection du groupe",
		calendar: "Calendrier",
		addToCalendar: "Ajouter au calendrier",
	},
	tuto: {
		nextButton: "Suivant",
		endButton: "Terminer",
		skipButton: "Passer le tutoriel"
	},
	languageScreen: {
		description1: "Veuillez sélectionner votre langue de préférence.",
		description2: "Il vous sera possible de changer la langue en tout temps.",
		button: "Confirmer",
		cookieSentence: 'En utilisant cette plateforme web, vous acceptez les modalités de notre <a href="{link1}" target="blank">politique de vie privée</a> ainsi que nos <a href="{link2}" target="blank">conditions d\'utilisation</a>.'
	},
	stats: {
		tabUsers: "Utilisateurs",
		titleUsers: "Utilisateurs inscrits",
		schools: "Écoles",
		teachers: "Enseignants.es",
		students: "Élèves",
		nbSchools: "Nombre d'écoles",
		nbSchoolsWithAtLeastOneTeacher: "Nombre d'écoles avec au moins 1 enseignant.e",
		nbSchoolsWithAtLeastOneStudent: "Nombre d'écoles avec au moins 1 élève",

		tabGames: "Formulaires",
		titleGames: "Formulaires complétés",
		forms: "Formulaires",
		nbCompletions: "Nombre de complétions",

		tabFiles: "Fichiers",
		titleFiles: "Fichiers téléchargés",
		nbFiles: "Nombre de fichiers",
		totalDownloads: "Téléchargements totaux",
		files: "Fichiers",
		nbDownloads: "Nombre de téléchargements",

		total: "Total",
	},
	modalNews: {
		title: "Vireo est au colloque de l’AQUOPS!",
		description1: "Venez-nous voir du 4 au 6 avril aux kiosques 39/40 au Hilton de Québec.",
		description2: "Au menu :",
		li1: "Venez rencontrer une enseignante et ses élèves qui ont fait le projet Vireo!",
		li2: "Apprenez ce qu’est l’hydroponie et ses avantages.",
		li3: "Inscrivez-vous à un essai gratuit de notre plateforme web.",
		description3: "Au plaisir de vous y voir!",
		close: "Fermer",
	},
	chatbot: {
		chooseOptions: "Choisis l'une des options suivantes",
		infos: {
			title: "Avec un compte vous pourrez accéder à l'assistance interactive",
			slide1: "L’assistant interactif est disponible en tout temps et répond à vos questions sur-le-champ.",
			slide2: "Il s’agit d’un outil indispensable pour développer l’autonomie et les habiletés de résolution de problèmes chez les élèves!",
			slide3: "Vous avez une question? Obtenez une réponse en quelques clics!",
		}
	},
	calendar: {
		removeFromCalendar: "Retirer du calendrier",
		month1: "Janvier",
		month2: "Février",
		month3: "Mars",
		month4: "Avril",
		month5: "Mai",
		month6: "Juin",
		month7: "Juillet",
		month8: "Août",
		month9: "Septembre",
		month10: "Octobre",
		month11: "Novembre",
		month12: "Décembre",
		day0: "Dimanche",
		day1: "Lundi",
		day2: "Mardi",
		day3: "Mercredi",
		day4: "Jeudi",
		day5: "Vendredi",
		day6: "Samedi",
		between: "Entre le",
		and: "et",
		more: "de plus",
		today: "Aujourd'hui",
		letterDurations: {
			y: "année",
			w: "semaine",
			d: "jour",
			h: "heure",
			m: "minute",
			s: "seconde",
		},
		addSeeds: {
			title: "Ajouter un projet",
			description: "Selon la sélection annuelle, Vireo fournira différentes semences pour l'année en cours. La sélection peut varier d'une année à l'autre.",
			noSeeds: "Aucune semence",
		},
		seedDetails: {
			difficulty: "Difficulté",
			growthTime: "Temps de croissance une fois transféré dans le potager",
			height: "Hauteur de la plante",
			location: "Emplacement dans le potager",
		},
		singleSeed: {
			difficulty: "Difficulté",
			growthTime: "Temps de croissance",
		},
		addEvent: {
			addToCalendar: "Ajouter au calendrier",
			mayaSentence1: "Souviens toi que cette semence à une croissance de",
			mayaSentence2: "Tu peux toujours modifier la date dans le calendrier, mais à quelle date aimerais tu commencer tes semis ?",
			harvestDate: "Date de récolte prévue",
			successTitle: "Projet ajouté",
			successText: "Le projet a correctement été ajoutée à votre calendrier.",
			noCalendar: "Vous devez être connecté.e et être associé.e à un groupe pour ajouter un projet au calendrier.",
			close: "Fermer",
		},
		editEvent: {
			title: "Souhaites-tu déplacer la tâche suivante à une nouvelle date?",
			sentence: "Il te sera toujours possible de redéplacer cette tâche par la suite. Déplacer cette tâche n'affectera pas les autres tâches associé à ce projet.",
			cancelButton: "Non, ne pas déplacer cette tâche",
			submitButton: "Oui, je souhaite déplacer cette tâche",
			successTitle: "Succès!",
			successText: "La tâche a correctement été déplacée.",
			errorTitle: "Erreur",
			errorText: "Une erreur est survenue, la tâche n'a pas été déplacée.",
			warning: {
				warningTitle: "Avertissement",
				warningSentence: "Vous vous apprêtez à déplacer une tâche dans une plage non-recommandée pour la bonne croissance de vos plantes.",
				warningQuestion: "Voulez-vous continuer?",
				warningCancel: "Annuler",
				warningSubmit: "Oui, je veux continuer",
			}
		},
		removeEvent: {
			title: "Souhaites-tu supprimer la tâche suivante et ses tâches associées?",
			sentence: "Cette tâche, ainsi que toutes ses tâches associées, seront supprimées définitivement.",
			cancelButton: "Non, ne pas supprimer les tâches",
			submitButton: "Oui, je souhaite supprimer les tâches",
			successTitle: "Succès!",
			successText: "La tâche a correctement été supprimée.",
			errorTitle: "Erreur",
			errorText: "Une erreur est survenue, la tâche n'a pas été supprimée.",
		}
	},
	adminDashboard: {
		actions: "Actions possibles",
		none: "Aucun",
		close: "Fermer",
		listItems: {
			searchPlaceholder: "Rechercher...",
			seeMore: "Voir plus",
			noResults: "Aucun résultat",
			item: {
				nbStudents: "Nbre élèves",
				nbTeachers: "Nbre enseignant.e.s",
				accessCode: "Code de groupe",
				showDetail: "Afficher le détail",
				edit: "Éditer",
				resetPassword: "Réinitialiser le mot de passe",
				disable: "Activer/Désactiver",
				remove: "Supprimer",
				removeFromGroup: "Retirer du groupe",
				lastActivity: "Dernière activité",
			}
		},

		panels: {
			home: {
				title: "Liste des districts",
				invitationsButton: "Vos invitations",
			},
			district: {
				defaultTitle: "District sans nom",
				schoolsList: "Liste des écoles enregistrées",
				yourSchools: "Vos écoles",
				invitedSchoolsList: "Liste des écoles invitées",
				stats: "Statistiques",
				nbSchools: "Nombre d'écoles",
				districtManager: "Responsable du district",
				email: "Courriel",
				accountState: "État du compte",
				subscribeState: "État de l'inscription",
			},
			school: {
				defaultTitle: "École sans nom",
				yourInvitations: "Vos invitations",
				tabTeachers: "Enseignant.e.s",
				tabTeachersSaved: "enregistré.e.s",
				tabGroups: "Groupes",
				tabSchools: "Écoles",
				stats: "Statistiques",
				nbTeachers: "Nombre d'enseignant.e.s",
				schoolManager: "Responsable de l'école",
				email: "Courriel",
				accountState: "État du compte",
				subscribeState: "État de l'inscription",
				supporterModels: "Potager.s supporté.s",
				seeCalendar: "Voir le calendrier",
			},
			group: {
				nbTeachers: "Nbre enseignant.e | Nbre enseignant.e | Nbre enseignant.e.s",
				nbStudents: "Nbre élève | Nbre élève | Nbre élève.s",
				accessCode: "Code de groupe",
				teachersList: "Liste des enseignant.e.s",
				studentsList: "Liste des élève.e.s",
			},
			teacher: {
				userAccount: "Compte utilisateur",
				anonymous: "Anonyme",
				firstname: "Prénom",
				lastname: "Nom de famille",
				username: "Nom d'utilisateur",
				email: "Courriel",
				lastActivity: "Dernière activité",
				idUser: "ID du compte utilisateur",
				accountState: "État du compte",
				subscribeState: "État de l'inscription",
				editUser: "Éditer le compte utilisateur",
				editEmail: "Éditer le courriel",
				resetUserPassword: "Réinitialiser le mot de passe",
				disableUser: "Activer/Désactiver le compte utilisateur",
				deleteUser: "Supprimer le compte utilisateur",
			}
		},
		tooltips: {
			addDistrict: "Ajouter un district",
			disableSelectedDistrict: "Désactiver les districts sélectionnés",
			deleteSelectedDistrict: "Supprimer les districts sélectionnés",
			addSchool: "Ajouter une école",
			linkSchoolToDistrict: "Associer des écoles à ce district",
			resetSelectedSchoolsPassword: "Réinitialiser les mots de passes des écoles sélectionnées",
			disableSelectedSchools: "Désactiver les écoles sélectionnées",
			unlinkSelectedSchoolsFromDistrict: "Retirer les écoles sélectionnées du district",
			deleteSelectedSchools: "Supprimer les écoles sélectionnées",
			editDistrictAccount: "Éditer le compte du district",
			resetDistrictPassword: "Réinitialiser le mot de passe du compte du district",
			disableDistrict: "Activer/Désactiver le compte du district",
			deleteDistrict: "Supprimer le district",
			add: "Ajouter",
			resetPasswordSelectedElements: "Réinitialiser les mots de passe des comptes sélectionnés",
			disabledSelectedElements: "Désactiver les éléments sélectionnés",
			deleteSelectedElements: "Supprimer les éléments sélectionnés",
			editSchool: "Éditer le compte de l'école",
			resetSchoolPassword: "Réinitialiser le mot de passe du compte de l'école",
			disableSchool: "Activer/Désactiver le compte de l'école",
			deleteSchool: "Supprimer l'école",
			editSchoolModels: "Modifier les modèles de potager supportés par cette école",
			linkTeachersToGroup: "Associer des enseignant.e.s au groupe",
			resetSelectedTeachersPassword: "Réinitialiser les mots de passes des comptes enseignants sélectionnés",
			resetSelectedStudentsPassword: "Réinitialiser les mots de passes des comptes étudiants sélectionnés",
			disableSelectedTeachers: "Désactiver les comptes enseignants sélectionnés",
			disableSelectedStudents: "Désactiver les comptes étudiants sélectionnés",
			unlinkSelectedTeachersFromGroup: "Retirer les enseignants sélectionnés du groupe",
			resetSelectedUsersPassword: "Réinitialiser les mots de passes des comptes utilisateurs sélectionnés",
			disableSelectedUsersPassword: "Désactiver les comptes utilisateurs sélectionnés",
			edit: "Éditer",
			resetPassword: "Réinitialiser mot de passe",
			disable: "Activer/Désactiver",
			delete: "Supprimer",
			editEmail: "Modifier le courriel",
		},
		states: {
			enabled: "Activé",
			disabled: "Désactivé",
		},
		onboarding: {
			is_anonymous: "Courriel non confirmé",
			is_validated: "Courriel confirmé",
		},
		modals: {
			global: {
				errorDifferentItems1: "Vous avez sélectionné plusieurs éléments de nature différente.",
				errorDifferentItems2: "Les opérations de groupe ne peuvent être réalisées que sur des éléments de même type.",
				resultSuccessTitle: "Modification enregistrée avec succès",
				resultErrorTitle: "Une erreur est survenue",
				resultTotal: "Total",
				resultSuccess: "Succès",
				resultErrors: "Erreurs",
				close: "Fermer",
				submit: "Confirmer",
				send: "Envoyer",
				cancel: "Annuler",
			},
			add: {
				emailImportant: "Il est important que l'adresse courriel soit à jour et correcte; elle servira notamment de moyen de communication, mais aussi d'information de connexion. Le premier lien d'accès au compte enseignant sera également envoyé en utilisant cette adresse.",
			},
			disable: {
				questionValidation: "Voulez-vous changer l'état d'activation de",
			},
			remove: {
				question1: "Êtes-vous sûr.e de vouloir supprimer le.s élément.s suivant.e.s?",
				question2: "Ces éléments seront <strong>définitivement supprimés</strong> et ne pouront plus être récupérés.",
				resultSuccessTitle: "L'élément a été supprimé",
				resultErrorTitle: "La suppression a échoué",
				submit: "Supprimer définitivement",
			},
			resetPassword: {
				text1: "Êtes-vous sûr.e de vouloir réinitialiser les mots de passe des comptes suivants&nbsp;?",
				text2: "<strong>Un courriel de réinitialisation de mot de passe leur sera envoyé.</strong>",
				text3: "Pour les comptes avec des courriels non confirmés, le courriel d'invitation à l'inscription sera envoyé à la place.",
				textStudent1: "Êtes-vous sûr.e de vouloir réinitialiser les mots de passe des comptes étudiants suivants&nbsp;?",
				textStudent2: "<strong>Un nouveau mot de passe leur sera automatiquement généré.</strong>",
				textStudent3: "Les étudiant.e.s auront <strong>obligatoirement</strong> besoin de ce mot de passe temporaire lors de leur prochaine connexion.",
				textStudent4: "Le nouveau mot de passe sera affiché en face du pseudonyme de l'étudiant.e.",
			},
			removeTeachersFromGroup: {
				question: "Êtes-vous sûr.e de vouloir retirer les enseignant.e.s suivant.e.s du groupe",
				submit: "Retirer du groupe",
			},
			removeSchoolsFromDistrict: {
				question: "Êtes-vous sûr.e de vouloir retirer les écoles suivantes du district",
				submit: "Retirer du district",
			},
			addTeachersToGroup: {
				intro: "Sélectionnez les enseignant.e.s que vous souhaitez ajouter au groupe",
				submit: "Associer 0 enseignant.e | Associer 1 enseignant.e | Associer {n} enseignant.e.s",
			},
			addSchoolsToDistrict: {
				intro: "Sélectionnez les écoles que vous souhaitez ajouter au district",
				submit: "Associer 0 école | Associer 1 école | Associer {n} écoles",
			},
			editEmail: {
				sentence1: "Entrez le nouveau courriel.",
				sentence2: "En validant, vous accéderez à une étape de confirmation.",
				sentenceConfirm1: "Veuillez vérifier que vous avez correctement tapé le courriel.",
				sentenceConfirm2: "En confirmant, le changement suivant sera définitif et ne pourra plus être modifié.",
				old: "Actuel:",
				new: "Nouveau:",
				warning1: "Attention! Un autre utilisateur utilise déjà ce courriel!",
				warning2: "Il sera définitivement archivé:",
				firstname: "Prénom",
				lastname: "Nom",
				email: "Courriel",
				accountType: "Type de compte",
				lastActivity: "Dernière activité",
				type_vireo: "Administrateur",
				type_district: "Gestionnaire de district",
				type_school: "École",
				type_teacher: "Enseignant",
				type_student: "Étudiant",
				question: "Êtes-vous sûr.e de vouloir effectuer ce changement?",
			}
		},
		modalTitle: {
			'add-schools-to-district': "Associer des écoles au district",
			'remove-schools-from-district': "Retirer les écoles du district",
			'add-teachers-to-group': "Associer des comptes enseignants au groupe",
			'remove-teachers-from-group': "Retirer les enseignant.e.s du groupe",
			'edit-alternatives': "Modifier les modèles de potager",
			
			add: "Ajouter",
			remove: "Supprimer",
			edit: "Éditer",
			disable: "Changer l'état de",
			'reset-password': "Réinitialiser le mot de passe de",
			'resend-invitation': "Ré-envoyer les courriels d'invitation de",
			'edit-email': "Modifier le courriel de",

			district: "aucun district | {n} district | {n} districts",
			user: "aucun compte utilisateur | {n} compte utilisateur | {n} comptes utilisateurs",
			teacher: "aucun compte enseignant | {n} compte enseignant | {n} comptes enseignants",
			student: "aucun compte étudiant | {n} compte étudiant | {n} comptes étudiants",
			school: "aucune école | {n} école | {n} écoles",
			group: "aucun groupe | {n} groupe | {n} groupes",
			subscription: "aucune souscription | {n} souscription | {n} souscriptions",
			potager: "aucun potager | {n} potager | {n} potagers",
		},
		form: {
			labels: {
				email: "Courriel",
				name: "Nom",
				firstName: "Prénom",
				lastName: "Nom de famille",
				district: "District",
				school: "École",
				group: "Groupe",
				teacher: "Enseignant.e",
			},
			placeholders: {
				email: "Exemple: john{'@'}doe.com",
				name: "Entrez le nom ici",
				firstName: "Exemple: John",
				lastName: "Exemple: Doe",
			},
			buttons: {
				close: "Fermer",
				submit: "Valider",
			}
		}
	}
}