<template>
	<div class="modal-potager-selection">
		<div class="bg" v-tap="close"></div>
		<div class="content">
			
			<div class="header">
				<div class="title">{{ $t('potagerSelection.title') }}</div>
			</div>
			<div class="body">
				<div v-if="potagers.length" class="potagers" :class="{ 'has-selection': selectedPotager }">
					<div v-for="potager in potagers" :key="potager.id" class="potager" v-tap="() => togglePotager(potager)" :class="{ selected: selectedPotager?.id === potager.id }">
						<div class="image"><img :src="potager.img"></div>
						<div class="texts">
							<p>{{ $t('potagerSelection.' + potager.id.replace('.', '')) }}</p>
							<p>{{ $t('potagerSelection.' + potager.id.replace('.', '') + 'Pods') }}</p>
						</div>
					</div>
				</div>
				<div v-else class="no-elements">
					<p><strong>{{ $t('potagerSelection.noGardenTitle') }}</strong></p>
					<p>{{ $t('potagerSelection.noGardenSentence1') }}</p>
					<p>{{ $t('potagerSelection.noGardenSentence2') }}</p>
				</div>
			</div>
			<div class="footer">
				<ui-button v-if="potagers.length" class="button" :loading="loading" :disabled="!selectedPotager" size="big" color="orange" v-tap="select">{{ $t('potagerSelection.button') }}</ui-button>
				<ui-button v-else class="button" size="big" color="orange" v-tap="close">{{ $t('potagerSelection.close') }}</ui-button>
			</div>
			
		</div>
	</div>
</template>

<script>
import { useAppStore } from '@/store/app'
import { mapState, mapActions } from 'pinia'
import { switchAlternative } from '@/requests/app'

export default {
	name: 'modal-potager-selection',
	data() {
		return {
			selectedPotager: null,
			loading: false,
		}
	},
	computed: {
		...mapState(useAppStore, [ 'user', 'currentPotager' ]),
		potagers() {
			return this.user?.alternatives
				.map(id => ({ id, img: require('@/assets/img/potagers/' + id.replace('.', '') + '.jpg') }))
				.sort((a, b) => a > b ? 1 : -1)
		}
	},
	methods: {
		...mapActions(useAppStore, [ 'setModalPotagerSelectionVisible' ]),
		togglePotager(potager) {
			this.selectedPotager = (potager && potager.id !== this.selectedPotager?.id) ? potager : null
		},
		async select() {
			if (this.selectedPotager?.id && !this.loading) {
				this.loading = true
				if (this.selectedPotager?.id !== this.currentPotager) {
					const response = await switchAlternative({ userId: this.user.id, alternative: this.selectedPotager.id })
					if (response) {
						this.user.alternativeActive = this.selectedPotager.id
						this.close()
					} else
						this.loading = false
				} else
					this.close()
			}
		},
		close() {
			this.setModalPotagerSelectionVisible(false)
		}
	},
	created() {
		if (this.currentPotager) this.selectedPotager = this.potagers.find(p => p.id === this.currentPotager)
		if (!this.selectedPotager) this.selectedPotager = this.potagers[0]
	}
}
</script>

<style lang="stylus" scoped>

#app > .low-perf
	.modal-potager-selection
		transition none
		.bg, .content
			transition none
			backdrop-filter none

#mobile
	.modal-potager-selection
		.header
			height 48px
		.footer
			height 64px
		.body
			height calc(100% - 112px)
			.potagers .potager
				height 140px
				padding 8px
				&.selected
					background-position top 8px right 8px
					background-size 32px 32px
				.image
					height @height - 16px
					img
						height @height
				.texts
					p:first-child
						font-size 1.8rem

.modal-potager-selection
	fixed 0
	flex center center
	z-index 100000
	&.modal-potager-selection-enter-active
		transition 0.2s easeOutQuart
		.bg
			transition 0.2s easeOutQuart
		.content
			transition 0.2s easeOutBack
	&.modal-potager-selection-enter-from
		.bg
			opacity 0
		.content
			opacity 0
			transform scale(0.95)

	&.modal-potager-selection-leave-active
		transition 0.25s easeOutQuart
	&.modal-potager-selection-leave-to
		opacity 0

	.bg
		background-color alpha(#446364, 70%)
		absolute 0
		backdrop-filter blur(8px)
		transition 0.2s easeOutQuart
	.content
		width calc(100% - 32px)
		max-width 480px
		height calc(100% - 64px)
		max-height 700px
		background-color #fff
		padding 16px
		text-align center
		border-radius 6px
		.header
			height 80px
			flex center center column
			gap 8px
			border-bottom 2px solid #4C6D6D
			text-align center
			.title
				font-size 2rem
				font-weight 500
				text-transform uppercase
				color #4C6D6D
			.title, .description
				width 100%
		.body
			height calc(100% - 160px)
			overflow auto
			.potagers
				padding 16px 0
				&.has-selection
					.potager:not(.selected)
						opacity 0.5
				.potager
					height 216px
					padding 16px
					margin 0 0 8px 0
					flex center flex-start
					gap 16px
					background-color #B8D1D2
					border-radius 8px
					cursor pointer
					color #fff
					text-transform uppercase
					text-align left
					&:last-child
						margin 0
					&:hover
						background-color darken(@background-color, 10%)
					&.selected
						background darken(@background-color, 10%) url('../assets/img/check-dark-green.svg') right 8px center no-repeat
						background-size 40px 40px
					.image
						height (@height - 32px)
						img
							height @height
							width auto
							border-radius 8px
					.texts
						color #0D4444
						p
							&:first-child
								font-weight 500
								font-size 2rem
							&:last-child
								font-size 1.8rem
								opacity 0.5
			.no-elements
				padding 24px
				margin 32px 0
				background-color #eee
				border-radius 8px
				p
					margin 0 0 8px 0
					&:last-child
						margin-bottom 0
					&.buttons
						flex center center
					strong
						text-transform uppercase
						font-weight 600
		.footer
			height 80px
			flex center center column
			gap 8px
			border-top 2px solid #4C6D6D
			text-align center
			.description
				width 100%
				color #4C6D6D
			
		
</style>
