export default {
	mainPageTitle: "Vireo education",

	notepad: {
		backToHome: "Back to home",
		closePage: "Close page",
		hide: "Hide",
		copy: "Copy link",
		copied: "Copied!",
		search: "Search by title...",
		archived: "Experiment<br>archived!",
		backToGardenExperiments: "Back to the<br>experiments menu",
		tableOfContent: "Table of content",
	},
	demo: {
		pageTitle: "Garden Experiment completed",
		sentence1: "You have reached the end of a Garden Experiment. In order to have access to your results in the Archives section as well as to obtain other experiences, please login or create an account.",
		close: "Close",
	},
	noAccess: {
		pageTitle: "Page not available",
		sentence1: "You are trying to access a page that does not exist or is not accessible to you.",
		close: "Close",
	},
	userMenu: {
		dashboard: "Dashboard",
		stats: "Statistics",
		lowPerformanceMode: "Low performance mode",
		retryTuto: "Retry the tutorial",
		groupSelection: "Group selection",
		switchLang: "Switch lang:",
		french: "French",
		english: "English",
		logout: "Logout",
		privacyPolicy: "Privacy Policy",
		termsOfUse: "Terms of Use",
	},
	groupSelection: {
		edit: "Edit",
		title: "Select a group",
		description: "By selecting a group, you will be able to complete “My Garden Experiments” linked to this group’s archive system.",
		footer: "You can always switch groups from the main menu or through “My Garden Experiments”.",
		button: "Select",
		noGroupTitle: "You don't have a group.",
		noGroupSentence1: "You must be associated with a group to participate in the garden experiments.",
		noGroupSentence2: "Contact your administrator for more information.",
	},
	potagerSelection: {
		edit: "Edit",
		title: "Select a model",
		footer: "You can always change your model to consult his Guide to gardening.",
		close: "Close",
		button: "Select",
		noGardenTitle: "You don't have a model.",
		noGardenSentence1: "You must be associated with a model to access the Guide to gardening.",
		noGardenSentence2: "Contact your administrator for more information.",

		s02: "Vireo garden S02",
		s02Pods: "102 pods",
		s03: "Vireo garden S03",
		s03Pods: "99 pods",
		s032: "Vireo garden S03.2",
		s032Pods: "99 pods",
	},
	webinars: {
		today_title: "Today",
		future_title: "Coming soon",
		past_title: "Watch on demand",
		show_summary: "View the webinar summary",
		hide_summary: "Hide summary",
		signup: "Register",
		viewReplay: "Play",
		noResults: "No broadcast today",
		diffusion: "Broadcast",
		duration: "Duration",
		lang_fr: "French",
		lang_en: "English",
	},
	archives: {
		backToArchives: "Back to archives",
		dateArchive: "Maintenance performed on",
		performedBy: "Performed by",
		ph: "PH",
		ec: "EC",
		maintenance: "Experiment",
		group: "Group",
		viewTheReport: "View the report",
		loadMore: "Load more",
		noArchiveFound: "No archive found",
		noArhiveToDisplay: "No archive to display",
	},
	home: {
		whatsNextButton: "What's next",
		lockedContentTitle: "Locked content",
		lockedContentSentence: "To gain access to this content, you will need to log in to your account.",
		lockedContentButton: "Log in",
	},
	homeComment: {
		question1: "What do you think about",
		question2: "?",
		description1: "Does the application meet your needs? Are you satisfied with its content? Are you comfortable navigating the platform?",
		notation1: "Give us a rating out of 5! This will be collected automatically and anonymously, regardless of the comments.",
		description2: "Please feel free to share your opinion and observations with us. Your feedback is anonymous and helps us build a better platform!",
		inputPlaceholder: "Your comments",
		submitButton: "Submit your comments",
		sendedTitle: "Comments sent!",
		sendedDescription: "Thank you for your participation.",
		characters: "Characters",
	},
	contentTrivia: {
		title: "Knowledge test"
	},
	formContentReview: {
		title: "Content evaluation",
		question: "What did you think of the content of this article?",
		text: "Please feel free to share your opinion and observations with us. Your feedback is anonymous and helps us build a better platform!",
		inputPlaceholder: "Your comments",
		submitButton: "Submit my comments",
		success1: "Thank you!",
		success2: "We received your comments.",
		unableToEvaluate: "This content cannot be evaluated.",
	},
	formMaintenance: {
		audioPlayer: {
			guidedMeditation: "Guided meditation",
			or: "or",
			timer: "Timer",
		},
		between: "Between",
		and: "and",
		pressToContinue1: "Press",
		pressToContinue2: "to continue",
	},
	settings: {
		title: "Settings",
		lowPerformanceMode: "Low performance mode",
		close: "Close",
		lang: "Lang",
		privacyPolicy: '<a href="{link}" target="blank">Privacy Policy</a>',
		termsOfUse: '<a href="{link}" target="blank">Terms of Use</a>',
	},
	login: {
		back: "Back",
		loginTitle: "Log in",
		loginStudentCheckbox: "I am not a student",
		loginFormUsernameLabel: "Email",
		loginFormUsernamePlaceholder: "Your email or username",
		loginFormPasswordLabel: "Your password",
		loginFormPasswordPlaceholder: "Your secret password",
		loginFormForgotPasswordLabel: "I forgot my password",
		loginFormSubmitButton: "Log in",
		loginJoinPlatform: "You want to become a customer and join the Vireo adventure?",
		loginContactRepresentative: "Contact one of our representatives.",
		loginHubspotLink: "https://meetings.hubspot.com/shana-laurencelle/meeting-with-vireo",
		signupTitle: "Create a student account",
		signupButton: "Let's go",

		formSignupGroupCodeLabel: "Group code",
		formSignupGroupCodeInfo: "Creating an account is reserved for users belonging to a group associated with a garden, and not for a school administrator.",
		formSignupAcceptPolicies: 'I accept the <a href="{link}" target="blank">Privacy policy</a>.',
		formSignupAcceptTerms: 'I accept the <a href="{link}" target="blank">Terms and conditions</a>.',

		formSignupNextButton: "Next",

		formSignupStep2Title: "By continuing, you will join the group",
		formSignupStep2Info: "Your username can be seen by other users on the platform. Do not enter your full name. Your username cannot be changed once your user has been created.",
		formSignupAge: "How old are you?",
		formSignupAgePlaceholder: "Select your age",
		formSignupGoals: "What are you most looking forward to in the Vireo project?",
		formSignupMood: "How are you feeling towards the Vireo project?",
		formSignupInitialsLabel: "Initials",
		formSignupFoodLabel: "Plant",
		formSignupColorLabel: "Color",
		formSignupUsernameLabel: "Your username",
		formSignupPasswordLabel: "Password",
		formSignupConfirmationLabel: "Confirm password",

		formSignupStep1DefaultErrorTitle: "Invalid group code",
		formSignupStep1DefaultErrorText: "This group code is incorrect. Please contact your teacher.",
		formSignupStep2DefaultErrorTitle: "",
		formSignupStep2DefaultErrorText: "",

		formSignupSuccessTitle: "Registration completed!",
		formSignupSuccessUsernameLabel: "Your username is:",
		formSignupSuccessInfo: "If you forget, you will have to ask your teacher for your username.",
		formSignupSuccessButton: "I got it!",

		modalFoodTitle: "Select a food to represent you in the platform",
		modalFoodButton: "Select",
		modalColorTitle: "Select a color to represent you in the platform",
		modalColorButton: "Select",

		modalLogoutTitle: "Are you sure you want to log out?",
		modalLogoutButtonCancel: "Do not log out",
		modalLogoutButtonSubmit: "Log out",

		modalRequestResetPasswordTitle: "Forgot your password?",
		modalRequestResetPasswordDescription: "Enter your email address below to receive an email allowing you to change your password.",
		modalRequestResetPasswordInputPlaceholder: "Your email address",
		modalRequestResetPasswordSubmitButton: "Send",
		modalRequestResetPasswordSuccessTitle: "Email sent!",
		modalRequestResetPasswordSuccessText: "The password change email has been sent correctly.",
		modalRequestResetPasswordErrorTitle: "An error has occurred",
		modalRequestResetPasswordErrorText: "The password change email could not be sent.",

		modalUserDisabledTitle: "Account deactivated",
		modalUserDisabledText: "Your account is currently deactivated. Please contact the person in charge at your school.",
		modalUserAccountExpiredTitle: "Your package has expired",
		modalUserAccountExpiredText: "Your Vireo support package has expired. To renew our services, please <a href=\"mailto:info@vireoeducation.com\">contact us</a>.",
		
		modalUserDisabledCloseButton: "Close",

		modalLoginTitle: "Welcome to Vireo",
		modalLoginDescription: "You must be logged in to access this content.",
	},
	modalResetTuto: {
		title: "Retry the tutorial",
		description: "You are about to start the whole tutorial again.",
		question: "Are you sure you want to continue?",
		buttonCancel: "No, I don't want to redo the tutorial",
		buttonSubmit: "Yes, I want to redo the whole tutorial",
	},
	notifications: {
		loginSuccessTitle: "Successful login!",
		loginSuccessText: "You are now logged in.",
		loginErrorTitle: "Login failed",
		loginErrorDefaultText: "An error occurred during the login.",
		betaVersionTitle: "Early access",
		betaVersionText: "This content is not yet available in the current version.",
		signupSuccessTitle: "Registration completed!",
		signupSuccessText: "Your registration has been successfully completed. You are now connected.",
		signupErrorTitle: "Failed registration!",
		signupErrorText: "An error occurred during your registration. Please contact the administrator to find out more.",
		notificationLoginRequiredTitle: "Login required",
		notificationLoginRequiredText: "You must be logged in to access this section.",
		notificationUnauthorizedAccessTitle: "Unauthorized access",
		notificationUnauthorizedAccessText: "You do not have access to this section.",
		sendInvitationSuccessTitle: "Invitation sent!",
		sendInvitationSuccessText: "The invitation email was sent correctly.",
		sendInvitationErrorTitle: "Invitation error",
		sendInvitationErrorText: "An error occurred. The invitation email could not be sent.",
		sendResetPasswordErrorTitle: "Error",
		sendResetPasswordErrorText: "An error occurred. The password reset request could not be processed.",
		submitDeleteGroupSuccessTitle: "Deletion done",
		submitDeleteGroupSuccessText: "The group has been correctly deleted.",
		submitDeleteGroupErrorTitle: "Deletion error",
		submitDeleteGroupErrorText: "An error has occurred. The group has not been deleted.",

		submitAddGroupSuccessTitle: "Success!",
		submitAddGroupSuccessText: "The group has been successfully added.",
		submitEditSuccessTitle: "Success!",
		submitEditSuccessText: "The change has been successfully made.",
		submitEditErrorTitle: "An error has occurred!",
		submitEditErrorText: "The change has not been saved.",

		editEmailSuccessTitle: "Success!",
		editEmailSuccessText: "The email has been correctly edited.",
	},
	onboarding: {
		welcome1: "Welcome to the Vireo platform!",
		welcome2: "Please create a password that will allow you to log into your account.",
		welcome3: "The email address with which you received the invitation to the platform will act as your login. Please keep this information for future use when logging into your account.",
		formFieldPasswordLabel: "Your password",
		formFieldConfirmationLabel: "Confirm password",
		formNextButton: "Next",
		formSubmitButton: "Confirm and log in",
		invalidInvitation: "This invitation is no longer valid.<br>Please contact your administrator to receive a new one if needed.",
		invalidInvitationButton: "Go to home",
	},
	reset_password: {
		welcome1: "You used a temporary password",
		welcome2: "Choose a new password to log into your account.",
		formSubmitButton: "Confirm and log in",
		invalidUserText: "You must contact your teacher if you want to reset your password.",
		invalidUserButton: "Go to the home page",
		submitSuccessTitle: "Success",
		submitSuccessText: "Your password has been changed correctly.",
		submitErrorTitle: "Error",
		submitErrorText: "An error has occurred. Unable to change your password.",
	},
	dashboard: {
		rdvSentence: "Need support?",
		rdvLink: "Book a meeting with us here!",
		enabled: "Enabled",
		disabled: "Disabled",
		models: "Models",
		noModels: "None",
		
		registeredTeachers: "Registered teachers",
		registeredTeachersDescription: "Invite teachers to join the platform and see which ones are already registered!",
		groupOverview: "Groups overview",
		groupOverviewDescription: "See all registered groups from your school and all students within these groups!",
		schoolOverview: "Schools overview",
		schoolOverviewDescription: "Invite schools to join the platform and see which schools have already been added!",
		view: "View",
		back: "Back",
		search: "Search...",
		noTeachers: "No registered teachers.",
		noGroups: "No groups found.",
		noSchools: "The list of schools is coming soon.",
		notFound: "Unsuccessful search.",

		profile: "Profile",
		editProfile: "Edit your profile",
		profileFirstName: "First name",
		profileCurrentFirstName: "Current username",
		profileFirstNamePlaceholder: "Enter your new first name",
		profileLastName: "Last name",
		profileCurrentLastName: "Current last name",
		profileLastNamePlaceholder: "Enter your new last name",
		profileCancelButton: "Cancel",
		profileSubmitButton: "Update",

		addTeacher: "Add a teacher",
		addSchool: "Add a school",
		addAdmin: "Add a teacher",
		formAddTeacherUsername: "Username",
		formAddTeacherUsernameInfo: "This information will serve not only as a reference, but also as the login information for the teacher in question. It can be as simple as the teacher's name or a clear login.",
		formAddTeacherEmail: "Email Address",
		formAddTeacherEmailPlaceholder: "Example: john.doe{'@'}domain.com",
		formAddTeacherEmailInfo: "It is important that the email address be current and correct; it will serve as a means of communication with the teacher as well as their login information. The invitation email to the teacher account will also be sent using this address.",
		formAddTeacherCancelButton: "Cancel",
		formAddTeacherSubmitButton: "Add teacher",

		formAddSchoolEmail: "Email Address",
		formAddSchoolEmailPlaceholder: "Example: john.doe{'@'}domain.com",
		formAddSchoolEmailInfo: "It is important that the email address be current and correct; it will serve as a means of communication with the teacher as well as their login information. The invitation email to the teacher account will also be sent using this address.",
		formAddSchoolCancelButton: "Cancel",
		formAddSchoolSubmitButton: "Add school",

		addGroup: "Create a group",
		formAddGroupName: "Group name",
		formAddGroupNameInfo: "The group name will act primarily as a reference for administrators, teachers and students; all will be able to see the name assigned to the group.",
		formAddGroupNamePlaceholder: "Enter a group name",
		formAddGroupCode: "Group code",
		formAddGroupCodeInfo: "The group code will allow students to join the group. It is important to share this information with students when they first log in. The group code will also be visible from the group page.",
		formAddGroupCancelButton: "Cancel",
		formAddGroupSubmitButton: "Create group",

		groupNbStudents: "Total students",
		groupNbAdmins: "Total teachers",
		schoolNbTeachers: "Total teachers",
		groupCode: "Group code",
		schoolEmail: "School email",
		studentsList: "List of students",
		adminsList: "List of teachers",
		teachersList: "List of teachers",
		firstname: "First name",
		lastname: "Last name",
		username: "User name",
		gardener: "Gardener",
		administration: "Administration",
		resetPassword: "Reset password",

		modalSendInvitationTitle: "Would you like to resend a login link to this user?",
		modalSendInvitationInfo: "In the event that a user has not received or has lost their login link, it is possible to send a new one to their registered email address. The user will have to re-register a new password if this has already been done.",
		modalSendInvitationCancelButton: "No, do not resend the login link",
		modalSendInvitationSubmitButton: "Yes, send a new login link",

		modalResetPasswordTitle: "Send a password reset request to the following student?",
		modalResetPasswordInfo: "A new password will be automatically generated for the above user. The user will need this temporary password the next time they login.",
		modalResetPasswordCancelButton: "No, cancel the password reset request",
		modalResetPasswordSubmitButton: "Yes, send a password reset request",

		modalDeleteGroupTitle: "Do you really want to delete the following group?",
		modalDeleteGroupInfo: "This action is not reversible. Users with student access associated with this group will also be deleted, along with their data.",
		modalDeleteGroupCancelButton: "No, I want to keep this group",
		modalDeleteGroupSubmitButton: "Yes, I want to delete this group",

		modalAddAdminTitle: "Add administrators",
		modalAddAdminIntro: "Select the users you want to add as administrators for this group.",
		modalAddAdminTitleCancelButton: "Cancel",
		modalAddAdminTitleSubmitButton: "Add",

		modalEditAlternativesTitle: "Edit the models accessible to this school",
		modalEditAlternativesInfo: "Only the models selected below will be available at this school:",
		modalEditAlternativesCloseButton: "Close",

		modalDeleteUser: {
			titleEnabled: "Activate or delete a user account",
			titleDisabled: "Deactivate or delete a user account",
			questionEnabled: "Do you wish to activate or delete the user's account?",
			questionDisabled: "Do you wish to deactivate or delete the user's account?",
			disableTitle: "Deactivate the account",
			disableDescription1: "By deactivating this account, it will no longer be able to log in.",
			disableDescription2: "All account information will be retained and you can reactivate it at any time.",
			enableTitle: "Activate the account",
			enableDescription1: "By activating this account, its user will be able to log in again using their credentials.",
			deleteTitle: "Delete the account",
			deleteDescription1: "By deleting the account, all its data, as well as that of the associated students, will be permanently deleted.",
			deleteDescription2: "It will be impossible to recover data from these accounts.",
			warning1: "Warning! You are about to delete a school account.",
			warning2Singular: "The student account associated with it will also be permanently deleted!",
			warning2Plural: "The {n} student accounts associated with it will also be permanently deleted!",
			warningNoConnectedUsers: "This account has no associated student account.",
			close: "Close",
			cancelButton: "Cancel, I do not wish to modify this user account",
			submitButtonDelete: "Confirm, I want to permanently delete this account and its students",
			submitButtonDisable: "Confirm, I want to deactivate this account",
			submitButtonEnable: "Confirm, I want to activate this account",
			studentsToDeleteTitle: "{n} account{s} will be permanently deleted:",
			studentsToDeleteAnonyme1: "And {n} anonymous account{s}",
			noUserData: "Information not provided",
			search: "Search...",
		}
	},
	errors: {
		errorDefaultText: "An error has occurred.",
		errorRequestTitle: "Error",
		errorRequestText: "Unable to load the request <strong>{queryName}</strong>.",
		errorUserDisabled: "Unable to log in because the user is disabled.",
		errorInvalidCredentials: "Your login credentials are incorrect.",
	},
	tooltips: {
		login: "Log in / Sign up",
		logout: "Log out",
		dashboard: "Dashboard",
		home: "Back to home",
		stats: "Statistics",
		chatbot: "Interactive assistant",
		faq: "FAQ",
		settings: "Settings",
		groupSelection: "Group selection",
		calendar: "Calendar",
		addToCalendar: "Add to calendar",
	},
	tuto: {
		nextButton: "Next",
		endButton: "Complete",
		skipButton: "Skip the tutorial"
	},
	languageScreen: {
		description1: "Please select your preferred language.",
		description2: "You will be able to switch languages at any time.",
		button: "Confirm",
		cookieSentence: 'By using this online platform, you agree to our <a href="{link1}" target="blank">Privacy Policy</a> as well as our <a href="{link2}" target="blank">Terms of Use</a>.'
	},
	stats: {
		tabUsers: "Users",
		titleUsers: "Registered users",
		schools: "Schools",
		teachers: "Teachers",
		students: "Students",
		nbSchools: "Number of schools",
		nbSchoolsWithAtLeastOneTeacher: "Number of schools with at least 1 teacher",
		nbSchoolsWithAtLeastOneStudent: "Number of schools with at least 1 student",

		tabGames: "Forms",
		titleGames: "Completed forms",
		forms: "Forms",
		nbCompletions: "Number of completions",

		tabFiles: "Files",
		titleFiles: "Donwloaded files",
		nbFiles: "Number of files",
		totalDownloads: "Total downloads",
		files: "Files",
		nbDownloads: "Number of downloads",

		total: "Total",
	},
	modalNews: {
		title: "Vireo at the AQUOPS conference!",
		description1: "Come and see us from April 4 to 6 at booths 39/40 at the Hilton de Québec.",
		description2: "On the menu :",
		li1: "Come and meet a teacher and her students who took part in the Vireo project!",
		li2: "Learn about hydroponics and its benefits.",
		li3: "Sign up for a free trial of our web platform.",
		description3: "We look forward to seeing you there!",
		close: "Close",
	},
	chatbot: {
		chooseOptions: "Choose from the following options",
		infos: {
			title: "Get an account and unlock access to the interactive assistant",
			slide1: "The interactive assistant is available at all times and answers your questions on the spot.",
			slide2: "It is an indispensable tool for developing students' autonomy and problem-solving skills!",
			slide3: "Got a question? Get an answer in a few clicks!",
		}
	},
	calendar: {
		removeFromCalendar: "Remove from calendar",
		month1: "January",
		month2: "February",
		month3: "March",
		month4: "April",
		month5: "May",
		month6: "June",
		month7: "July",
		month8: "August",
		month9: "September",
		month10: "October",
		month11: "November",
		month12: "December",
		day0: "Sunday",
		day1: "Monday",
		day2: "Tuesday",
		day3: "Wednesday",
		day4: "Thursday",
		day5: "Friday",
		day6: "Saturday",
		between: "Between",
		and: "and",
		more: "more",
		today: "Today",
		letterDurations: {
			y: "year",
			w: "week",
			d: "day",
			h: "hour",
			m: "minute",
			s: "second",
		},
		addSeeds: {
			title: "Adding seeds",
			description: "Depending on the annual selection, Vireo will provide different seeds for the current year. The selection may vary from year to year.",
			noSeeds: "No seeds",
		},
		seedDetails: {
			difficulty: "Difficulty",
			growthTime: "Growth time once transferred to the garden",
			height: "Height of the plant",
			location: "Location in the garden",
		},
		singleSeed: {
			difficulty: "Difficulty",
			growthTime: "Growth time",
		},
		addEvent: {
			addToCalendar: "Add to calendar",
			mayaSentence1: "Remember that this seed has a growth of",
			mayaSentence2: "You can always change the date in the calendar, but when would you like to start your seedlings?",
			harvestDate: "Expected harvest date",
			successTitle: "Added seed",
			successText: "The seed has been correctly added to your calendar.",
			noCalendar: "You must be logged in and associated with a group to add a seed to the calendar.",
			close: "Close",
		},
		editEvent: {
			title: "Would you like to move the next task to a new date?",
			sentence: "You can always move this task later. Moving this task will not affect the other tasks associated with this seed.",
			cancelButton: "No, do not move this task",
			submitButton: "Yes, I want to move this task",
			successTitle: "Success!",
			successText: "The task has been correctly moved.",
			errorTitle: "Error",
			errorText: "An error has occurred, the task has not been moved.",
			warning: {
				warningTitle: "Warning",
				warningSentence: "You are about to move a task into a range not recommended for the proper growth of your plants.",
				warningQuestion: "Are you sure you want to continue?",
				warningCancel: "Cancel",
				warningSubmit: "Yes, I want to continue",
			}
		},
		removeEvent: {
			title: "Do you want to delete the following task and its associated tasks?",
			sentence: "This task and all its associated tasks will be permanently deleted.",
			cancelButton: "No, do not delete tasks",
			submitButton: "Yes, I want to delete the tasks",
			successTitle: "Success!",
			successText: "The task has been successfully deleted.",
			errorTitle: "Error",
			errorText: "An error has occurred, the task has not been deleted.",
		}
	},
	adminDashboard: {
		actions: "Possible actions",
		none: "None",
		close: "close",
		listItems: {
			searchPlaceholder: "Search...",
			seeMore: "See more",
			noResults: "No results",
			item: {
				nbStudents: "Nb students",
				nbTeachers: "Nb teachers",
				accessCode: "Access code",
				showDetail: "Show details",
				edit: "Edit",
				resetPassword: "Reset password",
				disable: "Activate/Deactivate",
				remove: "Remove",
				removeFromGroup: "Remove from group",
				lastActivity: "Last activity",
			}
		},

		panels: {
			home: {
				title: "List of districts",
				invitationsButton: "Your invitations",
			},
			district: {
				defaultTitle: "Unnamed District",
				schoolsList: "List of registered schools",
				yourSchools: "Your schools",
				invitedSchoolsList: "List of invited schools",
				stats: "Statistics",
				nbSchools: "Number of schools",
				districtManager: "District manager",
				email: "Email",
				accountState: "Account status",
				subscribeState: "Registration status",
			},
			school: {
				defaultTitle: "Unnamed school",
				yourInvitations: "Your invitations",
				tabTeachers: "Teachers",
				tabTeachersSaved: "registered",
				tabGroups: "Groups",
				tabSchools: "Schools",
				stats: "Statistics",
				nbTeachers: "Nb teachers",
				schoolManager: "School manager",
				email: "Email",
				accountState: "Account status",
				subscribeState: "Registration status",
				supporterModels: "Supported models",
				seeCalendar: "See calendar",
			},
			group: {
				nbTeachers: "Nb teacher | Nb teacher | Nb teachers",
				nbStudents: "Nb student | Nb student | Nb students",
				accessCode: "Access code",
				teachersList: "Teachers list",
				studentsList: "Students list",
			},
			teacher: {
				userAccount: "User account",
				anonymous: "Anonymous",
				firstname: "First name",
				lastname: "Last name",
				username: "User name",
				email: "Email",
				lastActivity: "Last activity",
				idUser: "User account ID",
				accountState: "Account status",
				subscribeState: "Registration status",
				editUser: "Edit user account",
				editEmail: "Edit user email",
				resetUserPassword: "Reset password",
				disableUser: "Activate/Deactivate user account",
				deleteUser: "Delete user account",
			}
		},
		tooltips: {
			addDistrict: "Add a district",
			disableSelectedDistrict: "Deactivate selected districts",
			deleteSelectedDistrict: "Remove selected districts",
			addSchool: "Add a school",
			linkSchoolToDistrict: "Associate schools with this district",
			resetSelectedSchoolsPassword: "Reset passwords for selected schools",
			disableSelectedSchools: "Deactivate selected schools",
			unlinkSelectedSchoolsFromDistrict: "Remove selected schools from district",
			deleteSelectedSchools: "Delete selected schools",
			editDistrictAccount: "Edit the district account",
			resetDistrictPassword: "Reset district account password",
			disableDistrict: "Enable/Disable District Account",
			deleteDistrict: "Delete District",
			add: "Add",
			resetPasswordSelectedElements: "Reset passwords for selected accounts",
			disabledSelectedElements: "Disable selected items",
			deleteSelectedElements: "Delete selected items",
			editSchool: "Edit school account",
			resetSchoolPassword: "Reset school account password",
			disableSchool: "Enable/Disable School Account",
			deleteSchool: "Delete the school",
			editSchoolModels: "Edit the garden models supported by this school",
			linkTeachersToGroup: "Associate teachers to the group",
			resetSelectedTeachersPassword: "Reset passwords for selected teacher accounts",
			resetSelectedStudentsPassword: "Reset passwords for selected student accounts",
			disableSelectedTeachers: "Disable selected teacher accounts",
			disableSelectedStudents: "Disable selected student accounts",
			unlinkSelectedTeachersFromGroup: "Remove selected teachers from the group",
			resetSelectedUsersPassword: "Reset passwords for selected user accounts",
			disableSelectedUsersPassword: "Disable selected user accounts",
			edit: "Edit",
			resetPassword: "Reset password",
			disable: "Enable/Disable",
			delete: "Delete",
			editEmail: "Edit email",
		},
		states: {
			enabled: "Activated",
			disabled: "Deactivated",
		},
		onboarding: {
			is_anonymous: "Email unconfirmed",
			is_validated: "Email confirmed",
		},
		modals: {
			global: {
				errorDifferentItems1: "You have selected several elements of different types.",
				errorDifferentItems2: "Group operations can only be performed on elements of the same type.",
				resultSuccessTitle: "Change saved successfully",
				resultErrorTitle: "An error has occurred",
				resultTotal: "Total",
				resultSuccess: "Successful",
				resultErrors: "Errors",
				close: "Close",
				submit: "Confirm",
				send: "Send",
				cancel: "Cancel",
			},
			add: {
				emailImportant: "It is important that the email address be current and correct; it will be used as a means of communication, but also as login information. The first link to the teacher account will also be sent using this address.",
			},
			disable: {
				questionValidation: "Do you want to change the activation status of",
			},
			remove: {
				question1: "Are you sure you want to delete the following items?",
				question2: "These items will be <strong>deleted permanently</strong> and can no longer be retrieved.",
				resultSuccessTitle: "The element has been deleted",
				resultErrorTitle: "Deletion failed",
				submit: "Delete permanently",
			},
			resetPassword: {
				text1: "Are you sure you want to reset the passwords for the following accounts?",
				text2: "<strong>A password reset email will be sent to them.</strong>",
				text3: "For accounts with unconfirmed emails, the registration invitation email will be sent instead.",
				textStudent1: "Are you sure you want to reset the passwords for the following student accounts?",
				textStudent2: "<strong>A new password will be automatically generated for them.</strong>",
				textStudent3: "Students will <strong>require</strong> this temporary password the next time they login.",
				textStudent4: "The new password will be displayed next to the student's username.",
			},
			removeTeachersFromGroup: {
				question: "Are you sure you want to remove the following teachers from the group",
				submit: "Remove from the group",
			},
			removeSchoolsFromDistrict: {
				question: "Are you sure you want to remove the following schools from the district",
				submit: "Remove from district",
			},
			addTeachersToGroup: {
				intro: "Select the teachers you wish to add to the group",
				submit: "Associate 0 teacher | Associate 1 teacher | Associate {n} teachers",
			},
			addSchoolsToDistrict: {
				intro: "Select the schools you wish to add to the district",
				submit: "Associate 0 school | Associate 1 school | Associate {n} schools",
			},
			editEmail: {
				sentence1: "Enter the new email address.",
				sentence2: "A confirmation step will be necessary.",
				sentenceConfirm1: "Please check that you have typed the email correctly.",
				sentenceConfirm2: "By confirming, the following change will be final and cannot be changed.",
				old: "Current:",
				new: "New:",
				warning1: "Warning! Another user is already using this email!",
				warning2: "It will be permanently archived:",
				firstname: "First name",
				lastname: "Last name",
				email: "Email",
				accountType: "Type of account",
				lastActivity: "Last activity",
				type_vireo: "Admin",
				type_district: "District manager",
				type_school: "School",
				type_teacher: "Teacher",
				type_student: "Student",
				question: "Are you sure you want to make this change?",
			}
		},
		modalTitle: {
			'add-schools-to-district': "Associate schools with the district",
			'remove-schools-from-district': "Remove schools from the district",
			'add-teachers-to-group': "Associate teacher accounts with the group",
			'remove-teachers-from-group': "Remove teachers from the group",
			'edit-alternatives': "Change garden templates",
			
			add: "Add",
			remove: "Remove",
			edit: "Edit",
			disable: "Change the state of",
			'reset-password': "Reset the password of",
			'resend-invitation': "Resend invitation emails from",
			'edit-email': "Change the email of",

			district: "no district | {n} district | {n} districts",
			user: "no user account | {n} user account | {n} user accounts",
			teacher: "no teacher account | {n} teacher account | {n} teacher accounts",
			student: "no student account | {n} student account | {n} student accounts",
			school: "no school | {n} school | {n} schools",
			group: "no group | {n} group | {n} groups",
			subscription: "no subscription | {n} subscription | {n} subscriptions",
			potager: "no model | {n} model | {n} models",
		},
		form: {
			labels: {
				email: "Email",
				name: "Name",
				firstName: "First Name",
				lastName: "Last Name",
				district: "District",
				school: "School",
				group: "Group",
				teacher: "Teacher",
			},
			placeholders: {
				email: "Example: john{'@'}doe.com",
				name: "Enter the name here",
				firstName: "Example: John",
				lastName: "Example: Doe",
			},
			buttons: {
				close: "Close",
				submit: "Submit",
			}
		}
	}
}