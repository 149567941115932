<template>
	<div class="modal-reset-password">

		<template v-if="modal.type === 'student'">
			<div class="texts">
				<p v-html="$t('adminDashboard.modals.resetPassword.textStudent1')"></p>
				<p v-html="$t('adminDashboard.modals.resetPassword.textStudent2')"></p>
				<p v-html="$t('adminDashboard.modals.resetPassword.textStudent3')"></p>
				<p class="note" v-html="$t('adminDashboard.modals.resetPassword.textStudent4')"></p>
			</div>
			<div class="items">
				<div v-for="item in modal.items" :key="item.id" class="item student">
					<span class="name" v-html="item?.userName || item?.name || '-'"></span>
					<span class="new-password">{{ newPasswords?.[item.id] || "-" }}</span>
				</div>
			</div>
		</template>

		<template v-else-if="[ 'district', 'school', 'teacher' ].includes(modal.type)">
			<div class="texts">
				<p v-html="$t('adminDashboard.modals.resetPassword.text1')"></p>
				<p v-html="$t('adminDashboard.modals.resetPassword.text2')"></p>
				<p class="note" v-html="$t('adminDashboard.modals.resetPassword.text2')"></p>
			</div>
			<div class="items">
				<div v-for="item in modal.items" :key="item.id" class="item">
					<p class="name" v-html="(item?.firstName || item?.lastName ? item?.firstName + ' ' + item?.lastName : false) || item?.profile?.school_name || item?.userName || item?.name || '-'"></p>
					<p v-if="item?.email" class="description" v-html="item.email"></p>
					<p v-if="item.onboarding" class="description"><admin-onboarding :onboarding="item.onboarding" /></p>
				</div>
			</div>
		</template>

		<admin-form-results :items="items" :dones="dones" :errors="errors" />

		<div class="buttons">
			<ui-button color="dark-green" size="big" :block="true" v-tap="() => $emit('close')">{{ $t('adminDashboard.modals.global.close') }}</ui-button>
			<ui-button v-if="dones.length + errors.length !== items.length" color="green" size="big" v-tap="submit" :loading="loading" :disabled="!submitable" :block="true">
				{{ modal.type === 'student' ? $t('adminDashboard.modals.global.submit') : $t('adminDashboard.modals.global.send') }}
			</ui-button>
		</div>

	</div>
</template>

<script>
import AdminOnboarding from '@/components/admin-dashboard/ui/admin-onboarding.vue'
import { resendInvitationEmail, resetMemberPassword } from '@/requests/dashboard'
import { requestPasswordReset } from '@/requests/login'
import AdminFormResults from '@/components/admin-dashboard/ui/admin-form-results.vue'

export default {
	data() {
		return {
			loading: false,
			dones: [],
			errors: [],
			newPasswords: []
		}
	},
	props: {
		modal: { default: null }
	},
	computed: {
		submitable() {
			return this.modal?.items?.length
		},
		items() {
			return this.modal?.items || []
		}
	},
	methods: {
		async submit() {
			if (this.submitable && !this.loading && this.items.length) {
				this.loading = true
				this.dones = []

				await Promise.all(this.items.map(async (item) => {
					if (await this.callRequest(item)) {
						this.dones.push(item.id)
					} else {
						this.errors.push(item.id)
					}
				}))

				this.loading = false
			}
		},

		async callRequest(item) {

			if ([ 'district', 'school', 'teacher' ].includes(this.modal.type)) {
				if (item.onboarding === 'is_anonymous')
					return await resendInvitationEmail({ email: item.email })
				else
					return await requestPasswordReset({ email: item.email })
			}
			if ([ 'student' ].includes(this.modal.type)) {
				const response = await resetMemberPassword({ groupId: this.modal.group.id, memberId: item.id })
				if (response) {
					this.newPasswords[item.id] = response
					return true
				}
				return false
			}

			console.error('[modal-remove.vue] No defined request for type: ' + this.modal.type)
		}
	},
	components: { AdminOnboarding, AdminFormResults }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.modal-reset-password
	text-align center
	.texts
		margin 0 0 32px 0
		> p
			margin 0 0 8px 0
			&:last-child
				margin-bottom 0
			&.note
				margin 16px 0 0 0
				background-color #c0d9e4
				padding 16px 24px
				border-radius 8px
				color darken(#c0d9e4, 60%)
				text-align left
	.items
		flex flex-start flex-start column
		gap 8px
		margin 0 0 32px 0
		max-height 400px
		overflow auto
		border 2px solid darken(#D5DEDE, 10%)
		border-radius 8px
		padding 8px
		.item
			width 100%
			background-color #D5DEDE
			padding 16px
			border-radius 8px
			text-align left
			&.student
				flex center space-between
			> .name
				font-weight 500
				text-transform uppercase
			> .description
				color #666
			.result
				position absolute
				right 0px
				top 0px
				width 32px
				height 32px
				border-radius 50%
				background #999 center center no-repeat
				box-shadow 0 0 0 4px #E7EEEE
				transition 0.15s easeInQuart
				&.done
					background-color #8BAE63
					background-size 24px !important
				&.error
					background-color #C17474
					background-size 16px !important
				&.v-enter-from
					transform scale(1.5)
					opacity 0

	.results
		flex center center
		gap 16px
		margin 0 0 32px 0
		.result
			flex center center column
			width 96px
			height 96px
			padding 8px 16px
			border-radius 50%
			background-color #999
			color #fff
			text-align center
			&.success
				background-color #8BAE63
			&.error
				background-color #C17474
			.label
				text-transform uppercase
				font-size 1.4rem
			.value
				font-size 2.4rem

	.buttons
		flex center center
		gap 16px
	

</style>