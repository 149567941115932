import { defineStore } from 'pinia'

export const useFormMaintenanceStore = defineStore({
	id: 'form-maintenance',

	state: () => ({
		progression: null,
		currentContainerIndex: 0,
		currentMaxContainerIndex: 0,
		currentSubElementVisible: 0,
		subElementsValues: [],
		formStatus: 'ready',
		trivia: null
	}),

	getters: {
		containers() { return this.progression?.currentContent?.formElements },
		currentContainer() { return this.containers?.length ? this.containers[this.currentContainerIndex] : null },
		currentSubElements() {
			let subElements = []
			if (this.currentContainer) {
				if (this.currentContainerIndex < this.currentMaxContainerIndex) {
					subElements = this.currentContainer?.entry.subElements
				} else {
					let n = 0
					this.currentContainer?.entry.subElements.forEach(subElement => {
						if (subElement.entry.__typename === 'ContentTextBaseQuery') n++
						if (n <= this.currentSubElementVisible + 1) subElements.push(subElement)
					})
				}
			}
			return subElements
		},
		currentTriviaStep() { return this.trivia?.currentContent }
	},

	actions: {

		setProgression({ progression }) {
			this.$reset()
			this.progression = progression
			this.subElementsValues = []

			this.progression?.currentContent?.formElements?.forEach(currentContent => {
				this.subElementsValues = [ ...this.subElementsValues, ...currentContent.entry.subElements
					.filter(subElement => subElement.submittable)
					.map(subElement => ({ id: subElement.id, value: null, type: subElement?.entry?.__typename }))
				]
			})

			if (this.progression?.currentContent?.clientLinks?.length) {
				this.formStatus = 'ended'
			}
		},

		setTrivia({ trivia }) {
			this.trivia = trivia
		},

	},

})
