<template>
	<div class="home" :class="{ 'stopped': currentTuto }">
		<div class="bg"><div class="pattern" v-bg="'home/bg-pattern.svg'"></div></div>
		<div class="content" :class="{ 'decal-left': decalLeft && !decalRight, 'decal-right': decalRight }">

			<div class="section garden" key="garden">
				<div class="column" v-bg="'home/column.svg'"></div>
				<div class="leaves" v-bg="'home/leaves.png'"></div>
				<div class="room" id="room-entretiens-de-routine" v-bg="'home/room-garden.png'" v-tap="() => goto('my-garden-experiments')">
					<div class="title" v-bg="badges.find(b => b.tags.includes('entretiens-de-routine'))?.base64"></div>
					<div class="title padlock"><div class="icon" v-bg="'padlock.svg'"></div></div>
				</div>
			</div>

			<div class="light"></div>

			<div class="section service" key="service">
				<div class="column" v-bg="'home/column.svg'"></div>
				<div class="leaves" v-bg="'home/leaves.png'"></div>
				<div class="room" id="room-guide-du-potager" v-bg="'home/room-service.png'" v-tap="() => goto('guide-to-gardening')">
					<div class="title" v-bg="badges.find(b => b.tags.includes('guide-du-potager'))?.base64"></div>
					<div class="title padlock"><div class="icon" v-bg="'padlock.svg'"></div></div>
				</div>
			</div>
			<div class="section activities" key="activities">
				<div class="column" v-bg="'home/column.svg'"></div>
				<div class="leaves" v-bg="'home/leaves.png'"></div>
				<div class="room" id="room-activites-educatives" v-bg="'home/room-activities.png'" v-tap="() => goto('lesson-plans')">
					<div class="title" v-bg="badges.find(b => b.tags.includes('activites-educatives'))?.base64"></div>
					<div class="title padlock"><div class="icon" v-bg="'padlock.svg'"></div></div>
				</div>
			</div>
			<div class="section library" key="library">
				<div class="column" v-bg="'home/column.svg'"></div>
				<div class="leaves" v-bg="'home/leaves.png'"></div>
				<div class="room" id="room-coin-bibliotheque" v-bg="'home/room-library.png'" v-tap="() => goto('library-articles')">
					<div class="title" v-bg="badges.find(b => b.tags.includes('coin-bibliotheque'))?.base64"></div>
				</div>
			</div>

			<div class="bottom-left-buttons">

				<transition name="home-comment-button">
					<div v-if="homeCommentButtonVisible && !homeCommentVisible" class="home-comment-button" v-tap="displayComment">
						<div class="image image-1" v-bg="'home-comment-1.svg'"></div>
						<div class="image image-2" v-bg="'home-comment-2.svg'"></div>
					</div>
				</transition>

				<!-- <router-link :to="{ name: 'webinars' }" class="avatar vireo-tv">
					<div class="image" v-bg="tvIcon">
						<div v-if="$route.name === 'home' && getVireoNewIndicator()" class="new-indicator"></div>
					</div>
				</router-link> -->

				<!-- <div class="avatar whats-new" v-tap="() => goto('whats-next')">
					<div class="image" v-bg="whatsNewIcon"></div>
				</div> -->

				<!-- <div class="avatar avatar-tuto" v-tap="displayTuto">
					<div class="image" v-bg="'avatar.svg'"></div>
				</div> -->
			</div>

		</div>

		<transition name="home-content">
			<home-comment v-if="homeCommentVisible" @close="() => homeCommentVisible = false" @sended="() => homeCommentButtonVisible = false" />
		</transition>

		<transition name="modal-news">
			<modal-news v-if="modalNews" @close="() => modalNews = false" />
		</transition>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useAppStore } from '@/store/app'
import { useOnboardingStore } from '@/store/onboarding'
import { useWebinarStore } from '@/store/webinar'
import { getMediasByTags } from '@/requests/medias'
import { localStorage } from '@affordancestudio/engage-game'
import HomeComment from '@/components/home/home-comment.vue'
import ModalNews from '@/components/modal-news'

export default {
	name: 'home',
	data() {
		return {
			homeCommentButtonVisible: false,
			homeCommentVisible: false,
			badges_visible: false,
			modalNews: false,
		}
	},
	computed: {
		...mapState(useAppStore, [ 'lang', 'isConnected', 'languageScreenVisible', 'popupNews' ]),
		...mapState(useOnboardingStore, [ 'currentTuto' ]),
		...mapState(useWebinarStore, [ 'webinars' ]),
		decalLeft() {
			return (this.$route.name !== 'home' && this.$route.name !== 'login' && this.$route.name !== 'stats' && this.$route.name !== 'help' && this.$route.name !== 'dashboard') ||
				(this.currentTuto && this.currentTuto.name === 'intro')
		},
		decalRight() {
			return this.languageScreenVisible
		},
		badges() {
			return getMediasByTags({ tags: [ 'home-badge' ] })
		},
		whatsNewIcon() {
			const medias = getMediasByTags({ tags: [ 'whats-new' ] })
			if (medias.length) return medias[0]?.base64
			return null
		},
		tvIcon() {
			const medias = getMediasByTags({ tags: [ 'tv-icon' ] })
			if (medias.length) return medias[0]?.base64
			return null
		},
	},
	methods: {
		getVireoNewIndicator() {
			// Utilisation d'une méthode plutôt qu'une computed car le localStorage n'est pas réactif
			const webinarsSeens = localStorage.getItem('webinarsSeens') || []
			return (this.webinars.length > webinarsSeens.length)
		},
		goto(name) {
			this.$router.push({ name })
		},
		displayTuto() {
			localStorage.removeItem('vireo-tutos')
			useOnboardingStore().startTuto({ name: 'intro' })
		},
		displayComment() {
			this.homeCommentVisible = true
		},
	},
	mounted() {
		if (!localStorage.getItem('hasPostCommentOrNote')) {
			const firstTime = localStorage.getItem('firstTime')
			if (firstTime) {
				const minutes = Math.floor((Math.abs(new Date() - new Date(firstTime))/1000)/60)
				this.homeCommentButtonVisible = minutes >= 30
			} else {
				localStorage.setItem('firstTime', new Date())
			}
		}

		if (this.popupNews?.slug && !localStorage.getItem(this.popupNews.slug)) {
			setTimeout(() => {
				this.modalNews = true
			}, 1000)
		}
	},
	components: { HomeComment, ModalNews }
}
</script>

<style lang="stylus" scoped>

#app > .low-perf
	.home
		&.route-leave-active, &.route-enter-active
			transition none
		.content
			transition none
			.section
				animation none
				.room
					filter none

#tablet-vertical
	.home .content .section
		&.service
			left calc(50% - 280px)
		&.library
			left calc(50% + 40px)

#mobile
	&.thin
		.home .content
			.section
				&.garden
					.column
						height 460px
				&.service
					.column
						height 530px
				&.library
					.column
						height 200px
				&.activities
					.column
						height 320px
	.home .content
		&:after
			content ''
			position absolute
			left 0
			bottom 0
			right 0
			height 64px
			pointer-events none
			background linear-gradient(to top, alpha(primary, 50%), alpha(primary, 0))
		.section
			width 130px
			&.disabled
				opacity 1
			&.garden
				left calc(48% + 0px)
				.column
					height 370px
			&.service
				left calc(52% - 160px)
				.column
					height 410px
			&.library
				left calc(48% + 20px)
				.column
					height 140px
			&.activities
				left calc(54% - 120px)
				.column
					height 250px
				.room
					left 0
					height 100px
					.title
						left calc(50% - 40px)
			.leaves
				width 160px
				height 100px
				left -12px
				bottom calc(100% - 88px)
			.room
				bottom calc(100% - 30px)
				width 132px
		.bottom-left-buttons
			.avatar
				&.avatar-tuto
					width (137px / 2)
					height (104px / 2)
					transform translateX(-3px)
					.image
						width @width
						height @height
				&.whats-new
					width (96px / 1.5)
					height (89px / 1.5)
					transform translateX(4px)
					.image
						width @width
						height @height
						svg
							width @width
							height @height
				&.vireo-tv
					width (96px / 1.5)
					height (81px / 1.5)
					transform translateX(4px)
					.image
						width @width
						height @height
						svg
							width @width
							height @height
				&.vireo-tv
					position fixed
					left 8px
					top 88px
			.home-comment-button
				width (91px * 0.85)
				height (75px * 0.85)
				.image
					width @width
					height @height


.home
	height 100%

	&.route-leave-active
		transition 0.1s linear 1s

	&.route-enter-active
		transition 1s easeOutQuart
		.content
			.section
				transition 0.4s easeOutQuart
				&.garden
					transition-delay (0.15s * 0)
				&.service
					transition-delay (0.15s * 1)
				&.library
					transition-delay (0.15s * 2)
				&.activities
					transition-delay (0.15s * 3)
	&.route-enter-from
		.content
			.section
				transform translateY(100%)
				opacity 0 !important

	&.stopped
		.content .section
			animation-play-state paused

	.bg
		absolute 0
		background radial-gradient(circle at -100%, #92CCCE, #1F9192)
		.pattern
			absolute 0
			background-position center center !important
			background-repeat repeat !important
			background-size 800px 800px !important
	.content
		height 100%
		transition transform 0.5s easeOutQuart

		&.decal-left
			transform translateX(-35%)
		&.decal-right
			transform translateX(29%)
		.light
			absolute 0
			background radial-gradient(circle at -50%, alpha(#FFDF4D, 50%), alpha(#FFDF4D, 0))
			pointer-events none
		.section
			position absolute
			left 0
			bottom 0
			width 250px
			animation 12s floating 0.5s easeInOutSine alternate infinite
			&:after
				content ''
				position absolute
				left -4px
				top 200px
				right -4px
				height 400px
			&.disabled
				opacity 0.75
				cursor not-allowed
				> .room
					cursor not-allowed !important
					&:hover
						filter none
					.padlock
						.icon
							width 80px
							height 80px
							background #156465 center center no-repeat
							border-radius 50%
							background-size 40px !important
			.column
				width 100%
				background center top repeat-y
				background-size cover !important
			.leaves
				position absolute
				left -40px
				bottom calc(100% - 196px)
				width (1071px / 3.3)
				height (684px / 3.3)
			.room
				position absolute
				left -2px
				bottom calc(100% - 50px)
				cursor pointer
				transition 0.15s easeOutQuart
				background bottom center no-repeat
				will-change filter
				&:hover
					filter drop-shadow(0 0 10px #fff)
				.title
					position absolute
					left calc(50% - 40px)
					top calc(100% - 20px)
					height 80px
					width 80px
					&.title-enter-active, &.title-leave-active
						transition 0.25s easeOutBack 0.5s
					&.title-enter-from, &.title-leave-to
						transform scale(0)
			&.garden
				left calc(50% + 20px)
				&:after
					top 400px
					height 350px
					background linear-gradient(to top, #33a295 50%, alpha(#33a295, 0))
				.column
					height 700px
				.room
					width (882px / 3.5)
					height (603px / 3.5)
					.title.title-enter-active
						transition-delay 0.5s
			&.service
				left calc(50% - 320px)
				animation-delay 1s
				animation-duration 16s
				&:after
					top 400px
					height 150px
					background linear-gradient(to top, #76B294, alpha(#76B294, 0))
				.column
					height 550px
				.room
					width (897px / 3.5)
					height (708px / 3.5)
					.title.title-enter-active
						transition-delay 0.6s
			&.library
				left calc(50% + 80px)
				animation-delay 1.5s
				animation-duration 10s
				.column
					height 350px
				.room
					width (885px / 3.5)
					height (582px / 3.5)
					.title.title-enter-active
						transition-delay 0.7s
			&.activities
				left calc(50% - 250px)
				animation-delay 2s
				animation-duration 8s
				.column
					height 180px
				.room
					left -84px
					width (836px / 2.25)
					height (518px / 2.25)
					.title
						left calc(50% - 20px)
						&.title-enter-active
							transition-delay 0.8s

		.bottom-left-buttons
			position fixed
			left 8px
			bottom 16px
			flex center flex-end column
			gap 8px
			.avatar
				cursor pointer
				&:hover .image
					transform translateX(4px)
				&.avatar-tuto
					width (137px / 1.3)
					height (104px / 1.3)
					transform translateX(-3px)
					.image
						width @width
						height @height
				&.whats-new
					width 96px
					height 89px
					transform translateX(4px)
					.image
						width @width
						height @height
						svg
							width @width
							height @height
				&.vireo-tv
					width 96px
					height 81px
					transform translateX(4px)
					.image
						width @width
						height @height
						svg
							width @width
							height @height
				.image
					background center bottom no-repeat
					transition transform 0.2s easeOutQuart
					::v-deep(svg)
						width 100%
						height auto
				.new-indicator
					position absolute
					right -4px
					top -4px
					width 20px
					height 20px
					background-color #C83F4D
					box-shadow 0 0 0 2px #8FBB92
					border-radius 50%
					pointer-events none

			.home-comment-button
				// position fixed
				// left 8px
				// top 56px
				width (91px * 1.25)
				height (75px * 1.25)
				transform translateX(2px)

				cursor pointer
				&.home-comment-button-enter-active
					transition 0.35s easeOutBack 2s
				&.home-comment-button-leave-active
					transition 0.35s easeOutQuart
				&.home-comment-button-enter-from, &.home-comment-button-leave-to
					transform translateX(-48px)
					opacity 0
				&:hover .image
					transform translateX(4px)
				.image
					position absolute
					left 0
					top 0
					width @width
					height @height
					background center bottom no-repeat
					transition transform 0.2s easeOutQuart
					&.image-2
						animation blink 0.5s linear alternate infinite
						@keyframes blink
							from
								opacity 0
							to
								opacity 1

@keyframes floating
	from
		transform translate3D(0, 0, 0)
	to
		transform translate3D(0, 24px, 0)


@media (max-height: 850px)
	.home .content .section
		&.garden
			.column
				height 550px
		&.library
			.column
				height 250px

@media (max-height: 720px)
	.home .content .section
		&.service
			.column
				height 450px
		&.garden
			.column
				height 450px
		&.library
			.column
				height 180px
		&.activities
			.column
				height 140px

@media (min-height: 1000px)
	.home .content .section
		&.garden
			&:after
				top 550px
			.column
				height 850px
		&.service
			&:after
				top 550px
			.column
				height 700px
		&.library
			.column
				height 450px
		&.activities
			.column
				height 300px



</style>
