<template>
	<div v-if="page" class="page-demo-completed">

		<div class="icon">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
		</div>
		<p>{{ $t('demo.sentence1') }}</p>

		<div class="buttons">
			<ui-button size="big" v-tap="close">{{ $t('demo.close') }}</ui-button>
		</div>

	</div>
</template>

<script>
export default {
	name: 'page-demo-completed',
	props: {
		page: { default: null }
	},
	methods: {
		close() {
			this.$router.push({ name: 'home' })
		}
	}
}
</script>

<style lang="stylus" scoped>

.page-demo-completed
	padding 32px 16px
	text-align center
	font-size 1.8rem
	line-height 1.5em
	.icon
		flex center center
		margin 0 0 32px 0
		svg
			width 64px
			height 64px
	p
		margin 0 0 32px 0
	.buttons
		flex center center
	
</style>
