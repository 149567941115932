<template>
	<div class="form-maintenance-text-input" :class="{ 'has-checkbox': hasCheckbox }">

		<ui-checkbox v-if="subElement.entry.label && hasCheckbox" v-model="checked" :text="subElement.entry.label" />
		<label v-else-if="subElement.entry.label" :for="'input-' + subElement.id" class="label" v-html="subElement.entry.label" />

		<template v-if="displayInput(subElement)">
			<textarea v-if="type === 'textarea'" class="input" rows="4" v-model="subElementValue" :placeholder="subElement.placeholder" :id="'input-' + subElement.id" ref="input"></textarea>
			<input v-else-if="type === 'number'"
				class="input"
				type="number"
				:step="subElement.entry.footstep ? subElement.entry.footstep : 1"
				:min="typeof subElement.entry.minValue === 'number' ? subElement.entry.minValue : null"
				:max="typeof subElement.entry.maxValue === 'number' ? subElement.entry.maxValue : null"
				v-model="subElementValue"
				:placeholder="subElement.placeholder"
				:id="'input-' + subElement.id"
				ref="input"
			>
			<input v-else class="input" :type="type" v-model="subElementValue" :placeholder="subElement.placeholder" :id="'input-' + subElement.id" ref="input">

			<div v-if="subElement.entry.characterLimit" class="character-limit">{{ subElementValue?.length ? subElement.entry.characterLimit - subElementValue.length : subElement.entry.characterLimit }} char.</div>
			<div v-if="type === 'number' && subElement.entry.minValue && subElement.entry.maxValue" class="number-hint">{{ $t('formMaintenance.between') }} {{ subElement.entry.minValue }} {{ $t('formMaintenance.and') }} {{ subElement.entry.maxValue }}</div>
		</template>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useFormMaintenanceStore } from '@/store/form-maintenance'
import UiCheckbox from '@/components/ui/ui-checkbox.vue'
import isEmpty from 'lodash.isempty'

export default {
	name: 'form-maintenance-text-input',
	data() {
		return {
			checked: false,
			canChange: true,
		}
	},
	props: {
		subElement: { default: null }
	},
	computed: {
		...mapState(useFormMaintenanceStore, [ 'subElementsValues' ]),
		type() {
			if (this.subElement.area) return 'textarea'
			if (this.subElement.entry.tags.includes('number')) return 'number'
			// if (this.subElement.entry.__typename === 'ContentFormTextInputBaseQuery') return 'number'
			return 'text'
		},
		hasCheckbox() {
			return this.subElement.entry.tags.includes('check_box')
		},
		subElementValue: {
			get() { return this.subElementsValues.find(v => v.id === this.subElement.id)?.value },
			set(value) {
				this.subElementsValues.find(v => v.id === this.subElement.id).value = value
				this.updateCheckbox()
			},
		}
	},
	watch: {
		checked(checked) {
			if (checked) {
				if (this.canChange) this.subElementValue = ''
				this.$nextTick(() => { this.$refs?.input?.focus() })
			} else {
				this.subElementValue = null
			}
			this.canChange = true
		}
	},
	methods: {
		displayInput(subElement) {
			const value = this.subElementsValues.find(v => v.id === subElement.id)?.value
			if (!isEmpty(value)) {
				this.canChange = false
				this.checked = true
				return true
			}

			if (!this.hasCheckbox) return true

			return this.checked
		},
		updateCheckbox() {
			// this.checked = this.subElementValue?.length > 0
		}
	},
	created() {
		this.updateCheckbox()
	},
	components: { UiCheckbox }
}
</script>

<style lang="stylus" scoped>

.form-maintenance-text-input
	margin 0 0 16px 0
	&.has-checkbox
		.input
			margin-left 28px
			width calc(100% - 28px)
	> .ui-checkbox
		margin 0 0 8px 0
	> .label
		display block
		margin 0 0 4px 0
	.input
		display block
		width 100%
		border 1px solid #dadddd
		background #fff
		padding 8px 12px
		outline none
		font inherit
		color inherit
		&:focus
			border-color primary
			box-shadow 0 0 0 1px primary
	textarea.input
		resize none
	.character-limit
		position absolute
		right 4px
		bottom 2px
		font-size 1.2rem
		color #467464
		pointer-events none
	.number-hint
		width 100%
		margin 2px 0 0 0
		font-size 1.4rem
		color #999
		text-align right


</style>
