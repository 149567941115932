import { engage } from '@/engage'
import { useAppStore } from '@/store/app'
import { i18n } from '@/js/i18n'

export const error = ({ error }) => {
	let text = ''

	if (error?.vars?.query) {
		text = i18n.global.t('errors.errorRequestText', { queryName: error.vars.query })
	} else if (error?.code && error.code <= 1000) {
		if (error?.message) {
			text = error.message
		} else if (error?.messages) {
			text = error.messages
				.map(m =>
					m.message === 'user disabled' ? i18n.global.t('errors.errorUserDisabled') :
					m.message === 'Invalid credentials' || m.message === 'Informations invalides.' ? i18n.global.t('errors.errorInvalidCredentials') :
					m.message
				)
				.join('.<br>')
		} /*else {
			text = i18n.global.t('errors.errorDefaultText')
		}*/
	} /*else {
		text = i18n.global.t('errors.errorDefaultText')
	}*/

	
	if (text && text !== 'Unauthenticated') {
		console.error('[ REQUEST ERROR ]', error)
		if (process.env.VUE_APP_ENV !== 'production') {
			useAppStore().addNotification({ status: 'error', title: i18n.global.t('errors.errorRequestTitle') /*+ (error?.code ? " n°" + error.code : "")*/, text })
		}
	}
}

export const get = async (name, variables = {}, headers = {}) => {
	const response = (await engage.boxGet(name, { variables, headers })).fold(error, (x) => x)
	if (response && response.code) error({ error: response })
	return response
}

export const post = async (name, variables = {}, headers = {}) => {
	const response = (await engage.boxPost(name, { variables, headers })).fold(error, (x) => x)
	if (response && response.code) error({ error: response })
	return response
}

engage.setError(error)
