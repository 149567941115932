import { get, post } from '@/requests/request'

export const getMainSection = async () => {
	const sections = await get('clientSectionBy', { slug: 'main' })
	if (sections?.length) return sections[0]
	return null
}

export const getLoginSection = async () => {
	const sections = await get('clientSectionBy', { slug: 'login' })
	if (sections?.length) return sections[0]
	return null
}

// export const switchTo = async ({ level }) => {
// 	return await post('switchTo', { level })
// }

export const switchAlternative = async ({ userId, alternative }) => {
	return await post('switchAlternative', { userId, alternative })
}