<template>
	<div v-if="page" class="page-archive">

		<archive-form-row v-for="(subElement, index) in page.archive.subElements" :key="index" :subElement="subElement" :index="index" />

	</div>
</template>

<script>
import ArchiveFormRow from '@/components/notepad/archives/archive-form-row'

export default {
	name: 'page-archive',
	props: {
		page: { default: null }
	},
	components: { ArchiveFormRow }
}
</script>

<style lang="stylus" scoped>

.page-archive
	padding 16px
	&:before
		content ''
		position absolute
		left 72px
		top 0
		bottom 0
		height 100%
		width 1px
		background-color #FBEEE2
	.archive-form-row
		margin 0 0 16px 0
	
</style>
