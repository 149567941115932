import { medias } from '@affordancestudio/engage-game'
import { post } from '@/requests/request'

export const loadMedias = async () => {
	console.info('=== INIT DISTANT MEDIAS ===')
	medias.initDB({ dbName: 'vireo', version: 1 })
	await medias.load()
}

export const getMedia = ({ id }) => {
	return medias.get({ id })?.base64
}

export const getMediasByTags = ({ tags }) => {
	return medias.findAllByTags({ tags })
}

export const getMediaUrl = async ({ id }) => {
	return await medias.getMediaUrl({ id })
}

export const openFile = async ({ id }) => {
	return await post('openFile', { clientFileId: id })
}
