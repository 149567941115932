<template>
	<div v-if="subElement?.entry" class="form-maintenance-bubble" :class="[ { decal, 'is-avatar': isAvatar, 'is-bubble': isBubble, 'is-label': isLabel } ]">

		<div v-if="isBubble" class="talk" v-bg="'form-maintenance/talk.svg'"></div>
		<div class="bubble" v-html="subElement.entry.html"></div>
		<div v-if="isAvatar" class="avatar" v-html="avatarIllustration"></div>

	</div>
</template>

<script>
import { getMediasByTags } from '@/requests/medias'

export default {
	name: 'form-maintenance-bubble',
	props: {
		subElement: { default: null },
		decal: { default: false }
	},
	computed: {
		isAvatar() { return this.subElement.entry.tags?.includes('avatar') },
		isBubble() { return this.subElement.entry.tags?.includes('bubble') },
		isLabel() { return this.subElement.entry.tags?.includes('label') },
		avatarIllustration() {
			const medias = getMediasByTags({ tags: [ 'game-maintenance', 'character', 'avatar' ] })
			if (medias?.length) return medias[0]?.data
			return null
		},
	}
}
</script>

<style lang="stylus" scoped>

.form-maintenance-bubble
	margin 0 0 8px 0
	
	&.is-avatar
		&.decal
			.bubble
				margin-left -80px
			.avatar
				left -72px
		.bubble
			padding-left 100px
			min-height 64px
		.avatar
			position absolute
			left 8px
			top -8px
			width 80px
			height 80px
			border-radius 50%
			::v-deep(svg)
				width 100%
				height 100%
			
	&.is-bubble
		&.decal
			.bubble
				margin-left -24px
			.talk
				left -48px
		.bubble
			margin-left 24px
		.talk
			position absolute
			left 0
			top 4px
			width 24px
			height 24px
			background center center no-repeat
			
	&.is-label
		.bubble
			font-style italic
			font-size 1.4rem
			padding 8px 16px
			background-color #EEF5F1
			font-weight 300
			
	.bubble
		padding 12px 16px
		background-color #DDEBE1
		border-radius 12px
		color #104544

</style>
