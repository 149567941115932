<template>
	<div class="modal-color-selection">
		<div class="bg" v-tap="close"></div>
		<div class="content">
			<div class="title">{{ $t('login.modalColorTitle') }}</div>

			<perfect-scrollbar class="scrollable" ref="scrollable">
				<div class="colors">
					<div v-for="color in colors" :key="color.id" class="color" :class="{ selected: selectedColor === color }" v-tap="() => selectColor(color)">
						<div class="round" :style="{ backgroundColor: color?.document?.hex }"></div>
						<div class="name">{{ color?.document?.title }}</div>
					</div>
				</div>
			</perfect-scrollbar>

			<div class="buttons">
				<ui-button size="big" color="orange" :disabled="!selectedColor" v-tap="submit">{{ $t('login.modalColorButton') }}</ui-button>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	name: 'modal-color-selection',
	data() {
		return {
			selectedColor: null
		}
	},
	props: {
		colors: { default: [] }
	},
	methods: {
		selectColor(color) {
			this.selectedColor = this.selectedColor !== color ? color : null
		},
		submit() {
			if (this.selectedColor) {
				this.$emit('select', { color: this.selectedColor })
				this.close()
			}
		},
		close() {
			this.$emit('close')
		},
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

#mobile, #tablet-vertical
	.modal-color-selection
		padding 0
		.content
			border-radius 0
			padding 16px
			.title
				font-size 1.6rem
			.buttons
				flex center center


.modal-color-selection
	absolute 0
	flex center center
	height 100%
	padding 80px 16px
	z-index 100000
	.bg
		absolute 0
		background-color alpha(#C58B62, 80%)
	.content
		width 100%
		max-width 960px
		height 100%
		padding 32px
		border-radius 8px
		background-color #fff
		.title
			height 64px
			flex center center
			color #4C6D6D
			text-transform uppercase
			border-bottom 2px solid #4C6D6D
			margin 0 0 16px 0
			text-align center
			font-weight 500
			font-size 2rem
		.scrollable
			overflow hidden
			margin 0 0 16px 0
			height 'calc(100% - %s)' % (64px + 16px + 64px)
			.colors
				// flex flex-start flex-start
				// flex-wrap wrap
				// display grid
				// grid-template-columns repeat(5, 1fr)
				flex flex-start center
				flex-wrap wrap

				// gap 16px
				// padding 8px
				.color
					width 16.6666666%
					min-width 114px
					// background-color #eee
					flex center center column
					padding 12px
					overflow hidden
					cursor pointer
					color #fff
					text-align center
					text-transform uppercase
					font-size 1.8rem
					font-weight 500
					&:hover
						.round
							transform scale(1.1)
							box-shadow 0 0 8px 2px alpha(#000, 35%)
					&.selected
						.round
							transform scale(1.1)
							box-shadow 0 0 0 2px #fff, 0 0 0 5px dark
						.name
							color dark
					.round
						display block
						width 80px
						height 80px
						margin 0 0 12px 0
						border-radius 50%
						flex-shrink 0
						box-shadow 0 0 8px alpha(#000, 25%)
						transition 0.25s easeOutBack
						overflow hidden
						&:before
							content ''
							position absolute
							left 0
							top 0
							bottom 0
							width 50%
							background-color alpha(#000, 5%)
					.name
						display block
						width 100%
						color #999
						height 40px

		.buttons
			height 48px
			flex flex-end flex-end
			.ui-button
				min-width 200px

</style>
