<template>
	<div class="modal-remove">

		<div class="texts">
			<p v-html="$t('adminDashboard.modals.remove.question1')"></p>
			<p v-html="$t('adminDashboard.modals.remove.question2')"></p>
		</div>

		<div class="items">
			<div v-for="item in items" :key="item.id" class="item">
				<p class="name" v-html="(item?.firstName || item?.lastName ? item?.firstName + ' ' + item?.lastName : false) || item?.profile?.school_name || item?.userName || item?.name || '-'"></p>
				<p v-if="item?.email" class="description" v-html="item?.email"></p>
				<transition>
					<div v-if="dones.includes(item.id)" class="result done" :title="$t('adminDashboard.modals.remove.resultSuccessTitle')" v-bg="'check-white.svg'"></div>
					<div v-else-if="errors.includes(item.id)" class="result error" :title="$t('adminDashboard.modals.remove.resultErrorTitle')" v-bg="'close.svg'"></div>
				</transition>
			</div>
		</div>

		<admin-form-results :items="items" :dones="dones" :errors="errors" />

		<div class="buttons">
			<ui-button color="dark-green" size="big" :block="true" v-tap="() => $emit('close')">{{ $t('adminDashboard.modals.global.close') }}</ui-button>
			<ui-button v-if="dones.length + errors.length !== items.length" color="red" size="big" :block="true" :loading="loading" :disabled="!submitable" v-tap="submit">{{ $t('adminDashboard.modals.remove.submit') }}</ui-button>
		</div>

		<div v-if="starting" class="starting"><ui-loader /></div>

		<transition appear>
			<div v-if="connectedUsers.length > items.length" class="side-content">
				<div class="popup">
					<div class="title">{{ $t('dashboard.modalDeleteUser.studentsToDeleteTitle', { n: connectedUsers.length, sera: connectedUsers.length > 1 ? 'seront' : 'sera', s: connectedUsers.length > 1 ? 's' : '' }) }}</div>
					<div class="users">
						<form class="form">
							<div class="icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
							</div>
							<input class="input" v-model="q" :placeholder="$t('dashboard.modalDeleteUser.search')" ref="search">
						</form>
						<div v-for="user in visibleConnectedUsers" :key="user.id" class="user">
							<span class="name">{{ user.name || '-' }}</span>
							<span class="email">{{ user.email }}</span>
						</div>
						<div v-if="nbAnonymous && !q" class="user anonymes">
							<span class="name">{{ $t('dashboard.modalDeleteUser.studentsToDeleteAnonyme1', { n: nbAnonymous, s: nbAnonymous > 1 ? 's' : '' }) }}</span>
							<span class="email">{{ $t('dashboard.modalDeleteUser.noUserData') }}</span>
						</div>
					</div>
				</div>
			</div>
		</transition>

	</div>
</template>

<script>
import { mapActions } from 'pinia'
import { useAdminDashboardStore } from '@/store/admin-dashboard'
import { deleteUser, deleteGroup, getAllConnectedUsers } from '@/requests/dashboard'
import { slugify } from '@affordancestudio/functions'
import AdminFormResults from '@/components/admin-dashboard/ui/admin-form-results.vue'

export default {
	data() {
		return {
			starting: true,
			connectedUsers: [],
			q: '',
			loading: false,
			dones: [],
			errors: [],
		}
	},
	props: {
		modal: { default: null }
	},
	computed: {
		submitable() {
			return !this.starting && this.modal?.items?.length
		},
		items() {
			return this.modal?.items || []
		},
		visibleConnectedUsers() {
			let users = this.connectedUsers
				.filter(u => u.email)
				.map(u => ({ name: (u.firstName && u.lastName) ? (u.firstName + ' ' + u.lastName) : u.userName, email: u.email }))
			if (this.q) {
				const q = slugify(this.q).replaceAll('-', '')
				users = users.filter(u => slugify(u.name + ' ' + u.email).replaceAll('-', '').indexOf(q) > -1)
			}
			return users
		},
		nbAnonymous() {
			return this.connectedUsers.filter(u => !u.email).length
		}
	},
	methods: {
		...mapActions(useAdminDashboardStore, [ 'logUpdate' ]),

		async submit() {
			if (this.submitable && !this.loading && this.items.length) {
				this.loading = true
				this.dones = []

				await Promise.all(this.items.map(async (item) => {
					if (await this.callRequest({ params: { id: item.id } })) {
						item.disabled = true
						this.dones.push(item.id)
					} else {
						this.errors.push(item.id)
					}
				}))

				this.logUpdate({ type: this.modal.type })

				this.loading = false
			}
		},

		async callRequest({ params }) {
			if ([ 'district', 'school', 'teacher', 'student' ].includes(this.modal.type)) {
				return await deleteUser({ userId: params.id })
			}
			if ([ 'group' ].includes(this.modal.type)) {
				return await deleteGroup({ id: params.id })
			}
			console.error('[modal-remove.vue] No defined request for type: ' + this.modal.type)
		}
	},
	async created() {
		this.starting = true
		this.connectedUsers = []
		await Promise.all(this.items.map(async (item) => {
			const response = await getAllConnectedUsers({ userId: item.id })
			if (response?.data?.length) this.connectedUsers = [ ...this.connectedUsers, ...response.data ]
		}))
		this.starting = false
	},
	components: { AdminFormResults }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.modal-remove
	text-align center
	.texts
		margin 0 0 32px 0
		> p
			margin 0 0 8px 0
			&:last-child
				margin-bottom 0
	.items
		flex flex-start flex-start column
		gap 8px
		margin 0 0 32px 0
		max-height 400px
		overflow auto
		border 2px solid darken(#D5DEDE, 10%)
		border-radius 8px
		padding 8px
		.item
			width 100%
			background-color #D5DEDE
			padding 16px
			border-radius 8px
			text-align left
			> .name
				font-weight 500
				text-transform uppercase
			> .description
				color #666
			.result
				position absolute
				right 0px
				top 0px
				width 32px
				height 32px
				border-radius 50%
				background #999 center center no-repeat
				box-shadow 0 0 0 4px #E7EEEE
				transition 0.15s easeInQuart
				&.done
					background-color #8BAE63
					background-size 24px !important
				&.error
					background-color #C17474
					background-size 16px !important
				&.v-enter-from
					transform scale(1.5)
					opacity 0

	.results
		flex center center
		gap 16px
		margin 0 0 32px 0
		.result
			flex center center column
			width 96px
			height 96px
			padding 8px 16px
			border-radius 50%
			background-color #999
			color #fff
			text-align center
			&.success
				background-color #8BAE63
			&.error
				background-color #C17474
			.label
				text-transform uppercase
				font-size 1.4rem
			.value
				font-size 2.4rem

	.buttons
		flex center center
		gap 16px

	> .starting
		absolute 0
		background-color #E7EEEE

	.side-content
		position fixed
		left calc(50% - 648px)
		top 0
		bottom 0
		width 340px
		flex center center
		pointer-events none

		&.v-enter-active
			transition 0.25s easeOutBack 0.25s
		&.v-enter-from
			transform translateX(32px)
			opacity 0
		.popup
			width 100%
			min-height 200px
			max-height 600px
			border-radius 8px
			padding 24px 0 0 0
			background-color #E7EEEE
			pointer-events auto
			&:before
				content ''
				position absolute
				left 100%
				top calc(50% - 16px)
				width 0
				height 0
				border-style solid
				border-width 16px 0 16px 16px
				border-color transparent transparent transparent #E7EEEE
			.title
				height 40px
				padding 0 16px 16px 16px
				// text-align center
				flex center flex-start
				line-height 20px
				font-weight 500
				text-transform uppercase
				border-bottom 2px solid #D5DEDE
				color #D67474
			.users
				max-height (600px - 64px)
				// background-color red
				overflow auto
				padding 16px
				.form
					height 32px
					margin 0 0 16px 0
					.icon
						position absolute
						left 0
						top 0
						width 32px
						height 32px
						flex center center
						color #aaa
						svg
							width 20px
							height 20px
						&:has(+ :focus)
							color inherit
					.input
						height 32px
						width 100%
						background none
						border none
						padding 0 0 0 32px
						font inherit
						color inherit
						border 2px solid #D5DEDE
						border-radius 8px
						outline none
						&:focus
							border-color primary
						&::-webkit-input-placeholder
							color #aaa
						&::-moz-placeholder
							color #aaa
						&:-ms-input-placeholder
							color #aaa
						&:-moz-placeholder
							color #aaa
				.user
					display block
					width 100%
					margin 0 0 8px 0
					background-color #D5DEDE
					border-radius 8px
					padding 8px 16px
					color #555
					overflow-wrap anywhere
					&:last-child
						margin-bottom 0
					&.anonymes
						margin-top 32px
						&:before
							content ''
							position absolute
							left 0
							right 0
							bottom calc(100% + 15px)
							height 2px
							background-color #ccc
					.name, .email
						display block
					.email
						font-size 1.4rem
						color #777


</style>
