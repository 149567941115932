<template>
	<div v-if="panel.group" class="side-panel-group">

		<div class="side primary green">
			<div class="main-title">{{ panel.group.name }}</div>
			<div class="stats">
				<div class="stat">
					<div class="label">{{ $tc('adminDashboard.panels.group.nbTeachers', totalTeachers) }}</div>
					<div class="value">{{ totalTeachers }}</div>
				</div>
				<div class="stat">
					<div class="label">{{ $tc('adminDashboard.panels.group.nbStudents', totalStudents) }}</div>
					<div class="value">{{ totalStudents }}</div>
				</div>
				<div class="stat" id="statAccessCode">
					<div class="label">{{ $t('adminDashboard.panels.group.accessCode') }}</div>
					<div class="value">{{ panel.group.accessCode }}</div>
				</div>
			</div>

			<div class="lists">

				<div v-if="features.teacher.includes('view')" class="box" :class="{ expanded: expandedTeachersBox }">
					<div class="box-title" v-tap="() => expandedTeachersBox = !expandedTeachersBox"><div class="icon" v-bg="'admin/chevron-down-white.svg'"></div>{{ $t('adminDashboard.panels.group.teachersList') }}</div>
					<div v-if="expandedTeachersBox" class="box-content">
						<list-items
							item-format="teacher"
							:request="{ name: 'teacherByGroup', params: { groupId: panel.group.id } }"
							format="table"
							:has-dark-background="true"
							:actions="[ 'view', 'edit', 'disable', 'remove-teachers-from-group' ]"
							:params="{ group: panel.group }"
							@loaded="(e) => totalTeachers = e.total"
							@change-selection="(items) => selectedTeachers = items"
						/>
					</div>
				</div>

				<div v-if="features.student.includes('view')" class="box" :class="{ expanded: expandedStudentsBox }">
					<div class="box-title" v-tap="() => expandedStudentsBox = !expandedStudentsBox"><div class="icon" v-bg="'admin/chevron-down-white.svg'"></div>{{ $t('adminDashboard.panels.group.studentsList') }}</div>
					<div v-if="expandedStudentsBox" class="box-content">
						<list-items
							item-format="student"
							format="table"
							:request="{ name: 'studentByGroup', params: { groupId: panel.group.id } }"
							:has-dark-background="true"
							:actions="[ 'disable', 'reset-password' ]"
							:params="{ group: panel.group }"
							@loaded="(e) => totalStudents = e.total"
							@change-selection="(items) => selectedStudents = items"
						/>
					</div>
				</div>
			</div>

		</div>

		<div class="side secondary white has-boxes">

			<div class="header">
				<div class="title">{{ $t('adminDashboard.actions') }}</div>
				<div class="actions">
					<ui-round-button v-if="features.group.includes('add-teachers-to-group')" :size="40" :disabled="selectedItems.length" v-tap="() => !selectedItems.length && addModal({ slug: 'add-teachers-to-group', group: panel.group })" :tooltip="$t('adminDashboard.tooltips.linkTeachersToGroup')" color="green" icon="admin/link-white.svg" id="linkTeachersToGroupButton" />
					
					<template v-if="selectedItemsType">
						<ui-round-button v-if="features.teacher.includes('reset-password') && selectedItemsType === 'teacher'" :size="40" :disabled="!selectedTeachers.length" v-tap="() => selectedTeachers.length && addModal({ slug: 'reset-password', type: 'teacher', items: selectedTeachers })" :tooltip="$t('adminDashboard.tooltips.resetSelectedTeachersPassword')" color="green" icon="admin/send-white.svg" />
						<ui-round-button v-else-if="features.student.includes('reset-password') && selectedItemsType === 'student'" :size="40" :disabled="!selectedStudents.length" v-tap="() => selectedStudents.length && addModal({ slug: 'reset-password', type: 'student', items: selectedStudents, group: panel.group })" :tooltip="$t('adminDashboard.tooltips.resetSelectedStudentsPassword')" color="green" icon="admin/send-white.svg" />

						<ui-round-button v-if="features.teacher.includes('disable') && selectedItemsType === 'teacher'" :size="40" :disabled="!selectedTeachers.length" v-tap="() => selectedTeachers.length && addModal({ slug: 'disable', type: 'teacher', items: selectedTeachers })" :tooltip="$t('adminDashboard.tooltips.disableSelectedTeachers')" tooltip-position="right" color="yellow" icon="admin/disable-green.svg" />
						<ui-round-button v-else-if="features.student.includes('disable') && selectedItemsType === 'student'" :size="40" :disabled="!selectedStudents.length" v-tap="() => selectedStudents.length && addModal({ slug: 'disable', type: 'student', items: selectedStudents })" :tooltip="$t('adminDashboard.tooltips.disableSelectedStudents')" tooltip-position="right" color="yellow" icon="admin/disable-green.svg" />
						
						<ui-round-button v-if="features.group.includes('remove-teachers-from-group') && selectedItemsType === 'teacher'" :size="40" :disabled="!selectedTeachers.length" v-tap="() => selectedTeachers.length && addModal({ slug: 'remove-teachers-from-group', items: selectedTeachers, group: panel.group })" :tooltip="$t('adminDashboard.tooltips.unlinkSelectedTeachersFromGroup')" tooltip-position="right" color="red" icon="admin/unlink-green.svg" />
					</template>
					<template v-else>
						<ui-round-button v-if="features.student.includes('reset-password')" :size="40" :disabled="true" :tooltip="$t('adminDashboard.tooltips.resetSelectedUsersPassword')" color="green" icon="admin/send-white.svg" />
						<ui-round-button v-if="features.student.includes('disable')" :size="40" :disabled="true" :tooltip="$t('adminDashboard.tooltips.disableSelectedUsersPassword')" tooltip-position="right" color="yellow" icon="admin/disable-white.svg" />
						<ui-round-button v-if="features.group.includes('remove-teachers-from-group')" :size="40" :disabled="true" :tooltip="$t('adminDashboard.tooltips.unlinkSelectedTeachersFromGroup')" tooltip-position="right" color="red" icon="admin/unlink-white.svg" />
					</template>
				</div>
			</div>

			<div class="content">

			</div>
		</div>

	</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useAppStore } from '@/store/app'
import { useAdminDashboardStore } from '@/store/admin-dashboard'
import { useOnboardingStore } from '@/store/onboarding'
import UiRoundButton from '@/components/ui/ui-round-button.vue'
import ListItems from '@/components/admin-dashboard/list/list-items.vue'

export default {
	data() {
		return {
			selectedTeachers: [],
			selectedStudents: [],
			expandedTeachersBox: true,
			expandedStudentsBox: true,
			totalTeachers: 0,
			totalStudents: 0,
		}
	},
	props: {
		panel: { default: null, required: true }
	},
	computed: {
		...mapState(useAppStore, [ 'role' ]),
		...mapState(useOnboardingStore, [ 'tutos' ]),
		...mapState(useAdminDashboardStore, [ 'features' ]),
		selectedItems() {
			if (this.selectedItemsType === 'teacher') return this.selectedTeachers
			if (this.selectedItemsType === 'student') return this.selectedStudents
			return []
		},
		selectedItemsType() {
			if (this.selectedTeachers.length && this.selectedStudents.length) return null
			if (this.selectedTeachers.length) return 'teacher'
			if (this.selectedStudents.length) return 'student'
			return null
		}
	},
	methods: {
		...mapActions(useOnboardingStore, [ 'startTuto' ]),
		...mapActions(useAdminDashboardStore, [ 'addModal' ]),
	},
	mounted() {
		if (this.role === 'school' && this.tutos.find(t => t?.slug === 'admin_school_with_groups')?.status !== 'completed') {
			this.startTuto({ slug: 'admin_school_panel_group' })
		}
		if (this.role === 'teacher' && this.tutos.find(t => t?.slug === 'admin_teacher_with_groups')?.status !== 'completed') {
			this.startTuto({ slug: 'admin_teacher_panel_group' })
		}
	},
	components: { UiRoundButton, ListItems }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.side-panel-group
	.side.primary
		> .main-title
			margin 0 !important
		.stats
			height 48px
			margin 0 0 16px 0
			flex center center
			gap 16px
			.stat
				height 48px
				padding 0 16px
				background-color #F3934D
				width 100%
				border-radius 8px
				flex center space-between
				font-size 1.8rem
		.lists
			height 'calc(100% - %s)' % (48px * 2 + 16px)
			overflow auto
			
			> .box
				margin 0 0 32px 0
				&:last-child
					margin-bottom 0
				&.expanded
					> .box-title .icon
						transform rotate(0)
					> .box-content
						display block
				> .box-title
					height 40px
					margin 0 0 16px 0
					flex center flex-start
					gap 8px
					font-size 2rem
					text-transform uppercase
					border-bottom 1px solid orange
					cursor pointer
					transition 0.15s easeInOutQuart
					border-radius 4px 4px 0 0
					&:hover
						background-color #255151
						padding 0 0 0 8px
					.icon
						width 24px
						height 24px
						background orange center center no-repeat
						background-size 16px !important
						border-radius 50%
						transform rotate(-180deg)
						transition transform 0.25s easeInOutQuart
				> .box-content
					display none

</style>