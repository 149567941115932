<template>
	<div class="tab"
		:class="[ tab.color, { 'allow-mobile': allowMobile } ]"
		:data-position="tab.position"
	>
		<div class="icon" :class="tab.name" v-bg="tab.name + '.svg'"></div>
		<div v-if="tab.tooltip" class="tooltip"><ui-text :text="tab.tooltip" /></div>
	</div>
</template>

<script>
export default {
	name: 'tab',
	props: {
		tab: { default: null },
		allowMobile: { default: true },
	},
}
</script>

<style lang="stylus" scoped>

#mobile, #app .app-content.small-height
	.tab.allow-mobile
		padding 8px 0 0 0
		height 56px
		border-radius 0
		border-bottom-left-radius 8px
		border-bottom-right-radius 8px
		left auto
		top calc(100% - 8px)
		for i in 0..10
			&[data-position=\"{i}\"]
				right (56px * (i - 1) + 16px)
		&:hover .tooltip
			display none

.tab
	height 48px
	width 48px
	border-top-right-radius 8px
	border-bottom-right-radius 8px
	background-color primary
	flex center center
	cursor pointer
	padding 0 0 0 8px
	transition 0.1s easeOutQuart
	&.red
		background-color red
	&.dark-green
		background-color #0F4444
	&.yellow
		background-color #F9AE19
	for i in 0..10
		&[data-position=\"{i}\"]
			top (56px * i + 88px)
	&:hover
		.tooltip
			visibility visible
			transform translate(4px, -50%)
			transition-delay 0.25s
			opacity 1
	.icon
		// position absolute
		// left calc(55% - 8px)
		// top calc(50% - 8px)
		width 32px
		height 32px
		background center center no-repeat
		&.home, &.calendar
			width 24px
			height 24px
		&.close
			width 16px
			height 16px
		&.back
			width 24px
			height 24px
			margin-right 4px
		&.plus
			width 24px
			height 24px
			filter brightness(5)
	.tooltip
		inline-flex center flex-start
		position absolute
		left calc(100% + 12px)
		top 50%
		width 250px
		transform translateY(-50%)
		opacity 0
		visibility hidden
		color #fff
		user-select none
		pointer-events none
		white-space nowrap
		transition 0.25s easeOutQuart
		// background-color alpha(#000, 50%)
		// padding 8px 16px
		// border-radius 4px
		&:before
			content ''
			position absolute
			left -7px
			top calc(50% - 8px)
			width 0
			height 0
			border-style solid
			border-width 8px 8px 8px 0
			border-color transparent #222 transparent transparent
		::v-deep(.ui-text)
			background-color #222
			padding 8px 16px
			border-radius 4px

</style>
