<template>
	<div class="ui-image" :class="{ placeholder: !imageLoaded, fullsize }" :style="{ cursor }">
		<ui-loader v-show="!imageLoaded" />
		<img :src="src" @load="imageLoaded = true" v-tap="($event) => displayModal($event.target.currentSrc)">
		<div class="loupe" v-bg="'notepad/loupe.svg'"></div>
	</div>
</template>

<script>
import { useAppStore } from '@/store/app'

export default {
	name: 'ui-image',
	data() {
		return {
			imageLoaded: false
		}
	},
	props: {
		src: { default: null },
		displayModalOnTap: { default: false },
		fullsize: { default: false }
	},
	computed: {
		cursor() {
			return !this.imageLoaded ? 'wait' : this.displayModalOnTap ? 'pointer' : 'default'
		}
	},
	methods: {
		displayModal(src) {
			if (this.displayModalOnTap) useAppStore().setModal({ src })
		}
	}
}
</script>

<style lang="stylus" scoped>

#mobile
	.ui-image
		max-height 150px
		&.fullsize
			max-height none

.ui-image
	margin 0 0 16px 0
	width 100%
	max-height 250px
	overflow hidden
	flex center center
	vertical-align middle
	box-shadow 0 0 0 1px #ddd
	&.fullsize
		max-height none
	&.placeholder
		min-height 200px
		background-color #eee
	&:hover .loupe
		transform scale(1.1)
	::v-deep(.ui-loader)
		opacity 0.5
	img
		display block
		width 100%
		height auto
	.loupe
		position absolute
		right 0
		bottom 0
		width 40px
		height 40px
		background #F9AE19 center center no-repeat
		background-size 24px auto !important
		border-radius 16px 0 0 0
		transform-origin bottom right
		transition 0.15s easeOutQuart
		opacity 1
		pointer-events none

</style>
