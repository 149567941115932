<template>
	<div class="modal-add-schools-to-district">

		<div class="texts">
			<p>{{ $t('adminDashboard.modals.addSchoolsToDistrict.intro') }} <strong>{{ modal.district?.profile?.district_name || "-" }}</strong>.</p>
		</div>

		<div class="schools">
			<list-items
				item-format="school"
				:request="{ name: 'invitedSchools', limit: 40 }"
				format="grid"
				nbCols="1"
				:actions="[ 'edit' ]"
				@change-selection="(items) => selectedSchools = items"
			/>
		</div>

		<div class="buttons">
			<ui-button color="dark-green" size="big" :block="true" v-tap="() => $emit('close')">{{ $t('adminDashboard.modals.global.close') }}</ui-button>
			<ui-button color="green" size="big" :block="true" :loading="loading" :disabled="!submitable" v-tap="submit">{{ $tc('adminDashboard.modals.addSchoolsToDistrict.submit', selectedSchools.length) }}</ui-button>
		</div>


	</div>
</template>

<script>
import { mapActions } from 'pinia'
import { useAdminDashboardStore } from '@/store/admin-dashboard'
import { addSchoolToDistrict } from '@/requests/dashboard'
import ListItems from '@/components/admin-dashboard/list/list-items.vue'
import UiButton from '@/components/ui/ui-button.vue'

export default {
	data() {
		return {
			loading: false,
			selectedSchools: []
		}
	},
	props: {
		modal: { default: null }
	},
	computed: {
		submitable() {
			return this.selectedSchools.length
		}
	},
	methods: {
		...mapActions(useAdminDashboardStore, [ 'logUpdate' ]),
		async submit() {
			if (this.submitable && !this.loading) {
				this.loading = true

				await Promise.all(this.selectedSchools.map(t => {
					return addSchoolToDistrict({ schoolId: t.id, districtId: this.modal.district.id }).catch(e => e)
				}))

				this.logUpdate({ type: 'school' })
				this.$emit('close')
				
				this.loading = false
			}
		}
	},
	components: { ListItems, UiButton }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.modal-add-schools-to-district
	.texts
		margin 0 0 32px 0
		text-align center
		> p
			margin 0 0 8px 0
			&:last-child
				margin-bottom 0
	.schools
		margin 0 0 32px 0
		max-height 400px
		overflow auto
		border 2px solid darken(#D5DEDE, 10%)
		border-radius 8px
		padding 16px
		::v-deep(.list-items)
			.list-items-content .items-wrap
				padding-bottom 0
				.items
					padding-bottom 0
					gap 8px
					.list-item
						box-shadow inset 0 0 0 2px #D5DEDE
						.content
							gap 2px
							.name
								border none
								padding-bottom 0
							.last-activity, .admin-state, .models
								display none
	.buttons
		flex center space-between
		gap 16px
		.ui-button
			min-width 200px
			line-height 1.2em

</style>