<template>
	<div class="trivia">

		<div class="bg"></div>

		<transition name="hint" mode="out-in">
			<div v-if="!characterVisible && bubbleText && currentTriviaStep" class="hint top" :key="currentTriviaStep.id">
				<div class="text"><ui-text :text="bubbleText" /></div>
			</div>
		</transition>

		<transition name="character" appear>
			<div v-show="characterVisible && bubbleText" class="character" key="character">
				<transition name="bubble" mode="out-in" appear>
					<div v-if="currentTriviaStep" class="bubble" :key="currentTriviaStep.id">
						<div class="text"><ui-text :text="bubbleText" /></div>
					</div>
				</transition>
				<div class="illustration" key="draw" v-bg="illustration"></div>
			</div>
		</transition>

		<transition name="answers" mode="out-in">
			<div v-if="answers.length" class="answers">
				<div v-for="answer in answers" class="answer" :class="{ selected: selectedAnswers.includes(answer) }" :key="answer.id" v-tap="() => selectAnswer(answer)">
					<ui-text :text="answer.contentTexts[0].html" />
				</div>
			</div>
		</transition>

		<div class="buttons">
			<div class="button next" :class="{ loading, disabled: question && !selectedAnswers.length }" v-tap="next">
				{{ $t('tuto.nextButton') }}
				<ui-loader v-show="loading" />
			</div>
		</div>

	</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useFormMaintenanceStore } from '@/store/form-maintenance'
import { sendAnswer, completeStep } from '@/requests/form-maintenance'
import { getMediasByTags } from '@/requests/medias'

export default {
	name: 'trivia',
	data() {
		return {
			selectedAnswers: [],
			loading: false
		}
	},
	computed: {
		...mapState(useFormMaintenanceStore, [ 'progression', 'currentTriviaStep' ]),
		characterVisible() {
			return ! this.currentTriviaStep?.questions?.length
		},
		bubbleText() {
			if (this.question?.contentTexts?.length) return this.question.contentTexts[0].html
			if (this.currentTriviaStep?.contentTexts?.length) return this.currentTriviaStep.contentTexts[0].html
			return "???"
		},
		illustration() {
			const medias = getMediasByTags({ tags: [ 'character', 'gif', 'game-maintenance' ] })
			if (medias?.length) return medias[0]?.base64
			return null
		},
		question() {
			return this.currentTriviaStep?.questions?.length ? this.currentTriviaStep.questions[0] : null
		},
		answers() {
			return this.question ? this.question.answers : []
		}
	},
	methods: {
		...mapActions(useFormMaintenanceStore, [ 'setTrivia', 'setProgression' ]),
		selectAnswer(answer) {
			if (this.selectedAnswers.includes(answer))
				this.selectedAnswers = this.selectedAnswers.filter(a => a !== answer)
			else
				this.selectedAnswers = [ ...this.selectedAnswers, answer ]
		},
		setTriviaByResponse(response) {
			if (response?.progression) {
				if (response?.gameCompleted) {
					this.setTrivia({ trivia: null })
					this.setProgression({ progression: response.progression })
				} else
					this.setTrivia({ trivia: response.progression })
			}
		},
		async next() {
			if (!this.loading) {
				this.loading = true
				if (this.question) {
					if (this.progression?.id && this.question?.id) {
						if (this.selectedAnswers.length > 0) {
							const response = await sendAnswer({ progression: this.progression, question: this.question, answers: this.selectedAnswers })
							this.setTriviaByResponse(response)
						}
					} else
						console.error('No progression or question found')
				} else {
					const response = await completeStep({ progression: this.progression })
					this.setTriviaByResponse(response)
				}
				this.loading = false
			}
		}
	},
}
</script>

<style lang="stylus" scoped>

#app > .low-perf
	.trivia
		.bg
			backdrop-filter none
			.main, .line
				animation none
		.highlight .hole, .hint, .answers, .buttons, .character, .character .bubble
			transition none

#tablet-vertical
	.trivia
		.character
			left -10%
		.answers
			width 310px
			left 45%
			bottom 200px
		.buttons
			left 32px
			right 32px
			bottom 32px

#mobile
	&.thin
		.trivia
			.buttons
				bottom 40px
	.trivia
		.character
			left -10%
			width 300px
			height 300px
			.bubble
				width 290px
				bottom calc(100% + 4px)
		.buttons
			bottom 16px
			left 16px
			right 16px
			.button
				height 40px
				font-size 1.6rem
				line-height @height
				white-space nowrap
				&.next
					padding-right 40px

.trivia
	fixed 0
	z-index 100000
	&.trivia-enter-active, &.trivia-leave-active
		transition 0.5s easeOutQuart
		.buttons
			transition-delay 0.75s
	&.trivia-enter-from
		opacity 0
		.buttons
			opacity 0
			transform translateY(50%)
	&.trivia-leave-to
		opacity 0

	.bg
		absolute 0
		background-color alpha(#1B2A24, 70%)
		backdrop-filter blur(8px)
	
	.hint
		position absolute
		left 50%
		bottom calc(60% + 16px)
		width calc(100% - 32px)
		max-width 500px
		font-size 2rem
		line-height 1.2em
		background-color #fff
		border-radius 12px
		padding 16px 24px
		transform translateX(-50%)
		// border 3px solid #ccc
		box-shadow -3px 3px alpha(#000, 50%)
		user-select none
		cursor default
		&.hint-enter-active, &.hint-leave-active
			transition 0.35s easeOutBack
		&.hint-enter-from
			transform translate(-50%, -16px)
			opacity 0
		&.hint-leave-to
			transform translate(-50%, 16px)
			opacity 0
		.text
			height 100%
			text-align center
			color dark
			::v-deep(strong)
				color primary
				font-weight 700
			::v-deep(img)
				height 100px
				width auto
				max-width 100%
	
	.character
		position absolute
		left 10%
		bottom 0
		width 600px
		height 600px
		font-size 2rem
		line-height 1.2em
		&.character-enter-active, &.character-leave-active
			transition 0.25s easeOutQuart
		&.character-enter-from, &.character-leave-to
			transform translate(-24px, 27%)
			opacity 0

		.bubble
			position absolute
			bottom calc(100% - 16px)
			left 40%
			width 80%
			max-height 242px
			background-color #fff
			border-radius 12px
			padding 24px
			transform-origin top left
			box-shadow -3px 3px alpha(#000, 50%)
			user-select none
			cursor default
			// animation bubble 4s easeOutBack infinite
			// transform-origin 30%
			&:before
				content ''
				position absolute
				left 0
				bottom -32px
				height 64px
				width 100%
				background url(../assets/img/bubble-bottom.svg) bottom left 40% no-repeat
				background-size contain
			&.bubble-enter-active, &.bubble-leave-active
				transition 0.35s easeOutBack
			&.bubble-enter-from
				transform rotate(4deg) translate3D(-8px, 8px, 0)
				opacity 0
			&.bubble-leave-to
				transform rotate(-4deg) translate3D(8px, -8px, 0)
				opacity 0
			.text
				height 100%
				text-align center
				color dark
				::v-deep(strong)
					color primary
					font-weight 700
				::v-deep(img)
					display block
					margin 8px auto
					max-height 100px
					width auto
					max-width 100%
		.illustration
			flex flex-end center
			width 100%
			height 100%
			// background-color alpha(red, 50%)
			transform-origin bottom center
			user-select none
			transform scale(1.2)
			&.flip
				transform none

	.answers
		position absolute
		left 50%
		bottom 100px
		top 40%
		width 100%
		max-width 500px
		padding 0 32px
		flex center center column
		transform translateX(-50%)
		transition 0.35s easeOutQuart
		&.answers-enter-active
			transition-delay 0.35s
		&.answers-enter-from
			transform translate(-50%, 24px)
			opacity 0
		&.answers-leave-to
			transform translate(-50%, -24px)
			opacity 0
		.answer
			width 100%
			min-height 48px
			padding 8px 16px
			margin 0 0 12px 0
			flex center center
			box-shadow -2px 2px alpha(#000, 25%)
			background-color #8EBABB
			text-align center
			color #fff
			border-radius 12px
			user-select none
			font-size 2rem
			cursor pointer
			transition 0.1s easeOutQuart
			&:last-child
				margin 0
			&:hover
				transform translateY(-2px)
				box-shadow -2px 4px alpha(#000, 25%)
			&.selected
				background-color #F3934D
				box-shadow inset -2px 2px alpha(#000, 25%)
				transform translateY(2px)
	.buttons
		position absolute
		left 32px
		right 32px
		bottom 32px
		transition 0.25s easeOutBack
		.button
			float right
			height 56px
			min-width 48px
			padding 0 48px 0 24px
			margin 0 0 0 16px
			background #01053B url(../assets/img/chevron-right.svg) right 8px center no-repeat
			background-size auto 70%
			box-shadow inset 0 0 0 4px #fff
			border-radius 48px
			text-transform uppercase
			font-size 2rem
			line-height @height
			text-align center
			color #fff
			cursor pointer
			&:hover
				transform translateY(-1px)
			&:active, &.loading
				transform translateY(2px)
			&.disabled
				cursor not-allowed
				background-color #bbb
				opacity 0.25
				&:hover, &:active
					transform none
			::v-deep(.ui-loader)
				absolute 4px
				border-radius 32px
				background-color #03073B



@keyframes next-button
	0%
		transform scale(1)
	100%
		transform scale(1.1)



@media (max-width: 1040px)
	.trivia
		.character
			left 0
			transform translate(-10%, 0%)

</style>
