<template>
	<div class="ui-notification" :class="[ notification.status, { closable } ]" v-tap="close">
		<div class="ui-notification-content">
			<div class="icon">
				<svg v-if="notification.status === 'error' || notification.status === 'warning'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
				<svg v-if="notification.status === 'success'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
				<svg v-if="notification.status === 'info'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
			</div>
			<div class="text">
				<p class="title" v-html="notification.title"></p>
				<p class="description" v-html="notification.text"></p>
			</div>
		</div>
	</div>
</template>

<script>
import { useAppStore } from '@/store/app'

export default {
	name: 'notification',
	props: {
		notification: { default: null },
		closable: { default: true },
	},
	methods: {
		close() {
			if (this.closable) useAppStore().removeNotification({ id: this.notification.id })
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


.ui-notification
	display block
	width 100%
	transition 0.25s easeInOutQuart
	&.notification-enter-from, &.notification-leave-to
		opacity 0
		transform translateX(-110%)
	&.notification-leave-active
		position absolute
	&.closable .ui-notification-content
		cursor pointer
	.ui-notification-content
		inline-flex flex-start flex-start
		max-width 400px
		padding 16px 24px 16px 16px
		background #999
		border-radius 4px
		font-size 1.4rem
		line-height 1.3
		color #333
		border 1px solid red
		.icon
			width 32px
			height 32px
			margin 0 12px 0 0
			border-radius 4px
			flex-shrink 0
			svg
				width 100%
				height 100%
		.text
			min-height 32px
			flex flex-start center column
			.title
				font-weight 600
				text-transform uppercase

	&.error .ui-notification-content
		background-color #FCEDEA
		border-color #F6C9C0
		color #ED4E2C

	&.success .ui-notification-content
		background-color #EBF7EE
		border-color #C9E9D1
		color darken(#EBF7EE, 60%)

	&.info .ui-notification-content
		background-color #E5EFFA
		border-color #BAD6F2
		color #006DE4

	&.warning .ui-notification-content
		background-color #FEF7EA
		border-color #FAE3BA
		color #EF9300

</style>
