<template>
	<form class="form form-edit" @submit.prevent="submit">

		<div v-for="field in fields" :key="field.name" class="row">
			<label class="label">{{ $t('adminDashboard.form.labels.' + field.name) }}</label>
			<input type="text" class="input" :disabled="disabledFields.includes(field.name)" v-model="field.value" :placeholder="$t('adminDashboard.form.placeholders.' + field.name)">
		</div>

		<div class="buttons">
			<ui-button type="submit" size="big" :block="true" color="dark-green" v-tap="() => $emit('close')">{{ $t('adminDashboard.form.buttons.close') }}</ui-button>
			<ui-button type="submit" size="big" :block="true" :disabled="!submitable" :loading="loading">{{ $t('adminDashboard.form.buttons.submit') }}</ui-button>
		</div>
	</form>
</template>

<script>
import { mapActions } from 'pinia'
import { useAppStore } from '@/store/app'
import { useAdminDashboardStore } from '@/store/admin-dashboard'
import { sendTeacherInvitation, createGroup } from '@/requests/dashboard'

export default {
	data() {
		return {
			loading: false,
			fields: [],
			disabledFields: [ 'district', 'school' ]
		}
	},
	props: {
		type: { default: "", required: true },
		modal: { default: null },
	},
	computed: {
		submitable() {
			return this.fields.filter(({ name, value }) => {
				if (!value) return false
				if (name === 'email') return value.match(/^([\w-.+]+@([\w-]+\.)+[\w-]{2,12})?$/)
				return true
			}).length === this.fields.length
		},
		invitorId() {
			const field = this.fields.find(f => this.disabledFields.includes(f.name))
			if (field) return this.modal?.[field.name]?.id || null
			return null
		}
	},
	methods: {
		...mapActions(useAppStore, [ 'addNotification' ]),
		...mapActions(useAdminDashboardStore, [ 'logUpdate' ]),

		async submit() {
			if (this.submitable && !this.loading) {
				this.loading = true

				// GROUP
				if (this.type === 'group') {
					const response = await createGroup({ input: {
						name: this.fields.find(f => f.name === 'name')?.value,
						schemaId: '60117ac7d582b800062eee91',
						data: '{}',
						ownerId: this.modal?.school?.id || null,
						groupAdminCalendarPrivilege:  [ 'r', 'w' ],
						memberCalendarPrivilege: [ 'r' ],
						giveAlternativesToMember: true
					} })
					this.handleResponse(response)
				}

				// DISTRICT, SCHOOL, TEACHER
				else {
					const role = { district: 'district_manager', school: 'group_owner', teacher: 'group_admin' }[this.type]
					if (role) {
						const email = this.fields.find(f => f.name === 'email')?.value
						if (email) {
							const response = await sendTeacherInvitation({ email, roles: [ role ], invitorId: this.invitorId })
							this.handleResponse(response)
						}
					} else
						console.warn("Aucune requête d'ajout spécifiée pour ce type: " + this.type)
				}

				this.loading = false
			}
		},

		resetFields() {
			this.fields.forEach(f => f.value = '')
		},

		handleResponse(response) {
			if (response && !response?.code && !response?.messages) {
				if (this.type === 'group') {
					this.addNotification({ status: 'success', title: this.$t('notifications.submitAddGroupSuccessTitle'), text: this.$t('notifications.submitAddGroupSuccessText') })
				} else
					this.addNotification({ status: 'success', title: this.$t('notifications.sendInvitationSuccessTitle'), text: this.$t('notifications.sendInvitationSuccessText') })

				this.resetFields()
				this.logUpdate({ type: this.type })
				this.$emit('close')
			}
		}
	},
	mounted() {
		this.$el.querySelector('input')?.focus()
	},
	created() {
		if (this.type) {

			const fields = {
				district: [ 'email' ],
				school: [ 'email', 'district' ],
				teacher: [ 'email', 'school' ],
				group: [ 'name', 'school' ],
				// student: [ 'firstName', 'lastName', 'email' ],
			}

			if (fields[this.type])
				this.fields = fields[this.type]
					.filter(name => !this.disabledFields.includes(name) || this.modal?.[name])
					.map(name => {
						let value = ''
						if (this.modal?.[name]) value = (this.modal[name]?.firstName || this.modal[name]?.lastName) ? this.modal[name]?.firstName + ' ' + this.modal[name]?.lastName : (this.modal[name]?.name || this.modal[name]?.profile?.school_name || this.modal[name]?.profile?.district_name || this.modal[name]?.userName || '-')
						return { name, value }
					})

		}
	},
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'



</style>
