<template>
	<div class="page-webinars-section">

		<div class="groups">
			<div v-for="group in groups" :key="group.slug" class="group" :class="[ group.slug, { expanded: expandedGroups.includes(group.slug) }]">
				<div class="group-title" v-tap="() => toggleGroup(group.slug)">
					<span class="text">{{ $t('webinars.' + group.slug + '_title') }}</span>
					<span class="nb">{{ group.sessions.length }}</span>
					<span class="arrow-down">
						<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
							<g transform="translate(-321 -304)">
								<path fill="currentColor" d="M-1186.824,210.926s5.487,5.662,7.176,7.988,1.688,1.8,4.644-2.008,4.763-5.456,4.824-6.5-.663-1.345-1.025-1.718-.823-.715-2.511,1.377-4.162,5.755-4.342,5.681a25.367,25.367,0,0,1-4.583-4.708c-1.328-2.018-2.354-2.991-3.611-1.641C-1187.3,210.525-1186.824,210.926-1186.824,210.926Z" transform="translate(1514.555 105.596)"/>
							</g>
						</svg>
					</span>
				</div>
				<div class="group-content">
					<div v-if="group.sessions.length" class="events">
						<div v-for="session in group.sessions" :key="session.id" class="event" :class="{ 'hidden-summary': !expandedSessions.includes(session.id) }">
							<div class="event-header">
								<div v-if="session.attributes.lang" class="lang">{{ $t('webinars.lang_' + session.attributes.lang) }}</div>
								<div class="name" v-html="session.event.attributes.title"></div>
								<div v-if="session.attributes.estimated_started_at" class="date">{{ $t('webinars.diffusion') }}: {{ $d(session.attributes.estimated_started_at * 1000, 'long') }}</div>
								<div v-if="session.attributes.duration" class="duration">{{ $t('webinars.duration') }}: {{ session.attributes.duration }}</div>
							</div>
							<div class="event-content">
								<div class="description" v-html="session.event.attributes.description"></div>
								<div v-if="session.event.attributes.description" class="buttons">
									<ui-button class="button" color="dark-green" v-tap="() => toggleSession(session)">
										{{ expandedSessions.includes(session.id) ? $t('webinars.hide_summary') : $t('webinars.show_summary') }}
									</ui-button>
								</div>
							</div>
							<div class="event-footer">
								<a class="signup" :href="session.attributes.room_link" v-tap="() => viewSession(session)" target="blank">{{ group.slug === 'past' ? $t('webinars.viewReplay') : $t('webinars.signup') }}</a>
							</div>
						</div>
					</div>
					<div v-else class="no-result">{{ $t('webinars.noResults') }}</div>
					<div class="hide-button" v-tap="() => toggleGroup(group.slug)">
						{{ $t('notepad.hide') }}
						<div class="icon">
							<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
								<g transform="translate(-321 -304)">
									<path fill="currentColor" d="M-1186.824,210.926s5.487,5.662,7.176,7.988,1.688,1.8,4.644-2.008,4.763-5.456,4.824-6.5-.663-1.345-1.025-1.718-.823-.715-2.511,1.377-4.162,5.755-4.342,5.681a25.367,25.367,0,0,1-4.583-4.708c-1.328-2.018-2.354-2.991-3.611-1.641C-1187.3,210.525-1186.824,210.926-1186.824,210.926Z" transform="translate(1514.555 105.596)"/>
								</g>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useAppStore } from '@/store/app'
import { useOnboardingStore } from '@/store/onboarding'
import { useWebinarStore } from '@/store/webinar'
import { localStorage } from '@affordancestudio/engage-game'

export default {
	name: 'page-webinars-section',
	data() {
		return {
			expandedGroups: [ 'today' ],
			expandedSessions: [],
		}
	},
	props: {
		page: { default: null }
	},
	computed: {
		...mapState(useAppStore, [ 'lang' ]),
		...mapState(useWebinarStore, [ 'webinars' ]),
		groups() {
			const now = new Date()
			const usNow = now.getFullYear() + '-' + String(now.getMonth() + 1).padStart(2, '0') + '-' + String(now.getDate()).padStart(2, '0')
			const groups = [
				{ slug: 'today', sessions: [] },
				{ slug: 'future', sessions: [] },
				{ slug: 'past', sessions: [] }
			]
			this.webinars.forEach(webinar => {
				let group = null
				if (webinar.usDate === usNow) group = groups[0]
				else if (webinar.usDate > usNow) group = groups[1]
				else if (webinar.usDate < usNow) group = groups[2]
				if (group) {
					group.sessions.push({
						event: { attributes: { title: webinar.title, description: webinar.description } },
						attributes: { estimated_started_at: webinar.date, room_link: webinar.room_link, duration: webinar.duration, lang: webinar.lang || null },
						meta: { checkpoint: webinar.checkpoint },
					})
				}
			})

			groups.forEach(group => {
				const signs = group.slug === 'past' ? [ -1, 1 ] : [ 1, -1 ]
				group.sessions.sort((a, b) => a.attributes.estimated_started_at > b.attributes.estimated_started_at ? signs[0] : signs[1])
			})

			return groups
		}
	},
	methods: {
		...mapActions(useWebinarStore, [ 'loadWebinars' ]),
		...mapActions(useOnboardingStore, [ 'completeCheckpoint' ]),
		toggleGroup(group) {
			this.expandedGroups = this.expandedGroups.includes(group)
				? this.expandedGroups.filter(g => g !== group)
				: [ ...this.expandedGroups, group ]
		},
		toggleSession(session) {
			this.expandedSessions = this.expandedSessions.includes(session.id)
				? this.expandedSessions.filter(g => g !== session.id)
				: [ ...this.expandedSessions, session.id ]
		},
		viewSession(session) {
			if (session?.meta?.checkpoint) {
				this.completeCheckpoint({ slug: session.meta.checkpoint })
			}
		}
	},
	async created() {
		await this.loadWebinars()

		localStorage.setItem('webinarsSeens', this.webinars.map((r, k) => k))
	}
}
</script>

<style lang="stylus" scoped>

.page-webinars-section
	padding 32px 16px
	font-size 1.8rem
	line-height 1.5em
	.groups
		.group
			margin 0 0 8px 0
			&.future
				.group-title
					background-color #C56825
					&:hover
						background-color darken(#C56825, 10%)
					.arrow-down
						color #C56825
				.group-content
					border-color #C56825
					.hide-button
						background #C56825
						.icon
							color #C56825
				.event
					.event-header
						background-color #E19156
						.lang
							color #D67833
					.event-content
						background-color #FFF2E9
						.buttons .button
							color #C56825
					.event-footer
						background-color #D67833
					&:not(.hidden-summary)
						.buttons .button
							background #AC5A1C
							color #fff
							&:hover
								background darken(#AC5A1C, 10%)
			&.past
				.group-title
					background-color #467464
					&:hover
						background-color darken(#467464, 10%)
					.arrow-down
						color #467464
				.group-content
					border-color #467464
					.hide-button
						background #467464
						.icon
							color #467464
				.event
					.event-header
						background-color #759084
					.event-content
						background-color lighten(#759084, 90%)
						.buttons .button
							color #467464
					.event-footer
						background-color #638275
					&:not(.hidden-summary)
						.buttons .button
							background #467464
							color #fff
							&:hover
								background darken(#467464, 10%)
			&.expanded
				margin 0 0 32px 0
				.group-title
					.arrow-down
						transform rotate(180deg)
				.group-content
					display block
			.group-title
				background-color #1F9192
				height 40px
				padding 0 16px
				color #fff
				line-height @height
				text-transform uppercase
				cursor pointer
				&:hover
					background-color darken(#1F9192, 10%)
				.nb
					position absolute
					right 40px
					top 0
					height 40px
					font-weight 500
				.arrow-down
					position absolute
					right 8px
					top 8px
					width 24px
					height 24px
					background center center no-repeat
					background-size contain
					transition 0.15s easeOutQuart
					color #1F9192
					background-color #fff
					border-radius 50%
					flex center center
			.group-content
				display none
				padding 16px
				border 2px solid #1F9192
				.no-result
					flex center center
					padding 16px 0
					text-transform uppercase
					color #999
					font-weight 500
					font-size 1.5rem
				.hide-button
					position absolute
					right 8px
					bottom -16px
					height 32px
					padding 0 38px 0 16px
					border-radius 32px
					font-size 1.4rem
					background-color #1F9192
					line-height @height
					color #fff
					text-transform uppercase
					cursor pointer
					.icon
						position absolute
						right 4px
						top 4px
						width 24px
						height 24px
						flex center center
						transform rotate(180deg)
						color primary
						background-color #fff
						border-radius 50%

.event
	margin 0 0 16px 0
	&.hidden-summary
		.event-header
			padding-bottom 24px
		.event-content
			padding 0
			.description
				display none
			.buttons
				position absolute
				left 0
				right 0
				bottom 0
				transform translateY(50%)
				z-index 1
				.button
					background-color #fff
					color #228384
					&:hover
						background-color #ddd
		.event-footer a
			padding-top 6px
	.event-header
		background-color #80B6B7
		padding 16px
		color #fff
		.lang
			display inline-block
			margin 0 0 4px 0
			padding 2px 8px
			border-radius 4px
			background-color #fff
			color #638275
			text-transform uppercase
			font-size 1.4rem
			font-weight 600
		.name
			width 100%
			text-transform uppercase
			padding 0 0 8px 0
			margin 0 0 8px 0
			border-bottom 2px solid alpha(#fff, 50%)
			font-weight 500
	.event-content
		padding 16px
		background #E8FCFC
		.description
			margin 0 0 16px 0
			font-size 1.6rem
			line-height 1.2em
			::v-deep(img)
				max-width 100% !important
				height auto !important
			::v-deep(p)
				margin 0 0 8px 0
				&:last-child
					margin-bottom 0
			::v-deep(ul)
				margin 0 0 8px 16px
				li
					margin 0 0 4px 0
					&:last-child
						margin-bottom 0
		.buttons
			flex center center
			.button
				border-radius 40px
	.event-footer
		height 56px
		padding 0 16px
		background-color #228384
		color #fff
		text-align right
		line-height @height
		a
			absolute 0
			padding 0 16px
			text-decoration none
			color inherit
			&:hover
				background-color alpha(#000, 10%)



</style>
