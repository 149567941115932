<template>
	<div v-if="subElement?.entry" class="form-maintenance-accept">

		<ui-checkbox :text="subElement.entry.label" v-model="subElementValue" />

	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useFormMaintenanceStore } from '@/store/form-maintenance'
import UiCheckbox from '@/components/ui/ui-checkbox.vue'

export default {
	name: 'form-maintenance-accept',
	data() {
		return {
			ready: false,
		}
	},
	props: {
		subElement: { default: null }
	},
	computed: {
		...mapState(useFormMaintenanceStore, [ 'subElementsValues' ]),
		subElementValue: {
			get() { return this.subElementsValues.find(v => v.id === this.subElement.id)?.value ? true : false },
			set(value) { this.subElementsValues.find(v => v.id === this.subElement.id).value = value ? true : false },
		}
	},
	components: { UiCheckbox }
}
</script>

<style lang="stylus" scoped>

.form-maintenance-accept
	margin 0 0 16px 0
	.element
		margin 0 0 16px 0
		width 100%

</style>
