<template>
	<div class="modal-edit-email">

		<div v-if="step === 1" class="texts">
			<p>{{ $t('adminDashboard.modals.editEmail.sentence1') }}</p>
			<br>
		</div>

		<form class="form" @submit.prevent="submit">

			<template v-if="step === 1">
				<div class="row">
					<label class="label">{{ $t('adminDashboard.form.labels.email') }}</label>
					<input type="text" class="input" v-model="email" :placeholder="$t('adminDashboard.form.placeholders.email')">
				</div>
			</template>
			<template v-if="step === 2">

				<div class="texts">
					<p class="note">{{ $t('adminDashboard.modals.editEmail.sentenceConfirm1') }}</p>
					<p><strong>{{ $t('adminDashboard.modals.editEmail.sentenceConfirm2') }}</strong></p>
				</div>

				<div class="changes">
					<div class="change from">
						<span class="label">{{ $t('adminDashboard.modals.editEmail.old') }}</span>
						<span class="value">{{ updatedUser?.email }}</span>
					</div>
					<div class="change to">
						<span class="label">{{ $t('adminDashboard.modals.editEmail.new') }}</span>
						<span class="value">{{ email }}</span>
					</div>
				</div>

				<div v-if="existingUser" class="warning existing">
					<p>{{ $t('adminDashboard.modals.editEmail.warning1') }}</p>
					<p>{{ $t('adminDashboard.modals.editEmail.warning2') }}</p>
					<div class="user-infos">
						<div v-if="existingUser.firstName" class="user-info">{{ $t('adminDashboard.modals.editEmail.firstname') }}: {{ existingUser.firstName }}</div>
						<div v-if="existingUser.lastName" class="user-info">{{ $t('adminDashboard.modals.editEmail.lastname') }}: {{ existingUser.lastName }}</div>
						<div v-if="existingUser.email" class="user-info">{{ $t('adminDashboard.modals.editEmail.email') }}: {{ existingUser.email }}</div>
						<div class="user-info">{{ $t('adminDashboard.modals.editEmail.accountType') }}: {{ $t('adminDashboard.modals.editEmail.type_' + getUserType(existingUser)) }}</div>
						<div v-if="existingUser.lastAuthorization" class="user-info">{{ $t('adminDashboard.modals.editEmail.lastActivity') }}: {{ existingUser.lastAuthorization }}</div>
						<div v-if="existingUser.onboarding" class="user-info"><admin-onboarding :onboarding="existingUser.onboarding" /></div>
					</div>
				</div>

				<div class="texts">
					<p><strong>{{ $t('adminDashboard.modals.editEmail.question') }}</strong></p>
				</div>
			</template>

			<div class="buttons">
				<ui-button v-if="step === 1" color="dark-green" size="big" :block="true" v-tap="() => $emit('close')">{{ $t('adminDashboard.modals.global.close') }}</ui-button>
				<ui-button v-if="step === 2" color="dark-green" size="big" :block="true" v-tap="reset">{{ $t('adminDashboard.modals.global.cancel') }}</ui-button>
				<ui-button type="submit" color="green" size="big" :loading="loading" :disabled="!submitable" :block="true">
					{{ $t('adminDashboard.modals.global.submit') }}
				</ui-button>
			</div>
		</form>

	</div>
</template>

<script>
import { mapActions } from 'pinia'
import { useAppStore } from '@/store/app'
import { useOnboardingStore } from '@/store/onboarding'
import { editEmail } from '@/requests/dashboard'
import AdminOnboarding from '@/components/admin-dashboard/ui/admin-onboarding.vue'

export default {
	data() {
		return {
			loading: false,
			email: '',
			token: null,
			existingUser: { email: this.email, firstName: 'Chris', lastName: 'Coyard' },
			updatedUser: { email: 'toto@gmail.com' },
		}
	},
	props: {
		modal: { default: null }
	},
	computed: {
		step() {
			return this.token ? 2 : 1
		},
		submitable() {
			return this.email !== this.modal.item?.email && this.email.match(/^([\w-.+]+@([\w-]+\.)+[\w-]{2,4})?$/)
		},
	},
	methods: {
		...mapActions(useAppStore, [ 'addNotification' ]),
		...mapActions(useOnboardingStore, [ 'resetAllTutos' ]),
		getUserType(user) {
			if (user.admin) return 'vireo'
			if (user.district_manager) return 'district'
			if (user.group_owner) return 'school'
			if (user.group_admin) return 'teacher'
			return 'student'
		},
		reset() {
			this.token = null
			this.existingUser = null
			this.updatedUser = null
			this.email = this.modal.item?.email || ''
		},
		async submit() {
			if (this.submitable && !this.loading) {
				this.loading = true

				const params = { userId: this.modal.item.id, email: this.email }
				if (this.token) params.token = this.token
				const response = await editEmail(params)
				if (response && !response?.code && !response?.messages) {
					if (!this.token) {
						this.token = response?.token
						this.existingUser = response?.existingUser
						this.updatedUser = response?.updatedUser
					} else {
						if (this.existingUser) {
							await this.resetAllTutos({ userId: this.existingUser.id })
						}
						this.addNotification({ status: 'success', title: this.$t('notifications.editEmailSuccessTitle'), text: this.$t('notifications.editEmailSuccessText') })
						this.reset()
						this.$emit('close')
					}
				}

				this.loading = false
			}
		},
	},
	created() {
		this.reset()
	},
	components: { AdminOnboarding }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.modal-edit-email
	.texts
		&.center
			text-align center
		> p
			margin 0 0 8px 0
			&:last-child
				margin-bottom 0
			&.note
				margin 0 0 16px 0
				background-color #c0d9e4
				padding 16px 24px
				border-radius 8px
				color darken(#c0d9e4, 60%)
				text-align left
	.changes
		flex center center column
		gap 0
		width 100%
		background-color darken(#E7EEEE, 10%)
		padding 16px 24px
		border-radius 8px
		.change
			width 100%
			min-height 32px
			flex center flex-start
			gap 8px
			&.to
				font-weight 500
			.label
				width 80px
				text-align right
			

	.warning
		width 100%
		padding 16px
		border-radius 8px
		background-color alpha(red, 10%)
		color darken(red, 25%)
		font-weight 500
		text-align center
		border 2px solid red
		.user-infos
			margin 8px 0 0 0
			padding 8px
			background-color alpha(red, 25%)
			// border 2px solid darken(red, 25%)
			border-radius 8px
			.user-info
				padding 2px 0

	.buttons
		flex center center
		gap 16px
	

</style>