import { defineStore } from 'pinia'
import { useOnboardingStore } from '@/store/onboarding'
import { useCalendarStore } from '@/store/calendar'
import { getRandomID } from '@affordancestudio/functions'
import { removeHeaders, getUser } from '@/requests/login'
import { localStorage } from '@affordancestudio/engage-game'
import { engage } from '@/engage'

export const useAppStore = defineStore({
	id: 'app',

	state: () => ({
		status: 'loading',
		lang: 'en',
		section: null,
		device: 'desktop',
		ratio: 1.777777,
		smallHeight: false,
		user: null,
		notifications: [],
		modal: null,
		modalSection: null,
		languageScreenVisible: false,
		modalLockedContent: false,
		modalGroupSelectionVisible: false,
		modalPotagerSelectionVisible: false,
		modalLoginVisible: false,
		settingsVisible: false,
		settings: {
			lowPerf: true
		},
		currentUserGroupId: null,
		pdfs: {
			fr: {
				privacyPolicy: "/privacy-policy-vireo-fr.pdf",
				termeOfUse: "/terms-of-use-vireo-fr.pdf",
			},
			en: {
				privacyPolicy: "/privacy-policy-vireo-en.pdf",
				termeOfUse: "/terms-of-use-vireo-en.pdf",
			}
		}
	}),

	getters: {
		isConnected() {
			return [ 'is_validated', 'is_confirmed', 'is_reset' ].includes(this.user?.onboarding)
		},
		role() {
			if (this.isConnected) {
				if (this.user.admin) return 'vireo'
				if (this.user.district_manager) return 'district'
				if (this.user.group_owner) return 'school'
				if (this.user.group_admin) return 'teacher'
				return 'student'
			}
			return false
		},
		currentPotager() {
			return this.user?.alternativeActive
		},
		popupNews() {
			const contentText = this.section?.contentTexts?.find(t => t?.tags?.includes('popup-news'))?.html
			if (contentText) {
				const splits = contentText.split('<p>===</p>')
				if (splits.length > 1) {
					return {
						slug: splits[0].replace('<p>', '').replace('</p>', '').replace('<br>', '').trim(),
						title: splits.length > 2 ? splits[1].replace('<p>', '').replace('</p>', '').replace('<br>', '').trim() : null,
						content: splits.length > 2 ? splits[2] : splits[1],
					}
				}
			}
			return null
		}
	},

	actions: {

		init({ section, user }) {
			this.section = section
			this.user = user
			const currentUserGroupId = localStorage.getItem('currentUserGroupId')
			if (currentUserGroupId) this.currentUserGroupId = currentUserGroupId
		},

		setRatio({ width, height }) {
			this.ratio = width / height
			this.smallHeight = height < 720

			if (width < 600) this.device = 'mobile'
			else if (width < 900) this.device = 'tablet-vertical'
			else if (width < 1200) this.device = 'tablet-horizontal'
			else this.device = 'desktop'
		},

		setModal(data) {
			this.modal = data
		},

		setModalSection(data) {
			this.modalSection = data
		},

		setModalGroupSelectionVisible(visible) {
			this.modalGroupSelectionVisible = visible
		},

		setModalPotagerSelectionVisible(visible) {
			this.modalPotagerSelectionVisible = visible
		},

		setSettingsVisible(settingsVisible) {
			this.settingsVisible = settingsVisible
		},

		async refreshUser() {
			this.setLoggedUser({ user: await getUser() })
		},

		setLoggedUser({ user }) {
			this.user = user
			// if (user?.groups?.length === 1) {
			// 	this.currentUserGroupId = user.groups[0].id
			// }
		},

		logout() {
			this.user = null
			this.currentUserGroupId = null
			useOnboardingStore().$reset()
			useCalendarStore().$reset()
			// localStorage.removeItem('refreshToken')
			localStorage.removeItem('currentUserGroupId')
			removeHeaders()
			engage.logOut()
		},



		addNotification({ text, status = 'info', title = '' }) {
			const id = getRandomID()
			this.notifications = [ ...this.notifications, { id, text, status, title } ]
			setTimeout(function() {
				useAppStore().removeNotification({ id })
			}, 5000)
		},


		removeNotification({ id }) {
			this.notifications = this.notifications.filter(n => n.id !== id)
		},

	},

})
