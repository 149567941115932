<template>
	<div class="modal-group-selection">
		<div class="bg"></div>
		<div class="content">

			<div class="header">
				<div class="title">{{ $t('groupSelection.title') }}</div>
				<p class="description">{{ $t('groupSelection.description') }}</p>
			</div>
			<div class="body">
				<div class="groups" :class="{ 'has-selection': selectedGroup }">
					<div v-for="group in groups" :key="group.id" class="group" v-tap="() => toggleGroup(group)" :class="{ selected: selectedGroup?.id === group.id }">
						{{ group.name }}
					</div>
				</div>
			</div>
			<div class="footer">
				<p class="description">{{ $t('groupSelection.footer') }}</p>
				<ui-button class="button" :disabled="!selectedGroup" size="big" color="orange" v-tap="select">{{ $t('groupSelection.button') }}</ui-button>
			</div>

		</div>
	</div>
</template>

<script>
import { useAppStore } from '@/store/app'
import { mapState, mapWritableState, mapActions } from 'pinia'
import { removeHeaders, addHeaders } from '@/requests/login'
import { localStorage } from '@affordancestudio/engage-game'

export default {
	name: 'modal-group-selection',
	data() {
		return {
			selectedGroup: null
		}
	},
	computed: {
		...mapState(useAppStore, [ 'user' ]),
		...mapWritableState(useAppStore, [ 'modalGroupSelectionVisible', 'currentUserGroupId' ]),
		groups() {
			return this.user?.groups
		}
	},
	methods: {
		toggleGroup(group) {
			this.selectedGroup = (group && group.id !== this.selectedGroup?.id) ? group : null
		},
		select() {
			if (this.selectedGroup?.id) {
				// localStorage.setItem('currentUserGroupId', this.selectedGroup.id)
				// removeHeaders()
				// addHeaders({ headers: { group: this.selectedGroup.id }})
				this.currentUserGroupId = this.selectedGroup.id
				this.close()
			}
		},
		close() {
			this.modalGroupSelectionVisible = false
		}
	},
	created() {
		if (this.currentUserGroupId) this.selectedGroup = this.groups.find(g => g.id === this.currentUserGroupId)
	}
}
</script>

<style lang="stylus" scoped>

#app > .low-perf
	.modal-group-selection
		transition none
		.bg, .content
			transition none
			backdrop-filter none

#mobile
	.modal-group-selection
		padding 16px

.modal-group-selection
	fixed 0
	flex center center
	z-index 100000
	&.modal-group-selection-enter-active
		transition 0.2s easeOutQuart
		.bg
			transition 0.2s easeOutQuart
		.content
			transition 0.2s easeOutBack
	&.modal-group-selection-enter-from
		.bg
			opacity 0
		.content
			opacity 0
			transform scale(0.95)

	&.modal-group-selection-leave-active
		transition 0.25s easeOutQuart
	&.modal-group-selection-leave-to
		opacity 0

	.bg
		background-color alpha(#446364, 70%)
		absolute 0
		backdrop-filter blur(8px)
		transition 0.2s easeOutQuart
	.content
		width calc(100% - 32px)
		max-width 480px
		height calc(100% - 64px)
		max-height 700px
		background-color #fff
		padding 16px
		text-align center
		border-radius 6px
		.header
			height 120px
			flex center center column
			gap 8px
			border-bottom 2px solid #4C6D6D
			text-align center
			.title
				font-size 2rem
				font-weight 500
				text-transform uppercase
				color #4C6D6D
			.title, .description
				width 100%
		.body
			height calc(100% - 240px)
			overflow auto
			.groups
				padding 16px 0
				&.has-selection
					.group:not(.selected)
						opacity 0.5
				.group
					height 48px
					padding 0 24px
					margin 0 0 8px 0
					flex center flex-start
					background-color #1F9192
					border-radius 8px
					cursor pointer
					color #fff
					text-transform uppercase
					text-align left
					&:last-child
						margin 0
					&:hover
						background-color darken(@background-color, 10%)
					&.selected
						background darken(@background-color, 10%) url('../assets/img/check-white.svg') right 8px center no-repeat
						background-size 40px 40px
		.footer
			height 120px
			flex center center column
			gap 8px
			border-top 2px solid #4C6D6D
			text-align center
			.description
				width 100%
				color #4C6D6D


</style>
