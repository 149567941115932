<template>
	<label class="ui-radio" :class="[ { 'no-text': !text, editable }, color ]">
		<input v-if="editable" type="radio" :checked="isChecked" :value="value" @change="$emit('update:modelValue', $event.target.value)">
		<span class="radio" :class="{ checked: isChecked }">
			<svg v-show="isChecked" xmlns="http://www.w3.org/2000/svg" width="28.396" height="31.861" viewBox="0 0 28.396 31.861">
				<path fill="none" stroke="currentColor" stroke-width="4px" d="M15030.176,5566.557l5.464,7.337,19.706-27.308" transform="translate(-15028.571 -5545.416)"/>
			</svg>
		</span>
		<span v-if="text" class="text"><ui-text :text="text" /></span>
	</label>
</template>

<script>

export default {
	name: 'ui-radio',
	props: {
		text: { default: '' },
		value: { default: null },
		modelValue: { default: false },
		color: { default: null },
		editable: { default: true }
	},
	computed: {
		isChecked() {
			return this.modelValue == this.value
		}
	}
}
</script>

<style lang="stylus" scoped>

.ui-radio
	flex flex-start flex-start
	&.editable
		cursor pointer
		&:hover
			.radio:after
				content ''
				absolute 0
				background-color alpha(#000, 10%)
	&.no-text
		inline-flex flex-start flex-start
		width 20px
		.radio
			margin 0
	&.white
		.radio
			border-color #fff
			> svg
				color #fff
	> input
		position absolute
		left -16px
		top 0
		opacity 0.5
		display none
	.radio
		display inline-block
		width 20px
		height 20px
		margin 0 8px 0 0
		border 2px solid dark
		transition 0.1s easeOutQuart
		flex-shrink 0
		border-radius 50%
		> svg
			position absolute
			left 0
			bottom 2px
			width 24px
			height 24px
			color primary
	.text
		user-select none



</style>
