<template>
	<div class="list-item" :class="'format-' + format">

		<div class="left" :class="{ checked }" v-tap="() => $emit('check', this.item)">
			<div class="checkbox">
				<div class="icon" v-bg="'check-white.svg'"></div>
			</div>
		</div>
		<div class="content">
			<div v-if="name" class="name" v-html="name"></div>
			<div v-if="email" class="email" v-html="email"></div>

			<!-- TYPE USER -->
			<template v-if="lastActivity/* && [ 'teacher', 'student' ].includes(type)*/">
				<div class="last-activity"><span>{{ $t('adminDashboard.listItems.item.lastActivity') }}</span> <span>{{ lastActivity }}</span></div>
			</template>

			<!-- TYPE GROUP -->
			<template v-if="type === 'group'">
				<div class="last-activity"><span>{{ $t('adminDashboard.listItems.item.nbStudents') }}</span> <span>{{ nbStudents }}</span></div>
				<div class="last-activity"><span>{{ $t('adminDashboard.listItems.item.nbTeachers') }}</span> <span>{{ nbTeachers }}</span></div>
				<div class="last-activity" id="listItemCodeGroup"><span>{{ $t('adminDashboard.listItems.item.accessCode') }}</span> <span>{{ codeGroup }}</span></div>
			</template>

			<!-- TYPE SCHOOL -->
			<template v-if="type === 'school'">
				<div v-if="models !== null" class="models">
					{{ $t('dashboard.models') }}:
					<template v-if="models.length">
						<span v-for="model in models" :key="model" class="model">{{ model }}</span>
					</template>
					<template v-else>{{ $t('dashboard.noModels').toLowerCase() }}</template>
				</div>
			</template>

			<div v-if="item.onboarding" class="onboarding"><admin-onboarding :onboarding="item.onboarding" /></div>
			<admin-state v-if="state" :state="state" />
		</div>
		<div class="right">
			<div v-if="features[type].includes('view') && visibleActions.includes('view')" class="action" v-bg="'admin/eye-white.svg'" v-tap="showDetail"><span class="tooltip">{{ $t('adminDashboard.listItems.item.showDetail') }}</span></div>
			<div v-if="features[type].includes('edit') && visibleActions.includes('edit')" class="action" v-bg="'edit.svg'" v-tap="edit"><span class="tooltip">{{ $t('adminDashboard.listItems.item.edit') }}</span></div>
			<div v-if="features[type].includes('reset-password') && visibleActions.includes('reset-password')" class="action" v-bg="'send.svg'" v-tap="resetPassword"><span class="tooltip">{{ $t('adminDashboard.listItems.item.resetPassword') }}</span></div>
			<div v-if="features[type].includes('disable') && visibleActions.includes('disable')" class="action disable" v-bg="'admin/disable-white.svg'" v-tap="disable"><span class="tooltip">{{ $t('adminDashboard.listItems.item.disable') }}</span></div>
			<div v-if="features[type].includes('remove') && visibleActions.includes('delete')" class="action delete" v-bg="'admin/trash-white.svg'" v-tap="remove"><span class="tooltip">{{ $t('adminDashboard.listItems.item.remove') }}</span></div>
			<div v-if="type === 'teacher' && features.group.includes('remove-teachers-from-group') && visibleActions.includes('remove-teachers-from-group')" class="action delete" v-bg="'admin/unlink-white.svg'" v-tap="removeFromGroup"><span class="tooltip">{{ $t('adminDashboard.listItems.item.removeFromGroup') }}</span></div>
		</div>

	</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useAppStore } from '@/store/app'
import { useAdminDashboardStore } from '@/store/admin-dashboard'
import AdminState from '@/components/admin-dashboard/ui/admin-state.vue'
import AdminOnboarding from '@/components/admin-dashboard/ui/admin-onboarding.vue'

export default {
	props: {
		type: { default: '', required: true },
		item: { default: null, required: true },
		format: { default: 'grid' },
		checked: { default: false },
		actions: { default: [] },
		params: { default: {} },
	},
	computed: {
		...mapState(useAppStore, [ 'lang' ]),
		...mapState(useAdminDashboardStore, [ 'features' ]),
		visibleActions() {
			if (this.actions === null) return []
			if (this.actions.length) return this.actions
			if (this.type === 'district') return [ 'edit', 'view', 'disable', 'reset-password' ]
			if (this.type === 'school') return [ 'edit', 'view', 'send', 'disable', 'reset-password', /*'delete'*/ ]
			if (this.type === 'teacher') return [ 'edit', 'view', 'disable', 'reset-password', /*'delete'*/ ]
			if (this.type === 'student') return [ 'view', 'disable', 'reset-password' ]
			if (this.type === 'group') return [ 'edit', 'view', 'delete' ]
			return []
		},

		name() {
			if (this.item?.firstName || this.item?.lastName) return this.item?.firstName + ' ' + this.item?.lastName
			return this.item?.name || this.item?.profile?.school_name || this.item?.profile?.district_name || this.item?.userName || '-'
		},
		email() {
			return this.item?.email || this.item?.userName || null
		},
		state() {
			if (this.item.disabled === undefined) return null
			return this.item.disabled ? 'disabled' : 'enabled'
		},
		lastActivity() {
			const strDate = this.item?.lastAuthorization || null
			if (strDate) {
				const date = new Date(strDate)
				if (date instanceof Date && !isNaN(date)) return date.toLocaleDateString(this.lang === 'fr' ? 'fr-CA' : 'en-US', { year: 'numeric', month: 'long', day: 'numeric' })
			}
			return null
		},

		// SCHOOL
		models() {
			return this.item.alternatives?.length ? [ ...this.item.alternatives ].sort() : []
		},

		// GROUP
		nbTeachers() {
			return this.item.adminCount || 0
		},
		nbStudents() {
			return this.item.validMembershipCount || 0
		},
		codeGroup() {
			return this.item.accessCode || '-'
		}

	},
	methods: {
		...mapActions(useAdminDashboardStore, [ 'addPanel', 'addModal' ]),
		showDetail() {
			let params = { slug: this.type }
			params[this.type] = this.item
			this.addPanel(params)
		},
		toggleChecked() {
			this.$emit('check', !this.checked)
		},

		edit() {
			this.addModal({ slug: 'edit', type: this.type, item: this.item })
		},
		removeFromGroup() {
			this.addModal({ slug: 'remove-teachers-from-group', ...this.params, items: [ this.item ] })
		},

		resetPassword() {
			this.addModal({ slug: 'reset-password', ...this.params, type: this.type, items: [ this.item ] })
		},
		disable() {
			this.addModal({ slug: 'disable', type: this.type, items: [ this.item ] })
		},
		remove() {
			this.addModal({ slug: 'remove', type: this.type, items: [ this.item ] })
		},
	},
	components: { AdminState, AdminOnboarding }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.list-item
	background-color #E7EEEE
	color #667D7E
	flex center center
	> *
		height 100%

	&.format-table
		background-color #0F4444
		color #fff
		&:nth-child(odd)
			background-color #255151
			.left
				background-color @background-color
		.left
			background-color @background-color
			flex center flex-start
			flex-shrink 0
		.content
			flex center flex-start
			width 100%
			flex-shrink 1
			> *
				padding 0 8px
				flex-shrink 1
			.name
				padding 0
				border none
				width 25%
				flex-shrink 0
			.email
				width 35%
				flex-shrink 0
			.last-activity
				width 124px
				flex-shrink 0
				opacity 0.5
				font-size 1.4rem
				line-height 1em
				flex center flex-start column
				gap 4px
				> span
					display block
					width 100%
					white-space nowrap
			.admin-state
				width auto
			.onboarding
				display none
		.right
			width auto
			flex center flex-end
			flex-shrink 0
			background none
			gap 8px
			padding 0 8px 0 0
			.action
				background-color alpha(#fff, 25%)
				border-radius 50%
				&:after
					display none
				&:hover
					background-color alpha(#fff, 15%)
				&.disable
					background-color darken(#D6BC65, 10%)
					&:hover
						background-color darken(#D6BC65, 20%)
				&.delete
					background-color red

	.left
		width 40px
		flex-shrink 0
		padding 8px
		background-color #D5DEDE
		flex center flex-start column
		cursor pointer
		&:not(.checked):hover .checkbox
			border-color #667D7E
		&.checked
			background-color #0F4444
			.checkbox .icon
				display block
		.checkbox
			width 24px
			height 24px
			border 2px solid #fff
			border-radius 4px
			&.checked
				background-color red
			.icon
				absolute 0
				display none
	.content
		width calc(100% - 80px)
		flex center flex-start column
		gap 8px
		padding 12px 16px
		> *
			width 100%
		.name
			text-transform uppercase
			padding-bottom 4px
			font-weight 500
			border-bottom 1px solid #9AAAAB
		.last-activity
			flex center space-between
		.onboarding
			flex center space-between
		.models
			flex flex-start flex-start
			flex-wrap wrap
			gap 4px
			.model
				height 24px
				padding 2px 8px 0 8px
				border-radius 4px
				background-color #73AEB1
				flex center center
				color #fff
				text-transform uppercase
				transform translateY(-2px)
	.right
		width 40px
		flex-shrink 0
		background-color #667D7E
		.action
			height @width
			width @width
			background center center no-repeat
			background-size auto 20px !important
			cursor pointer
			&:last-child:after
				display none

			&:hover
				background-color darken(#667D7E, 20%)
				.tooltip
					opacity 1
					visibility visible
					transform translate(0, -50%)
					transition-delay 0.35s
			&.delete:hover
				background-color #C17474
			&:after
				content ''
				position absolute
				left 8px
				right 8px
				bottom 0
				height 1px
				background-color #fff
			.tooltip
				position absolute
				right calc(100% + 8px)
				top 50%
				transform translate(8px, -50%)
				transition 0.1s easeOutQuart
				padding 8px 12px
				border-radius 4px
				white-space nowrap
				font-size 1.4rem
				background-color #111
				color #fff
				pointer-events none
				opacity 0
				visibility hidden
				&:before
					content ''
					position absolute
					left calc(100% - 1px)
					width 0
					height 0
					border-style solid
					border-width 8px 0 8px 8px
					border-color transparent transparent transparent #111


</style>
