<template>
	<div v-if="dones.length || errors.length" class="admin-form-results">
		<div class="result">
			<div class="label">{{ $t('adminDashboard.modals.global.resultTotal') }}</div>
			<div class="value">{{ dones.length + errors.length }}/{{ items.length }}</div>
		</div>
		<div class="result success">
			<div class="label">{{ $t('adminDashboard.modals.global.resultSuccess') }}</div>
			<div class="value">{{ dones.length }}</div>
		</div>
		<div class="result error">
			<div class="label">{{ $t('adminDashboard.modals.global.resultErrors') }}</div>
			<div class="value">{{ errors.length }}</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		items: { default: null },
		dones: { default: null },
		errors: { default: null },
	},
	
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.admin-form-results
	flex center center
	gap 16px
	margin 0 0 32px 0
	.result
		flex center center column
		width 96px
		height 96px
		padding 8px 16px
		border-radius 50%
		background-color #999
		color #fff
		text-align center
		&.success
			background-color #8BAE63
		&.error
			background-color #C17474
		.label
			text-transform uppercase
			font-size 1.4rem
		.value
			font-size 2.4rem


</style>