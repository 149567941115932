<template>
	<div class="modal-news">
		<div class="bg" v-tap="close"></div>
		<div class="scroll">
			<div class="scroll-content">
				<div class="content">

					<div v-if="popupNews.title" class="title"><ui-text :text="popupNews.title" /></div>
					<div v-if="popupNews.content" class="description"><ui-text :text="popupNews.content" /></div>

					<div class="buttons">
						<ui-button class="button" size="big" :block="true" color="dark-green" v-tap="close">{{ $t('modalNews.close') }}</ui-button>
					</div>

				</div>
			</div>
		</div>

		<div class="close" :title="$t('settings.close')" v-tap="close">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
		</div>

	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useAppStore } from '@/store/app'
import { localStorage } from '@affordancestudio/engage-game'
import UiText from '@/components/ui/ui-text.vue'

export default {
	name: 'modal-news',
	computed: {
		...mapState(useAppStore, [ 'lang', 'popupNews' ]),
	},
	methods: {
		close() {
			localStorage.setItem(this.popupNews.slug, true)
			this.$emit('close', true)
		},
	},
	components: { UiText }
}
</script>

<style lang="stylus" scoped>

#app > .low-perf
	.modal-news
		transition none
		.bg, .content
			transition none
			backdrop-filter none

#mobile
	.modal-news
		.scroll-content
			padding 15% 0
		.content
			.concours
				flex-direction column
				gap 16px
				margin 0 0 16px 0
				img
					width 100%


.modal-news
	fixed 0
	flex center center
	z-index 100000
	&.modal-news-enter-active
		transition 0.2s easeOutQuart
		.bg
			transition 0.2s easeOutQuart
		.content
			transition 0.2s easeOutBack
	&.modal-news-enter-from
		.bg
			opacity 0
		.content
			opacity 0
			transform scale(0.95)

	&.modal-news-leave-active
		transition 0.25s easeOutQuart
	&.modal-news-leave-to
		opacity 0

	.bg
		background-color alpha(#446364, 70%)
		absolute 0
		backdrop-filter blur(8px)
		transition 0.2s easeOutQuart
	.scroll
		height 100%
		width 100%
		overflow auto
		.scroll-content
			min-height 100%
			width 100%
			padding 32px 0
			flex center center
	.content
		width calc(100% - 32px)
		max-width 580px
		background-color #fff
		padding 32px
		text-align center
		border-radius 6px
		.title
			margin 0 0 32px 0
			font-size 2.8rem
			color #748787
			text-transform uppercase
		.description
			margin 0 0 16px 0
			color #7D9697
			text-align left
			> ::v-deep(.ui-text)
				font-size 1.8rem
				line-height 1.4em
				strong
					font-weight 600
				p
					margin 0 0 8px 0
					a
						font inherit
						color inherit
						text-decoration underline
						&:hover
							text-decoration none
				img
					width 100%
					margin 0 0 8px 0
				ul, ol
					margin-left 20px
					li
						margin 0 0 8px 0

		.buttons
			.button
				width 100%
				background-color #7D9697
				&:hover
					background-color #748787
			.link-button
				display inline-block
				background-color #0D4444
				height 64px
				padding 0 48px
				border-radius 8px
				flex center center
				cursor pointer
				font inherit
				color #fff
				font-size 1.8rem
				line-height 1.1em
				font-weight 500
				text-decoration none
				user-select none
				&:hover
					background-color lighten(#0D4444, 5%)
				&:active
					background-color lighten(#0D4444, 0%)
	.close
		position absolute
		right 32px
		top 32px
		width 64px
		height 64px
		background-color #fff
		color dark
		border-radius 50%
		box-shadow 0 0 8px 2px darken(#446364, 50%)
		flex center center
		cursor pointer
		transition 0.1s easeOutQuart
		&:hover
			transform scale(1.1)
		&:active
			transform scale(0.9)
		svg
			width 48px
			height 48px


</style>
