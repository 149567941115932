import { createI18n } from 'vue-i18n'
import messages from '@/texts'
import { localStorage } from '@affordancestudio/engage-game'

const lang = localStorage.getItem('Language')

export const i18n = createI18n({
	locale: lang ? lang : 'en',
	fallbackLocale: 'en',
	messages,
	warnHtmlInMessage: 'off',
	silentTranslationWarn: true,
	silentFallbackWarn: true,
	datetimeFormats: {
		en: {
			short: {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric'
			},
			long: {
				year: 'numeric',
				month: 'short',
				day: 'numeric',
				weekday: 'short',
				hour: 'numeric',
				minute: 'numeric',
				hour12: true
			}
		},
		fr: {
			short: {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric'
			},
			long: {
				weekday: 'long',
				day: '2-digit',
				month: 'long',
				year: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
				hour12: false
			}
		}
	}
	
})
