<template>
	<div class="page">
		<div v-if="pageContent" class="page-content" :key="page.id">

			<div v-if="pageContent?.document?.title" class="header">
				<p class="category"><ui-text :text="pageContent.category ? pageContent.category : ''" /></p>
				<p class="title"><ui-text :text="pageContent?.document?.title" /></p>
				<p class="subtitle"><ui-text :text="pageContent?.document?.subtitle" /></p>
				<p class="subtitle"><ui-text :text="pageContent?.document?.tooltip" /></p>
			</div>

			<div class="content" v-if="device === 'mobile'" :class="{ 'has-footer': pageFooter }" ref="scrollable" :key="'mobile-' + pageContent.id">
				<div class="scroll-content">
					<component :is="'page-' + pageType" :page="pageContent" :key="pageContent.id" :main-section="page" />
				</div>
			</div>

			<perfect-scrollbar v-else class="content go-to-top" :class="{ 'has-footer': pageFooter }" ref="scrollable" :key="'desktop-' + pageContent.id">
				<component :is="'page-' + pageType" :page="pageContent" :key="pageContent.id" :main-section="page" />
			</perfect-scrollbar>

			<transition name="scroll-hint" mode="out-in" appear>
				<div v-if="pageContent?.id && pageType === 'section'" v-show="isScrollable" class="scroll-hint" :key="pageContent.id" v-tap="scrollHint">
					<svg class="arrow" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>
					<svg class="arrow" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>
					<svg class="arrow" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>
				</div>
			</transition>

			<div v-if="pageFooter" class="footer">

				<div v-if="pageFooter === 'back-to-archives'" class="footer-content" :class="pageFooter">
					<ui-button icon="doodle-arrow-left.svg" icon-position="left" v-tap="close">
						<ui-text :text="$t('archives.backToArchives')"/>
					</ui-button>
				</div>

				<div v-else-if="pageFooter === 'multiple-pages'" class="footer-content multiple-pages">
					<div class="arrow left" v-bg="'yellow-arrow-left.svg'" :class="{ disabled: page.pageIndex <= 0 }" v-tap="prevPage"></div>
					<div class="arrow right" v-bg="'yellow-arrow-right.svg'" :class="{ disabled: page.pageIndex >= page.children.length - 1 }" v-tap="nextPage"></div>

					<div class="table-of-content" v-tap="() => sectionsListVisible = !sectionsListVisible">{{ $t('notepad.tableOfContent') }}</div>
					<div class="burger-button" :class="{ active: sectionsListVisible }" v-tap="() => sectionsListVisible = !sectionsListVisible">
						<svg viewBox="0 0 32 32" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg">
							<line x1="4" y1="8" x2="28" y2="8"></line>
							<line x1="4" y1="16" x2="28" y2="16"></line>
							<line x1="4" y1="24" x2="28" y2="24"></line>
						</svg>
					</div>
					<div class="pagination" v-tap="() => sectionsListVisible = !sectionsListVisible">{{ page.pageIndex + 1 }}/{{ page.children.length }}</div>
				</div>

				<div v-else-if="pageFooter === 'form-maintenance' && formStatus === 'ready'" class="footer-content multiple-pages">
					<div class="arrow left" v-bg="'yellow-arrow-left.svg'" :class="{ disabled: currentContainerIndex <= 0 }" v-tap="prevFormMaintenanceContainer"></div>
					<div class="arrow right" v-bg="nextableContainer ? 'blue-arrow-right.svg' : 'yellow-arrow-right.svg'" :class="{ disabled: nextableContainer && !isCurrentContainerValid }" v-tap="nextFormMaintenanceContainer">
						<ui-loader v-if="formMaintenanceLoading" />
					</div>
					<div class="pagination">{{ currentContainerIndex + 1 }}/{{ containers?.length }}</div>
				</div>

				<div v-else-if="pageFooter === 'form-maintenance' && formStatus === 'ended'" class="footer-content footer-form-maintenance-end">
					<div class="left"><ui-text :text="$t('notepad.archived')" /></div>
					<div class="right">
						<ui-button size="big" icon="back.svg" icon-position="right" v-tap="close">
							<ui-text :text="$t('notepad.backToGardenExperiments')" />
						</ui-button>
					</div>
				</div>

			</div>

		</div>

		<tab v-if="tab" :tab="tab" v-tap="setNewActivePage" />

		<transition name="footer-sections-list">
			<footer-sections-list v-if="sectionsListVisible" :page="page" @change="gotoPage" @close="() => sectionsListVisible = false" />
		</transition>
	</div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useAppStore } from '@/store/app'
import { useNotepadStore } from '@/store/notepad'
import { useFormMaintenanceStore } from '@/store/form-maintenance'
import { submitMaintenanceForm } from '@/requests/form-maintenance'
import Tab from '@/components/notepad/tab'
import PageSection from '@/components/notepad/pages/page-section'
import PageMainSection from '@/components/notepad/pages/page-main-section'
import PageArchivesSection from '@/components/notepad/pages/page-archives-section'
import PageGameListSection from '@/components/notepad/pages/page-game-list-section'
import PageFormMaintenance from '@/components/notepad/pages/page-form-maintenance.vue'
import FooterSectionsList from '@/components/notepad/parts/footer-sections-list.vue'
import PageArchive from '@/components/notepad/pages/page-archive.vue'
import PageDemoCompleted from '@/components/notepad/pages/page-demo-completed.vue'
import PageNoAccess from '@/components/notepad/pages/page-no-access.vue'
import PageWebinarsSection from '@/components/notepad/pages/page-webinars-section.vue'

export default {
	name: 'page',
	data() {
		return {
			formMaintenanceLoading: false,
			sectionsListVisible: false,
			isScrollable: false,
			isCurrentContainerValid: false,
		}
	},
	props: {
		page: { default: null },
		tabPosition: { default: null }
	},
	computed: {
		...mapState(useAppStore, [ 'device' ]),
		...mapState(useNotepadStore, [ 'scrollTo' ]),
		...mapState(useFormMaintenanceStore, [ 'progression', 'containers', 'currentSubElements', 'currentContainer', 'subElementsValues' ]),
		...mapWritableState(useFormMaintenanceStore, [ 'currentContainerIndex', 'currentSubElementVisible', 'currentMaxContainerIndex', 'formStatus' ]),
		pageContent() {
			if (this.pageType === 'section' && this.page?.children?.length) {
				return this.page.children[this.page.pageIndex]
			}
			return this.page
		},
		pageType() {
			if (this.page.type) return this.page.type
			if (this.page.tags.includes('main-section')) return 'main-section'
			if (this.page.tags.includes('game-list-section')) return 'game-list-section'
			if (this.page.tags.includes('archives-section')) return 'archives-section'
			if (this.page.tags.includes('webinars-section')) return 'webinars-section'
			return 'section'
		},
		pageFooter() {
			if (this.pageType === 'form-maintenance') return 'form-maintenance'
			if (this.pageType === 'form-maintenance-end') return 'form-maintenance-end'
			if (this.pageType === 'archive') return 'back-to-archives'
			if (this.pageType === 'section' && this.page?.children?.length) return 'multiple-pages'
			return this.page.footer ? this.page.footer : false
		},
		tab() {
			if (this.page && this.page.tab) {
				return { ...this.page.tab, position: this.tabPosition }
			}
			return null
		},
		nextableContainer() {
			return this.currentSubElements.length === this.currentContainer?.entry.subElements.length
		},
	},
	watch: {
		subElementsValues: {
			handler() {
				this.isCurrentContainerValid = this.verifyCurrentContainerValid()
			},
			deep: true
		},
		pageContent: {
			handler() {
				this.$nextTick(() => {
					if (this.pageType === 'section' && this.$refs.scrollable) {
						const $el = this.$refs.scrollable.$el ? this.$refs.scrollable.$el : this.$refs.scrollable
						this.isScrollable = $el.scrollTop === 0 && $el.firstChild.offsetHeight > $el.offsetHeight
						if (this.isScrollable) {
							$el.addEventListener('scroll', () => this.isScrollable = false)
						}
					} else
						this.isScrollable = false
				})
			},
			immediate: true
		},
		'scrollTo.time'() {
			this.$nextTick(() => {
				if (this.scrollTo.element && this.$refs.scrollable) {
					const $el = this.$refs.scrollable.$el ? this.$refs.scrollable.$el : this.$refs.scrollable
					const maxScroll = $el.firstChild.offsetHeight - $el.offsetHeight
					let top = this.scrollTo.element.offsetTop + this.scrollTo.element.parentNode.offsetTop - 8
					if (top > maxScroll) top = maxScroll
					$el.scroll({ top, left: 0, behavior: this.scrollTo.smooth ? 'smooth' : 'auto' })
				}
			})
		},
		currentContainerIndex(newIndex) {
			if (newIndex > this.currentMaxContainerIndex) this.currentMaxContainerIndex = newIndex
			if (this.$refs.scrollable?.$el) this.$refs.scrollable.$el.scrollTop = 0
			this.currentSubElementVisible = 0
		}
	},
	methods: {
		...mapActions(useFormMaintenanceStore, [ 'setTrivia', 'setProgression' ]),
		...mapActions(useNotepadStore, [ 'removePage', 'setActivePage', 'setPageIndex', 'addPage' ]),
		verifyCurrentContainerValid() {
			if (this.currentContainer?.entry?.subElements) {
				const { minInput = 1 } = this.currentContainer.entry

				let requiredCounter = 0
				let answers = 0

				const toValidate = this.currentContainer.entry.subElements
					.filter(({ submittable }) => submittable)

				const nbRequired =
					toValidate
						.filter(({entry}) => entry?.isRequired)
						.length

				const isInvalid =
					toValidate
						.reduce((a, { entry, id}) => {
							const {isRequired, minInput = 0, minValue, maxInput, maxValue, tags} = entry

							const { value } =
								this.subElementsValues
									.find(({ id:ID }) => id === ID)

							if (value === '') return true
							if (isRequired && ([false, null, undefined].includes(value))) return true
							if (isRequired && Array.isArray(value) && !value?.length) return true
							if (isRequired) requiredCounter += 1

							answers += 1

							if (tags?.includes('number')) {
								const max = Number(maxValue)
								const min = Number(minValue)

								if (value > max || value < min) {
									return true
								}
							}

							if (
								value?.length
								&& (value.length < minInput || value.length > maxInput
								)
							) {
								return true
							}

							return a
						}, false)

				if (isInvalid) return false
				return answers >= minInput && requiredCounter === nbRequired
			}

			return false
		},
		scrollHint() {
			const $el = this.$refs.scrollable.$el ? this.$refs.scrollable.$el : this.$refs.scrollable
			$el.scroll({ top: $el.offsetHeight - 24, left: 0, behavior: 'smooth' })
		},
		tap(method) {
			if (this[method]) this[method]()
		},
		close() {
			this.removePage({ page: this.page })
		},
		setNewActivePage() {
			this.$emit('flip', { page: this.page })
			setTimeout(() => {
				this.setActivePage({ page: this.page })
			}, 150)
		},
		prevPage() {
			if (this.page.pageIndex > 0) {
				this.gotoPage({ index: this.page.pageIndex - 1 })
				// this.setPageIndex({ page: this.page, pageIndex: this.page.pageIndex - 1 })
			}
		},
		nextPage() {
			if (this.page.pageIndex < this.page.children.length - 1) {
				this.gotoPage({ index: this.page.pageIndex + 1 })
				// this.setPageIndex({ page: this.page, pageIndex: this.page.pageIndex + 1 })
			}
		},
		gotoPage({ index }) {
			this.setPageIndex({ page: this.page, pageIndex: index })
			this.sectionsListVisible = null
		},
		scrollTop(className) {
			const element = document.getElementsByClassName(className)
			if (element && element.length > 2 && element[2]) {
				setTimeout(() => {
					element[2].scrollTop = 0
				}, 0)
			}
		},
		prevFormMaintenanceContainer() {
			if (this.currentContainerIndex > 0) this.currentContainerIndex--
			this.scrollTop('go-to-top')
		},
		async nextFormMaintenanceContainer() {
			this.scrollTop('go-to-top')

			if (this.nextableContainer) {
				if (this.isCurrentContainerValid) {
					// Next container
					if (this.currentContainerIndex < this.containers.length - 1) {
						this.currentContainerIndex++
					}
					// Submit form
					else {
						if (!this.formMaintenanceLoading) {
							this.formMaintenanceLoading = true

							if (!this.isConnected && this.progression.isDemo) {
								setTimeout(() => {
									this.addPage({
										page: {
											id: "demo-completed",
											type: 'demo-completed',
											document: { title: this.$t('demo.pageTitle') },
										}
									})
									this.formMaintenanceLoading = false
								}, 500)
							} else {
								const response = await submitMaintenanceForm({ progression: this.progression, values: this.subElementsValues })
								if (response?.score === 1 && response?.progression) {
									if (response?.progression?.currentContent?.layout === 'tuto' || response?.progression?.currentContent?.__typename === 'ClientGameStepTriviaBaseQuery') {
										this.setTrivia({ trivia: response.progression })
									} else
										this.setProgression({ progression: response.progression })
								}
								this.formMaintenanceLoading = false
							}
						}
					}

				}
			} else
				this.currentSubElementVisible++
		},
	},
	updated() {
		this.isCurrentContainerValid = this.verifyCurrentContainerValid()
		// const a = this.$refs.scrollable.$el
		// this.$refs.scrollable.$el.scrollTop = 0
	},
	components: { Tab, PageSection, PageMainSection, PageArchivesSection, PageGameListSection, PageFormMaintenance, PageNoAccess, FooterSectionsList, PageArchive, PageDemoCompleted, PageWebinarsSection }
}
</script>

<style lang="stylus" scoped>

#mobile
	.page .page-content
		.content
			overflow auto
		.footer .footer-content.form-buttons
			.left
				width 40px
				flex center center column
				.slash
					display none
				.max
					border-top 2px solid primary
					width 20px
			.right
				width calc(100% - 40px)
				::v-deep(.ui-button).has-icon-right
					padding 0 28px 0 8px
					line-height 1.2em
					.icon
						right 8px

#app > .low-perf
	.page .page-content .footer .footer-content.multiple-pages .burger-button svg line
		transition none

.page
	padding 8px
	background-color #fff
	box-shadow 0 0 4px alpha(#000, 50%)
	&:after
		content ''
		absolute 6px
		border 2px solid primary
		pointer-events none
	.page-content
		height 100%
		.header
			padding 0 16px
			height 120px
			flex center center column
			text-align center
			border-bottom 1px solid primary
			font-weight 500
			.category
				margin 0 0 8px 0
				color primary
				font-size 1.3rem
				text-transform uppercase
			.title
				font-size 2.4rem
				color primary
				text-transform uppercase
			.subtitle
				color primary
				font-size 1.4rem
		.content
			overflow hidden
			height calc(100% - 120px)
			// scroll-behavior smooth
			&.has-footer
				height calc(100% - 200px)
			// > .scroll-content
			// 	min-height 100%
		.scroll-hint
			position absolute
			left calc(50% - 16px)
			bottom 96px
			height 64px
			width 32px
			background-color #fff
			border-radius 32px
			box-shadow 0 0 8px alpha(#000, 50%)
			cursor pointer
			&:hover
				background-color #eee
				transform scale(1.05)
			&:active
				background-color #ddd
				transform scale(0.95)
			&.scroll-hint-enter-active, &.scroll-hint-leave-active
				transition 0.25s easeOutQuart
			&.scroll-hint-enter-from
				opacity 0
				transform translateY(-16px)
			&.scroll-hint-leave-to
				opacity 0
				transform translateY(16px)
			.arrow
				position absolute
				left calc(50% - 12px)
				top calc(50% - 12px)
				width 24px
				height 24px
				transform translateY(-32px)
				opacity 0
				animation arrow 3s linear infinite
				&:nth-child(2)
					animation-delay 1s
				&:nth-child(3)
					animation-delay 2s
				@keyframes arrow
					0%
						transform translateY(-16px)
						opacity 0
					50%
						transform translateY(0)
						opacity 1
					100%
						transform translateY(16px)
						opacity 0
		.footer
			height 80px
			border-top 1px solid primary
			text-align center
			.footer-content
				width 100%
				height 100%
				flex center center
				&.buttons
					width 100%
					height 100%
				&.form-buttons
					.left
						flex center center
						width 80px
						height 100%
						color primary
						.max
							font-size 2rem
							// transform translateY(2px)
					.right
						width 100%
						height 100%
						padding 0 4px
						flex center center
						width calc(100% - 80px)
						border-left 1px solid #f3934d
				&.form-buttons .right, &.back-to-archives
					::v-deep(.ui-button)
						max-width calc(100% - 16px)
						height 48px
						margin 0 4px
						text-align left
						font-size 1.4rem
						&.has-icon-right
							// padding-left 8px
							padding-right 48px
				&.multiple-pages
					flex center center
					gap 16px
					.arrow
						position absolute
						top calc(50% - 24px)
						width 48px
						height 48px
						background center center no-repeat
						cursor pointer
						&.left
							left 8px
						&.right
							right 8px
							::v-deep(.ui-loader)
								absolute 0
								.ui-loader-content
									position absolute
									left 8px
									top 6px
									width 32px
									height 32px
									background-color #467463
									border-radius 50%
									color #fff
									svg
										width 24px
										height 24px
						&:active
							transform translateY(2px)
						&.disabled
							opacity 0.5
							cursor not-allowed
							transform none
					.table-of-content
						display inline-block
						text-align right
						width 80px
						text-transform uppercase
						font-size 1.4rem
						line-height 1em
						color #1F9192
						font-weight 500
					.pagination
						display inline-block
						text-align left
						width 80px
						font-size 2.4rem
						font-weight 500
						white-space nowrap
					.burger-button
						width 32px
						height 32px
						flex center center
						border-radius 50%
						cursor pointer
						color #fff
						&.active
							background-color red
							z-index 100001
							svg line
								&:nth-child(1)
									transform translate(17.5%, 17.5%) rotate(-45deg)
								&:nth-child(2)
									opacity 0
								&:nth-child(3)
									transform translate(17.5%, -17.5%) rotate(45deg)
						svg
							width 24px
							height 24px
							line
								transition 0.25s easeOutQuart
								transform-origin center center

					.bullets
						flex center center
						.bullet
							width 10px
							height 10px
							margin 0 2px
							border-radius 50%
							border 1px solid #1F9192
							background-color #C8E5E5
							&.current
								background-color primary
				&.footer-form-maintenance-end
					flex center space-between
					padding 0 16px
					text-align left
					::v-deep(.ui-button)
						text-transform none
						text-align left
	.tab
		position absolute
		left calc(100% - 8px)

</style>
