<template>
	<div class="invite" :class="{ 'started': pageIndex > 0 }">

		<div class="bg">
			<div class="plant" v-bg="'login/plant-light-green.svg'"></div>
			<div class="shadow"></div>
		</div>

		<div v-if="ready" class="content">
			<div class="part left">

				<div class="welcome">{{ $t('onboarding.welcome1') }}</div>
				<p>{{ $t('onboarding.welcome2') }}</p>
				<p>{{ $t('onboarding.welcome3') }}</p>

			</div>

			<div v-if="progression" class="part right">

				<form v-if="currentPage?.id" class="form" @submit.prevent="submit()">
					<transition name="page" mode="out-in">
						<div :key="currentPage.id" class="page">
							<div v-for="field in currentPageFields" :key="field.id" class="row">
								<label v-if="field.label !== '*'" :for="'field-' + field.id" class="label">{{ field.label }}</label>
								<div class="field">
									<input v-if="field.type === 'Content::Form::TextInput'" type="text" class="input" v-model="field.value" :id="'field-' + field.id" autocomplete="off" required>
									<input v-else-if="field.type === 'Content::Form::PasswordInput'" type="password" class="input" v-model="field.value" :id="'field-' + field.id" autocomplete="off" required>
									<select v-else-if="field.type === 'Content::Form::SelectInput' && field.tags.includes('dropdown') && field.options?.length" v-model="field.value" :id="'field-' + field.id" class="input" required>
										<option value=""></option>
										<option v-for="option in field.options" :key="option.id" :value="option.id">{{ option.text }}</option>
									</select>
									<div v-else-if="field.type === 'Content::Form::SelectInput' && field.tags.includes('check_box') && field.options?.length" class="checkboxes">
										<ui-checkbox v-for="option in field.options" :key="option.id" v-model="option.checked" :text="option.text" />
									</div>
								</div>
							</div>
							<div class="buttons">
								<ui-button type="submit" size="big" :disabled="!nextable" :loading="loading">{{ isLastPage ? $t('onboarding.formSubmitButton') : $t('onboarding.formNextButton') }}</ui-button>
							</div>
						</div>
					</transition>
				</form>
			</div>

			<div v-else class="part right">

				<div class="error">
					<p class="message"><ui-text :text="$t('onboarding.invalidInvitation')" /></p>
					<ui-button size="big" v-tap="() => $router.push({ name: 'home' })">{{ $t('onboarding.invalidInvitationButton') }}</ui-button>
				</div>

			</div>
		</div>

		<div v-else class="main-loading">
			<ui-loader />
		</div>

	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useAppStore } from '@/store/app'
import { initTeacherInvitation, submitTeacherInvitation } from '@/requests/on-boarding'
import { getUser } from '@/requests/login'
import UiLoader from '@/components/ui/ui-loader.vue'
import UiCheckbox from '@/components/ui/ui-checkbox.vue'

export default {
	name: 'invite',
	data() {
		return {
			ready: false,
			loading: false,
			progression: null,
			pageIndex: 0,
			fields: []
		}
	},
	computed: {
		...mapState(useAppStore, [ 'user' ]),
		pages() {
			return this.progression?.currentMark?.step?.formElements
		},
		currentPage() {
			return this.pages?.length ? this.pages[this.pageIndex] : null
		},
		currentPageFields() {
			return this.fields.filter(f => !f.invisible && f.pageId === this.currentPage?.id)
		},
		nextable() {
			return this.currentPageFields.filter(f => f.value || (f.tags.includes('check_box') && f.options.filter(o => o.checked).length >= (f.minInput || 1) && f.options.filter(o => o.checked).length <= (f.maxInput || 100))).length === this.currentPageFields.length
		},
		isLastPage() {
			return this.pages?.length ? this.currentPage?.id === this.pages[this.pages.length - 1]?.id : false
		}
	},
	methods: {
		async submit() {
			if (!this.loading && this.nextable) {
				if (this.isLastPage) {
					this.loading = true
					const { setLoggedUser, addNotification } = useAppStore()
					const response = await submitTeacherInvitation({
						progression: this.progression,
						values: this.fields
					})
					if (response?.score === 1) {
						addNotification({ status: 'success', title: this.$t('notifications.signupSuccessTitle'), text: this.$t('notifications.signupSuccessText') })
						const user = await getUser()
						if (user) setLoggedUser({ user })
						this.$router.push({ name: 'home' })
					} else
						addNotification({ status: 'error', title: this.$t('notifications.signupErrorTitle'), text: this.$t('notifications.signupErrorText') })
					this.loading = false
				} else
					this.pageIndex++
			}
		},
	},
	async created() {
		if (this.$route.query.token) this.$router.push({ name: 'invite' })

		const progression = await initTeacherInvitation()
		if (progression) {
			this.progression = progression

			const { setLoggedUser } = useAppStore()
			const user = await getUser()
			if (user) setLoggedUser({ user })

			progression.currentMark.step.formElements.forEach(page => {
				this.fields = [
					...this.fields,
					...page.entry.subElements
						.filter(formElement => formElement.entryType && formElement.entry)
						.map(formElement => {
							return {
								id: formElement.id,
								pageId: page.id,
								label: formElement.entry?.label?.trim(),
								type: formElement?.entryType,
								tags: formElement.entry?.tags ? formElement.entry?.tags : [],
								invisible: formElement.entry?.tags?.includes('invisible'),
								minInput: formElement.entry?.minInput,
								maxInput: formElement.entry?.maxInput,
								value: formElement.entry?.tags?.includes('username') ? this.user.email : '',
								options: formElement.entry?.selectableElements?.filter(e => e?.id && e.option?.html)?.map(e => ({ id: e.id, text: e.option?.html, checked: false }))
							}
						})
				]
			})
		}

		this.ready = true
	},
	components: { UiLoader, UiCheckbox }
}
</script>

<style lang="stylus" scoped>

#mobile, #tablet-vertical, #tablet-horizontal
	.invite
		.bg
			.shadow
				absolute 0
				width 100%
				height 38%
				transform none
			.plant
				opacity 0.25
		.content
			width 100%
			max-width 600px
			margin 0 auto
			.part
				top auto
				bottom auto
				height auto
				width 100%
				left 0
				right 0
				transform none
				padding 16px
				&.left
					top 0
					height 38%
					flex center center column
					text-align center
					.welcome
						font-size 2.4rem
					p
						font-size 1.6rem
				&.right
					bottom 0
					height 62%
					padding 16px
					flex center center column
					.form
						width 100%
						z-index 1
						.row
							margin 0 0 16px 0
							.label
								margin 0 0 4px 0
								font-size 1.6rem
							.input
								height 40px
						.buttons
							padding-top 8px
							flex center center
							.ui-button
								display block
								width 100%

#tablet-vertical, #tablet-horizontal
	.invite
		.content
			.part
				&.left
					.welcome
						font-size 2.8rem

#mobile
	.invite
		&.started
			.bg .shadow
				display none
			.content
				.part
					&.left
						display none
					&.right
						height 100%
		.content
			.part
				&.left
					.welcome
						margin 0 0 4px 0
					p
						margin 0 0 4px 0
				&.right
					.form
						z-index 1
						padding-bottom 32px


.invite
	absolute 0
	flex center center
	color #fff
	height 100%

	.bg
		absolute 0
		background-color #3A5F5F
		.plant
			absolute -200px
			background left 200px top 200px no-repeat
			opacity 0.5
			transform rotate(15deg)
		.shadow
			position absolute
			top -20%
			left -20%
			bottom -20%
			width 55%
			transform rotate(15deg)
			background-color alpha(#647B7C, 75%)

	.content
		width 100%
		height 100%
		flex center space-between

		.part
			position absolute
			top 50%
			transform translateY(-50%)
			&.left
				left 40px
				width 30%
				.welcome
					margin 0 0 16px 0
					font-size 5rem
					text-transform uppercase
					line-height 1.2em
				p
					margin 0 0 16px 0
					font-size 2rem
					font-weight 300
					line-height 1.5em
					&:last-child
						margin 0
			&.right
				right 15%
				width 500px
				.form
					.page
						&.page-enter-active, &.page-leave-active
							transition 0.25s easeOutQuart
						&.page-enter-from
							transform translateX(16px)
							opacity 0
						&.page-leave-to
							transform translateX(-16px)
							opacity 0
						.row
							display block
							width 100%
							margin 0 0 32px 0
						.label
							display block
							width 100%
							text-transform uppercase
							margin 0 0 8px 0
							font-weight 500
							font-size 1.8rem
						.input
							display block
							width 100%
							height 48px
							padding 0 16px
							border none
							background #fff
							border-radius 4px
							font inherit
							font-size 1.8rem
							color dark
							outline none
							text-align left
							&:focus
								box-shadow 0 0 0 3px darken(#3A5F5F, 25%)
						select.input
							appearance none
							background #fff url('../assets/img/arrow-down-black.svg') right 16px center no-repeat
							background-size 24px auto
						.checkboxes
							display grid
							grid-template-columns 1fr 1fr
							gap 12px
							::v-deep(.ui-checkbox)
								.checkbox
									border-color #fff
									> svg
										color #fff
						.buttons
							flex center flex-end
							.ui-button
								width 240px
								height 64px
								padding 0 40px
								background-color #687E7F
								font-size 1.8rem
								line-height 1.2em
								&:hover
									background-color lighten(#687E7F, 5%)
								&:active
									background-color darken(#687E7F, 5%)
				.error
					flex center center column
					.message
						width 100%
						margin 0 0 32px 0
						padding 16px
						background-color red
						border-radius 6px
						color #fff
						text-align center
						font-weight 500
						text-transform uppercase
	.main-loading
		absolute 0
		background-color #3A5F5F
		color #fff
		::v-deep(.ui-loader) svg
			width 64px
			height 64px




</style>
