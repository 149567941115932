<template>
	<div class="modal">
		<div class="bg" v-tap="close"></div>
		<div class="content" v-tap="close">
			<!-- <div v-if="modal.src" class="image" key="image" v-bg="modal.src"></div> -->
			<div v-if="modal.video" class="video" key="video">
				<iframe v-if="modal.youtubeId" width="100%" height="100%" :src="'https://www.youtube-nocookie.com/embed/' + modal.youtubeId + '?autoplay=1'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>
			<img v-if="modal.src" key="image" :src="modal.src" />
		</div>
		<div class="close" :title="$t('settings.close')" v-tap="close">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
		</div>
	</div>
</template>

<script>
import { useAppStore } from '@/store/app'

export default {
	name: 'modal',
	props: {
		modal: { default: {} }
	},
	methods: {
		close() {
			useAppStore().setModal(null)
		}
	}
}
</script>

<style lang="stylus" scoped>

#app > .low-perf
	.modal
		transition none

#mobile, #tablet-vertical, #tablet-horizontal
	.modal .content .video
		height 100%
		width 100%


.modal
	fixed 0
	z-index 100000
	flex center center
	&.modal-enter-active
		transition 0.2s easeOutQuart
		.bg
			transition 0.2s easeOutQuart
		.content
			transition 0.2s easeOutQuart
	&.modal-enter-from
		.bg
			opacity 0
		.content
			opacity 0
			transform scale(0)

	&.modal-leave-active
		transition 0.25s easeOutQuart
	&.modal-leave-to
		opacity 0

	.bg
		background-color alpha(#446364, 90%)
		absolute 0
	.content
		width 95%
		height 95%
		flex center center
		.video
			height 90%
			width 90%
			flex center center
			iframe
				width 100vw
				height 56.25vw
				max-height 100%
				max-width 100%
		img
			max-width 100%
			max-height 100%
			user-select none
	.close
		position absolute
		right 32px
		top 32px
		width 64px
		height 64px
		background-color #fff
		color dark
		border-radius 50%
		box-shadow 0 0 8px 2px darken(#446364, 50%)
		flex center center
		cursor pointer
		transition 0.1s easeOutQuart
		&:hover
			transform scale(1.1)
		&:active
			transform scale(0.9)
		svg
			width 48px
			height 48px

</style>
