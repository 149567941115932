<template>
	<label class="ui-checkbox" :class="[ { 'no-text': !text, 'has-image': image, editable }, color ]">
		<div v-if="image" class="select-image" v-bg="image"></div>
		<input v-if="editable" type="checkbox" :checked="modelValue" @input="$emit('update:modelValue', $event.target.checked)">
		<span class="checkbox" :class="{ checked: modelValue }">
			<svg v-show="modelValue" xmlns="http://www.w3.org/2000/svg" width="28.396" height="31.861" viewBox="0 0 28.396 31.861">
				<path fill="none" stroke="currentColor" stroke-width="4px" d="M15030.176,5566.557l5.464,7.337,19.706-27.308" transform="translate(-15028.571 -5545.416)"/>
			</svg>
		</span>
		<span v-if="text" class="text"><ui-text :text="text" /></span>
	</label>
</template>

<script>
export default {
	name: 'ui-checkbox',
	props: {
		text: { default: '' },
		modelValue: { type: Boolean, default: false },
		color: { default: null },
		image: { default: null },
		editable: { default: true }
	},
}
</script>

<style lang="stylus" scoped>

.ui-checkbox
	flex flex-start flex-start
	&.editable
		cursor pointer
		&:hover
			.checkbox:after
				content ''
				absolute 0
				background-color alpha(#000, 10%)
	&.no-text
		inline-flex flex-start flex-start
		width 20px
		.checkbox
			margin 0
	&.white
		.checkbox
			border-color #fff
			> svg
				color #fff
	&.has-image
		padding-top (120px + 8px)
		.select-image
			position absolute
			left 0
			top 0
			width 100%
			height 120px
			background red center center no-repeat
			background-size cover !important
	> input
		position absolute
		left -16px
		top 0
		opacity 0.5
		display none
	.checkbox
		display inline-block
		width 20px
		height 20px
		margin 0 8px 0 0
		border 2px solid dark
		transition 0.1s easeOutQuart
		flex-shrink 0
		> svg
			position absolute
			left 0
			bottom 2px
			width 24px
			height 24px
			color primary
	.text
		user-select none
		::v-deep(a)
			color inherit
			text-decoration underline
			&:hover
				text-decoration none



</style>
