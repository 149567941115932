<template>
	<div class="notepad" :class="categoryClass">
		<div class="bg">
			<div class="main" v-bg="'notepad/main-bg.svg'"></div>
			<div class="line"></div>
			<div class="line"></div>
		</div>
		<div class="content" v-bg="'notepad/notepad-bg.svg'">
			<div class="notepad-clip" v-bg="'notepad/notepad-clip.svg'"></div>

			<transition name="copy-url-button">
				<div v-if="[ 'vireo' ].includes(role) && pages.length && copyable" class="copy-url-button" :class="{ copied: urlCopied }" @click="copyActivePageUrl">
					<span v-if="urlCopied" class="icon" key="1" v-bg="'check-white.svg'"></span>
					<span v-else class="icon" key="2" v-bg="'notepad/link.svg'"></span>

					<span class="tooltip">
						<template v-if="urlCopied">{{ $t('notepad.copied') }}</template>
						<template v-else>{{ $t('notepad.copy') }}</template>
					</span>
				</div>
			</transition>

			<div v-if="!loading" class="pages shadows">
				<div class="page"></div>
				<div class="page"></div>
			</div>

			<transition name="tab">
				<tab v-if="pages.length" :tab="{ position: 0, name: 'home', color: 'red', tooltip: $t('notepad.backToHome') }" class="tab-home" key="home" v-tap="() => goto('home')" />
			</transition>
			<transition name="tab">
				<tab v-if="backable" :tab="{ position: 1, name: 'back', tooltip: $t('notepad.closePage') }" class="tab-back" key="back" v-tap="closeTopPage" />
			</transition>

			<transition-group :name="animation" tag="div" class="pages" :class="animation" appear>
				<page v-for="(page, index) in sortedPages"
					:class="{ active: activePage?.id === page.id, flip: activePageAnimation?.id === page.id }"
					:key="page.id"
					:page="page"
					:tabPosition="index + 1"
					:style="{ zIndex: page.zIndex }"
					@flip="flip"
				/>
			</transition-group>
			<div class="notepad-pin" :class="{ up: movingPage || animation === 'starting' }" v-bg="'notepad/notepad-pin.svg'"></div>

			<ui-loader v-if="loading || loadingSubSection" class="main-loader" />
		</div>
	</div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useAppStore } from '@/store/app'
import { useNotepadStore } from '@/store/notepad'
import { useOnboardingStore } from '@/store/onboarding'
import { openNotepadBySection, getSectionContent } from '@/requests/notepad'
import { wait } from '@affordancestudio/functions'
import Page from '@/components/notepad/page'
import Tab from '@/components/notepad/tab'

export default {
	name: 'notepad',
	data() {
		return {
			// loading: true,
			// animation: 'starting',
			activePageAnimation: null,
			movingPage: true,
			loadingSubSection: false,
			urlCopied: false
		}
	},
	computed: {
		...mapState(useAppStore, [ 'section', 'role', 'isConnected', 'currentPotager' ]),
		...mapState(useNotepadStore, [ 'pages', 'animation' ]),
		...mapWritableState(useAppStore, [ 'modalLoginVisible' ]),
		loading() {
			return this.animation === 'starting'
		},
		sortedPages() {
			return [ ...this.pages ].sort((a, b) => b.pageIndex - a.pageIndex)
		},
		activePage() {
			if (this.pages) {
				return this.pages.reduce((acc, p) => p.zIndex > acc.zIndex ? p : acc, this.pages[0])
			}
			return null
		},
		backable() {
			return this.pages.find(p => !p.native) !== undefined
		},
		categoryClass() {
			if ([ 'my-garden-experiments', 'guide-to-gardening', 'library-articles', 'lesson-plans' ].includes(this.$route.name))
				return this.$route.name
			return 'my-garden-experiments'
		},
		copyable() {
			if ([ 'no-access', 'demo-completed', 'archive', 'archives-section', 'form-maintenance' ].includes(this.activePage?.type)) return false
			return this.pages.length === 1 ||
				this.activePage?.tags?.includes('game-list-section') ||
				[ 'guide-to-gardening', 'library-articles', 'lesson-plans', 'faq' ].includes(this.$route.name)
		}
	},
	watch: {
		isConnected(isConnected) {
			if (isConnected) this.loadPages()
		},
		activePage() {
			this.movingPage = true
			setTimeout(() => {
				this.movingPage = false

				// TODO: id en dur
				if (this.activePage?.id === '620d87ce51b1cc000a4bcee8' || this.activePage?.id === '62fbd65e5f5e0a000a3e3e31') {
					this.completeCheckpoint({ slug: 'video-introduction' })
				}

			}, 500)
		},
	},
	methods: {
		...mapActions(useAppStore, [ 'addNotification' ]),
		...mapActions(useNotepadStore, [ 'removePages', 'removePage', 'setPages', 'addPage' ]),
		...mapActions(useOnboardingStore, [ 'completeCheckpoint' ]),
		flip({ page }) {
			this.movingPage = true
			this.activePageAnimation = page

			// Delete all non native pages
			const pages = this.pages.filter(p => !p.native)
			if (pages) this.removePages({ pages })
		},
		closeTopPage() {
			const max = Math.max(...this.pages.filter(p => !p.native).map(p => p.zIndex))
			const page = this.pages.find(p => !p.native && p.zIndex === max)
			if (page) {
				this.removePage({ page })
			}
		},
		goto(name) {
			this.$router.push({ name })
		},
		async copyActivePageUrl() {
			if (this.pages.length > 0) {
				const nativePageSlug = window.location.pathname.split('/')?.[1]
				if (nativePageSlug) {
					let url = document.location.origin + '/' + nativePageSlug

					if (this.activePage.id !== this.pages[0].id) {
						url += '/' + this.activePage.id
						if (this.activePage.pageIndex) url += '/' + this.activePage.pageIndex
					}

					await navigator.clipboard.writeText(url)
					this.urlCopied = true
					await wait(1000)
					this.urlCopied = false
				}
			}
		},
		async loadPages() {
			let sectionId = this.$route.params.sectionId

			if (sectionId?.startsWith('[ID]')) {
				const splits = sectionId.replace('[ID]', '').split('&')
				const potagers = splits.reduce((acc, s) => {
					const a = s.split('=')
					if (a.length === 2) acc[a[0]] = a[1]
					return acc
				}, {})
				if (this.currentPotager === 's03.2') sectionId = potagers['S032']
				else if (this.currentPotager === 's03') sectionId = potagers['S03']
				else if (this.currentPotager === 's02') sectionId = potagers['S02']
			}

			if (sectionId && !this.$route.params.isHashSection) {
				this.loadingSubSection = true

				const page = await getSectionContent({ id: sectionId })
				if (this.isConnected || page?.tags?.includes('require-no-user')) {
					await openNotepadBySection({ sectionNames: this.$route.meta.sectionNames })
					if (page?.id) {
						this.addPage({ page: page, pageIndex: parseInt(this.$route.params.pageIndex) || 0 })
					} else {
						this.addPage({
							page: {
								id: "no-access",
								type: 'no-access',
								document: { title: this.$t('noAccess.pageTitle') },
							}
						})
					}
				} else {
					this.modalLoginVisible = true
				}
				this.loadingSubSection = false
			} else
				await openNotepadBySection({ sectionNames: this.$route.meta.sectionNames })
		}
	},
	created() {
		this.loadPages()
	},
	beforeUnmount() {
		this.setPages({ pages: [] })
	},
	components: { Page, Tab }
}
</script>

<style lang="stylus" scoped>

#app > .low-perf
	.notepad
		&.route-enter-active, &.route-leave-active
			transition none
		.bg
			.main, .line
				animation none
		.content
			.tab
				transition none
			.notepad-pin
				transform none
			.pages .page
				&.starting-enter-active, &.default-enter-active, &.default-leave-active
					transition none
				&.flip
					animation none

#mobile, #app .app-content.small-height
	.notepad
		.bg
			left 0
		.content
			width 100%
			height 100%
			max-width 700px
			background none !important
			.notepad-clip, .notepad-pin
				display none
			.tab
				&.tab-home
					left 24px
					top calc(100% - 72px)
				&.tab-back
					left 80px
					top calc(100% - 72px)
				&.tab-enter-from
					transform translateY(25%)
					opacity 0
				&.tab-leave-to
					opacity 0
					transform translateY(25%)
			.pages
				absolute 8px
				bottom 64px
				&.shadows
					display block
					.page
						background-color #fff
						box-shadow 0 0 8px 2px alpha(#000, 50%)
						&:nth-child(1)
							transform translateY(6px)
						&:nth-child(2)
							transform translateY(3px)
				&.default
					.page
						&.flip
							transition-delay 0
							animation page_active_mobile 0.35s easeOutQuart forwards

.notepad
	absolute 0
	flex center center
	background-color alpha(#B2D8D8, 75%)

	&.route-enter-active
		transition 0.6s easeOutQuart
		.bg
			transition transform 0.6s easeOutQuart
		.content
			transition 0.4s easeOutBack 0.2s
	&.route-enter-from
		opacity 0
		.bg
			transform translateX(200px)
		.content
			transform translateY(-64px)
			opacity 0

	&.route-leave-active
		transition 0.2s easeOutQuart
	&.route-leave-to
		opacity 0

	.bg
		absolute 0
		background-color darken(#3C6161, 25%)
		absolute -20%
		left 30%
		right -150px
		transform rotate(10deg)
		.main
			absolute 0
			background-size 500px auto !important
			opacity 0.25
			animation bg-main 200s linear infinite
		.line
			position absolute
			top 0
			bottom 0
			perspective 1000px
			&:nth-child(2)
				left -32px
				width 24px
				animation bg-line (1s * 10) easeInOutSine infinite
				background-color #F5B122
			&:nth-child(3)
				left -64px
				width 12px
				animation bg-line (1.5s * 10) easeInOutSine infinite
				animation-delay 0.25s
				background-color #789388
	.content
		width round(361px * 1.5)
		height round(518px * 1.5)
		background center center no-repeat
		::v-deep(.main-loader)
			color dark
			z-index 10
			.ui-loader-content
				padding 16px
				border-radius 40px
				background-color #fff
				box-shadow 0 2px 8px alpha(#000, 35%)
		.tab
			position absolute
			left calc(100% - 24px)
			&.tab-home
				top 32px
			&.tab-back
				top 88px
			&.tab-enter-active
				transition 0.25s easeOutQuart 0.25s
			&.tab-enter-from
				transform translateX(-100%)
				opacity 0
			&.tab-leave-active
				transition 0.15s easeOutQuart
			&.tab-leave-to
				opacity 0
				transform translateX(-100%)
		.pages
			absolute 16px
			&.shadows
				display none
			&.default
				.page
					&.flip
						transition-delay 0
						animation page_active 0.35s easeOutQuart forwards
			.page
				absolute 0

				&.starting-enter-active
					transform-origin top center
					transition transform 0.4s easeOutQuart, opacity 0.2s easeOutQuart
					for i in 1..20
						&:nth-child({ i })
							transition-delay (0.1s * (i - 1))
				&.starting-enter-from
					transform translate(100px, 200px) rotate(-8deg)
					opacity 0

				&.default-enter-active
					transition 0.4s easeOutQuart
				&.default-enter-from
					transform translate(64px, 25%) rotate(-10deg)
					opacity 0

				&.default-leave-active
					transition 0.25s easeInOutSine
				&.default-leave-to
					transform translate(-64px, 25%) rotate(10deg)
					opacity 0

		.notepad-clip
			position absolute
			left calc(50% - 110px)
			top -28px
			width (176px * 1.25)
			height (54px * 1.25)
			background center center no-repeat
		.notepad-pin
			position absolute
			left calc(50% - 101px)
			top -11px
			width (162px * 1.25)
			height (36px * 1.25)
			background center center no-repeat
			z-index 19
			transition transform 0.1s easeInOutSine
			transform-origin top left
			&.up
				transform perspective(400px) rotateX(35deg)

		.copy-url-button
			position absolute
			right 32px
			bottom calc(100% - 16px)
			width 48px
			height 40px
			background-color primary
			border-radius 8px 8px 0 0
			cursor pointer
			transform translateY(8px)
			transition 0.15s easeOutQuart
			&.copy-url-button-enter-active
				transition 0.25s easeOutQuart 0.25s
			&.copy-url-button-enter-from
				transform translateY(8px)
				opacity 0
			&:hover
				transform translateY(4px)
				background-color lighten(primary, 5%)
				.tooltip
					display flex
			&.copied
				transform translateY(12px)
				background-color darken(primary, 25%)
				.icon
					bottom 10px
				.tooltip
					display flex
			.icon
				absolute 0
				bottom 8px
				background center center no-repeat
				background-size 24px !important
			.tooltip
				position absolute
				left 50%
				bottom calc(100% + 8px)
				height 28px
				flex center center
				padding 0 16px
				transform translateX(-50%)
				background-color #111
				border-radius 4px
				font-size 1.4rem
				text-align center
				color #fff
				white-space nowrap
				display none
				&:after
					content ''
					position absolute
					left calc(50% - 8px)
					top calc(100% - 1px)
					width 0
					height 0
					border-style solid
					border-width 8px 8px 0 8px
					border-color #111 transparent transparent transparent



override_section_color(name, primary, secondary)
	.notepad.{name}
		::v-deep(.page)
			&.active > .tab
				background-color #1F927E
			> .tab
				background-color #6EACA2
			.page-content .header
				.title, .subtitle, .category
					color primary
			.page-content > .footer
				.left
					color primary
					.max
						border-color primary !important
				.right .ui-button, .ui-button
					background-color primary
			.footer
				.pagination, .table-of-content
					color primary !important
				.burger-button
					background-color primary
			.footer-sections-list
				.window
					border-color primary
					.title
						background-color primary
					.content .row
						color primary
						.no:after
							background-color primary
				.arrow
					color primary
			.section-row
				.section-name
					background-color primary
					&.is-link
						background-color secondary
					.icon.arrow-down
						color primary
				.groups
					border-color primary
					.hide-button, .copy-button
						background-color primary
						.icon
							color primary
					.group .group-name
						color primary
						border-color primary
			.ui-text
				strong
					color primary
				a, .internal-link
					color primary
					text-decoration underline
					&:hover
						text-decoration none


override_section_color(my-garden-experiments, #3B5F5F, #687E7F)
override_section_color(library-articles, #3B5F5F, #687E7F)
override_section_color(guide-to-gardening, #1F9192, #80B6B7)
override_section_color(lesson-plans, #467464, #759084)


@media (max-height: 850px)
	.notepad .content
		width round(361px * 1.35)
		height round(518px * 1.35)





@keyframes bg-main
	0%
		background-position 0 0
	100%
		background-position 0 -5000px

@keyframes bg-line
	0%, 100%
		transform translateX(0)
	50%
		transform translateX(-50%)

@keyframes page_active
	0%
		transform translateY(0)
	50%
		transform translateX(50%) rotate(4deg)
	100%
		transform translateY(0)

@keyframes page_active_mobile
	0%
		transform translateY(0)
	50%
		transform translate(8px, 32px) rotate(4deg)
	100%
		transform translateY(0)

</style>
