<template>
	<div class="modal-event-detail-tab" :class="{ active }">
		<ui-calendar-event-icon :event="event" class="icon" />
	</div>
</template>

<script>
import UiCalendarEventIcon from '@/components/ui/ui-calendar-event-icon.vue'

export default {
	props: {
		event: { required: true },
		active: { default: false },
	},
	components: { UiCalendarEventIcon }
}
</script>

<style lang="stylus" scoped>


.modal-event-detail-tab
	height 48px
	width 48px
	flex flex-start center
	cursor pointer
	background-color #3B5F5F
	&:hover > .icon
		opacity 0.75
	&.active
		&:before
			content ''
			position absolute
			left calc(50% - 12px)
			bottom -2px
			width 0
			height 0
			border-style solid
			border-width 0 12px 12px 12px
			border-color transparent transparent #fff transparent
		.icon
			opacity 1
	.icon
		width 32px
		height 32px
		color #fff
		opacity 0.5



</style>