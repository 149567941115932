import { engage } from '@/engage'
import { get, post, error } from '@/requests/request'
import { localStorage } from '@affordancestudio/engage-game'

export const login = async ({ username, password, loginError = (e) => e }) => {
	return (await engage.logInWithUserName({ userName: username, password })).fold(loginError, (x) => x)
	// return (await engage.logIn({ email: username, password })).fold(error, (x) => x)
}


export const addHeaders = ({ headers }) => {
	engage.headers.add(headers)
}

export const removeHeaders = () => {
	engage.headers.remove('group')
}


export const requestPasswordReset = async ({ email }) => {
	return await post('requestPasswordReset', { email })
}



export const getUser = async () => {
	if (localStorage.getItem('refreshToken')) return await get('currentUser')
	return null
}

export const loginWithInvitation = async ({ invitationToken }) => {
	const response = await engage.logInWithInvitation({ invitationToken })
	if (response && response.code && response.message) error({ error: response })
	return response
}

// export const loginWithToken = async ({ refreshToken }) => {
// 	const response = await engage.logInWithRefreshToken({ refreshToken })
// 	if (response && response.code && response.message) error({ error: response })
// 	return response
// }
