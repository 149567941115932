<template>
	<div class="modal-chatbot-infos">
		<div class="bg" v-tap="close"></div>
		<div class="content">

			<div class="title">{{ $t('chatbot.infos.title') }}</div>

			<div class="carousel">
				<div class="carousel-content">
					<div class="slides" :class="'slide-' + currentSlide">
						<div v-for="i in 3" :key="i" class="slide" v-tap="nextLoop">
							<p class="text">{{ $t('chatbot.infos.slide' + i) }}</p>
							<div class="img" v-bg="'chatbot-infos/' + lang + '/' + i + '.png'"></div>
						</div>
					</div>
				</div>
				<div class="dots">
					<div v-for="i in 3" :key="i" class="dot" :class="{ active: i === currentSlide + 1 }" v-tap="() => goToSlide(i - 1)"></div>
				</div>
				<div class="arrow prev" :class="{ disabled: currentSlide <= 0 }" v-tap="prev"><div class="icon" v-bg="'yellow-arrow-left.svg'"></div></div>
				<div class="arrow next" :class="{ disabled: currentSlide >= 2 }" v-tap="next"><div class="icon" v-bg="'yellow-arrow-right.svg'"></div></div>
			</div>

			<div class="close" :title="$t('settings.close')" v-tap="close">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
			</div>

		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useAppStore } from '@/store/app'
import { minmax } from '@affordancestudio/functions'

export default {
	data() {
		return {
			currentSlide: 0
		}
	},
	computed: {
		...mapState(useAppStore, [ 'lang' ])
	},
	methods: {
		prev() {
			this.goToSlide(this.currentSlide - 1)
		},
		next() {
			this.goToSlide(this.currentSlide + 1)
		},
		nextLoop() {
			if (this.currentSlide >= 2)
				this.currentSlide = 0
			else
				this.next()
		},
		goToSlide(n) {
			this.currentSlide = minmax(n, 0, 2)
		},
		close() {
			this.$emit('close')
		}
	}
}
</script>

<style lang="stylus" scoped>

#app > .low-perf
	.modal-chatbot-infos
		transition none
		.bg, .content
			transition none
			backdrop-filter none

.modal-chatbot-infos
	fixed 0
	flex center center
	z-index 100000
	--slideWidth 720px
	--slideGap 32px
	--slidePadding 64px

	&.modal-chatbot-infos-enter-active
		transition 0.2s easeOutQuart
		.bg
			transition 0.2s easeOutQuart
		.content
			transition 0.2s easeOutBack
	&.modal-chatbot-infos-enter-from
		.bg
			opacity 0
		.content
			opacity 0
			transform scale(0.95)

	&.modal-chatbot-infos-leave-active
		transition 0.25s easeOutQuart
	&.modal-chatbot-infos-leave-to
		opacity 0

	.bg
		background-color alpha(#446364, 70%)
		absolute 0
		backdrop-filter blur(8px)
		transition 0.2s easeOutQuart

	.content

		width calc(100% - 32px)
		max-width calc(var(--slideWidth) + calc(var(--slidePadding) * 2))
		padding 32px 0
		// height calc(100% - 64px)
		// max-height 576px
		background-color #fff
		text-align center
		border-radius 16px
		box-shadow 0 0 16px alpha(#000, 25%)

		.title
			padding 0 32px 32px 32px
			margin-bottom 24px
			font-size 2.4rem
			color #3B5F5F
			&:after
				content ''
				position absolute
				bottom 0
				left 64px
				right 64px
				border-bottom 2px solid #3B5F5F

		.carousel
			width 100%
			// padding 16px 0
			flex center flex-start column
			gap 16px
			.carousel-content
				overflow hidden
				width 100%
				.slides
					flex flex-start flex-start
					gap var(--slideGap)
					padding 8px 0
					transition 0.25s easeInOutQuart
					for i in 0..5
						&.slide-{i}
							transform 'translateX(calc((var(--slideWidth) + var(--slideGap)) * %s + var(--slidePadding)) )' % (- i)
					.slide
						width var(--slideWidth)
						flex center flex-start column
						gap 16px
						flex-shrink 0
						cursor pointer
						.img
							width var(--slideWidth)
							aspect-ratio 1.722
							border-radius 16px
							flex-shrink 0
							box-shadow 0 0 8px alpha(#000, 50%)
						.text
							width 100%
							height 48px
							flex flex-end center
							flex-shrink 0
							font-size 1.8rem
							text-align center

			.dots
				width 100%
				height 12px
				flex center center
				.dot
					width 24px
					height 24px
					flex-shrink 0
					border-radius 50%
					background-color #3B5F5F
					opacity 0.5
					transition 0.125s easeInOutQuart
					cursor pointer
					border 6px solid #fff
					&:hover
						opacity 1
					&.active
						opacity 1
						transform scale(1.25)

			.arrow
				position absolute
				top calc(50% - 20px)
				width 80px
				height 80px
				cursor pointer
				&.disabled
					filter grayscale(1) brightness(0.75)
					cursor not-allowed
				&:not(.disabled)
					&:hover
						transform scale(1.05)
					&:active
						transform scale(1)
				&.prev
					left -88px
				&.next
					right -88px
				.icon
					absolute 0
					background center center no-repeat
	.close
		position absolute
		right -8px
		top -8px
		width 40px
		height 40px
		background-color #3B5F5F
		box-shadow 0 0 0 3px #fff
		color #fff
		border-radius 50%
		flex center center
		cursor pointer
		transition 0.1s easeOutQuart
		&:hover
			transform scale(1.1)
		&:active
			transform scale(0.9)
		svg
			width 75%
			height 75%


@media (max-width: 1030px)
	.modal-chatbot-infos
		--slideWidth 520px

@media (max-width: 830px)
	.modal-chatbot-infos
		// --slideWidth 375px
		// --slideGap 24px
		.content .carousel .arrow
			&.prev
				left -24px
			&.next
				right -24px

@media (max-width: 730px)
	.modal-chatbot-infos
		--slideWidth 375px
		--slideGap 24px

@media (max-width: 550px)
	.modal-chatbot-infos
		--slideWidth 400px
		--slideGap 16px
		--slidePadding 16px
		.content
			max-width calc(var(--slideWidth) + 32px)
			.title
				padding-left 16px
				padding-right 16px
				&:after
					left 16px
					right 16px
			.carousel
				.carousel-content .slides .slide .text
					font-size 1.7rem
				.arrow
					width 64px
					height 64px
					&.prev
						left -20px
					&.next
						right -20px

@media (max-width: 470px)
	.modal-chatbot-infos
		--slideWidth 300px
		--slideGap 16px
		.content
			padding 16px 0
			.title
				padding-bottom 16px
				margin-bottom 8px
				font-size 2.2rem

</style>
