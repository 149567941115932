<template>
	<div v-if="formStatus === 'ready'" class="page-form-maintenance">
		<div class="vertical-bar"></div>

		<div class="content">
			<transition-group name="sub-element" mode="out-in">
				<div v-for="subElement in currentSubElements" :key="subElement.id" class="sub-element">
					<!-- <p>[ {{ subElement.entry.__typename }} ]</p> -->
					<form-maintenance-audio-player v-if="subElement.entry.__typename === 'ContentTextBaseQuery' && subElement.entry.tags.includes('timer-5')" :sub-element="subElement" />
					<form-maintenance-bubble v-else-if="subElement.entry.__typename === 'ContentTextBaseQuery'" :sub-element="subElement" :decal="true" />
					<form-maintenance-select v-else-if="subElement.entry.__typename === 'ContentFormSelectInputBaseQuery'" :sub-element="subElement" />
					<form-maintenance-accept v-else-if="subElement.entry.__typename === 'ContentFormAcceptInputBaseQuery'" :sub-element="subElement" />
					<form-maintenance-text-input v-else-if="subElement.entry.__typename === 'ContentFormTextInputBaseQuery'" :sub-element="subElement" />
					<form-maintenance-text-input v-else-if="subElement.entry.__typename === 'ContentFormNumberInputBaseQuery'" :sub-element="subElement" />
				</div>
			</transition-group>
		</div>

		<div v-if="currentSubElements.length < currentContainer?.entry.subElements.length" class="next-hint" v-tap="nextHint">
			<div class="text">{{ $t('formMaintenance.pressToContinue1') }} <span class="icon" v-bg="'yellow-arrow-right.svg'"></span> {{ $t('formMaintenance.pressToContinue2') }}</div>
		</div>

		<teleport to="#app .app-content">
			<trivia v-if="isTrivia" />
		</teleport>
	</div>

	<div v-else-if="formStatus === 'ended'" class="page-form-maintenance end">
		<form-maintenance-bubble v-for="bubble in bubbles" :key="bubble.id" :sub-element="bubble" />
		<form-maintenance-link-box v-for="link in links" :key="link.id" :sub-element="link" />
	</div>
</template>

<script>
import { mapState, mapWritableState } from 'pinia'
import { useFormMaintenanceStore } from '@/store/form-maintenance'
import FormMaintenanceBubble from '@/components/notepad/form-maintenance/form-maintenance-bubble.vue'
import FormMaintenanceSelect from '@/components/notepad/form-maintenance/form-maintenance-select.vue'
import FormMaintenanceAccept from '@/components/notepad/form-maintenance/form-maintenance-accept.vue'
import FormMaintenanceTextInput from '@/components/notepad/form-maintenance/form-maintenance-text-input.vue'
import FormMaintenanceLinkBox from '@/components/notepad/form-maintenance/form-maintenance-link-box.vue'
import FormMaintenanceAudioPlayer from '@/components/notepad/form-maintenance/form-maintenance-audio-player.vue'
import Trivia from '@/components/trivia.vue'

export default {
	name: 'page-form-maintenance',
	computed: {
		...mapState(useFormMaintenanceStore, [ 'progression', 'currentContainer', 'currentSubElements', 'formStatus', 'currentTriviaStep' ]),
		...mapWritableState(useFormMaintenanceStore, [ 'currentSubElementVisible' ]),
		bubbles() {
			return this.progression?.currentContent?.contentTexts
				?.filter(t => t?.tags?.includes('avatar'))
				?.map(t => ({
					entry: { ...t }
				})) || []
		},
		links() {
			return this.progression?.currentContent?.clientLinks
		},
		isTrivia() {
			return this.currentTriviaStep ? true : false
		}
	},
	methods: {
		nextHint() {
			this.currentSubElementVisible++
		}
	},
	components: { FormMaintenanceBubble, FormMaintenanceSelect, FormMaintenanceAccept, FormMaintenanceTextInput, FormMaintenanceLinkBox, FormMaintenanceAudioPlayer, Trivia }
}
</script>

<style lang="stylus" scoped>

#mobile
	.page-form-maintenance
		.vertical-bar
			left 40px
			min-height 250px

.page-form-maintenance
	padding 16px 8px
	min-height 100%
	&.end
		.form-maintenance-bubble
			margin 0 0 16px 0
		.form-maintenance-link-box
			margin 0 0 8px 0
		.separator
			height 2px
			background-color #A7BEB7
			margin 0 0 8px 0
	.vertical-bar
		position absolute
		left 72px
		top 0
		bottom 0
		height 100%
		width 1px
		background-color #FBEEE2
	.content
		width calc(100% - 80px)
		margin-left 80px
		.sub-element
			&.sub-element-enter-active
				transition 0.25s easeOutQuart
				for i in 1..10
					&:nth-child({i})
						transition-delay (0.05s * (i - 1))
			&.sub-element-enter-from
				transform translateY(-32px)
				opacity 0
	.next-hint
		flex center center
		margin 0 auto
		text-align center
		color #467464
		opacity 0.5
		user-select none
		font-style italic
		&:hover
			opacity 0.75
		.text
			inline-flex center center
			cursor pointer
			.icon
				display inline-block
				width 48px
				height 48px
				background center center no-repeat



</style>
