<template>
	<div class="invite">

		<div class="bg">
			<div class="plant" v-bg="'login/plant-light-green.svg'"></div>
			<div class="shadow"></div>
		</div>

		<div v-if="ready" class="content">
			<div class="part left">

				<div class="welcome">{{ $t('reset_password.welcome1') }}</div>
				<p>{{ $t('reset_password.welcome2') }}</p>

			</div>

			<div v-if="progression" class="part right">

				<form class="form" @submit.prevent="submit()">
					<div v-for="field in filteredFields" :key="field.id" class="row">
						<label :for="'field-' + field.id" class="label">{{ field.label }}</label>
						<input :type="field.type" class="input" v-model="field.value" :id="'field-' + field.id" autocomplete="off" required>
					</div>
					<div class="buttons">
						<ui-button type="submit" size="big" :loading="loading">{{ $t('reset_password.formSubmitButton') }}</ui-button>
					</div>
				</form>
			</div>

			<div v-else class="part right">

				<div class="error">
					<p class="message"><ui-text :text="$t('reset_password.invalidUserText')" /></p>
					<ui-button size="big" v-tap="() => $router.push({ name: 'home' })">{{ $t('reset_password.invalidUserButton') }}</ui-button>
				</div>

			</div>
		</div>

		<div v-else class="main-loading">
			<ui-loader />
		</div>

	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useAppStore } from '@/store/app'
import { initResetPassword, submitResetPassword } from '@/requests/on-boarding'
import { getUser } from '@/requests/login'
import UiLoader from '@/components/ui/ui-loader.vue'

export default {
	data() {
		return {
			ready: false,
			loading: false,
			progression: null,
			fields: []
		}
	},
	computed: {
		...mapState(useAppStore, [ 'user' ]),
		filteredFields() {
			return this.fields.filter(f => !f.invisible)
		}
	},
	methods: {
		async submit() {
			if (!this.loading) {
				this.loading = true
				const { addNotification, setLoggedUser } = useAppStore()
				const response = await submitResetPassword({
					progression: this.progression,
					values: this.fields
				})
				if (response?.score === 1) {
					addNotification({ status: 'success', title: this.$t('reset_password.submitSuccessTitle'), text: this.$t('reset_password.submitSuccessText') })
					const user = await getUser()
					if (user) setLoggedUser({ user })
					this.$router.push({ name: 'home' })
				} else
					addNotification({ status: 'error', title: this.$t('reset_password.submitErrorTitle'), text: this.$t('reset_password.submitErrorText') })
				this.loading = false
			}
		}
	},
	async created() {
		const progression = await initResetPassword()
		if (progression) {
			this.progression = progression
			this.fields = progression.currentMark.step.formElements
				.filter(formElement => formElement.entryType && formElement.entry)
				.map(formElement => {
					return {
						id: formElement.id,
						label: formElement.entry?.label,
						type: formElement.entryType.toLowerCase().indexOf('password') > -1 ? 'password' : 'text',
						invisible: formElement.entry?.tags?.includes('invisible'),
						value: formElement.entry?.tags?.includes('username') ? this.user.email : '',
					}
				})
		}

		this.ready = true
	},
	components: { UiLoader }
}
</script>

<style lang="stylus" scoped>

#mobile, #tablet-vertical, #tablet-horizontal
	.invite
		.bg
			.shadow
				absolute 0
				width 100%
				height 38%
				transform none
			.plant
				opacity 0.25
		.content
			width 100%
			max-width 600px
			margin 0 auto
			.part
				top auto
				bottom auto
				height auto
				width 100%
				left 0
				right 0
				transform none
				padding 16px
				&.left
					top 0
					height 38%
					flex center center column
					text-align center
					.welcome
						font-size 2.4rem
					p
						font-size 1.6rem
				&.right
					bottom 0
					height 62%
					padding 16px
					flex center center column
					.form
						width 100%
						z-index 1
						.row
							margin 0 0 16px 0
							.label
								margin 0 0 4px 0
								font-size 1.6rem
							.input
								height 40px
						.buttons
							padding-top 8px
							flex center center
							.ui-button
								display block
								width 100%

#tablet-vertical, #tablet-horizontal
	.invite
		.content
			.part
				&.left
					.welcome
						font-size 2.8rem

#mobile
	.invite
		.content
			.part
				&.left
					.welcome
						margin 0 0 4px 0
					p
						margin 0 0 4px 0
				&.right
					.form
						z-index 1
						padding-bottom 32px
						.row
							margin 0 0 12px 0
							.input
								height 32px


.invite
	absolute 0
	flex center center
	color #fff
	height 100%

	.bg
		absolute 0
		background-color #3A5F5F
		.plant
			absolute -200px
			background left 200px top 200px no-repeat
			opacity 0.5
			transform rotate(15deg)
		.shadow
			position absolute
			top -20%
			left -20%
			bottom -20%
			width 55%
			transform rotate(15deg)
			background-color alpha(#647B7C, 75%)

	.content
		width 100%
		height 100%
		flex center space-between

		.part
			position absolute
			top 50%
			transform translateY(-50%)
			&.left
				left 40px
				width 30%
				.welcome
					margin 0 0 16px 0
					font-size 5rem
					text-transform uppercase
					line-height 1.2em
				p
					margin 0 0 16px 0
					font-size 2rem
					font-weight 300
					line-height 1.5em
					&:last-child
						margin 0
			&.right
				right 15%
				width 500px
				.form
					.row
						display block
						width 100%
						margin 0 0 32px 0
					.label
						display block
						width 100%
						text-transform uppercase
						margin 0 0 8px 0
						font-weight 500
						font-size 1.8rem
					.input
						display block
						width 100%
						height 48px
						padding 0 16px
						border none
						background #fff
						border-radius 4px
						font inherit
						font-size 1.8rem
						color dark
						outline none
						text-align left
					.buttons
						flex center flex-end
						.ui-button
							width 240px
							height 64px
							padding 0 40px
							background-color #687E7F
							font-size 1.8rem
							line-height 1.2em
				.error
					flex center center column
					.message
						width 100%
						margin 0 0 32px 0
						padding 16px
						background-color red
						border-radius 6px
						color #fff
						text-align center
						font-weight 500
						text-transform uppercase
	.main-loading
		absolute 0
		background-color #3A5F5F
		color #fff
		::v-deep(.ui-loader) svg
			width 64px
			height 64px




</style>
