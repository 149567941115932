<template>
	<div class="game-content-trivia">

		<div :key="currentQuestion.id" class="game">

			<template v-if="!feedbackVisible">
				<div class="question">
					<p class="subtitle">Question {{ currentQuestionIndex + 1 }}/{{ questions.length }}</p>
					<ui-text :text="currentQuestion.contentTexts.find(a => a.tags.includes('question')).html" />
				</div>
				<div class="answers">
					<div v-for="answer in currentQuestion.answers" :key="answer.id" class="answer"
						:class="{ wrong: selectedAnswer?.id === answer.id && !answer.score, correct: selectedAnswer && answer.score, selected: selectedAnswer?.id === answer.id }"
						@click="() => sendAnswer(answer)"
					>
						<ui-text :text="answer.contentTexts.find(a => a.tags.includes('answer')).html" />

						<transition name="answering-icon">
							<span v-if="selectedAnswer && answer.score" v-bg="'game-content-trivia/answer-success.svg'" class="answering-icon"></span>
							<span v-else-if="selectedAnswer?.id === answer.id && !answer.score" v-bg="'game-content-trivia/answer-wrong.svg'" class="answering-icon"></span>
						</transition>
					</div>
				</div>
				<div></div>
			</template>
			<template v-else>
				<div class="feedback">
					<ui-text :text="selectedAnswer.contentTexts.find(a => a.tags.includes('feedback')).html" />
					<ui-button size="big" @click="nextQuestion">Continuer</ui-button>
				</div>
			</template>
		</div>

	</div>
</template>

<script>
import { wait } from '@affordancestudio/functions'

export default {
	data() {
		return {
			questions: [
				{
					id: 1,
					contentTexts: [
						{ id: 1, html: "Vrai ou faux? Selon les botanistes, un concombre est, en fait, un fruit.", tags: [ 'question' ] },
					],
					answers: [
						{
							id: 1,
							score: 1,
							contentTexts: [
								{ id: 1, html: "Vrai", tags: [ 'answer' ] },
								{ id: 2, html: "Bravo! Le terme \"légume\" n'existe pas en botanique. Le fruit est le résultat de la rencontre du pollen d’une fleur mâle avec l’ovule d’une fleur femelle. Un concombre est donc bien un fruit, d'un point de vue botanique.", tags: [ 'feedback' ] },
							]
						},
						{
							id: 2,
							score: 0,
							contentTexts: [
								{ id: 1, html: "Faux", tags: [ 'answer' ] },
								{ id: 2, html: "Oups! Le terme \"légume\" n'existe pas en botanique. Le fruit est le résultat de la rencontre du pollen d’une fleur mâle avec l’ovule d’une fleur femelle. Un concombre est donc bien un fruit, d'un point de vue botanique.", tags: [ 'feedback' ] },
							]
						},
					]
				},
				{
					id: 2,
					contentTexts: [
						{ id: 1, html: "Suite à la pollinisation, les fruits sont les résultats d'une modification de...", tags: [ 'question' ] },
					],
					answers: [
						{
							id: 1,
							score: 1,
							contentTexts: [
								{ id: 1, html: "Des fleurs de la plante", tags: [ 'answer' ] },
								{ id: 2, html: "Bravo! Ce sont bien les fleurs qui, une fois fécondées grâce à la pollinisation, vont donner des fruits.", tags: [ 'feedback' ] },
							]
						},
						{
							id: 2,
							score: 0,
							contentTexts: [
								{ id: 1, html: "De la tige de la plante", tags: [ 'answer' ] },
								{ id: 2, html: "Oups! Ce sont bien les fleurs qui, une fois fécondées grâce à la pollinisation, vont donner des fruits.", tags: [ 'feedback' ] },
							]
						},
						{
							id: 3,
							score: 0,
							contentTexts: [
								{ id: 1, html: "D'une feuille spéciale de la plante", tags: [ 'answer' ] },
								{ id: 2, html: "Oups! Ce sont bien les fleurs qui, une fois fécondées grâce à la pollinisation, vont donner des fruits.", tags: [ 'feedback' ] },
							]
						},
					]
				},
				{
					id: 3,
					contentTexts: [
						{ id: 1, html: "Les graines d'une plante se forment à partir de...", tags: [ 'question' ] },
					],
					answers: [
						{
							id: 1,
							score: 1,
							contentTexts: [
								{ id: 1, html: "D'ovules de la fleur femelle", tags: [ 'answer' ] },
								{ id: 2, html: "Bravo! Au moment de la pollinisation, les cellules de la fleur vont se différencier. Ce sont les ovules qui deviendront les graines.", tags: [ 'feedback' ] },
							]
						},
						{
							id: 2,
							score: 0,
							contentTexts: [
								{ id: 1, html: "D'ovaire de la fleur femelle", tags: [ 'answer' ] },
								{ id: 2, html: "Oups! Au moment de la pollinisation, les cellules de la fleur vont se différencier. Ce sont les ovules qui deviendront les graines.", tags: [ 'feedback' ] },
							]
						},
						{
							id: 3,
							score: 0,
							contentTexts: [
								{ id: 1, html: "D'étamines de la fleur mâle.", tags: [ 'answer' ] },
								{ id: 2, html: "Oups! Au moment de la pollinisation, les cellules de la fleur vont se différencier. Ce sont les ovules qui deviendront les graines.", tags: [ 'feedback' ] },
							]
						},
					]
				},
			],
			currentQuestionIndex: 0,
			selectedAnswer: null,
			feedbackVisible: false
		}
	},
	computed: {
		currentQuestion() {
			return this.questions[this.currentQuestionIndex]
		}
	},
	methods: {
		async sendAnswer(answer) {
			this.selectedAnswer = answer
			await wait(1500)
			this.feedbackVisible = true
			// this.selectedAnswer = null
		},
		async nextQuestion() {
			this.currentQuestionIndex++
			this.selectedAnswer = null
			this.feedbackVisible = false
		}
	}
}
</script>

<style lang="stylus" scoped>

.game-content-trivia

	.game
		min-height 496px
		flex center space-between column
		gap 32px
		padding 8px 32px
		.question
			width 100%
			font-size 2rem
			text-align center
			.subtitle
				width 100%
				margin 0 0 8px 0
				text-transform uppercase
				font-size 1.6rem
				font-weight 500
				color primary
		.answers
			width 100%
			flex center center column
			gap 24px
			.answer
				width 100%
				padding 24px 16px
				background-color #f5f5f5
				box-shadow 0 0 8px alpha(#000, 50%)
				border 3px solid transparent
				border-radius 8px
				font-size 1.8rem
				text-align center
				cursor pointer
				&:hover
					background-color #eee
				&.correct
					background-color alpha(#4C9820, 35%)
					box-shadow 0 0 8px alpha(#4C9820, 80%)
					border-color #4C9820
					// color #fff
				&.wrong
					background-color alpha(#EF8080, 35%)
					box-shadow 0 0 8px alpha(#EF8080, 80%)
					border-color #EF8080
					// color #fff
					animation 0.2s error linear 0.4s
					animation-iteration-count 3
					.answering-icon
						background-size 65%
					@keyframes error
						0%
							transform translateX(0)
						25%, 75%
							transform translateX(4px)
						50%
							transform translateX(-4px)
				.answering-icon
					position absolute
					right -16px
					top -16px
					width 48px
					height 48px
					background center center no-repeat
					background-size 65%
					z-index 1
					&.answering-icon-enter-active
						transition transform 0.25s easeInQuart, opacity 0.25s easeOutSine
					&.answering-icon-enter-from
						transform scale(1.5)
						opacity 0
		.feedback
			width 100%
			flex center center column
			gap 32px
			min-height @min-height
			font-size 2rem
			text-align center
		.buttons
			width 100%
			flex center center

</style>
