<template>
	<div class="side-panel-home">

		<div class="side secondary green has-boxes">
			<div class="main-content">
				<div class="title">{{ $t('adminDashboard.panels.home.title') }}</div>
				<div class="actions">
					<ui-round-button v-if="features.district.includes('add')" :disabled="selectedItems.length" v-tap="() => !selectedItems.length && addModal({ slug: 'add', type: 'district' })" :tooltip="$t('adminDashboard.tooltips.addDistrict')" color="white" icon="admin/plus-green.svg" />
					<ui-round-button v-if="features.district.includes('disable')" :disabled="!selectedItems.length" v-tap="() => selectedItems.length && addModal({ slug: 'disable', type: 'district', items: selectedItems })" :tooltip="$t('adminDashboard.tooltips.disableSelectedDistrict')" color="yellow" icon="admin/disable-green.svg" />
					<ui-round-button v-if="features.district.includes('remove')" :disabled="!selectedItems.length" v-tap="() => selectedItems.length && addModal({ slug: 'remove', type: 'district', items: selectedItems })" :tooltip="$t('adminDashboard.tooltips.deleteSelectedDistrict')" color="red" icon="admin/trash-green.svg" />
				</div>
			</div>
			<div class="buttons">
				<ui-button size="big" :block="true" v-tap="() => addPanel({ slug: 'school', params: { school: null } })">{{ $t('adminDashboard.panels.home.invitationsButton') }}</ui-button>
			</div>
		</div>

		<div class="side primary white">
			<list-items
				item-format="district"
				:nb-cols="2"
				:request="{ name: 'invitedDistrict' }"
				@change-selection="(items) => selectedItems = items"
			/>
		</div>

	</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useAdminDashboardStore } from '@/store/admin-dashboard'
import UiRoundButton from '@/components/ui/ui-round-button.vue'
import ListItems from '@/components/admin-dashboard/list/list-items.vue'

export default {
	data() {
		return {
			selectedItems: []
		}
	},
	computed: {
		...mapState(useAdminDashboardStore, [ 'features' ]),
	},
	methods: {
		...mapActions(useAdminDashboardStore, [ 'addPanel', 'addModal' ]),
	},
	components: { UiRoundButton, ListItems }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.side-panel-home
	.side.secondary
		flex center center column
		gap 16px
		text-align center
		.main-content
			height 100%
			width 100%
			flex-shrink 1
			flex center center column
			gap 16px
			
			.title
				font-size 4rem
				text-transform uppercase
			.actions
				flex center center
				gap 16px
				.action
					width 64px
					height 64px
					border-radius 50%
					cursor pointer
		.buttons
			width 100%
			flex center center
			gap 16px
			.ui-button
				height 64px
				&.square
					width 64px
					padding 0
					flex-shrink 0
				.icon
					display inline-block
					width 24px
					height 24px
					background center center no-repeat

</style>