<template>
	<div v-if="page" class="page-no-access">

		<div class="icon">
			<img src="@/assets/img/notepad/no-access.png">
		</div>
		<p class="description">{{ $t('noAccess.sentence1') }}</p>

		<div class="buttons">
			<ui-button size="big" v-tap="close">{{ $t('noAccess.close') }}</ui-button>
		</div>

	</div>
</template>

<script>
import { mapActions } from 'pinia'
import { useNotepadStore } from '@/store/notepad'

export default {
	name: 'page-no-access',
	props: {
		page: { default: null }
	},
	methods: {
		...mapActions(useNotepadStore, [ 'removePage' ]),
		close() {
			this.removePage({ page: this.page })
			// this.$router.push({ name: 'home' })
		}
	}
}
</script>

<style lang="stylus" scoped>

.page-no-access
	padding 32px 16px
	text-align center
	font-size 1.8rem
	line-height 1.5em
	.icon
		margin 0 0 8px 0
		flex center center
		img
			width 100%
			height auto
	> .title
		font-size 2rem
		font-weight 500
		color #3b5f5f
		text-transform uppercase
	> .description
		margin 0 0 32px 0
		padding 0 16px
		font-size 2rem
		font-weight 400
	.buttons
		flex center center
	
</style>
