import { defineStore } from 'pinia'
import { useAppStore } from '@/store/app'
import { getCalendarEvents, getCalendarTimelines } from '@/requests/calendar'

export const useCalendarStore = defineStore({
	id: 'calendar',

	state: () => ({
		loadingState: 'not-loaded',
		rawCalendar: null,
		rawEvents: { default: [] },
		rawTimelines: { default: [] },
		rawSeedsGroups: [],
		activeCalendarId: null,
		highlightEventTimelineId: null,

		eventDetail: null,
		addingSeed: null,
		viewingSeed: null,
		editMode: false,
		editingEvent: null,
		editingEventNewDate: false,
		removingEventVisible: false,
	}),

	getters: {
		canEdit() {
			return [ 'vireo', 'district', 'school', 'teacher' ].includes(useAppStore().role)
		},
		allowedEditDates() {
			if (this.editingEvent?.delta && this.editingEvent?.relativeDate) {
				const formatDate = (date) => date.getUTCFullYear() + '-' + ("0" + (date.getUTCMonth() + 1)).slice(-2) + '-' + ("0" + date.getUTCDate()).slice(-2)
				
				const dates = [ formatDate(new Date(this.editingEvent.relativeDate)) ]
				for (let i = 1; i <= this.editingEvent.delta; i++) {
					let dateBefore = new Date(this.editingEvent.relativeDate)
					dateBefore.setUTCDate(dateBefore.getUTCDate() - i)
					dates.push(formatDate(dateBefore))

					let dateAfter = new Date(this.editingEvent.relativeDate)
					dateAfter.setUTCDate(dateAfter.getUTCDate() + i)
					dates.push(formatDate(dateAfter))
				}
				return dates
			}
			return []
		},
		events() {
			if (this.activeCalendarId) return this.rawEvents?.[this.activeCalendarId] || []
			return this.rawEvents.default
		},
		timelines() {
			if (this.activeCalendarId) return this.rawTimelines?.[this.activeCalendarId] || []
			return this.rawTimelines.default
		}
	},

	actions: {

		async initCalendar({ userId, force = false } = {}) {
			if (this.loadingState === 'not-loaded' || force) {
				this.loadingState = 'loading'
				let promises = [ getCalendarEvents(userId) ]
				if (!userId) promises.push(getCalendarTimelines())
				promises = await Promise.all(promises)
				this.updateCalendarEvents(promises[0], userId)
				if (promises.length > 1) this.rawSeedsGroups = promises[1]?.data
				this.loadingState = 'loaded'
			}
		},

		
		// param peut être un tableau de plusieurs calendriers (on conserve le premier) ou un objet calendrier
		updateCalendarEvents(param, userId = 'default') {
			if (param) {
				if (Array.isArray(param)) {
					if (param.length) this.rawCalendar = param[0]
				} else if (param?.id && param?.abilities?.length) {
					this.rawCalendar = param
				}
				if (this.rawCalendar?.calendarEvents) this.rawEvents[userId] = this.rawCalendar.calendarEvents
				if (this.rawCalendar?.calendarTimelines) this.rawTimelines[userId] = this.rawCalendar.calendarTimelines
			}
		},

		resetEditingState() {
			this.addingSeed = null
			this.viewingSeed = null
			this.editMode = false
			this.editingEvent = null
			this.editingEventNewDate = false
			this.removingEventVisible = false
		}


	},

})
