<template>
	<div v-if="archives?.length" class="page-archives-section">

		<archive-list-item v-for="archive in archives" :key="archive.id" :page="page" :archive="archive" />

		<div v-if="moreButtonVisible" class="buttons">
			<ui-button class="more" size="big" :loading="loading" v-tap="loadNextArchives">{{ $t('archives.loadMore') }}</ui-button>
		</div>
	</div>
	<div v-else-if="loading" class="page-archives-section">
		<div class="main-loading"><ui-loader /></div>
	</div>
	<div v-else-if="lastMeta" class="page-archives-section">
		<p class="no-result">{{ $t('archives.noArchiveFound') }}</p>
	</div>
	<div v-else-if="!isConnected || hasNoGroup || !archives?.length" class="page-archives-section">
		<p class="no-result">{{ $t('archives.noArhiveToDisplay') }}</p>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useAppStore } from '@/store/app'
import { useNotepadStore } from '@/store/notepad'
import { useFormMaintenanceStore } from '@/store/form-maintenance'
import { initArchivesPagination, nextArchivesPagination } from '@/requests/notepad'
import ArchiveListItem from '@/components/notepad/archives/archive-list-item'

export default {
	name: 'page-archives-section',
	data() {
		return {
			loading: false,
			lastMeta: null,
			archives: [],
			groupId: null
		}
	},
	props: {
		page: { default: null }
	},
	computed: {
		...mapState(useAppStore, [ 'isConnected', 'user', 'currentUserGroupId' ]),
		...mapState(useNotepadStore, [ 'pages' ]),
		...mapState(useFormMaintenanceStore, [ 'formStatus' ]),
		hasNoGroup() {
			return this.user?.groups?.length === 0
		},
		maintenanceSection() {
			return this.pages.find(s => s.slug === process.env.VUE_APP_MAINTENANCE_SECTION_SLUG)
		},
		moreButtonVisible() {
			if (this.lastMeta && this.lastMeta.limit && this.lastMeta.page && this.lastMeta.count) {
				return (this.lastMeta.count > this.lastMeta.page * this.lastMeta.limit)
			}
			return false
		}
	},
	watch: {
		formStatus(formStatus) {
			if (formStatus === 'ended') {
				this.lastMeta = null
				this.archives = []
				this.loadNextArchives()
			}
		},
		'page.zIndex'() {
			if (this.currentUserGroupId && this.groupId && this.currentUserGroupId !== this.groupId) {
				this.groupId = this.currentUserGroupId
				this.lastMeta = null
				this.archives = []
				this.loadNextArchives()
			}
		}
	},
	methods: {
		async loadNextArchives() {
			if (!this.loading && this.isConnected && this.maintenanceSection?.id) {
				this.loading = true

				const response = (!this.archives.length) ?
					await initArchivesPagination({ sectionId: this.maintenanceSection.id }) :
					await nextArchivesPagination()
				if (response?.data?.length) this.archives = [ ...this.archives, ...response.data ]
				if (response?.meta) this.lastMeta = response.meta

				this.loading = false
			}
		}
	},
	created() {
		this.groupId = this.currentUserGroupId
		this.loadNextArchives()
	},
	components: { ArchiveListItem }
}
</script>

<style lang="stylus" scoped>

.page-archives-section
	padding 16px
	.main-loading
		padding 64px 0
		text-align center
	.no-result
		padding 32px 0
		text-align center
		text-transform uppercase
		color #999
	.buttons
		flex center center


</style>
