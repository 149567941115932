import { createRouter, createWebHistory } from 'vue-router'
import { useAppStore } from '@/store/app'
import Home from '@/components/home.vue'
import Notepad from '@/components/notepad.vue'
import Login from '@/components/login/login.vue'
import Dashboard from '@/components/dashboard/dashboard.vue'
import AdminDashboard from '@/components/admin-dashboard/admin-dashboard.vue'
import Invite from '@/components/invite.vue'
import ResetPassword from '@/components/reset-password.vue'

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
		// meta: {
		// 	tutos: [ 'intro', 'map' ] // A l'arrivée sur cette route, lance le 1er tuto, s'il est déjà fait lance le 2ème, etc...
		// }
	},
	{
		path: '/my-garden-experiments',
		name: 'my-garden-experiments',
		component: Notepad,
		meta: {
			sectionNames: [ process.env.VUE_APP_MAINTENANCE_SECTION_SLUG, 'archives' ], // A l'arrivée sur cette route, affiche les sections ayant ce slug
			checkpoint: 'explore_experience_potager', // A l'arrivée sur cette route, on complete ce checkpoint
			tuto: 'section_experience_potager'
		}
	},
	{
		path: '/guide-to-gardening/:isHashSection(s)?/:sectionId?/:pageIndex(\\d+)?',
		name: 'guide-to-gardening',
		component: Notepad,
		meta: {
			sectionNames: [ 'guide-du-potager' ],
			checkpoint: 'explore_guide_potager',
			tuto: 'section_guide_potager'
		}
	},
	{
		path: '/library-articles/:isHashSection(s)?/:sectionId?/:pageIndex(\\d+)?',
		name: 'library-articles',
		component: Notepad,
		meta: {
			sectionNames: [ 'bibliotheque' ],
			checkpoint: 'explore_coin_bibliotheque',
			tuto: 'section_coin_bibliotheque'
		}
	},
	{
		path: '/lesson-plans/:isHashSection(s)?/:sectionId?/:pageIndex(\\d+)?',
		name: 'lesson-plans',
		component: Notepad,
		meta: {
			sectionNames: [ 'activites-educatives' ],
			checkpoint: 'explore_activites_educatives',
			tuto: 'section_activites_educatives'
		}
	},
	{
		path: '/whats-next',
		name: 'whats-next',
		component: Notepad,
		meta: {
			sectionNames: [ 'nouveautes' ]
		}
	},
	{
		path: '/faq/:isHashSection(s)?/:sectionId?/:pageIndex(\\d+)?',
		name: 'faq',
		component: Notepad,
		meta: {
			sectionNames: [ 'faq' ],
			checkpoint: 'explore_faq',
			tuto: 'section_faq'
		}
	},
	{
		path: '/webinars',
		name: 'webinars',
		component: Notepad,
		meta: {
			sectionNames: [ 'webinars' ],
			checkpoint: 'explore_tv_vireo',
			tuto: 'section_tv_vireo'
		}
	},
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/invite',
		name: 'invite',
		component: Invite
	},
	{
		path: '/reset-password',
		name: 'reset-password',
		component: ResetPassword
	},
	{
		path: '/help',
		name: 'help',
		component: () => import('@/components/chatbot/chatbot.vue'),
		meta: {
			tuto: 'section_chatbot',
			checkpoint: 'explore_chatbot'
		}
	},
	{
		path: '/stats',
		name: 'stats',
		component: () => import('@/components/stats/stats.vue'),
	},
	{
		path: '/calendar',
		name: 'calendar',
		component: () => import('@/components/calendar/calendar.vue'),
		meta: {
			sectionNames: [ 'calendar' ],
		},
		children: [
			{
				path: 'details',
				name: 'calendar-details',
				component: () => import('@/components/calendar/page-calendar/page-calendar.vue')
			},
			{
				path: 'add-seeds',
				name: 'calendar-add-seed',
				component: () => import('@/components/calendar/page-add-seed/page-add-seed.vue')
			},
		]
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: Dashboard,
		meta: {
			authorizations: [ 'vireo', 'school', 'teacher' ],
			checkpoint: 'explore_tableau_de_bord'
		},
		children: [
			{
				path: 'groups',
				name: 'groups',
				component: () => import('@/components/dashboard/groups/groups.vue'),
				meta: { type: 'groups', authorizations: [ 'vireo', 'school', 'teacher' ] }
			},
			{
				path: 'groups/edit-profile',
				name: 'edit-profile',
				component: () => import('@/components/dashboard/groups/edit-profile.vue'),
				meta: { type: 'groups', backTo: 'groups', authorizations: [ 'vireo', 'school', 'teacher' ] }
			},
			{
				path: 'groups/add',
				name: 'add-group',
				component: () => import('@/components/dashboard/groups/add-group.vue'),
				meta: { type: 'groups', backTo: 'groups', authorizations: [ 'vireo', 'school', 'teacher' ] }
			},
			{
				path: 'groups/:id/members',
				name: 'group-members',
				component: () => import('@/components/dashboard/groups/group-members.vue'),
				meta: { type: 'groups', backTo: 'groups', authorizations: [ 'vireo', 'school', 'teacher' ] }
			},
			{
				path: 'groups/:id/admins',
				name: 'group-admins',
				component: () => import('@/components/dashboard/groups/group-admins.vue'),
				meta: { type: 'groups', backTo: 'groups', authorizations: [ 'vireo', 'school', 'teacher' ] }
			},
			{
				path: 'teachers',
				name: 'teachers',
				component: () => import('@/components/dashboard/teachers/teachers.vue'),
				meta: {
					type: 'teachers',
					authorizations: [ 'vireo', 'school' ],
					checkpoint: 'invitez_enseignants',
				}
			},
			{
				path: 'teachers/add',
				name: 'add-teacher',
				component: () => import('@/components/dashboard/teachers/add-teacher.vue'),
				meta: { type: 'teachers', backTo: 'teachers', authorizations: [ 'vireo', 'school' ] }
			},
			{
				path: 'schools',
				name: 'schools',
				component: () => import('@/components/dashboard/schools/schools.vue'),
				meta: { type: 'schools', authorizations: [ 'vireo' ] }
			},
			{
				path: 'schools/:id/members',
				name: 'school-members',
				component: () => import('@/components/dashboard/schools/school-members.vue'),
				meta: { type: 'schools', backTo: 'schools', authorizations: [ 'vireo' ] }
			},
			{
				path: 'teachers/add',
				name: 'add-school',
				component: () => import('@/components/dashboard/schools/add-school.vue'),
				meta: { type: 'schools', backTo: 'schools', authorizations: [ 'vireo' ] }
			},
		]
	},
	{
		path: '/admin',
		name: 'admin-dashboard',
		component: AdminDashboard,
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'not-found',
		redirect: { name: 'home' }
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

router.beforeEach((to, from, next) => {
	if (to.meta.authorizations) {
		if (to.meta.authorizations.includes(useAppStore().role)) {
			return next()
		} else
			return next({ name: 'home' })
	}
	next()
})

export default router
