const BackgroundImage = {}
BackgroundImage.install = function (Vue) {

	console.info('=== INIT LOCAL MEDIAS ===')

	// Récupération des images locales
	const localFiles = require.context('@/assets/img/', true, /^.*\.jpg|png|svg|gif$/)
		.keys()
		.map((localFile, id) => {
			const slug = localFile.substr(2)
			return { id, slug, url: require('@/assets/img/' + slug) }
		})

	const getUrl = function(slug) {

		// Recherche dans les images locales
		const file = localFiles.find(l => l.slug === slug)
		if (file) return file.url

		return slug
	}

	Vue.directive('bg', {
		beforeMount (el, binding) {
			if (binding.value) {
				// el.style.backgroundPosition = 'top left'
				el.style.backgroundSize = 'contain'
				// el.style.backgroundRepeat = 'no-repeat'
				el.style.backgroundImage = 'url(' + getUrl(binding.value) + ')'
			}
		},
		updated(el, binding) {
			if (binding.value) {
				// el.style.backgroundPosition = 'top left'
				el.style.backgroundSize = 'contain'
				// el.style.backgroundRepeat = 'no-repeat'
				el.style.backgroundImage = 'url(' + getUrl(binding.value) + ')'
			}
		}
	})

}

export default BackgroundImage
