<template>
	<div class="onboarding" :class="[ 'is-' + (isCollapsed ? 'collapsed' : 'expanded'), { unclickable: $route.name !== 'home', animated: completedCheckpoint }]">
		<div v-if="!isCollapsed" class="window-bg" v-tap="toggle"></div>
		<div class="bg">
			<div class="plus" v-tap="toggle">{{ isCollapsed ? '+' : '-' }}</div>
		</div>

		<div class="content">
			<transition name="part" mode="out-in" appear>
				<div v-if="isCollapsed" class="part collapsed" v-tap="toggle">

					<transition name="tuto" mode="out-in" appear>
						<div v-if="completedCheckpoint" class="tuto completed" key="completed">
							<div class="goal" v-html="completedCheckpoint?.document?.title"></div>
							<div class="checkbox" v-bg="'check-white.svg'">
								<div class="star star-1" v-bg="'star.svg'"></div>
								<div class="star star-2" v-bg="'star.svg'"></div>
							</div>
						</div>
						<div v-else-if="activeCheckpoint" class="tuto current" key="current">
							<div class="goal" v-html="activeCheckpoint?.document?.title"></div>
							<div class="avatar"></div>
						</div>
					</transition>

				</div>
				<div v-else class="part expanded">

					<div class="group-names">
						<div v-if="firstGroup?.document?.title" class="group-name left" v-html="firstGroup.document.title"></div>
						<div v-if="secondGroup?.document?.title" class="group-name right" v-html="secondGroup.document.title"></div>
					</div>

					<div class="main-bar">

						<div v-if="firstGroup?.children" class="dots">
							<div v-for="group in firstGroup.children.filter(g => g?.children?.length)" :key="group.id" class="dot"
								:class="{ checked: group.children.length && group.children.filter(t => t.status === 'completed').length === group.children.length, active: selectedGroup?.id === group.id }"
								v-tap="() => gotoGroup(group)"
							>
							</div>
						</div>

						<div class="avatar-bubble" v-bg="'avatar.svg'"></div>

						<div v-if="secondGroup?.children" class="dots">
							<div v-for="group in secondGroup.children.filter(g => g?.children?.length)" :key="group.id" class="dot"
								:class="{ checked: group.children.length && group.children.filter(t => t.status === 'completed').length === group.children.length, active: selectedGroup?.id === group.id }"
								v-tap="() => gotoGroup(group)"
							>
							</div>
						</div>

						<div class="arrow prev" v-bg="'yellow-arrow-left.svg'" v-tap="prev"></div>
						<div class="arrow next" v-bg="'yellow-arrow-right.svg'" v-tap="next"></div>
					</div>

					<div class="main-content">
						<transition :name="pageTransition" mode="out-in">
							<div v-if="selectedGroup" class="inner-content" :key="selectedGroup.id">
								<div class="title" v-html="selectedGroup?.document?.title"></div>
								<div v-if="selectedGroup.children" class="todos">
									<div v-for="tuto in selectedGroup.children" :key="tuto.id" class="todo">
										<div class="checkbox" :class="{ checked: tuto.status === 'completed' }"></div>
										<div class="text" v-html="tuto?.document?.title"></div>
									</div>
								</div>
							</div>
						</transition>
					</div>

				</div>
			</transition>
		</div>

	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useOnboardingStore } from '@/store/onboarding'
import { minmax } from '@affordancestudio/functions'

export default {
	data() {
		return {
			isCollapsed: true,
			selectedGroupIndex: 0,
			pageTransition: 'next'
		}
	},
	computed: {
		...mapState(useOnboardingStore, [ 'rawCheckpoints', 'groups', 'activeGroup', 'activeCheckpoint', 'completedCheckpoint' ]),
		firstGroup() {
			return this.rawCheckpoints?.length ? this.rawCheckpoints[0] : []
		},
		secondGroup() {
			return this.rawCheckpoints?.length > 1 ? this.rawCheckpoints[1] : []
		},
		selectedGroup() {
			return this.selectedGroupIndex <= this.groups.length ? this.groups[this.selectedGroupIndex] : null
		},
	},
	watch: {
		'$route.name'() {
			this.isCollapsed = true
		},
		selectedGroupIndex(newValue, oldValue) {
			this.pageTransition = newValue < oldValue ? 'prev' : 'next'
		},
		isCollapsed(s) {
			if (!s) this.gotoGroup(this.activeGroup)
		}
	},
	methods: {
		toggle() {
			this.isCollapsed = !this.isCollapsed
		},
		prev() {
			this.selectedGroupIndex = minmax(this.selectedGroupIndex - 1, 0, this.groups.length - 1)
		},
		next() {
			this.selectedGroupIndex = minmax(this.selectedGroupIndex + 1, 0, this.groups.length - 1)
		},
		gotoGroup(group) {
			const index = this.groups.findIndex(g => g.id === group.id)
			if (index > -1) this.selectedGroupIndex = minmax(index, 0, this.groups.length - 1)
		}
	},
}
</script>

<style lang="stylus" scoped>

#tablet-vertical .onboarding
	width calc(100% - 140px)

#tablet-vertical, #mobile
	.onboarding
		&.is-expanded
			height 100%
			.bg
				height calc(100% - 8px)
				.plus
					height 24px
					line-height @height
		.bg .plus
			height 16px
			font-size 2rem
			line-height @height
		.content
			height calc(100% - 88px)
			.part.expanded
				height 100%
				padding-left 16px
				padding-right 16px
				.group-names .group-name
					line-height 16px
					height 32px
					flex center center
				.main-bar
					border-radius 8px 8px 0 0
					.dots
						gap 16px
						.dot
							margin 0
							&:after
								display none
				.main-content
					height calc(100% - 8px)
					width 100%
					border-radius 0 0 8px 8px
					.inner-content .todos
						padding-bottom 16px

.onboarding
	position absolute
	left 50%
	top 0
	width calc(100% - 16px)
	max-width 700px
	transform translateX(-50%)
	transition 0.5s easeOutQuart
	user-select none
	&.animated
		z-index 100001
	&.unclickable
		pointer-events none
		.bg
			top -8px
			.plus
				opacity 0
	&.is-collapsed
		&:hover, &:active
			.bg .plus
				background-color darken(#FEDFAE, 15%)
		.bg
			transform 'translateY(-%s)' % (636 - 80px)
	&.is-expanded
		.window-bg
			fixed -2000px
		.bg
			transform translateY(0)
	&.onboarding-enter-from, &.onboarding-leave-to
		transform translate(-50%, -110%)
	.bg
		position absolute
		left 0
		right 0
		top 0
		height 636px
		flex flex-end center
		background #1F7374
		border-radius 0 0 8px 8px
		transition 0.5s easeOutQuart
		.plus
			background-color #FEDFAE
			width 80px
			height 12px
			line-height 12px
			font-weight 600
			border-radius 8px 8px 0 0
			text-align center
			color @background
			cursor pointer
			transition opacity 0.5s easeOutQuart
			&:hover, &:active
				background-color darken(#FEDFAE, 15%)
	.content
		.part
			&.part-enter-active, &.part-leave-active
				transition opacity 0.25s easeOutQuart
			&.part-enter-from, &.part-leave-to
				opacity 0
			&.collapsed
				cursor pointer
				padding 16px
				min-height 80px
				.tuto
					background-color #DDEBE1
					border-radius 8px
					padding 0 16px 0 56px
					height 40px
					&.tuto-enter-active
						transition transform 1s easeOutQuart, opacity 0.25s easeOutQuart
						.checkbox
							transition 0.25s easeOutQuart
							transition-delay 0.5s
							.star
								transition 0.25s easeOutBack
								transition-delay 0.75s
								&.star-2
									transition-delay 0.7s
					&.tuto-leave-active
						transition 0.5s easeInQuart
					&.tuto-enter-from
						transform translateY(-16px)
						opacity 0
						.checkbox
							transform scale(1.5)
							opacity 0
							.star
								opacity 0
								&.star-1
									transform scale(0.5) rotate(-8deg) translateX(8px)
								&.star-2
									transform scale(0.7) rotate(12deg) translateX(-8px)
					&.tuto-leave-to
						transform translateY(16px)
						opacity 0
					.goal
						line-height 40px
						overflow hidden
						text-overflow ellipsis
						white-space nowrap
						color #104544
					.avatar
						position absolute
						left -8px
						top -8px
						width 56px
						height 56px
						background url('../../assets/img/avatar-maud.svg') center center no-repeat
						background-size contain
					.checkbox
						position absolute
						left 12px
						top 4px
						width 32px
						height 32px
						border-radius 50%
						background #0F4444 center center no-repeat
						background-size 24px auto !important
						.star
							position absolute
							width 24px
							height 24px
							background center center no-repeat
							background-size contain
							&.star-1
								left -24px
								top -8px
								transform scale(0.8) rotate(-8deg)
							&.star-2
								left 28px
								top 28px
								transform rotate(12deg)

			&.expanded
				padding 16px 24px
				.group-names
					width 100%
					height 32px
					transform translateY(-8px)
					.group-name
						position absolute
						top 0
						width calc(46% - 16px)
						font-size 1.4rem
						line-height @height
						text-transform uppercase
						color #fff
						text-align center
						&.left
							left 16px
						&.right
							right 16px
				.main-bar
					background-color #5CA4A6
					flex center center
					height 40px
					width 100%
					border-radius 8px
					color #fff
					gap 32px
					z-index 1
					.dots
						flex center center
						gap 12px
						height 24px
						width 50%
						.dot
							width 20px
							height 20px
							border-radius 50%
							cursor pointer
							margin 0 64px 0 0
							&:before
								content ''
								absolute 2px
								border 2px solid #fff
								border-radius 50%
							&:after
								content ''
								position absolute
								left 32px
								top 9px
								height 2px
								width 52px
								background-color #fff
							&:last-child
								margin-right 0
								&:after
									display none
							&.checked
								background #fff url('../../assets/img/check-green.svg') center 3px no-repeat
								background-size 16px auto
								background-color #fff
								&:before
									display none
							&.active
								background-color #EF771C
								box-shadow 0 0 0 2px #fff
								&:before
									display none
								&.checked
									background-image url('../../assets/img/check-white.svg')
					.avatar-bubble
						position absolute
						left calc(50% - 32px)
						top calc(50% - 32px)
						width 64px
						height 64px
						background center center no-repeat
						background-size contain
						z-index 1
					.arrow
						position absolute
						width 40px
						height 40px
						top 0
						background center center no-repeat
						background-size contain
						cursor pointer
						&:hover
							transform scale(1.05)
						&:active
							transform scale(0.95)
						&.prev
							left -16px
						&.next
							right -16px
				.main-content
					width calc(100% - 32px)
					margin 0 auto
					border-radius 0 0 8px 8px
					background-color #fff
					color #3B3B3B
					overflow auto
					height (600px - 40px - 32px - 8px)
					.inner-content
						&.next-enter-active, &.prev-enter-active
							transition 0.15s easeOutQuart
						&.next-leave-active, &.prev-leave-active
							transition 0.15s easeInQuart

						&.next-enter-from, &.prev-leave-to
							transform translateX(64px)
							opacity 0
						&.prev-enter-from, &.next-leave-to
							transform translateX(-64px)
							opacity 0
						.title
							padding 24px
							margin 0 0 24px 0
							border-bottom 2px dashed #3A9E9F
							font-size 1.8rem
							font-weight 400
							color #3A9E9F
							text-align center
							text-transform uppercase
						.todos
							padding 0 32px
							.todo
								flex flex-start flex-start
								gap 16px
								padding 0 0 28px 0
								&:last-child
									padding-bottom 0
									&:before
										display none
								&:before
									content ''
									position absolute
									left 11px
									top 28px
									width 2px
									height calc(100% - 32px)
									background-color @color
								.checkbox
									width 24px
									height 24px
									border 2px solid @color
									border-radius 50%
									flex-shrink 0
									transform scale(0.5)
									background-color #0F4444
									transition 0.25s easeOutQuart
									&.checked
										background #0F4444 url('../../assets/img/check-white.svg') center 3px no-repeat
										background-size 16px auto
										transform scale(1)
								.text
									display block
									padding 2px 0 0 0

</style>
