<template>
	<div class="modal-section">
		<div class="bg"></div>
		<div class="content">
			
			<page :page="page" />
			
		</div>
		<div class="close" :title="$t('settings.close')" v-tap="close">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
		</div>
	</div>
</template>

<script>
import { useAppStore } from '@/store/app'
import Page from '@/components/notepad/page.vue'

export default {
	name: 'modal-section',
	data() {
		return {
			page: null
		}
	},
	props: {
		section: { default: null }
	},
	methods: {
		close() {
			useAppStore().setModalSection(null)
		}
	},
	created() {
		this.page = { ...this.section, pageIndex: 0, native: true, zIndex: 1 }
	},
	components: { Page }
}
</script>

<style lang="stylus" scoped>

#app > .low-perf
	.modal-section
		transition none
		.bg, .content
			transition none
			backdrop-filter none

#mobile
	.modal-section .content
		height 100%
		width 100%


.modal-section
	fixed 0
	flex center center
	z-index 100000
	&.modal-section-enter-active
		transition 0.2s easeOutQuart
		.bg
			transition 0.2s easeOutQuart
		.content
			transition 0.2s easeOutQuart
	&.modal-section-enter-from
		.bg
			opacity 0
		.content
			opacity 0
			transform scale(0)

	&.modal-section-leave-active
		transition 0.25s easeOutQuart
	&.modal-section-leave-to
		opacity 0

	.bg
		background-color alpha(#446364, 70%)
		absolute 0
		// backdrop-filter blur(8px)
		transition 0.2s easeOutQuart
	.content
		width round(361px * 1.5)
		height round(518px * 1.5)
		> .page
			absolute 0
			border-radius 8px
			&:after
				border-radius 8px
	.close
		position absolute
		right 32px
		top 32px
		width 64px
		height 64px
		background-color #fff
		color dark
		border-radius 50%
		box-shadow 0 0 8px 2px alpha(#000, 25%)
		flex center center
		cursor pointer
		transition 0.1s easeOutQuart
		&:hover
			transform scale(1.1)
		&:active
			transform scale(0.9)
		svg
			width 48px
			height 48px
			
@media (max-height: 850px)
	.modal-section .content
		width round(361px * 1.35)
		height round(518px * 1.35)
		
</style>
