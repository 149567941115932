<template>
	<button type="button" class="ui-round-button" :disabled="disabled">
		<span v-if="color" class="bg" :class="color"></span>
		<span v-if="icon" class="icon" v-bg="icon"></span>
		<span v-if="tooltip" class="tooltip" :class="tooltipPosition" v-html="tooltip"></span>
	</button>
</template>

<script>

export default {
	props: {
		size: { default: 64 },
		color: { default: 'white' },
		icon: { default: '' },
		disabled: { default: false },
		tooltip: { default: "" },
		tooltipPosition: { default: "" },
	},
	computed: {
		cssSize() {
			return this.size + 'px'
		}
	}
}
</script>

<style lang="stylus" scoped>

.ui-round-button
	width v-bind(cssSize)
	height v-bind(cssSize)
	background none
	border none
	cursor pointer
	border-radius 50%
	font inherit
	color #0F4444
	
	&[disabled]
		cursor not-allowed
		.bg
			opacity 0.25
		
	&:focus
		.tooltip
			display none
	
	&:hover
		&:not([disabled]) .bg
			filter brightness(1.2)
		.tooltip
			opacity 1
			visibility visible
			transform translate(-50%, 0)
			transition-delay 0.35s
	
	&:active:not([disabled])
		.bg
			filter brightness(0.8)
		
	.bg
		absolute 0
		border-radius 50%
		&.white
			background-color #DAE0E2
		&.yellow
			background-color #D6BC65
		&.red
			background-color #C17474
		&.green
			background-color #0F4444
	
	.icon
		absolute 0
		background center center no-repeat
		background-size 45% auto !important
		pointer-events none
	
	.tooltip
		position absolute
		left 50%
		bottom calc(100% + 12px)
		transform translate(-50%, 8px)
		transition 0.1s easeOutQuart
		width 180px
		padding 8px
		border-radius 4px
		background-color #111
		color #fff
		font-size 1.4rem
		pointer-events none
		opacity 0
		visibility hidden
		&:before
			content ''
			position absolute
			left calc(50% - 8px)
			top calc(100% - 1px)
			width 0
			height 0
			border-style solid
			border-width 8px 8px 0 8px
			border-color #111 transparent transparent transparent
		&.right
			left -100%
			&:before
				left 80%




</style>
