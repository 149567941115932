import { get, post } from '@/requests/request'


const formatData = (data) => {
	if (!data) return null
	if (!Array.isArray(data)) data = [ data ]
	return data?.map(d => ({ ...d,
		calendarTimelines: d.allCalendarTimelines?.map(t => ({ ...t, timeline: t?.clientTimelines[0], clientTimelines: null })),
		calendarEvents: d.allCalendarEvents?.map(e => ({ ...e, event: e?.clientEvents[0], clientEvents: null })),
		allCalendarTimelines: null,
		allCalendarEvents: null,
	}))
}
// QUERIES

export const getCalendarEvents = async (userId = null) => {
	if (userId) return formatData(await get('seeCalendar', { ownerId: userId, limit: 500 }))
	return formatData(await get('myCalendars', { limit: 500 }))
}

export const getCalendarTimelines = async () => {
	return await get('categoryIndex', { limit: 500 })
}

export const getTimeline = async ({ timelineId = null }) => {
	return await get('getTimeline', { timelineId })
}

// MUTATIONS

export const addToCalendar = async (input) => {
	return formatData(await post('addToCalendar', { input, limit: 500 }))
}

export const removeFromCalendar = async (input) => {
	return formatData(await post('removeFromCalendar', { input, limit: 500 }))
}

export const editFromCalendar = async (input) => {
	return formatData(await post('editFromCalendar', { input, limit: 500 }))
}

export const resetFromCalendar = async (input) => {
	return formatData(await post('resetFromCalendar', { input, limit: 500 }))
}
