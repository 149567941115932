<template>
	<div class="admin-box" :class="[ color, { disabled } ]">
		<div class="admin-box-content">
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		color: { default: 'light' },
		disabled: { default: false }
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.admin-box
	width 100%
	border-radius 8px
	border 2px solid #E7EEEE
	background-color alpha(#E7EEEE, 20%)

	&.green
		border 2px solid #0F4444
		background none
		::v-deep(.admin-box-content)
			> .title
				background-color #0F4444
				color #E7EEEE
			> .content
				color #0F4444
	
	&.disabled
		border-color alpha(#E7EEEE, 25%)
		.admin-box-content
			opacity 0.15
		&:after
			content 'COMING SOON'
			absolute 16px
			flex center center
			font-size 3.2rem
			color inherit
			transform rotate(-4deg)

	::v-deep(.admin-box-content)
		> .title
			padding 8px 16px
			border-radius 4px 4px 0 0
			background-color #E7EEEE
			flex center center
			color #0F4444
			font-size 1.8rem
			font-weight 500
			text-transform uppercase
		> .content
			padding 16px
			color #fff
			font-size 1.8rem
			font-weight 300
			flex center center column
			gap 8px
			.row
				width 100%
				flex center space-between
				flex-wrap wrap
				.label
					text-transform uppercase
					padding-right 16px
				.value
					white-space nowrap
					text-overflow ellipsis
					overflow hidden
					> a
						color inherit
						&:hover
							text-decoration none
					.models
						flex flex-start flex-start
						flex-wrap wrap
						gap 4px
						.model
							height 24px
							padding 2px 8px 0 8px
							border-radius 4px
							background-color #73AEB1
							flex center center
							color #fff
							text-transform uppercase
		> .actions
			border-top 2px solid #E7EEEE
			padding 8px
			flex center center
			gap 8px
			.separator
				height 24px
				width 2px
				margin 0 8px
				background-color #E7EEEE
				border-radius 2px
				

</style>