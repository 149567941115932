<template>
	<div v-if="currentTuto" class="tuto">

		<div v-if="currentStepSettings.background === 'pattern'" class="bg">
			<div class="main" v-bg="'notepad/main-bg.svg'"></div>
			<div class="line"></div>
			<div class="line"></div>
		</div>

		<div v-else v-show="!highlight" class="bg-flat"></div>

		<div v-show="highlight && highlightStyle" class="highlight">
			<div class="hole" :class="{ tappable: currentStepSettings.highlightTap }" :style="highlightStyle" v-tap="tapHighlight"></div>
		</div>

		<!-- Character not visible -->
		<transition name="hint" mode="out-in">
			<div v-if="!loadingNext && currentStepSettings.characterVisible === false && currentStep?.text" class="hint" :class="currentStepSettings.bubblePositionY" :key="'hint-' + currentRoute + '-' + index">
				<div class="text"><ui-text :text="currentStep.text" /></div>
			</div>
		</transition>

		<!-- Character visible -->
		<transition name="character" appear>
			<div v-if="!loadingNext && currentStepSettings.characterVisible && currentStep?.text" class="character" key="character">
				<transition name="bubble" mode="out-in" appear>
					<div v-if="currentStep" class="bubble" :key="currentRoute + '-' + index">
						<div class="text"><ui-text :text="currentStep.text" /></div>
					</div>
				</transition>
				<div class="illustration" v-bg="tuto?.details?.illustration"></div>
			</div>
		</transition>

		<!-- <transition name="answers" mode="out-in">
			<div v-if="currentStep && currentStep.answers" class="answers" :key="currentRoute + '-' + index">
				<div v-for="(answer, index) in currentStep.answers" class="answer" :key="index" v-tap="() => selectAnswer(answer)">{{ answer.text[lang] }}</div>
			</div>
		</transition> -->

		<div class="buttons">
			<!-- <div class="button skip" v-tap="skipTuto">{{ $t('tuto.skipButton') }}</div> -->
			<div v-if="!loadingNext && currentStepSettings.nextButton" class="button next" :class="currentStepSettings.nextButtonColor" v-tap="next">
				<span>{{ currentStepSettings.nextButton }}</span>
			</div>
		</div>

	</div>
</template>

<script>
import { mapActions } from 'pinia'
import { useOnboardingStore } from '@/store/onboarding'
import { wait } from '@affordancestudio/functions'
import { i18n } from '@/js/i18n'

export default {
	name: 'tuto',
	data() {
		return {
			loadingNext: false,
			currentRoute: 'default',
			index: 0,
			highlight: null,
			forceHighlightStyle: null,
		}
	},
	props: {
		tuto: { default: null }
	},
	computed: {
		currentTuto() {
			return this.tuto?.details
		},
		currentStep() {
			return this.index > -1 && this.currentTuto.routes[this.currentRoute].length > this.index ?
				{ ...this.currentTuto.routes[this.currentRoute][this.index], index: this.index } :
				null
		},
		currentStepSettings() {
			const defaultSettings = {
				background: 'opacity',
				characterVisible: true,
				nextButton: i18n.global.t('tuto.nextButton'),
				nextButtonColor: 'blue',
				highlight: null,
				highlightTap: null,
				delay: 0,
				bubblePositionY: 'middle'
			}
			return { ...defaultSettings, ...this.currentTuto.defaultSettings, ...this.currentStep.settings }
		},
		highlightStyle() {
			if (this.currentStepSettings?.highlight && this.highlight) {
				const border = 16
				return {
					left: (this.highlight.x - border) + 'px',
					top: (this.highlight.y - border) + 'px',
					width: (this.highlight.width + border * 2) + 'px',
					height: (this.highlight.height + border * 2) + 'px'
				}
			}
			return {}
		},
		isLastStep() {
			return this.index === this.currentTuto.routes[this.currentRoute].length - 1
		},
	},
	watch: {
		index: {
			handler() {
				if (this.currentStepSettings?.highlight) {
					const el = document.querySelector(this.currentStepSettings.highlight)
					if (el) this.highlight = el.getBoundingClientRect()
				} else
					this.highlight = null

				if (this.currentStepSettings?.autoTapOnHighlight) this.tapHighlight()
			},
			immediate: true
		}
	},
	methods: {
		...mapActions(useOnboardingStore, [ 'checkFirstCheckpointsAndDisplayNextGroup' ]),
		tapHighlight() {
			if (this.currentStepSettings.highlightTap) {
				const el = document.querySelector(this.currentStepSettings.highlightTap)
				if (el && el.hammer && el.hammer.handlers && el.hammer.handlers.tap) {
					el.hammer.handlers.tap[0]({ srcEvent: {} })
				}
				this.next()
			}
		},
		selectAnswer(answer) {
			if (answer.route) {
				this.currentRoute = answer.route
				this.index = 0
			} else if (answer.backToIndex !== undefined) {
				this.index = answer.backToIndex
			}
		},
		async next() {
			this.loadingNext = true

			if (this.currentStep.onNext) {
				if (this.currentStep.onNext.updateHighlightStyle) this.highlight = { ...this.highlight, ...this.currentStep.onNext.updateHighlightStyle }
				if (this.currentStep.onNext.action) await this[this.currentStep.onNext.action]()
				if (this.currentStep.onNext.delay) await wait(this.currentStep.onNext.delay)
			}

			if (this.isLastStep) {
				this.endTuto()
			} else {
				this.index++
			}

			this.loadingNext = false
		},
		endTuto() {
			this.$emit('end', this.tuto)
		},
		skipTuto() {
			this.endTuto()
		},


		// expandOnboardingComponent() {
		// 	this.isCollapsed = false
		// }
	},
}
</script>

<style lang="stylus" scoped>

#app > .low-perf
	.tuto
		.bg-flat
			backdrop-filter none
		.bg
			.main, .line
				animation none
		.highlight .hole, .hint, .answers, .buttons, .character, .character .bubble
			transition none

#tablet-vertical
	.tuto
		.character
			left -15%
		.answers
			width 310px
			left 45%
			bottom 200px
		.buttons
			left 32px
			right 32px
			bottom 32px

#mobile
	&.thin
		.tuto
			.buttons
				bottom 40px
	.tuto
		.character
			left -15%
			width 300px
			height 300px
			.bubble
				width 290px
				bottom calc(100% + 4px)
		.hint
			left calc(50% - 170px)
			max-width 340px
			&.top
				top 56px
			&.middle
				top 50%
				transform translateY(-50%)
			&.bottom
				bottom 80px
		.answers
			left 140px
			width 210px
			bottom 80px
			.answer
				line-height 1.2em
		.buttons
			bottom 16px
			left 16px
			right 16px
			flex flex-end flex-end
			.button
				height 40px
				font-size 1.6rem
				line-height @height
				white-space nowrap
				&.next
					padding-right 40px

#app .app-content.small-height
	.tuto
		.character
			bottom -20%


.tuto
	fixed 0
	z-index 100000
	&.intro
		background-color alpha(#B2D8D8, 75%)
	&.tuto-enter-active, &.tuto-leave-active
		transition 0.5s easeOutQuart
		.tuto-bg
			.line
				&:nth-child(1)
					transition 0.4s easeOutQuart
					transition-delay 0.1s
					animation none
				&:nth-child(2)
					transition 0.3s easeOutQuart
					transition-delay 0.2s
					animation none
		.buttons
			transition-delay 0.75s
	&.tuto-enter-from
		opacity 0
		.tuto-bg
			transform translateX(-48px)
			.line
				transform translateX(-48px)
				opacity 0
		.buttons
			opacity 0
			transform translateY(50%)
	&.tuto-leave-to
		opacity 0

	.bg-flat
		absolute 0
		background-color alpha(#1B2A24, 80%)
		// backdrop-filter blur(8px)
	.highlight
		absolute 0
		.hole
			position absolute
			box-shadow 0 0 0 9999px alpha(#1B2A24, 80%)
			transition 0.5s easeOutQuart
			border-radius 8px
			cursor default
			&.tappable
				cursor pointer

	.bg
		absolute 0
		background-color darken(#3C6161, 25%)
		absolute -20%
		left 30%
		right -150px
		transform rotate(10deg)
		.main
			absolute 0
			background-size 500px auto !important
			opacity 0.25
			animation bg-main 200s linear infinite
		.line
			position absolute
			top 0
			bottom 0
			perspective 1000px
			&:nth-child(2)
				left -32px
				width 24px
				animation bg-line (1s * 10) easeInOutSine infinite
				background-color #F5B122
			&:nth-child(3)
				left -64px
				width 12px
				animation bg-line (1.5s * 10) easeInOutSine infinite
				animation-delay 0.25s
				background-color #789388

	.hint
		position absolute
		left calc(50% - 200px)
		width 100%
		max-width 400px
		font-size 2rem
		line-height 1.2em
		background-color #fff
		border-radius 12px
		padding 24px
		transform-origin top left
		// border 3px solid #ccc
		box-shadow -3px 3px alpha(#000, 50%)
		user-select none
		cursor default
		&.top
			top 80px
		&.middle
			top 50%
			transform translateY(-50%)
			&.hint-enter-from
				transform translate3D(0, -40%, 0)
			&.hint-leave-to
				transform translate3D(0, -60%, 0)
		&.bottom
			bottom 180px
		&.hint-enter-active, &.hint-leave-active
			transition 0.35s easeOutBack
		&.hint-enter-from
			transform translate3D(0, 8px, 0)
			opacity 0
		&.hint-leave-to
			transform translate3D(0, -8px, 0)
			opacity 0
		.text
			height 100%
			text-align center
			color dark
			::v-deep(strong)
				color primary
				font-weight 400
			::v-deep(img)
				height 100px
				width auto
				max-width 100%
	.character
		position absolute
		left 10%
		bottom 0
		width 600px
		height 600px
		font-size 2rem
		line-height 1.2em
		&.character-enter-active, &.character-leave-active
			transition 0.25s easeOutQuart
		&.character-enter-from, &.character-leave-to
			transform translate(-24px, 27%)
			opacity 0

		.bubble
			position absolute
			bottom calc(100% - 16px)
			left 40%
			width 80%
			max-height 242px
			background-color #fff
			border-radius 12px
			padding 24px
			transform-origin top left
			box-shadow -3px 3px alpha(#000, 50%)
			user-select none
			cursor default
			// animation bubble 4s easeOutBack infinite
			// transform-origin 30%
			&:before
				content ''
				position absolute
				left 0
				bottom -32px
				height 64px
				width 100%
				background url(../assets/img/bubble-bottom.svg) bottom left 40% no-repeat
				background-size contain
			&.bubble-enter-active, &.bubble-leave-active
				transition 0.35s easeOutBack
			&.bubble-enter-from
				transform rotate(4deg) translate3D(-8px, 8px, 0)
				opacity 0
			&.bubble-leave-to
				transform rotate(-4deg) translate3D(8px, -8px, 0)
				opacity 0
			.text
				height 100%
				text-align center
				color dark
				::v-deep(strong)
					color primary
					font-weight 400
				::v-deep(img)
					display block
					margin 8px auto
					max-height 100px
					width auto
					max-width 100%
		.illustration
			flex flex-end center
			width 100%
			height 100%
			// background-color alpha(red, 50%)
			transform-origin bottom center
			user-select none
			&.flip
				transform none
			// &.observer_le_vivant, &.verifier_les_composants, &.ajuster_les_solutions
			// 	transform scale(1.2)

	.answers
		position absolute
		left calc(10% + 450px)
		bottom 250px
		width 400px
		transition 0.35s easeOutQuart
		&.answers-enter-active
			transition-delay 0.35s
		&.answers-enter-from
			transform translateY(24px)
			opacity 0
		&.answers-leave-to
			transform translateY(-24px)
			opacity 0
		.answer
			width 100%
			min-height 48px
			padding 12px 24px
			margin 0 0 16px 0
			flex center center
			box-shadow -2px 2px alpha(#000, 25%)
			background-color #8EBABB
			text-align center
			// line-height 1.1em
			color #fff
			border-radius 64px
			// font-weight 700
			font-size 2rem
			cursor pointer
			transition 0.1s easeOutQuart
			&:last-child
				margin 0
			&:hover
				transform translateY(-2px)
				box-shadow -2px 4px alpha(#000, 25%)
			&:active
				box-shadow none
				transform translateY(2px)
	.buttons
		position absolute
		left 64px
		right 64px
		bottom 64px
		transition 0.25s easeOutBack
		.button
			height 56px
			min-width 48px
			padding 0 24px
			margin 0 8px
			background-color red
			box-shadow inset 0 0 0 4px #fff
			border-radius 48px
			text-transform uppercase
			font-size 2rem
			line-height @height
			text-align center
			color #fff
			cursor pointer
			user-select none
			&:hover
				transform translateY(-1px)
			&:active
				transform translateY(2px)
			&.skip
				float left
				margin 0 16px 0 0
			&.next
				float right
				margin 0 0 0 16px
				padding-right 48px
				background url(../assets/img/chevron-right.svg) right 8px center no-repeat
				background-size auto 70%
				&.blue
					background-color #01053B
				&.yellow
					background-color #C9A04D



@keyframes bg-main
	0%
		background-position 0 0
	100%
		background-position 0 -5000px

@keyframes bg-line
	0%, 100%
		transform translateX(0)
	50%
		transform translateX(-50%)

@keyframes next-button
	0%
		transform scale(1)
	100%
		transform scale(1.1)



@media (max-width: 1040px)
	.tuto
		.character
			left 0
			transform translate(-10%, 0%)
		.answers
			left 40%


</style>
