<template>
	<div class="admin-state" :class="state">
		<span class="text">{{ $t('adminDashboard.states.' + state) }}</span>
		<div class="icon"></div>
	</div>
</template>

<script>
export default {
	props: {
		state: { default: '' }
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.admin-state
	width 100%
	height 28px
	padding 0 8px
	flex center space-between
	gap 8px
	background-color #999
	border-radius 4px
	// font-size 1.6rem
	color #fff
	text-transform uppercase
	&.enabled
		background-color #8BAE63
	&.disabled
		background-color #C17474
		.icon:after
			content ''
			position absolute
			left calc(50% - 1px)
			top 0
			height 100%
			width 2px
			background-color #fff
			transform rotate(45deg)
	.text
		transform translateY(1px)
	.icon
		width 16px
		height 16px
		border-radius 50%
		border 2px solid #fff
				

</style>