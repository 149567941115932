<template>
	<div v-if="page" class="page-section">

		<game-content-trivia v-if="isGameContentTrivia" :page="page" :main-section="mainSection" />
		<form-content-review v-else-if="isContentReview" :page="page" :main-section="mainSection" />
		
		<section-content v-else :page="page" />

	</div>
</template>

<script>
import SectionContent from '@/components/notepad/parts/section-content.vue'
import FormContentReview from '@/components/notepad/parts/form-content-review.vue'
import GameContentTrivia from '@/components/notepad/parts/game-content-trivia.vue'

export default {
	name: 'page-section',
	props: {
		page: { default: null },
		mainSection: { default: null }
	},
	computed: {
		isContentReview() {
			return this.page?.tags?.includes('content-review')
		},
		isGameContentTrivia() {
			return this.page?.tags?.includes('content-trivia')
		}
	},
	components: { SectionContent, FormContentReview, GameContentTrivia }
}
</script>

<style lang="stylus" scoped>

.page-section
	padding 16px
	
</style>
