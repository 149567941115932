<template>
	<div v-if="page" class="page-game-list-section">

		<div v-if="currentGroup" class="current-group">
			<span class="name">{{ currentGroup?.name ? currentGroup.name : "Group" }}</span>
			<span class="button" v-tap="() => setModalGroupSelectionVisible(true)">{{ $t('groupSelection.edit') }}</span>
		</div>
		<div v-else-if="hasNoGroup" class="no-group">
			<p><strong>{{ $t('groupSelection.noGroupTitle') }}</strong></p>
			<p>{{ $t('groupSelection.noGroupSentence1') }}</p>
			<p>{{ $t('groupSelection.noGroupSentence2') }}</p>
		</div>

		<div class="games">
			<div v-for="game in page.clientGames" :key="game.id" class="game" :class="{ disabled: hasNoGroup }" v-tap="() => startGame(game)">
				<div class="icon left" v-bg="getIcon(game)"></div>
				<div class="icon right" v-bg="getIcon(game)"></div>
				<p class="title"><ui-text :text="game.document?.title" /></p>
				<p class="description"><ui-text :text="game.document?.description" /></p>
				<div v-if="!isConnected && game.tags.includes('require-connected-user')" class="padlock" v-bg="'padlock.svg'"></div>
				<div v-if="loading === game.id" class="loader"><ui-loader /></div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useAppStore } from '@/store/app'
import { useOnboardingStore } from '@/store/onboarding'
import { useNotepadStore } from '@/store/notepad'
import { useFormMaintenanceStore } from '@/store/form-maintenance'
import { startGame, getFormDemo } from '@/requests/form-maintenance'
import { getMedia } from '@/requests/medias'

export default {
	data() {
		return {
			loading: null
		}
	},
	props: {
		page: { default: null }
	},
	computed: {
		...mapState(useAppStore, [ 'isConnected', 'user', 'currentUserGroupId' ]),
		hasNoGroup() {
			return this.user?.groups?.length === 0
		},
		currentGroup() {
			if (this.user?.groups?.length > 1 && this.currentUserGroupId) {
				return this.user?.groups.find(g => g.id === this.currentUserGroupId)
			}
			return null
		}
	},
	methods: {
		...mapActions(useAppStore, [ 'setModalGroupSelectionVisible' ]),
		...mapActions(useNotepadStore, [ 'addPage' ]),
		...mapActions(useFormMaintenanceStore, [ 'setProgression' ]),
		...mapActions(useOnboardingStore, [ 'startTuto' ]),
		getIcon(game) {
			return getMedia({ id: game.clientMedias.find(m => m.tags.includes('game-icon'))?.id })
		},
		async startGame(game) {
			if (!this.loading && !this.hasNoGroup) {
				this.loading = game.id
				if (this.isConnected) {
					const progression = await startGame({ game })
					if (progression) {
						this.setProgression({ progression })
						this.addPage({
							page: {
								id: game.id,
								type: 'form-maintenance',
								document: {
									title: game.document.title,
									subtitle: game.document.description,
								}
							}
						})
						this.startTuto({ slug: 'game_' + game.slug, force: true })
					}
				} else if (!game.tags.includes('require-connected-user')) {
					// Launch demo game
					const currentContent = await getFormDemo()
					const progression = { currentContent, isDemo: true }
					this.setProgression({ progression })
					this.addPage({
						page: {
							id: game.id,
							type: 'form-maintenance',
							document: {
								title: game.document.title,
								subtitle: game.document.description,
							}
						}
					})
				} else {
					useAppStore().$patch({ modalLockedContent: true })
				}
				this.loading = null
			}
		}
	}
}
</script>

<style lang="stylus" scoped>


.page-game-list-section
	padding 16px
	.current-group
		flex center space-between
		height 48px
		padding 0 8px 0 16px
		margin 0 0 16px 0
		background-color #5CA4A6
		text-transform uppercase
		color #fff
		font-weight 500
		user-select none
		.button
			flex center center
			background-color #1F9192
			border-radius 4px
			cursor pointer
			height 32px
			padding 0 16px
			&:hover
				background-color darken(@background-color, 10%)
			&:active
				background-color darken(@background-color, 15%)
				transform translateY(2px)
	.no-group
		margin 0 0 16px 0
		padding 16px
		background-color red
		color #fff
		p
			margin 0 0 4px 0
			strong
				display block
				font-size 1.6rem
				text-transform uppercase
				font-weight 500
	.games
		.game
			min-height 120px
			margin 0 0 16px 0
			background-color #3B5F5F
			padding 16px
			color #fff
			text-align center
			flex center center column
			cursor pointer
			user-select none
			overflow hidden
			&:hover, &:active
				background-color darken(#3B5F5F, 15%)
				.icon
					opacity 0.5
					transform scale(1.1) translateY(-50%)
			&.disabled
				opacity 0.5
				background-color @background-color
				cursor not-allowed
				.icon
					opacity 1
					transform translateY(-50%)
			.icon
				position absolute
				background center center no-repeat
				top 50%
				transform translateY(-50%)
				transition 0.25s easeOutBack
				&.left
					left -16px
					height 140px
					width 140px
				&.right
					right -80px
					height 240px
					width 240px
				::v-deep(svg)
					width 100%
					height 100%
			.title
				margin 0 0 8px 0
				text-transform uppercase
				font-size 2rem
			.padlock
				absolute 0
				background alpha(#3F6767, 90%) center center no-repeat
				background-size 48px !important
				cursor not-allowed
			.loader
				absolute 0
				flex center center
				background-color darken(#3B5F5F, 15%)
				cursor wait

</style>
