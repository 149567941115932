<template>
	<div class="modal-remove-schools-from-district">

		<div class="texts">
			<p>{{ $t('adminDashboard.modals.removeSchoolsFromDistrict.question') }} <strong>{{ modal.district?.profile?.district_name || "-" }}</strong>&nbsp;?</p>
		</div>

		<div class="items">
			<div v-for="item in items" :key="item.id" class="item">
				<p class="name" v-html="item?.profile?.school_name || '-'"></p>
				<p v-if="item?.email" class="description" v-html="item?.email"></p>
				<transition>
					<div v-if="dones.includes(item.id)" class="result done" v-bg="'check-white.svg'"></div>
					<div v-else-if="errors.includes(item.id)" class="result error" v-bg="'close.svg'"></div>
				</transition>
			</div>
		</div>

		<admin-form-results :items="items" :dones="dones" :errors="errors" />

		<div class="buttons">
			<ui-button color="dark-green" size="big" :block="true" v-tap="() => $emit('close')">{{ $t('adminDashboard.modals.global.close') }}</ui-button>
			<ui-button v-if="dones.length + errors.length !== items.length" color="red" size="big" :block="true" :loading="loading" :disabled="!submitable" v-tap="submit">{{ $t('adminDashboard.modals.removeSchoolsFromDistrict.submit') }}</ui-button>
		</div>
		
	</div>
</template>

<script>
import { mapActions } from 'pinia'
import { useAdminDashboardStore } from '@/store/admin-dashboard'
import { removeSchoolFromDistrict } from '@/requests/dashboard'
import AdminFormResults from '@/components/admin-dashboard/ui/admin-form-results.vue'

export default {
	data() {
		return {
			connectedUsers: [],
			q: '',
			loading: false,
			dones: [],
			errors: [],
		}
	},
	props: {
		modal: { default: null }
	},
	computed: {
		submitable() {
			return this.modal?.items?.length
		},
		items() {
			return this.modal?.items || []
		},
	},
	methods: {
		...mapActions(useAdminDashboardStore, [ 'logUpdate' ]),

		async submit() {
			if (this.submitable && !this.loading && this.items.length) {
				this.loading = true
				this.dones = []

				await Promise.all(this.items.map(async (item) => {
					if (await removeSchoolFromDistrict({ schoolId: item.id })) {
						this.dones.push(item.id)
					} else {
						this.errors.push(item.id)
					}
				}))
				
				this.logUpdate({ type: 'school' })

				this.loading = false
			}
		},
	},
	components: { AdminFormResults }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.modal-remove-schools-from-district
	text-align center
	.texts
		margin 0 0 32px 0
		> p
			margin 0 0 8px 0
			&:last-child
				margin-bottom 0
	.items
		flex flex-start flex-start column
		gap 8px
		margin 0 0 32px 0
		max-height 400px
		overflow auto
		border 2px solid darken(#D5DEDE, 10%)
		border-radius 8px
		padding 8px
		.item
			width 100%
			background-color #D5DEDE
			padding 16px
			border-radius 8px
			text-align left
			> .name
				font-weight 500
				text-transform uppercase
			> .description
				color #666
			.result
				position absolute
				right 0px
				top 0px
				width 32px
				height 32px
				border-radius 50%
				background #999 center center no-repeat
				box-shadow 0 0 0 4px #E7EEEE
				transition 0.15s easeInQuart
				&.done
					background-color #8BAE63
					background-size 24px !important
				&.error
					background-color #C17474
					background-size 16px !important
				&.v-enter-from
					transform scale(1.5)
					opacity 0

	.results
		flex center center
		gap 16px
		margin 0 0 32px 0
		.result
			flex center center column
			width 96px
			height 96px
			padding 8px 16px
			border-radius 50%
			background-color #999
			color #fff
			text-align center
			&.success
				background-color #8BAE63
			&.error
				background-color #C17474
			.label
				text-transform uppercase
				font-size 1.4rem
			.value
				font-size 2.4rem

	.buttons
		flex center center
		gap 16px

	.side-content
		position fixed
		left calc(50% - 648px)
		top 0
		bottom 0
		width 340px
		flex center center
		pointer-events none
		
		&.v-enter-active
			transition 0.25s easeOutBack 0.25s
		&.v-enter-from
			transform translateX(32px)
			opacity 0
		.popup
			width 100%
			min-height 200px
			max-height 600px
			border-radius 8px
			padding 24px 0 0 0
			background-color #E7EEEE
			pointer-events auto
			&:before
				content ''
				position absolute
				left 100%
				top calc(50% - 16px)
				width 0
				height 0
				border-style solid
				border-width 16px 0 16px 16px
				border-color transparent transparent transparent #E7EEEE
			.title
				height 40px
				padding 0 16px 16px 16px
				// text-align center
				flex center flex-start
				line-height 20px
				font-weight 500
				text-transform uppercase
				border-bottom 2px solid #D5DEDE
				color #D67474
			.users
				max-height (600px - 64px)
				// background-color red
				overflow auto
				padding 16px
				.form
					height 32px
					margin 0 0 16px 0
					.icon
						position absolute
						left 0
						top 0
						width 32px
						height 32px
						flex center center
						color #aaa
						svg
							width 20px
							height 20px
						&:has(+ :focus)
							color inherit
					.input
						height 32px
						width 100%
						background none
						border none
						padding 0 0 0 32px
						font inherit
						color inherit
						border 2px solid #D5DEDE
						border-radius 8px
						outline none
						&:focus
							border-color primary
						&::-webkit-input-placeholder
							color #aaa
						&::-moz-placeholder
							color #aaa
						&:-ms-input-placeholder
							color #aaa
						&:-moz-placeholder
							color #aaa
				.user
					display block
					width 100%
					margin 0 0 8px 0
					background-color #D5DEDE
					border-radius 8px
					padding 8px 16px
					color #555
					overflow-wrap anywhere
					&:last-child
						margin-bottom 0
					&.anonymes
						margin-top 32px
						&:before
							content ''
							position absolute
							left 0
							right 0
							bottom calc(100% + 15px)
							height 2px
							background-color #ccc
					.name, .email
						display block
					.email
						font-size 1.4rem
						color #777


</style>