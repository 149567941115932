<template>
	<div class="modal-logout-confirmation">
		<div class="bg" v-tap="close"></div>
		<div class="content">

			<div class="title">{{ $t('login.modalLogoutTitle') }}</div>

			<div class="buttons">
				<ui-button size="big" color="dark-green" v-tap="close">{{ $t('login.modalLogoutButtonCancel') }}</ui-button>
				<ui-button size="big" color="red" v-tap="logout">{{ $t('login.modalLogoutButtonSubmit') }}</ui-button>
			</div>

		</div>
	</div>
</template>

<script>
import { useAppStore } from '@/store/app'
import uiButton from '@/components/ui/ui-button.vue'

export default {
	components: { uiButton },
	name: 'modal-logout-confirmation',
	methods: {
		close() {
			this.$emit('close')
		},
		logout() {
			useAppStore().logout()
			this.$router.push({ name: 'home' })
			this.$emit('close')
		},
	},
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

#app > .low-perf
	.modal-logout-confirmation
		transition none
		.bg, .content
			transition none

#mobile
	.modal-logout-confirmation
		.content
			.buttons
				flex-direction column
				row-gap 16px
				.ui-button
					width 100%

.modal-logout-confirmation
	absolute 0
	flex center center
	height 100%
	padding 80px 16px
	transition 0.2s easeOutQuart
	z-index 20
	&.modal-enter-from, &.modal-leave-to
		.bg
			opacity 0
		.content
			transform translateY(-16px)
			opacity 0
	.bg
		absolute 0
		background-color alpha(#000, 50%)
		transition 0.2s easeOutQuart
	.content
		width 100%
		max-width 700px
		padding 32px
		border-radius 8px
		background-color #fff
		text-align center
		transition 0.2s easeOutQuart
		.title
			width 100%
			margin 0 0 32px 0
			text-transform uppercase
			font-size 2rem
			color #446E6E
		.buttons
			flex center center
			gap 32px
			.ui-button
				width 50%

</style>
