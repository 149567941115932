<template>
	<div v-if="page" class="page-main-section">

		<div v-if="potagerSelectionVisible" class="current-potager">
			<span class="name">{{ $t('potagerSelection.' + currentPotager.replace('.', '')) }}</span>
			<span class="button" v-tap="() => setModalPotagerSelectionVisible(true)">{{ $t('potagerSelection.edit') }}</span>
		</div>

		<form class="search" @submit.prevent="search">
			<input type="text" class="input" v-model="q" :placeholder="$t('notepad.search')" data-hj-allow>
			<div class="icon" v-bg="'notepad/search.svg'"></div>
		</form>

		<section-content v-if="!q" :page="page" />

		<div v-if="tree && tree.children" class="sections">
			<section-row
				v-for="section in children"
				:section="section"
				:parent="section.parent"
				:key="section.id"
				:isLink="section.depth > 1"
				:isSearch="q !== ''"
			/>
		</div>

	</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useAppStore } from '@/store/app'
import { useNotepadStore } from '@/store/notepad'
import { openNotepadBySection } from '@/requests/notepad'
import SectionRow from '@/components/notepad/parts/section-row'
import SectionContent from '@/components/notepad/parts/section-content'
import { debounce } from '@affordancestudio/functions'

export default {
	name: 'page-main-section',
	data() {
		return {
			searchText: '',
		}
	},
	props: {
		page: { default: null }
	},
	computed: {
		...mapState(useAppStore, [ 'isConnected', 'role', 'currentPotager', 'user' ]),
		...mapState(useNotepadStore, [ 'tree' ]),
		potagerSelectionVisible() {
			return this.$route.meta.sectionNames.includes('guide-du-potager') && this.currentPotager && this.user?.alternatives?.length > 1
		},
		q: {
			get() { return this.searchText },
			set: debounce(function(q) { this.searchText = q }, 250)
		},
		children() {
			if (this.q) {
				const q = this.q.toLowerCase()
				const children = []
				this.tree.children.forEach(c1 => {
					if (c1.document.title.toLowerCase().indexOf(q) > -1 && !this.isLocked(c1) && !this.isHidden(c1)) children.push({ ...c1, parent: null })
					if (c1.children && !this.isLocked(c1) && !this.isHidden(c1)) {
						c1.children.forEach(c2 => {
							if (c2.document.title.toLowerCase().indexOf(q) > -1 && !c2?.children?.length && !this.isLocked(c2) && !this.isHidden(c2)) children.push({ ...c2, parent: c1 })
							if (c2.children && !this.isLocked(c2) && !this.isHidden(c2)) {
								c2.children.forEach(c3 => {
									if (c3.document.title.toLowerCase().indexOf(q) > -1 && !this.isLocked(c3) && !this.isHidden(c3)) children.push({ ...c3, parent: c2 })
									if (c3.children && !this.isLocked(c3) && !this.isHidden(c3)) {
										c3.children.forEach(c4 => {
											if (c4.document.title.toLowerCase().indexOf(q) > -1 && !this.isLocked(c4) && !this.isHidden(c4)) children.push({ ...c4, parent: c3 })
										})
									}
								})
							}
						})
					}
				})
				return children
			}
			return this.tree.children
		}
	},
	watch: {
		async currentPotager(id) {
			if (id) {
				await openNotepadBySection({ sectionNames: this.$route.meta.sectionNames })
				// this.addPage({ page })
			}
		}
	},
	methods: {
		...mapActions(useAppStore, [ 'setModalPotagerSelectionVisible' ]),
		...mapActions(useNotepadStore, [ 'addPage' ]),
		isLocked(section) {
			if (this.isConnected && (this.role === 'vireo' || this.role === 'school')) return false
			if (section.tags.includes('require-teacher-user')) return !(this.isConnected && this.role !== 'student')
			if (section.tags.includes('require-student-user')) return !this.isConnected
			return false
		},
		isHidden(section) {
			return this.isConnected && section.tags.includes('require-no-user')
		}
	},
	components: { SectionRow, SectionContent }
}
</script>

<style lang="stylus" scoped>

#mobile
	.page-main-section .image
		max-height 150px

.page-main-section
	padding 16px

	.current-potager
		flex center space-between
		height 48px
		padding 0 8px 0 16px
		margin 0 0 16px 0
		background-color #5CA4A6
		text-transform uppercase
		color #fff
		font-weight 500
		user-select none
		.button
			flex center center
			background-color #1F9192
			border-radius 4px
			cursor pointer
			height 32px
			padding 0 16px
			&:hover
				background-color darken(@background-color, 10%)
			&:active
				background-color darken(@background-color, 15%)
				transform translateY(2px)

	.search
		margin 0 0 16px 0
		.input
			display block
			width 100%
			height 32px
			padding 0 16px 0 40px
			background #fff
			border 1px solid #ccc
			outline none
			border-radius 40px
			font inherit
			color inherit
			appearance none
			&:focus
				box-shadow 0 0 0 1px primary
				border-color primary
				& + .icon
					opacity 1
		.icon
			position absolute
			left 12px
			top 6px
			height 20px
			width 20px
			opacity 0.25
			background center center no-repeat

	.sections
		::v-deep(.no-children)
			color alpha(#fff, 25%)
			cursor not-allowed


</style>
