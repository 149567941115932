<template>
	<div v-if="section && isVisible" class="section-row" :class="{ active: groupsVisible, highlight: isHighlighted, orange: isOrange, blue: isBlue }">

		<div class="section-name" :class="{ 'is-link': isLink, loading, 'no-children': isDisabled, 'locked': isLocked }" v-tap="clickOnSection">
			<div class="section-title">
				<div v-if="isSearch && parent" class="section-title-parent">
					<ui-text :text="parent?.document?.title" /><br>
				</div>
				<span v-if="isSearch && parent" class="separator" v-bg="'arrow-right.svg'"></span>
				<ui-text class="section-title-current" :text="section?.document?.title" />
			</div>
			<span v-if="isLocked" class="icon" v-bg="'padlock.svg'"></span>
			<span v-else-if="isPdfSection" class="icon" v-bg="'doodle-file.svg'"></span>
			<div v-else-if="section?.children?.length && !isLink" class="icon arrow-down">
				<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
					<g transform="translate(-321 -304)">
						<path fill="currentColor" d="M-1186.824,210.926s5.487,5.662,7.176,7.988,1.688,1.8,4.644-2.008,4.763-5.456,4.824-6.5-.663-1.345-1.025-1.718-.823-.715-2.511,1.377-4.162,5.755-4.342,5.681a25.367,25.367,0,0,1-4.583-4.708c-1.328-2.018-2.354-2.991-3.611-1.641C-1187.3,210.525-1186.824,210.926-1186.824,210.926Z" transform="translate(1514.555 105.596)"/>
					</g>
				</svg>
			</div>
			<ui-loader v-if="loading" />
		</div>

		<div v-if="section?.children?.length" v-show="groupsVisible" class="groups">
			<div class="hide-button" v-tap="hideGroups">
				{{ $t('notepad.hide') }}
				<div class="icon">
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
						<g transform="translate(-321 -304)">
							<path fill="currentColor" d="M-1186.824,210.926s5.487,5.662,7.176,7.988,1.688,1.8,4.644-2.008,4.763-5.456,4.824-6.5-.663-1.345-1.025-1.718-.823-.715-2.511,1.377-4.162,5.755-4.342,5.681a25.367,25.367,0,0,1-4.583-4.708c-1.328-2.018-2.354-2.991-3.611-1.641C-1187.3,210.525-1186.824,210.926-1186.824,210.926Z" transform="translate(1514.555 105.596)"/>
						</g>
					</svg>
				</div>
			</div>
			<div v-if="[ 'vireo' ].includes(role)" class="copy-button" v-tap="copyExpandedGroupUrl">
				<template v-if="urlCopied">{{ $t('notepad.copied') }}</template>
				<template v-else>{{ $t('notepad.copy') }}</template>
			</div>
			<div v-for="(subsection, j) in section.children" class="group" :key="'b' + j">
				<div v-if="subsection.tags.includes('display_children')" class="group-name"><ui-text :text="subsection?.document?.title" /></div>
				<div v-if="subsection.tags.includes('display_children') && subsection.children" class="sections">
					<section-row
						v-for="(subsubsection, k) in subsection.children"
						:key="'c' + k"
						:section="subsubsection"
						:isLink="true"
					/>
				</div>
				<div v-if="!subsection.tags.includes('display_children')">
					<section-row
						:key="'d' + j"
						:section="subsection"
						:isLink="true"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useAppStore } from '@/store/app'
import { useNotepadStore } from '@/store/notepad'
import { useOnboardingStore } from '@/store/onboarding'
import { getSectionContent } from '@/requests/notepad'
import { openFile } from '@/requests/medias'
import { wait } from '@affordancestudio/functions'

export default {
	name: 'section-row',
	data() {
		return {
			groupsVisible: false,
			loading: false,
			urlCopied: false
		}
	},
	props: {
		section: { default: null },
		parent: { default: null },
		isLink: { default: false },
		isSearch: { default: false },
	},
	computed: {
		...mapState(useAppStore, [ 'lang', 'user', 'isConnected', 'role' ]),
		isVisible() {
			return !this.section?.tags.includes('hide-' + this.lang) && !this.section?.parent?.tags.includes('hide-' + this.lang)
		},
		isPdfSection() {
			return this.section?.tags.includes('display-pdf')
		},
		isDisabled() {
			if (this.isPdfSection) return false
			return (!this.section?.children?.length && (!this.isSearch || (this.isSearch && this.section.depth === 1)))
		},
		isLocked() {
			if (this.isConnected && (this.role === 'vireo' || this.role === 'school')) return false
			if (this.section.tags.includes('require-teacher-user')) return !(this.isConnected && this.role !== 'student')
			if (this.section.tags.includes('require-student-user')) return !this.isConnected
			return false
		},
		isHighlighted() {
			return this.section?.tags.includes('highlight')
		},
		isOrange() {
			return this.section?.tags.includes('orange')
		},
		isBlue() {
			return this.section?.tags.includes('blue')
		}
	},
	methods: {
		...mapActions(useOnboardingStore, [ 'completeCheckpoint' ]),
		...mapActions(useNotepadStore, [ 'setScrollTo' ]),
		hideGroups() {
			this.groupsVisible = false
			this.setScrollTo({ element: this.$el })
		},
		async copyExpandedGroupUrl() {
			const nativePageSlug = window.location.pathname.split('/')?.[1]
			if (nativePageSlug) {
				const url = document.location.origin + '/' + nativePageSlug + '/s/' + this.section.id
				await navigator.clipboard.writeText(url)
				this.urlCopied = true
				await wait(1000)
				this.urlCopied = false
			}
		},
		async clickOnSection() {

			if (!this.isLocked) {

				// Slugs: GP2_intro__video-introduction || GP3_Introduction__vid_intro
				if (this.section.id === '620d87ce51b1cc000a4bcee8' || this.section.id === '62fbd65e5f5e0a000a3e3e31') {
					this.completeCheckpoint({ slug: 'video_presentation' })
				}

				// Slugs: GP2_intro__ligne-du-temps || GP3_Introduction__lignes-du-temps
				else if (this.section.id === '620d8f2d51b1cc00094bce66' || this.section.id === '62fbd87f5f5e0a00093e3eb7') {
					this.completeCheckpoint({ slug: 'consultez_ligne_temps' })
				}

				// Slugs: GP2_reception-potager__procedure || GP3_reception-potager__assemblage-structure
				else if (this.section.id === '620d908651b1cc000a4bceec' || this.section.id === '62fbdb715f5e0a000a3e3e32') {
					this.completeCheckpoint({ slug: 'consultez_section_reception_potager' })
				}

				// Download pdf
				if (this.isPdfSection) {
					const windowReference = window.open()
					this.loading = true
					if (this.section?.clientFileIds?.length) {
						const response = await openFile({ id: this.section.clientFileIds[0] })
						if (response?.clientMedias?.length) {
							windowReference.location = response.clientMedias[0]?.url
						}
					}
					this.loading = false
				}

				// Déroulement de la section
				else if (this.section.children && !this.isLink) {
					this.groupsVisible = !this.groupsVisible
					this.setScrollTo({ element: this.$el })
				}

				else {
					if (!this.loading && !this.isDisabled) {
						this.loading = true

						// Ouverture du pdf dans un nouvel onglet
						if (this.section.type === 'file') {
							window.open(this.section.file)
						}

						// Ajout de la nouvelle page section sur le dessus du notepad
						else {
							let page = null
							let pageIndex = 0
							if (this.isSearch && this.section?.parent?.id) {
								page = await getSectionContent({ id: this.section.parent.id })
								pageIndex = page.children.findIndex(p => p.id === this.section.id)
							} else
								page = await getSectionContent({ id: this.section.id })
							if (page?.children?.length && this.isConnected) {
								page.children = page.children.filter(s => !s.tags.includes('require-no-user'))
							}
							useNotepadStore().addPage({ page, pageIndex })
						}

						this.loading = false
					}
				}
			} else
				useAppStore().$patch({ modalLockedContent: true })
				// useAppStore().addNotification({ status: 'info', title: this.$t('notifications.notificationUnauthorizedAccessTitle'), text: this.$t('notifications.notificationUnauthorizedAccessText') })
		}
	},
	mounted() {
		if (this.$route.params.isHashSection && this.$route.params.sectionId === this.section.id) {
			this.groupsVisible = true
			this.setScrollTo({ element: this.$el, smooth: false })
		}
	},
	created() {
		this.groupsVisible = this.isHighlighted

	}
}
</script>

<style lang="stylus" scoped>

.section-row
	margin 0 0 8px 0
	&.active
		margin 0 0 32px 0
		.section-name .icon.arrow-down
			transform rotate(180deg)
		.groups
			display block
	&.blue
		blue = #8EBABB
		.section-name
			background-color blue !important
			&.is-link
				background-color blue !important
			.icon.arrow-down
				color blue !important
		.groups
			border-color blue !important
			.hide-button, .copy-button
				background-color blue !important
				.icon
					color blue !important
			.group .group-name
				color blue !important
				border-color blue !important
	&.highlight, &.orange
		orange = #F3944D
		.section-name
			background-color orange !important
			&.is-link
				background-color orange !important
			.icon.arrow-down
				color orange !important
		.groups
			border-color orange !important
			.hide-button, .copy-button
				background-color orange !important
				.icon
					color orange !important
			.group .group-name
				color orange !important
				border-color orange !important

	.section-name
		background-color primary
		// min-height 40px
		padding 10px 48px 10px 16px
		line-height 1.25em
		flex center flex-start
		color #fff
		cursor pointer
		user-select none
		&.is-link
			background-color #80B6B7
			&:active, &.loading
				transform translateY(2px)
		&.disabled
			text-decoration line-through
			color alpha(#fff, 50%)
			cursor not-allowed
		&.locked
			color alpha(#fff, 25%)
			cursor not-allowed
		.section-title
			display block
			width 100%
			.section-title-parent
				font-weight 300
				font-style italic
				margin 0 0 4px 0
			.section-title-current
				text-transform uppercase
			.separator
				display inline-block
				width 12px
				height 12px
				margin 0 8px 0 0
		.icon
			position absolute
			right 8px
			top 8px
			width 24px
			height 24px
			background center center no-repeat
			background-size contain
			transition 0.15s easeOutQuart
			&.arrow-down
				color primary
				background-color #fff
				border-radius 50%
				flex center center
	.groups
		padding 16px
		border 2px solid primary
		.group
			// margin 0 0 32px 0
			&:last-child
				margin-bottom 16px
			.group-name
				margin 0 0 8px 0
				border-bottom 2px solid #A5D4D5
				color primary
				text-transform uppercase
			.section
				.section-name
					background-color #80B6B7
		.copy-button
			position absolute
			left 8px
			bottom -16px
			height 32px
			width 110px
			border-radius 32px
			font-size 1.4rem
			background-color primary
			line-height @height
			color #fff
			text-transform uppercase
			text-align center
			cursor pointer
		.hide-button
			position absolute
			right 8px
			bottom -16px
			height 32px
			padding 0 38px 0 16px
			border-radius 32px
			font-size 1.4rem
			background-color primary
			line-height @height
			color #fff
			text-transform uppercase
			cursor pointer
			.icon
				position absolute
				right 4px
				top 4px
				width 24px
				height 24px
				flex center center
				transform rotate(180deg)
				color primary
				background-color #fff
				border-radius 50%
	.ui-loader
		background-color alpha(#fff, 50%)

</style>
