<template>
	<div class="ui-text" v-html="formated_text" v-tap="tap"></div>
</template>

<script>
import { useAppStore } from '@/store/app'
import { useNotepadStore } from '@/store/notepad'
import { getSectionContent } from '@/requests/notepad'
import { getMediaUrl, openFile } from '@/requests/medias'

export default {
	name: 'ui-text',
	props: {
		text: { default: '' }
	},
	computed: {
		formated_text() {
			let html = this.text

			// Link markdown style
			let matches = html.match(/\[(.*?)\]\((.*?)\)/gm)
			if (matches) {
				matches.forEach((match) => {
					let m = (/\[(.*?)\]\((.*?)\)/gm).exec(match)
					if (m && m.length >= 3) {
						const link = m[2]
						html = html.replace(match, '<span class="internal-link" data-link="' + link + '">' + m[1] + '</span>')
					}

				})
			}

			// media links
			let matches2 = html.match(/<a.*?data-media="(.*?)".*?>(.*?)<\/a>/gm)
			if (matches2) {
				matches2.forEach((match) => {
					let m = (/<a.*?data-media="(.*?)".*?data-type="(.*?)".*?>(.*?)<\/a>/gm).exec(match)
					if (m && m.length >= 4) {
						html = html.replace(match, '<span class="internal-link" data-media="' + m[1] + '" data-type="' + m[2] + '">' + m[3] + '</span>')
					}
				})
			}

			// section links
			let matches3 = html.match(/<a.*?data-section="(.*?)".*?>(.*?)<\/a>/gm)
			if (matches3) {
				matches3.forEach((match) => {
					let m = (/<a.*?data-section="(.*?)".*?>(.*?)<\/a>/gm).exec(match)
					if (m && m.length >= 2) {
						html = html.replace(match, '<span class="internal-link" data-section="' + m[1] + '">' + m[2] + '</span>')
					}
				})
			}

			// File links
			let matches4 = html.match(/<span.*?data-file="(.*?)".*?>(.*?)<\/span>/gm)
			if (matches4) {
				matches4.forEach((match) => {
					let m = (/<span.*?data-file="(.*?)".*?>(.*?)<\/span>/gm).exec(match)
					if (m && m.length >= 2) {
						html = html.replace(match, '<span class="internal-link" data-file="' + m[1] + '">' + m[2] + '</span>')
					}
				})
			}

			return html
		}
	},
	methods: {
		async tap(e) {
			if (e.target.classList.contains('internal-link')) {
				e.srcEvent.stopped = true
				if (e.target.getAttribute('data-link')) {
					e.target.classList.add('loading')
					const parts = e.target.getAttribute('data-link')?.split(':')
					if (parts && parts.length >= 2) {
						if (parts[0] === 'section') {
							const store = useNotepadStore()
							const page = await getSectionContent({ id: parts[1] })
							store.addPage({ page, pageIndex: parts[2] ? parseInt(parts[2]) : 0 })
						}
						e.target.classList.remove('loading')
					}
				}
				if (e.target.getAttribute('data-media')) {
					e.target.classList.add('loading')
					const isPdf = e.target.getAttribute('data-type') === 'pdf'
					if (isPdf) {
						const windowReference = window.open()
						const src = await getMediaUrl({ id: e.target.getAttribute('data-media') })
						if (src) windowReference.location = src
					} else {
						const src = await getMediaUrl({ id: e.target.getAttribute('data-media') })
						if (src) useAppStore().setModal({ src })
					}

					e.target.classList.remove('loading')
				}
				if (e.target.getAttribute('data-file')) {
					e.target.classList.add('loading')
					const windowReference = window.open()
					const response = await openFile({ id: e.target.getAttribute('data-file') })
					if (response?.clientMedias?.length) {
						windowReference.location = response.clientMedias[0]?.url
					}
					e.target.classList.remove('loading')
				}
				if (e.target.getAttribute('data-section')) {
					e.target.classList.add('loading')
					const section = await getSectionContent({ id: e.target.getAttribute('data-section') })
					if (section) useAppStore().setModalSection(section)
					e.target.classList.remove('loading')
				}
			}
		}
	},
	mounted() {
		const anchors = this.$el.getElementsByTagName('a')
		for (let i = 0; i < anchors.length; i++) {
			anchors[i].setAttribute('target', '_blank');
		}
	}
}
</script>

<style lang="stylus" scoped>

.ui-text
	display inline
	// user-select text !important
	::v-deep(a)
		word-wrap break-word
		text-decoration underline
		&:hover
			text-decoration none
	::v-deep(.internal-link)
		cursor pointer
		text-decoration underline
		&:hover
			text-decoration none
		&.loading
			cursor wait
			opacity 0.5
			text-decoration none

</style>
