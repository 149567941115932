import { useAppStore } from '@/store/app'
import { useNotepadStore } from '@/store/notepad'
import { get, post, error } from '@/requests/request'
import { i18n } from '@/js/i18n'
import { engage } from '@/engage'

const wait = (ms) => {
	return new Promise(resolve => setTimeout(resolve, ms))
}


export const openNotepadBySection = async ({ sectionNames = [], sectionIds = [] }) => {
	const notepadStore = useNotepadStore()
	notepadStore.$patch({ pages: [], animation: 'starting' })

	let pages = []
	for (let i = 0; i < sectionIds.length; i++) {
		const page = await get('clientSectionDisplay', { id: sectionIds[i] })
		if (page?.document?.description) {
			page.tab = { name: 'tab-icon-' + page.slug, position: i, tooltip: page.document.description }
		}
		pages = [ ...pages, page ]
	}
	for (let i = 0; i < sectionNames.length; i++) {
		const page = (await getPages({ slug: sectionNames[i] }))[0]
		if (page?.document?.description) {
			page.tab = { name: 'tab-icon-' + page.slug, position: i, tooltip: page.document.description }
		}
		pages = [ ...pages, page ]
	}

	if (pages?.length && pages[0]) {
		const tree = (pages[0].tags?.includes('main-section')) ? await getTree({ id: pages[0].id }) : null
		const page = (!pages[0].tags?.includes('main-section') && !pages[0].tags?.includes('game-list-section')) ? await getSectionContent({ id: pages[0].id }) : null

		await wait(600)

		if (tree) notepadStore.setTree({ tree })
		if (page) notepadStore.addPage({ page, native: true })
		else notepadStore.setPages({ pages })
	}

	await wait(500)
	notepadStore.$patch({ animation: 'default' })
}


export const getTree = async ({ id }) => {
	return await get('clientSectionTree', { id })
}

export const getPages = async ({ slug }) => {
	return await get('clientSectionBy', { slug })
}

export const getSectionContent = async ({ id }) => {
	return await get('clientSectionDisplay', { id })
}

// export const getMediasBySection = async ({ id }) => {
// 	return (await get('clientSectionMediaDisplay', { id }))?.clientMedias
// }


export const initArchivesPagination = async ({ sectionId }) => {
	try {
		engage.paginate.init({
			name: 'getArchiveList',
			data: { sectionId },
			limit: 10,
			get
		})
		return await engage.paginate.first({ name: 'getArchiveList' })
	}
	catch (err) {
		error({ error: err })
	}
	return null
}

export const nextArchivesPagination = async () => {
	try {
		return await engage.paginate.next({ name: 'getArchiveList' })
	}
	catch (err) {
		error({ error: err })
	}
	return null
}


export const getArchiveDetail = async ({ sectionId, progressionId }) => {
	const response = await get('getArchiveDetail', { sectionId, ids: [ progressionId ] })
	if (response?.data?.length && response.data[0]?.archive?.length) {
		return response.data[0].archive
	}
	return []
}

export const getEntretienRoutineDetails = async () => {
	await wait(500)
	useAppStore().addNotification({ title: i18n.global.t('notifications.betaVersionTitle'), text: i18n.global.t('notifications.betaVersionText') })
	return null
	// return entretiensDeRoutineDetails

}


export const evaluate = async ({ elementId, elementType, like, dislike, comment }) => {
	return await post('evaluate', { elementId, elementType, like, dislike, comment })
}

export const submitEntretienForm = async () => {
	await wait(1000)
	return true
}