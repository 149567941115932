<template>
	<div class="settings">
		<div class="bg" v-tap="close"></div>

		<div v-if="device === 'mobile'" class="content">
			<div class="title">{{ $t('settings.lang') }}</div>
			<div class="lang-checkboxes">
				<div class="checkbox" :class="{ active: locale === 'fr' }" v-tap="() => locale = locale !== 'fr' ? 'fr' : null">
					<div class="box">
						<transition name="stamp">
							<div v-show="locale === 'fr'" class="stamp" v-bg="'stamp-fr.svg'"></div>
						</transition>
					</div>
					<div class="text">Français</div>
				</div>
				<div class="checkbox" :class="{ active: locale === 'en' }" v-tap="() => locale = locale !== 'en' ? 'en' : null">
					<div class="box">
						<transition name="stamp">
							<div v-show="locale === 'en'" class="stamp" v-bg="'stamp-en.svg'"></div>
						</transition>
					</div>
					<div class="text">English</div>
				</div>
			</div>
		</div>

		<div class="content">
			<div class="title">{{ $t('settings.title') }}</div>
			<div class="row">
				<ui-checkbox v-model="settings.lowPerf" :text="$t('settings.lowPerformanceMode')" />
			</div>
			<div class="footer">
				<span class="link" v-html="$t('settings.privacyPolicy', { link: pdfs[lang].privacyPolicy })"></span>
				<span>-</span>
				<span class="link" v-html="$t('settings.termsOfUse', { link: pdfs[lang].termeOfUse })"></span>
			</div>
		</div>
		<div v-if="device === 'mobile'" class="faq-button" v-tap="() => goto('faq')">FAQ</div>


		<div class="close" :title="$t('settings.close')" v-tap="close">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
		</div>

	</div>
</template>

<script>
import { mapState, mapWritableState } from 'pinia'
import { useAppStore } from '@/store/app'
import {localStorage, medias} from '@affordancestudio/engage-game'
import UiCheckbox from '@/components/ui/ui-checkbox.vue'

export default {
	name: 'settings',
	data() {
		return {
			locale: 'en',
		}
	},
	computed: {
		...mapState(useAppStore, [ 'pdfs' ]),
		...mapWritableState(useAppStore, [ 'lang', 'device', 'settings' ])
	},
	watch: {
		'settings.lowPerf'() {
			localStorage.setItem('vireo-settings', JSON.stringify(this.settings))
		},
		async locale(locale) {
			if (['en', 'fr'].includes(locale) && locale !== this.lang) {
				localStorage.setItem('Language', this.locale)
				await medias.changeLanguage()
				location.reload()
			}
		}
	},
	methods: {
		goto(name) {
			this.$router.push({ name })
			this.close()
		},
		close() {
			useAppStore().setSettingsVisible(false)
		}
	},
	created() {
		this.locale = this.lang
	},
	components: { UiCheckbox }
}
</script>

<style lang="stylus" scoped>

#app > .low-perf
	.settings
		transition none
		.bg, .content
			transition none
			backdrop-filter none

.settings
	fixed 0
	z-index 100000
	padding 64px 16px 16px 16px
	flex center center column
	gap 16px
	transition 0.2s easeOutQuart
	&.settings-enter-from, &.settings-leave-to
		.bg
			opacity 0
		.content, .faq-button
			opacity 0
			transform scale(0.95)
	.bg
		background-color alpha(#446364, 70%)
		absolute 0
		backdrop-filter blur(8px)
		transition 0.2s easeOutQuart
	.content
		width 100%
		max-width 400px
		padding 16px
		border-radius 8px
		background-color #fff
		flex center center column
		transition 0.2s easeOutBack

		.title
			width 100%
			margin 0 0 16px 0
			padding-bottom 8px
			border-bottom 1px solid #446E6E
			font-size 2.8rem
			text-transform uppercase
			font-weight 500
			color #446E6E
			text-align center

		.row
			width 100%
			padding 16px
			margin 0 0 16px 0
			border-radius 8px
			background-color #f5f5f5
			&:last-child
				margin-bottom 0

			.link-faq
				flex center flex-start
				gap 8px
				cursor pointer
				text-decoration underline
				&:hover
					text-decoration none
				svg
					width 16px
					height 16px

		.lang-checkboxes
			width 100%
			flex flex-start flex-start
			padding 0 16px
			text-align left
			.checkbox
				flex center flex-start
				cursor pointer
				width 50%
				&.active .box
					background-color #446E6E
				.box
					width 32px
					height 32px
					margin 0 8px 0 0
					border 1px solid #ccc
					flex-shrink 0
					border-radius 4px
					.stamp
						position absolute
						left 2px
						top 2px
						width (@width - 6px)
						height (@height - 6px)
						flex center center
						background center center no-repeat
						background-size 100%
						&.stamp-enter-active
							transition 0.25s easeOutQuart
						&.stamp-leave-active
							transition 0.25s easeOutQuart
						&.stamp-enter-from, &.stamp-leave-to
							transform scale(2)
							opacity 0
				.text
					text-transform uppercase
		.footer
			width 100%
			border-top 1px dashed #ccc
			padding 16px 0 4px 0
			flex center center
			gap 8px
			font-size 1.4rem
			color #999
			.link ::v-deep(a)
				color @color
				&:hover
					text-decoration none
	.faq-button
		width 100%
		max-width 400px
		height 48px
		border-radius 8px
		flex center center
		background-color #7BC392
		font-size 2.4rem
		text-transform uppercase
		color #fff
		cursor pointer
		transition 0.2s easeOutBack
		&:hover
			background-color darken(#7BC392, 15%)
		&:active
			background-color darken(#7BC392, 25%)
			transform translateY(2px)

	.close
		position absolute
		right 16px
		top 16px
		width 48px
		height 48px
		background-color #fff
		color dark
		border-radius 50%
		flex center center
		cursor pointer
		transition 0.1s easeOutQuart
		&:hover
			transform scale(1.1)
		&:active
			transform scale(0.9)
		svg
			width 32px
			height 32px

</style>
