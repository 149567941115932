<template>
	<div v-if="modal.slug" class="admin-modal" :class="modal.slug">
		<div class="bg" v-tap="close"></div>
		<div class="content" :class="{ 'has-tabs': tabs }">
			<div v-if="modalTitle" class="modal-title" v-html="modalTitle"></div>

			<component v-if="!isEmpty && !hasManyDifferentsItemsTypes" :is="'modal-' + modal.slug" :modal="modal" @close="close" />

			<div v-else-if="hasManyDifferentsItemsTypes" class="no-data">
				<p class="empty">{{ $t('adminDashboard.modals.global.errorDifferentItems1') }}<br><br>{{ $t('adminDashboard.modals.global.errorDifferentItems2') }}</p>
				<div class="buttons">
					<ui-button color="dark-green" size="big" v-tap="close">{{ $t('adminDashboard.close') }}</ui-button>
				</div>
			</div>

			<div v-else class="no-data">
				<p class="empty">{{ $tc('adminDashboard.modalTitle.' + modal.type, items.length || 0) }}</p>
				<div class="buttons">
					<ui-button color="dark-green" size="big" v-tap="close">{{ $t('adminDashboard.close') }}</ui-button>
				</div>
			</div>

			<div v-if="tabs" class="secondary-actions">
				<ui-round-button v-if="features[modal.type].includes('edit') && tabs.includes('edit')" :class="{ active: modal.slug === 'edit' }" v-tap="() => switchModal({ slug: 'edit', type: modal.type, item: items?.[0] || null })" :tooltip="$t('adminDashboard.tooltips.edit')" color="green" icon="admin/edit-white.svg" />
				<ui-round-button v-if="features[modal.type].includes('reset-password') && tabs.includes('reset-password')" :class="{ active: modal.slug === 'reset-password' }" v-tap="() => switchModal({ slug: 'reset-password', type: modal.type, items: items || [] })" :tooltip="$t('adminDashboard.tooltips.resetPassword')" color="green" icon="admin/send-white.svg" />
				<ui-round-button v-if="features[modal.type].includes('disable') && tabs.includes('disable')" :class="{ active: modal.slug === 'disable' }" v-tap="() => switchModal({ slug: 'disable', type: modal.type, items: items || [] })" :tooltip="$t('adminDashboard.tooltips.disable')" color="yellow" icon="admin/disable-green.svg" />
				<ui-round-button v-if="features[modal.type].includes('remove') && tabs.includes('remove')" :class="{ active: modal.slug === 'remove' }" v-tap="() => switchModal({ slug: 'remove', type: modal.type, items: items || [] })" :tooltip="$t('adminDashboard.tooltips.delete')" color="red" icon="admin/trash-green.svg" />
			</div>

		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useAdminDashboardStore } from '@/store/admin-dashboard'
import ModalEdit from '@/components/admin-dashboard/modals/modal-edit.vue'
import ModalAdd from '@/components/admin-dashboard/modals/modal-add.vue'
import ModalRemove from '@/components/admin-dashboard/modals/modal-remove.vue'
import ModalDisable from '@/components/admin-dashboard/modals/modal-disable.vue'
import ModalResetPassword from '@/components/admin-dashboard/modals/modal-reset-password.vue'
import ModalAddSchoolsToDistrict from '@/components/admin-dashboard/modals/modal-add-schools-to-district.vue'
import ModalAddTeachersToGroup from '@/components/admin-dashboard/modals/modal-add-teachers-to-group.vue'
import ModalRemoveTeachersFromGroup from '@/components/admin-dashboard/modals/modal-remove-teachers-from-group.vue'
import ModalRemoveSchoolsFromDistrict from '@/components/admin-dashboard/modals/modal-remove-schools-from-district.vue'
import ModalEditAlternatives from '@/components/admin-dashboard/modals/modal-edit-alternatives.vue'
import ModalEditEmail from '@/components/admin-dashboard/modals/modal-edit-email.vue'
import UiRoundButton from '@/components/ui/ui-round-button.vue'

export default {
	props: {
		modal: { default: null }
	},
	computed: {
		...mapState(useAdminDashboardStore, [ 'features' ]),
		isEmpty() {
			const v = {
				edit: !this.modal?.item,
				disable: this.items?.length === 0,
				remove: this.items?.length === 0,
				add: false,
			}
			return v[this.modal.slug]
		},
		// nbItems() {
		// 	return this.items?.length || this.modal?.item ? 1 : 0
		// },
		items() {
			if (this.modal.items?.length) return this.modal.items
			if (this.modal.item) return [ this.modal.item ]
			return []
		},

		hasManyDifferentsItemsTypes() {
			if (this.items.length > 1) {
				const keys = Object.keys(this.items[0])
				return this.items.find(item => {
					const itemKeys = Object.keys(item)
					return !(itemKeys.length === keys.length && itemKeys.findIndex(k => !keys.includes(k)) === -1)
				})
			}
			return false
		},

		modalTitle() {
			if (this.modal.type) {
				if (this.modal.slug === 'add') {
					return this.$t('adminDashboard.modalTitle.' + this.modal.slug) + ' ' + this.$tc('adminDashboard.modalTitle.' + this.modal.type, 1)
				}
				return this.$t('adminDashboard.modalTitle.' + this.modal.slug) + ' ' + this.$tc('adminDashboard.modalTitle.' + this.modal.type, this.items.length || 0)
			}
			return this.$t('adminDashboard.modalTitle.' + this.modal.slug)
		},

		tabs() {
			if ([ 'edit', 'reset-password' ].includes(this.modal.slug)) return null

			if ([ 'district', 'school', 'group', 'teacher', 'student' ].includes(this.modal.type) && [ 'edit', 'disable', 'remove', 'reset-password' ].includes(this.modal.slug)) {
				let tabs = {
					district: [ /*'edit', 'reset-password',*/ 'disable', 'remove' ],
					school: [ /*'edit', 'reset-password',*/ 'disable', 'remove' ],
					group: [ /*'edit',*/ 'remove' ],
					teacher: [ /*'edit', 'reset-password',*/ 'disable', 'remove' ],
					student: [ /*'reset-password',*/ 'disable' ]
				}[this.modal.type]

				if (this.items.length > 1) tabs = tabs.filter(t => t !== 'edit')
				
				if (tabs.length <= 1) return null
				return tabs
			}
			return null
		}
	},
	methods: {
		...mapActions(useAdminDashboardStore, [ 'replaceModal' ]),
		switchModal(newModal) {
			if (newModal.slug !== this.modal.slug) {
				this.replaceModal({ oldModal: this.modal, newModal })
			}
		},
		close() {
			this.$emit('close')
		}
	},
	components: { ModalEdit, ModalAdd, ModalRemove, ModalDisable, ModalResetPassword, ModalAddTeachersToGroup, ModalRemoveSchoolsFromDistrict, ModalAddSchoolsToDistrict, ModalRemoveTeachersFromGroup, ModalEditAlternatives, ModalEditEmail, UiRoundButton }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.admin-modal
	absolute 0
	flex center center
	
	&.edit-alternatives > .content
		max-width 700px
	&.remove-teachers-from-group > .content
		max-width 650px

	> .bg
		absolute 0
		background-color alpha(#000, 90%)
	> .content
		width calc(100% - 32px)
		max-width 550px
		background-color #E7EEEE
		padding 32px
		border-radius 16px
		&.has-tabs
			min-height 340px
		.modal-title
			display block
			padding 0 0 16px 0
			margin 0 0 32px 0
			border-bottom 2px solid #0F4444
			font-size 2.4rem
			line-height 1
			text-transform uppercase
			font-weight 500
			color #0F4444
			text-align center
		::v-deep(.form)
			flex center center column
			gap 24px
			> .row
				width 100%
				flex center center column
				gap 4px
				> .label
					display block
					width 100%
					font-size 1.4rem
					line-height 1
					text-transform uppercase
					color #667d7e
					font-weight 500
				> .input
					display block
					width 100%
					height 40px
					padding 0 16px
					border 2px solid #D5DEDE
					border-radius 4px
					background #fff
					outline none
					font inherit
					color #0F4444
					&:focus
						border-color #0F4444
					&[disabled]
						background-color #E7EEEE
						font-style italic
						cursor default
						color #667d7e
						
			> .buttons
				width 100%
				flex center center
				gap 16px
		> .no-data
			flex center center column
			gap 16px
			> .empty
				width 100%
				display block
				border 2px solid darken(#D5DEDE, 10%)
				border-radius 8px
				padding 32px 16px
				text-transform uppercase
				text-align center
				font-weight 500
				color #999
		::v-deep(.info)
			width 100%
			border-top 2px solid #ccc
			margin 16px 0 0 0
			padding 16px 0 0 0
			font-size 1.4rem
			color #666
			font-style italic
		> .secondary-actions
			position absolute
			left calc(100% + 0px)
			top 32px
			// transform translateY(-50%)
			flex flex-start flex-start column
			gap 8px
			::v-deep(.ui-round-button)
				border-radius 0 16px 16px 0
				transition transform 0.15s
				will-change transform
				.bg
					border-radius 0 15px 15px 0
				&:not(.active) .bg
					box-shadow inset 4px 0 2px alpha(#000, 25%)
				&.active
					transform translate3D(-8px, 0, 0)
					box-shadow 0 0 0 4px #E7EEEE
				&:hover .bg
					filter brightness(1.1)


</style>