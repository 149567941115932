<template>
	<form class="form form-edit" @submit.prevent="submit">

		<div v-for="field in fields" :key="field.name" class="row">
			<label class="label">{{ $t('adminDashboard.form.labels.' + field.name) }}</label>
			<input type="text" class="input" v-model="field.value" :placeholder="$t('adminDashboard.form.placeholders.' + field.name)">
		</div>

		<div class="buttons">
			<ui-button size="big" :block="true" color="dark-green" v-tap="() => $emit('close')">{{ $t('adminDashboard.form.buttons.close') }}</ui-button>
			<ui-button type="submit" size="big" :block="true" :disabled="!submitable" :loading="loading">{{ $t('adminDashboard.form.buttons.submit') }}</ui-button>
		</div>
	</form>
</template>

<script>
import { mapActions } from 'pinia'
import { useAppStore } from '@/store/app'
import { useAdminDashboardStore } from '@/store/admin-dashboard'
import { editDistrict, editSchool, editTeacher, editGroup } from '@/requests/dashboard'

export default {
	data() {
		return {
			loading: false,
			fields: []
		}
	},
	props: {
		type: { default: "", required: true },
		item: { default: null, required: true },
	},
	computed: {
		submitable() {
			return this.fields.filter(({ name, value }) => {
				if (!value) return false
				if (name === 'email') return value.match(/^([\w-.+]+@([\w-]+\.)+[\w-]{2,4})?$/)
				return true
			}).length === this.fields.length
		}
	},
	methods: {
		...mapActions(useAppStore, [ 'addNotification' ]),
		...mapActions(useAdminDashboardStore, [ 'logUpdate' ]),

		async submit() {
			if (this.submitable && !this.loading) {
				this.loading = true

				let response = null

				if (this.type === 'district') {
					response = await editDistrict({
						userId: this.item.id,
						profile: JSON.stringify({
							district_name: this.fields.find(f => f.name === 'name')?.value || ""
						})
					})
				}

				else if (this.type === 'school') {
					response = await editSchool({
						userId: this.item.id,
						profile: JSON.stringify({
							school_name: this.fields.find(f => f.name === 'name')?.value || ""
						})
					})
				}

				else if (this.type === 'teacher') {
					response = await editTeacher({
						userId: this.item.id,
						input: {
							firstName: this.fields.find(f => f.name === 'firstName')?.value || "",
							lastName: this.fields.find(f => f.name === 'lastName')?.value || "",
						}
					})
				}

				else if (this.type === 'group') {
					response = await editGroup({
						id: this.item.id,
						input: {
							name: this.fields.find(f => f.name === 'name')?.value || "",
						}
					})
				}

				if (response && !response?.code && !response?.messages) {
					this.addNotification({ status: 'success', title: this.$t('notifications.submitEditSuccessTitle'), text: this.$t('notifications.submitEditSuccessText') })
					this.logUpdate({ type: this.type })
					this.$emit('close')
				} else {
					this.addNotification({ status: 'error', title: this.$t('notifications.submitEditErrorTitle'), text: this.$t('notifications.submitEditErrorText') })
					console.error(response)
				}

				this.loading = false
			}
		},

		resetFields() {
			this.fields.forEach(f => f.value = '')
		}
	},
	mounted() {
		this.$el.querySelector('input')?.focus()
	},
	created() {
		if (this.item && this.type) {

			// FIELDS CONFIG BY TYPE
			const fields = {
				district: {
					name: this.item.profile?.district_name || "",
				},
				school: {
					name: this.item.profile?.school_name || this.item?.userName || "",
				},
				teacher: {
					firstName: this.item?.firstName || "",
					lastName: this.item?.lastName || "",
				},
				group: {
					name: this.item?.name || "",
				}
			}

			if (fields[this.type]) {
				this.fields = Object.keys(fields[this.type]).map(name => ({ name, value: fields[this.type][name] }))
			}

		}
	},
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'



</style>