<template>
	<span class="admin-onboarding" :class="[ onboarding, background ]">{{ onboarding ? $t('adminDashboard.onboarding.' + onboarding) : onboarding }}</span>
</template>

<script>
export default {
	props: {
		onboarding: { default: '' },
		background: { default: null }
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.admin-onboarding
	display inline-block
	font-size 1.4rem
	text-transform uppercase
	font-weight 500
	&.light
		padding 2px 8px
		border-radius 4px
		background-color #E7EEEE
	&.is_anonymous
		color darken(#C17474, 10%)
	&.is_validated
		color darken(#8BAE63, 25%)

</style>