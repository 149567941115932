import { get, post } from '@/requests/request'
import { engage } from '@/engage'
import { wait } from '@affordancestudio/functions'

export const sendTeacherInvitation = async ({ email, roles = [], invitorId = null }) => {
	return await post('sendInvitationEmail', { email, roles, invitorId })
}

export const resendTeacherInvitation = async ({ email }) => {
	return await post('resendInvitationEmail', { email })
}

export const getTeachersList = async ({ page = 1 }) => {
	engage.paginate.init({ name: 'invitedTeachers', get, limit: 8, page })
	return await engage.paginate.current({ name: 'invitedTeachers' })
}

export const getGroupsList = async ({ page = 1 }) => {
	engage.paginate.init({ name: 'myGroups', get, limit: 8, page })
	return await engage.paginate.current({ name: 'myGroups' })
}

export const getGroupAdmin = async ({ page = 1 }) => {
	engage.paginate.init({ name: 'groupDisplayAdmin', get, limit: 8, page })
	return await engage.paginate.current({ name: 'groupDisplayAdmin' })
}

export const getGroupStudent = async ({ page = 1 }) => {
	engage.paginate.init({ name: 'groupDisplayStudent', get, limit: 8, page })
	return await engage.paginate.current({ name: 'groupDisplayStudent' })
}

export const getSchoolsList = async ({ page = 1 }) => {
	engage.paginate.init({ name: 'invitedSchools', get, limit: 8, page })
	return await engage.paginate.current({ name: 'invitedSchools' })
}

export const createGroup = async (data) => {
	return await post('createGroup', data)
}

export const deleteGroup = async ({ id }) => {
	return await post('deleteGroup', { id })
}

export const addGroupAdmin = async (data) => {
	return await post('addGroupAdmin', data)
}

export const removeGroupAdmin = async (data) => {
	return await post('removeGroupAdmin', data)
}

export const getGroupStudents = async ({ id }) => {
	return await get('groupDisplayStudent', { id })
}

export const getGroupAdmins = async ({ id }) => {
	return await get('groupDisplayAdmin', { id })
}

export const resetMemberPassword = async ({ groupId, memberId }) => {
	return await post('resetMemberPassword', { groupId, memberId })
}

export const editName = async (data) => {
	return await post('editName', data)
}

export const disableSchool = async ({ id }) => {
	return await post('disabledSchool', { userId: id })
}

export const enableSchool = async ({ id }) => {
	return await post('enabledSchool', { userId: id })
}

export const addAlternative = async ({ userId, alternative }) => {
	return await post('addAlternative', { userId, alternative })
}

export const removeAlternative = async ({ userId, alternative }) => {
	return await post('removeAlternative', { userId, alternative })
}

export const getAllConnectedUsers = async ({ userId }) => {
	return await get('getUserCascade', { userId })
}

export const deleteUser = async ({ userId }) => {
	return await post('deleteUser', { userId })
}







export const disableUser = async ({ id }) => {
	return await post('disabledSchool', { userId: id })
}

export const enableUser = async ({ id }) => {
	return await post('enabledSchool', { userId: id })
}

export const editDistrict = async (data) => {
	return await post('editDistrict', data)
}

export const editSchool = async (data) => {
	return await post('editSchool', data)
}

export const editTeacher = async (data) => {
	return await post('editTeacher', data)
}

export const editGroup = async (data) => {
	return await post('editGroup', data)
}

export const resendInvitationEmail = async ({ email }) => {
	return await post('resendInvitationEmail', { email })
}

export const addSchoolToDistrict = async (data) => {
	return await post('addSchoolToDistrict', data)
}

export const removeSchoolFromDistrict = async (data) => {
	return await post('removeSchoolFromDistrict', data)
}

export const editEmail = async (data) => {
	return await post('changeEmail', data)
}
