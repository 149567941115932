<template>
	<div class="modal-request-reset-password">
		<div class="bg" v-tap="close"></div>
		<form class="content" @submit.prevent="submit">
			<div class="title">{{ $t('login.modalRequestResetPasswordTitle') }}</div>
			<div class="description">{{ $t('login.modalRequestResetPasswordDescription') }}</div>

			<input type="email" v-model="email" ref="input" class="input" :placeholder="$t('login.modalRequestResetPasswordInputPlaceholder')">

			<div class="buttons">
				<ui-button type="submit" size="big" color="orange" :loading="loading" :disabled="!isEmailValid">{{ $t('login.modalRequestResetPasswordSubmitButton') }}</ui-button>
			</div>

		</form>
		<div class="close" :title="$t('settings.close')" v-tap="close">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'pinia'
import { useAppStore } from '@/store/app'
import { requestPasswordReset } from '@/requests/login'

export default {
	name: 'modal-request-reset-password',
	data() {
		return {
			loading: false,
			email: ''
		}
	},
	computed: {
		isEmailValid() {
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return re.test(String(this.email).toLowerCase())
		}
	},
	methods: {
		...mapActions(useAppStore, [ 'addNotification' ]),
		async submit() {
			if (!this.loading) {
				this.loading = true
				if (this.isEmailValid) {
					const response = await requestPasswordReset({ email: this.email })
					if (!response || response?.error) {
						this.addNotification({ status: 'error', title: this.$t('login.modalRequestResetPasswordErrorTitle'), text: this.$t('login.modalRequestResetPasswordErrorText') })
					} else {
						this.addNotification({ status: 'success', title: this.$t('login.modalRequestResetPasswordSuccessTitle'), text: this.$t('login.modalRequestResetPasswordSuccessText') })
						this.close()
					}
				}
				this.loading = false
			}
		},
		close() {
			this.$emit('close')
		},
	},
	mounted() {
		this.$refs?.input?.focus()
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

#mobile, #tablet-vertical
	.modal-request-reset-password
		.content
			padding 24px
			.description, .input
				margin 0 0 24px 0


.modal-request-reset-password
	absolute 0
	flex center center
	height 100%
	padding 32px
	z-index 100000
	.bg
		absolute 0
		background-color alpha(#C58B62, 80%)
	.content
		width 100%
		max-width 400px
		padding 32px
		border-radius 8px
		background-color #fff
		.title
			flex center center
			color #4C6D6D
			text-transform uppercase
			margin 0 0 16px 0
			text-align center
			font-weight 500
			font-size 2rem
		.description
			margin 0 0 32px 0
		.input
			display block
			width 100%
			height 48px
			padding 0 16px
			margin 0 0 32px 0
			border 1px solid #ccc
			background #fff
			border-radius 4px
			font inherit
			font-size 1.8rem
			color dark
			outline none
			text-align left
			&.failed
				animation shake 0.25s linear infinite
				background-color lighten(red, 75%)
			&:focus
				border-color #4C6D6D
				box-shadow 0 0 0 1px #4C6D6D
		.buttons
			height 48px
			flex center center
			.ui-button
				min-width 200px
	.close
		position absolute
		right 32px
		top 32px
		width 48px
		height 48px
		background-color #fff
		color dark
		border-radius 50%
		box-shadow 0 0 8px 2px alpha(#000, 25%)
		flex center center
		cursor pointer
		transition 0.1s easeOutQuart
		&:hover
			transform scale(1.1)
		&:active
			transform scale(0.9)
		svg
			width 32px
			height 32px
</style>
