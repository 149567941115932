<template>
	<div class="form-maintenance-audio-player">

		<div class="box">
			<div class="box-title">{{ $t('formMaintenance.audioPlayer.guidedMeditation') }}</div>
			<div v-if="audio.url" class="box-content" key="1">
				<audio ref="audio" :current-time.prop="audio.currentTime" @load="loaded">
					<source :src="audio.url" type="audio/mpeg">
				</audio>
				<div class="audio">
					<div class="button" v-tap="toggleAudio">
						<svg v-if="audio.playing" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" /></svg>
						<svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" /></svg>
					</div>
					<div class="bar">
						<div class="elapsed-time" :style="{ transform: `translateX(${progress}%)` }"></div>
						<input class="input" type="range" min="0" :max="audio.duration" v-model="audio.currentTime" />
						<div v-show="progress > 1 && progress < 99" class="border"></div>
					</div>
					<div class="current-time">{{ format(audio.currentTime) }}</div>
				</div>
			</div>
			<div v-else class="box-content" key="2"></div>
		</div>
		<div class="separator">{{ $t('formMaintenance.audioPlayer.or') }}</div>
		<div class="box">
			<div class="box-title">{{ $t('formMaintenance.audioPlayer.timer') }}</div>
			<div class="box-content">
				<div class="timer">
					<div class="button" v-tap="toggleTimer">
						<svg v-if="timer.interval" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" /></svg>
						<svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" /></svg>
					</div>
					<div class="current-time">{{ format(timer.currentTime) }}</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { getMediaUrl } from '@/requests/medias'

let audio = null

export default {
	name: 'form-maintenance-audio-player',
	data() {
		return {
			audio: {
				url: '',
				playing: false,
				duration: 0,
				currentTime: 0,
			},
			timer: {
				interval: null,
				currentTime: 300
			}
		}
	},
	props: {
		subElement: { default: null },
	},
	computed: {
		progress() {
			return this.audio.currentTime * 100 / this.audio.duration
		}
	},
	watch: {
		'audio.currentTime'(currentTime) {
			const diff = Math.abs(currentTime - audio.currentTime)
			if (diff > 0.5) audio.currentTime = currentTime
		}
	},
	methods: {
		async initAudioUrl() {
			if (this.subElement.entry?.clientMedias?.length) {
				this.audio.url = await getMediaUrl({ id: this.subElement.entry.clientMedias[0]?.id })
			}
			return null
		},
		format(seconds) {
			const format = val => `0${Math.floor(val)}`.slice(-2)
			const minutes = (seconds % 3600) / 60
			return [ minutes, seconds % 60 ].map(format).join(":")
		},
		toggleAudio() {
			if (this.$refs.audio.paused) {
				this.$refs.audio.play()
				this.audio.playing = true
			} else {
				this.$refs.audio.pause()
				this.audio.playing = false
			}
		},
		initSlider() {
			this.audio.duration = Math.round(audio.duration)
		},
		updateSlider() {
			this.audio.currentTime = Math.round(audio.currentTime)
		},

		toggleTimer() {
			if (!this.timer.interval) {
				this.timer.interval = setInterval(() => {
					this.timer.currentTime--
				}, 1000)
			} else {
				clearInterval(this.timer.interval)
				this.timer.interval = null
			}
		},
	},
	async mounted() {
		await this.initAudioUrl()
		if (this.$refs.audio) {
			audio = this.$refs.audio
			audio.addEventListener('loadedmetadata', this.initSlider)
			audio.addEventListener("timeupdate", this.updateSlider)
		}
	},
	beforeUnmount() {
		if (this.$refs.audio) {
			audio.removeEventListener('loadedmetadata', this.initSlider)
			audio.removeEventListener("timeupdate", this.updateSlider)
		}
		if (this.timer.interval) {
			clearInterval(this.timer.interval)
		}
	}
}
</script>

<style lang="stylus" scoped>
@import url('https://fonts.googleapis.com/css2?family=Kelly+Slab&display=swap');

#mobile
	.form-maintenance-audio-player
		flex-direction column
		.box, .separator
			width 100%
		.separator
			min-height 0

.form-maintenance-audio-player
	flex center center
	width 100%
	margin 0 0 16px 0
	gap 8px
	user-select none
	.box
		width 50%
		padding 0 16px
		background-color #F6F6F6
		border-radius 8px
		color #90A79B
		.box-title
			height 32px
			flex center flex-start
			border-bottom 1px solid #D6DCD9
			white-space nowrap
		.box-content
			height 48px
			flex center center
	.separator
		flex center center
		flex-shrink 0
		padding 0 8px
		min-height 48px
		text-align center

	.audio
		flex center center
		width 100%
		gap 8px
		.bar
			background-color #fff
			height 12px
			width 100%
			border-radius 40px
			overflow hidden
			.elapsed-time
				position absolute
				top 2px
				right calc(100% - 2px)
				width calc(100% - 4px)
				background-color #FBBF53
				border-radius 40px 0 0 40px
				// min-width 8px
				// max-width calc(100% - 10px)
				height (@height - 4px)
			.input
				position absolute
				top 0
				left 0
				width 100%
				height @height
				background-color blue
				-webkit-appearance none
				appearance none
				border 1px solid #CED8D3
				border-radius 40px
				background none
			.input::-webkit-slider-thumb
				-webkit-appearance none
				appearance none
				width (@height - 4px)
				height (@height - 4px)
				border-radius 50%
				background #EF771C
				// opacity 0.5
			.input::-moz-range-thumb
				appearance none
				width (@height - 4px)
				height (@height - 4px)
				border none
				border-radius 50%
				background #EF771C
				outline none
			.border
				absolute 1px
				border 1px solid #fff
				border-radius 40px
				pointer-events none
		.current-time
			flex-shrink 0
			width 48px
			text-align center
	.timer
		flex center space-between
		width 100%
		gap 8px
		.current-time
			display inline-block
			width 68px
			text-align left
			font-family 'Kelly Slab'
			font-size 3rem
			white-space nowrap
			// background-color red

.button
	width 24px
	height 24px
	flex-shrink 0
	background-color #fff
	border-radius 50%
	flex center center
	color #A1B2A9
	cursor pointer
	&:hover
		color darken(@color, 25%)
	svg
		absolute -4px
		width calc(100% + 8px)
		height calc(100% + 8px)
	
</style>
